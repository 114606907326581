import React from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { date, Loader } from 'legacy';
import { Button, Col, Form, Input, Row, Space, Typography, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { notifications } from 'components/notification';

const { TextArea } = Input;
const { Title } = Typography;

const IMAGES_EXTENSION = ['png', 'jpg', 'jpeg'];

const BookingsRemarks = ({ remarks, loading, update }) => {
    const { refCode } = useParams();
    const [form] = Form.useForm();

    const getIsValidFile = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            notifications('File must be smaller than 20MB!', 'failed');
        }
        return isLt2M;
    };

    const getIsDisabledButton = (getFieldValue) => {
        const files = getFieldValue('files')?.fileList || [];
        const isHasErrorStatuses = files.find((file) => file.status !== 'done');

        return (!files?.length && !getFieldValue('text')) || isHasErrorStatuses;
    };

    const onSubmit = ({ text, files }) => {
        const formDataBody = new FormData();

        if (files?.fileList) {
            files.fileList.forEach((file) => formDataBody.append('files', file.originFileObj));
        } else {
            formDataBody.append('files', null);
        }

        formDataBody.append('text', text || '');

        API.post({
            url: apiMethods.bookingRemarks(refCode),
            formDataBody,
            success: () => {
                notifications('Remark saved!', 'success');
                form.resetFields();
                update();
            },
        });
    };

    const download = (file) => {
        API.get({
            fullUrl: file.url,
            response: (res) => {
                res.blob().then((blobby) => {
                    let anchor = document.createElement('a');
                    document.body.appendChild(anchor);

                    let objectUrl = window.URL.createObjectURL(blobby);
                    anchor.href = objectUrl;
                    anchor.download = file.name;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                });
            },
        });
    };

    const previewFile = (file) => {
        const fileName = file.name.split('.');
        const extension = fileName?.[fileName.length - 1];

        if (IMAGES_EXTENSION.includes(extension)) {
            let anchor = document.createElement('a');
            document.body.appendChild(anchor);

            anchor.href = file.url;
            anchor.target = '_blank';
            anchor.click();
        } else download(file);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Row size="large" gutter={30}>
            {remarks?.length < 15 && (
                <Col span={12}>
                    <Form form={form} layout="vertical" onFinish={onSubmit}>
                        <Form.Item name="text">
                            <TextArea
                                showCount
                                maxLength={500}
                                placeholder="Remark Descriprion"
                                style={{ height: 160, width: '100%' }}
                            />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="files"
                                    rules={[
                                        {
                                            validator(_, value) {
                                                if (value?.fileList?.length > 5) {
                                                    return Promise.reject('You cannot add more than 5 files');
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Upload
                                        action={() => null}
                                        listType="picture"
                                        accept=".jpg,.jpeg,.png,.txt,.csv,.xls,.xlsx,.doc,.docx"
                                        name="files"
                                        multiple
                                        customRequest={({ onSuccess, onError, file }) => {
                                            if (!getIsValidFile(file)) {
                                                return onError();
                                            }
                                            onSuccess();
                                        }}
                                    >
                                        <Space>
                                            <Button
                                                disabled={getIsDisabledButton(getFieldValue)}
                                                htmlType="submit"
                                                type="primary"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                Add Remark
                                            </Button>

                                            <Button
                                                disabled={getFieldValue('files')?.fileList?.length > 5}
                                                icon={<UploadOutlined />}
                                            >
                                                Click to Upload Files
                                            </Button>
                                        </Space>
                                    </Upload>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Form>
                </Col>
            )}

            <Col span={12}>
                <Title level={4}>Remarks ({remarks?.length || 0}):</Title>

                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {remarks.map(({ text, administrator, created, files }, index) => (
                        <div style={{ width: '100%' }} key={index}>
                            <span>
                                <span className="link">Admin: {administrator}</span>{' '}
                                <span style={{ color: '#888' }}>{date.format.e(created)}</span>
                            </span>
                            <p style={{ marginBottom: 0, wordWrap: 'break-word' }}>{text}</p>

                            <Upload
                                listType="picture"
                                fileList={files}
                                openFileDialogOnClick={false}
                                showUploadList={{
                                    showRemoveIcon: false,
                                    showDownloadIcon: true,
                                    downloadIcon: <DownloadOutlined />,
                                }}
                                onDownload={download}
                                onPreview={previewFile}
                            />
                        </div>
                    ))}
                </Space>
            </Col>
        </Row>
    );
};

export default BookingsRemarks;
