import React from 'react';
import { date } from 'legacy';
import { Typography, Timeline, Space } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const convertCurrenciesToView = (currencies) => (currencies?.length ? currencies.join(', ') : 'All');

const convertTypeToView = (isExcluded) => (isExcluded ? 'Except' : 'Include');

const changedFields = {
    IsExcluded: ({ Before, Current }) =>
        `Currencies Type from ${convertTypeToView(Before)} to ${convertTypeToView(Current)}`,

    ConversionBuffer: ({ Before, Current }) => `Conversion Buffer from ${Before || 0}% to ${Current || 0}%`,

    ConvertedCurrencies: ({ Before, Current }) =>
        `Active Currencies from ${convertCurrenciesToView(Before)} to ${convertCurrenciesToView(Current)}`,
};

const AccountsHistory = ({ history }) => {
    if (!history) return null;

    return (
        <Space direction="vertical" size="large" style={{ marginTop: 60 }}>
            <Title level={4}>History of Active VCC Accounts</Title>

            <Timeline>
                {history?.map((item, index) => (
                    <Timeline.Item key={index} color={index ? 'gray' : 'green'}>
                        <b>{date.format.a(item.created)}</b>{' '}
                        <span style={{ color: '#888', marginRight: 3 }}>
                            at {date.format.time(item.created)}.
                        </span>
                        <b>
                            {item.type} {item.accountNumber}
                        </b>{' '}
                        {Object.entries(item.changes || {})
                            .map(([field, changes]) => changedFields?.[field]?.(changes))
                            .join(', ')}
                        {!!item.administratorName && (
                            <span>
                                {' '}
                                by{' '}
                                <Link
                                    target="_blank"
                                    className="link"
                                    to={`/administrators/${item.administratorId}/details`}
                                >
                                    Admin: {item.administratorName}
                                </Link>
                            </span>
                        )}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Space>
    );
};

export default AccountsHistory;
