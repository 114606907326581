import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';

const SuppliersPopover = ({ id }) => {
    const [suppliers, setSuppliers] = useState(null);

    useEffect(() => {
        API.get({
            shimabara: apiMethods.suppliersByHtId(id),
            success: setSuppliers,
        });
    }, []);

    if (!suppliers) {
        return 'Loading...';
    }

    if (!suppliers.length) {
        return 'No Suppliers';
    }

    return suppliers.sort((a, b) => a.localeCompare(b)).map((item) => <p style={{ margin: 0 }}>{item}</p>);
};

export default SuppliersPopover;
