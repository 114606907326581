import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ConversionFeesWrap from './conversion-fees-wrap';
import AccountPage from './accounts/account-page';

const ConversionFeesPage = () => (
    <Routes>
        <Route path="/*" element={<ConversionFeesWrap />} />
        <Route path="/accounts/:id" element={<AccountPage />} />
    </Routes>
);

export default ConversionFeesPage;
