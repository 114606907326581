import React, { useEffect, useState } from 'react';
import { useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { serializeRequest } from './serializer';
import AgencySearchForm from './agency-search-form';
import TablePages from 'components/table-pages';
import { columns } from '../agency-parts/agencies-table-settings';
import usePages from 'components/use-pages';

const AgenciesList = ({ agencySearchForm }) => {
    useTitle('Agencies');

    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    useEffect(() => {
        agencySearchForm.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <AgencySearchForm agencySearchForm={agencySearchForm} onSubmit={onSubmit} />
            <TablePages
                columns={columns}
                formValues={formValues}
                route={{ url: apiMethods.agencies }}
                serializeRequest={serializeRequest}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default AgenciesList;
