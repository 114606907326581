import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const optionsGenerator = (list) =>
    list
        .filter((item) => item.id !== 1)
        .map((item) => ({
            value: item.id,
            label: item.name,
        }));

const MarketSelector = (props) => {
    const { options, loading } = useSelector({ url: apiMethods.markets() }, optionsGenerator);

    return <EntitySelector placeholder="Any Supplier" {...props} options={options} loading={loading} />;
};

export default MarketSelector;
