const apiShimabaraMethods = {
    localities: (searchString) => `/localities/search-with-include-exclude-filters?query=${searchString}`,
    accommodations: (searchString) =>
        `/accommodations/search-with-include-exclude-filters?query=${searchString}`,

    activateAccommodation: (accommodationHtId, reason) =>
        `/admin/accommodations/${accommodationHtId}/activate?reason=${reason}`,
    deactivateAccommodation: (accommodationHtId, reason) =>
        `/admin/accommodations/${accommodationHtId}/deactivate?reason=${reason}`,

    destinations: (countryCode, searchQueryName = '') =>
        `/admin/locations/countries/${countryCode}/destinations?searchName=${searchQueryName}`,
    localitiesSearch: (countryCode, destinationId = '', searchQueryName = '') =>
        `/admin/locations/countries/${countryCode}/localities-with-merge-info?destinationId=${destinationId}&searchName=${searchQueryName}`,
    mergeLocalities: '/admin/locations/localities/process-merge',
};

export default apiShimabaraMethods;
