import React, { useMemo } from 'react';
import { Descriptions } from 'antd';
import moment from 'moment';
import { date } from 'legacy';

const SPECIAL_PROMOTION_NAME = 'Special Promo';
const PROMOTION_NAME = 'Promotion';

const Allotments = ({ room, contract, booking, isExpanded }) => {
    const { remarks } = room;
    let deadline = room.deadline || room.deadlineDetails;

    // temporary solution, todo: remove this logic when backed move promotion from remarks to new field
    const remarksData = useMemo(() => {
        const promotions = [];
        const allRemarks = [];

        remarks?.forEach((item) => {
            const isPromo =
                item?.key?.includes(PROMOTION_NAME) || item?.key?.includes(SPECIAL_PROMOTION_NAME);
            if (isPromo) {
                const promoName = item?.key?.split(':');
                const isSpecialPromo = promoName?.[0] === SPECIAL_PROMOTION_NAME;
                promotions.push({
                    ...item,
                    type: isSpecialPromo ? SPECIAL_PROMOTION_NAME : PROMOTION_NAME,
                    ...(isSpecialPromo && { name: promoName?.[1] }),
                });
                return;
            }
            allRemarks.push(item);
        });

        return { remarks: allRemarks, promotions };
    }, [remarks]);

    return (
        <>
            <Descriptions column={1} labelStyle={{ width: 180 }}>
                <Descriptions.Item label="Deadline">
                    {contract.isNonRefundable ? (
                        <div>
                            <strong>Non Refundable</strong>
                        </div>
                    ) : Boolean(deadline?.date) ? (
                        <div>
                            <div>
                                <strong>
                                    <span>
                                        {date.isFuture(deadline.date)
                                            ? 'Cancellation Deadline: '
                                            : 'Within Deadline: '}
                                    </span>
                                    {moment(deadline.date).format('dddd, DD MMMM yyyy, HH:mm')}
                                </strong>
                            </div>
                            {deadline?.policies?.map((item, index) => (
                                <div key={index}>
                                    From {moment(item.fromDate).format('dddd, DD MMMM yyyy, HH:mm')}{' '}
                                    cancellation costs {item.percentage}% of total amount.
                                    {Boolean(item.remark) && <div>{item.remark}</div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>No data</div>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Board Basis">
                    {room.boardBasis}
                    {room.mealPlan ? ` (${room.mealPlan})` : null}
                </Descriptions.Item>
                {contract.specialRequest ? (
                    <Descriptions.Item label="Special Request">{contract.specialRequest}</Descriptions.Item>
                ) : null}

                {!!remarksData.promotions.length &&
                    remarksData.promotions.map(({ type, name, value }, index) => (
                        <Descriptions.Item label={type} key={index}>
                            <div>
                                {name && (
                                    <div>
                                        <strong>{name}</strong>
                                    </div>
                                )}
                                <div>{value}</div>
                            </div>
                        </Descriptions.Item>
                    ))}

                {isExpanded && (
                    <>
                        {booking.isGuaranteeRequired && (
                            <Descriptions.Item label="Payment Guarantee">VCC</Descriptions.Item>
                        )}
                        <Descriptions.Item label="Invoice Number">{booking.invoiceNumber}</Descriptions.Item>
                        {remarksData.remarks?.map((item, index) => (
                            <Descriptions.Item label={item.key} key={index}>
                                {item.value}
                            </Descriptions.Item>
                        ))}
                        {booking.specialRequest && !contract.specialRequest && (
                            <Descriptions.Item label="Description">
                                {booking.specialRequest}
                            </Descriptions.Item>
                        )}
                    </>
                )}
            </Descriptions>
        </>
    );
};

export default Allotments;
