import React from 'react';
import { API } from 'htcore';
import { notifications } from 'components/notification';
import { Typography, Button, Upload, Modal, List } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import sunpuMethods from '../../../../core/sunpu-methods';

const { Title } = Typography;
const { confirm } = Modal;

const SupplierFiles = ({ code, supplier, loadSupplier }) => {
    const uploadContract = (file) => {
        return new Promise((resolve, reject) => {
            confirm({
                title: `The "${file.name}" will be uploaded as supplier contract.`,
                onOk: () => {
                    resolve(true);
                    const data = new FormData();
                    data.append('file', file);

                    API.put({
                        sunpu: sunpuMethods.supplierContractFiles(code),
                        formDataBody: data,
                        success: () => {
                            loadSupplier();
                            notifications('Contract uploaded!', 'success');
                        },
                    });
                },
                onCancel: () => {
                    reject(true);
                    notifications('Canceled', 'warning');
                },
            });
        });
    };

    const downloadContract = (file) => {
        API.get({
            sunpu: sunpuMethods.supplierContractFile(code, file.id),
            response: (res) => {
                res.blob().then((blobby) => {
                    let anchor = document.createElement('a');
                    document.body.appendChild(anchor);

                    let objectUrl = window.URL.createObjectURL(blobby);
                    anchor.href = objectUrl;
                    anchor.download = String(file.fileName) + String(file.extension);
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                });
            },
        });
    };

    return (
        <div style={{ marginTop: 70 }}>
            <Title level={4}>Upload Contract</Title>
            <List
                itemLayout="horizontal"
                dataSource={supplier?.contracts}
                locale={{
                    emptyText: (
                        <div style={{ textAlign: 'left', margin: '-15px -25px' }}>No documents uploaded</div>
                    ),
                }}
                header={
                    <Upload
                        action={uploadContract}
                        customRequest={() => null}
                        multiple={false}
                        showUploadList={false}
                        name="file"
                    >
                        <Button type="secondary">Upload Contract</Button>
                    </Upload>
                }
                renderItem={(item) => (
                    <List.Item>
                        <Button
                            disabled={false}
                            className="link"
                            onClick={() => downloadContract(item)}
                            style={{ padding: 0, border: 0, boxShadow: 'none', height: 'auto' }}
                        >
                            <FileOutlined /> {item.fileName}
                        </Button>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default SupplierFiles;
