import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import BuffersList from '../buffers-list';

const BuffersListWrap = () => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = () => {
        setLoading(true);

        API.get({
            url: apiMethods.allRates,
            success: (result) => {
                setList(
                    result.map(({ currencyExchangeRate, auditLogs }) => {
                        const mainLog = auditLogs?.[0];
                        const { source, target } = currencyExchangeRate;

                        return {
                            ...mainLog,
                            eventType: mainLog?.eventType || 'Create',
                            ...currencyExchangeRate,
                            children: auditLogs
                                .filter((_, index) => !!index)
                                .map((item) => ({ ...item, source, target })),
                        };
                    })
                );
            },
            after: () => setLoading(false),
        });
    };

    return <BuffersList loading={loading} list={list} updateData={getData} />;
};

export default BuffersListWrap;
