import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { EntitySelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const optionsGenerator = (list = []) =>
    list.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.id}) ${item.isActive ? '' : ' (INACTIVE)'}`,
    }));

const AgencySelector = ({ filter, ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let newOptions = [];
            let skip = 0;
            while (skip === newOptions.length) {
                newOptions.push(
                    ...(await API.get({
                        url: apiMethods.agencies,
                        body: {
                            ...(filter
                                ? {
                                      $filter: filter,
                                  }
                                : {}),
                            $top: 100,
                            $skip: skip,
                        },
                    }))
                );
                skip += 100;
            }

            if (props.onlyActiveAgencies) {
                newOptions = newOptions.filter(
                    (option) => option.isActive || props.selectedAgencies?.includes(option.id)
                );
            }

            newOptions.sort((a, b) => b.isActive - a.isActive);
            newOptions = optionsGenerator(newOptions);
            setOptions(newOptions);
            setLoading(false);
        })();
    }, []);

    return (
        <EntitySelector
            placeholder="Any Agency"
            maxTagCount={3}
            {...props}
            value={typeof props.value === 'string' ? parseInt(props.value) : props.value}
            options={options}
            loading={loading}
        />
    );
};

export default AgencySelector;
