import React, { useState } from 'react';
import Table from 'components/table';
import { Loader } from 'legacy';
import { Form } from 'antd';
import { columns, CustomFilter, searches } from './bookings-table-settings';

const BookingsList = ({ bookings }) => {
    const [statusFilter, setStatusFilter] = useState(null);

    if (!bookings) {
        return <Loader />;
    }

    if (statusFilter) {
        bookings = bookings.filter((item) => item.status === statusFilter);
    }

    return (
        <Table
            list={bookings}
            columns={columns(null)}
            CustomFilter={
                <Form layout="vertical">
                    <CustomFilter apply={setStatusFilter} />
                </Form>
            }
            textEmptyResult="No bookings match your search"
            textEmptyList="No bookings found"
            searches={searches}
            searchText="Find on this Page"
        />
    );
};

export default BookingsList;
