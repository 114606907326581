import React from 'react';
import { Space } from 'antd';
import { BOOKING_STATUS, PAYMENT_STATUS } from 'htcore/enum';
import DownloadDocumentButton from './download-document-button';
import OfflineBookingCancelButton from '../offline-bookings/offline-booking-cancel-button';
import CompletePaymentManuallyButton from './complete-payment-manually-button';
import $auth from 'stores/auth';

const BookingServiceActions = ({ status, booking, loadBooking }) => {
    const isPermittedBookingManagement = $auth.permitted('BookingManagement');
    const isCompleted = status === BOOKING_STATUS.Confirmed || status === BOOKING_STATUS.Completed;
    const isWithCancellationFee =
        booking?.appliedCancellationFee?.amount > 0 && status === BOOKING_STATUS.Cancelled;

    return (
        <Space direction="horizontal" wrap style={{ marginTop: -20, marginBottom: 40 }}>
            {isPermittedBookingManagement && status === BOOKING_STATUS.Confirmed && (
                <OfflineBookingCancelButton
                    isService
                    onlyWithoutCharges={
                        booking.paymentStatus === PAYMENT_STATUS.NotPaid ||
                        booking.paymentStatus === PAYMENT_STATUS.Authorized
                    }
                    loadBooking={loadBooking}
                />
            )}

            {$auth.permitted('OfflinePayment') &&
                status === BOOKING_STATUS.Confirmed &&
                booking.paymentStatus === PAYMENT_STATUS.NotPaid && (
                    <CompletePaymentManuallyButton booking={booking} loadBooking={loadBooking} isService />
                )}

            {(isCompleted || isWithCancellationFee) && (
                <DownloadDocumentButton isService type="invoice" booking={booking} />
            )}
        </Space>
    );
};

export default BookingServiceActions;
