import React from 'react';
import apiMethods from 'core/methods';
import { EntitySelector, useSelector } from 'common/entity-selector';

const optionsGenerator = (list) =>
    list.map((item) => ({
        value: item,
        label: item === 'NotSpecified' ? 'None (Use default)' : item,
    }));

const temporaryOptions = [
    { value: 'Payfort', label: 'Payfort' },
    { value: 'PayTabs', label: 'PayTabs (ADCB)' },
];

const PaymentProcessorSelector = (props) => {
    // TODO return request after backend fix
    // const { options, loading } = useSelector({ url: apiMethods.paymentProcessors() }, optionsGenerator);

    return <EntitySelector {...props} options={temporaryOptions} />;
};

export default PaymentProcessorSelector;
