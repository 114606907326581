import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Select, Tag } from 'antd';
import { ArrowRight } from 'iconsax-react';
import { BOOKING_STATUS, PAYMENT_STATUS } from 'htcore/enum';
import { date, price, remapStatus } from 'legacy';
import $auth from 'stores/auth';
import BookingStatus from './booking-status';

const getColor = (status) =>
    ({
        [PAYMENT_STATUS.NotPaid]: 'red',
        [PAYMENT_STATUS.Authorized]: 'lime',
        [PAYMENT_STATUS.Invoiced]: 'green',
        [PAYMENT_STATUS.Refunded]: 'blue',
        [PAYMENT_STATUS.Voided]: 'grey',
    }[status]);

export const columns = (navigationState, isService) => [
    {
        header: (
            <div style={{ paddingLeft: 42 }}>
                Itinerary&nbsp;Number&nbsp;/
                <br />
                Reference&nbsp;Code
                {!isService && (
                    <>
                        &nbsp;/
                        <br />
                        Hotel&nbsp;Confirmation&nbsp;Code&nbsp;/
                        <br />
                        Supplier&nbsp;Ref.Code
                    </>
                )}
            </div>
        ),
        cell: (cell) => (
            <Link
                to={`/booking/${cell.referenceCode}`}
                state={navigationState}
                className="row-link"
                style={{ minWidth: 160 }}
            >
                <div className="arrow-holder">
                    <span>
                        <ArrowRight size={14} />
                    </span>
                </div>
                <div>
                    <small>{cell.itineraryNumber}</small>
                    <br />
                    {cell.referenceCode}
                    <br />
                    {Boolean(cell.hotelConfirmationCode) && (
                        <>
                            <small>{cell.hotelConfirmationCode}</small>
                            <br />
                        </>
                    )}
                    <small>{Boolean(cell.supplierReferenceCode) && cell.supplierReferenceCode}</small>
                </div>
            </Link>
        ),
    },
    {
        header: (
            <>
                Agent&nbsp;/
                <br /> Agency&nbsp;/
                <br /> Surrogate Agency
            </>
        ),
        cell: (cell) => (
            <>
                <div>{cell.agentName}</div>
                <div>{cell.agencyName}</div>
                <div>{cell.surrogateAgencyName}</div>
                {cell.adminId ? <div>by {cell.adminName || `#${cell.adminId}`}</div> : null}
            </>
        ),
    },
    {
        header: (
            <>
                Accommodation&nbsp;/
                <br />
                {!isService && (
                    <>
                        City,&nbsp;Country&nbsp;/
                        <br />
                    </>
                )}
                Supplier
            </>
        ),
        cell: (cell) => (
            <>
                {cell.accommodationName}
                <br />
                {!isService && (
                    <>
                        {cell.locality}, {cell.country}
                        <br />
                    </>
                )}

                {cell.supplier}
            </>
        ),
    },

    ...(isService
        ? [
              {
                  header: (
                      <>
                          Service Type&nbsp;/
                          <br />
                          Service Name
                      </>
                  ),
                  cell: (cell) =>
                      cell.serviceType || cell.serviceName ? (
                          <>
                              {cell.serviceType}
                              <br />
                              {cell.serviceName}
                          </>
                      ) : (
                          'Accommodation'
                      ),
              },
          ]
        : []),
    {
        header: 'Status',
        cell: (cell) => (
            <>
                <BookingStatus status={cell.status} />
                {Boolean(cell.status === BOOKING_STATUS.Cancelled && cell.cancellationDate) && (
                    <div>at&nbsp;{date.format.dots(new Date(cell.cancellationDate))}</div>
                )}
            </>
        ),
    },
    {
        header: (
            <>
                Created&nbsp;/
                <br />
                {$auth.permitted('ViewPaxNames') && (
                    <>
                        Main&nbsp;Guest&nbsp;/
                        <br />
                    </>
                )}
                Passengers
            </>
        ),
        cell: (cell) => (
            <>
                {date.format.dots(new Date(cell.created))}
                <br />
                {$auth.permitted('ViewPaxNames') && (
                    <>
                        {cell.mainPassengerName}
                        <br />
                    </>
                )}
                {cell.totalPassengers} Passenger{cell.totalPassengers > 1 && 's'}
            </>
        ),
    },
    {
        header: (
            <>
                Deadline&nbsp;/
                <br />
                Check&nbsp;In&nbsp;/
                <br />
                Check&nbsp;Out
            </>
        ),
        cell: (cell) => (
            <>
                <b>{date.format.dots(new Date(cell.deadlineDate))}</b> <br />
                <span className="gray">{date.format.dots(new Date(cell.checkInDate))}</span> <br />
                <span className="gray">{date.format.dots(new Date(cell.checkOutDate))}</span>
            </>
        ),
    },
    {
        header: (
            <>
                NPtS&nbsp;(USD)&nbsp;/
                <br />
                NPtS&nbsp;(Original)&nbsp;/
                <br />
                Markup Percentage
            </>
        ),
        cell: (cell) => (
            <div style={{ minWidth: '140px' }}>
                {cell.supplierCurrency !== 'USD' && cell.supplierUsdPrice && (
                    <>
                        {price('USD', cell.supplierUsdPrice)} <br />
                    </>
                )}
                {price(cell.supplierCurrency, cell.supplierPrice)} <br />
                {cell.totalMarkupPercent.toFixed(2)}%
            </div>
        ),
    },
    {
        header: (
            <>
                Total&nbsp;Price&nbsp;/
                <br />
                Payment&nbsp;Method&nbsp;/
                <br />
                Payment&nbsp;Status
            </>
        ),
        cell: (cell) => (
            <div style={{ minWidth: '140px' }}>
                {price(
                    cell.currency,
                    cell.status === 'Cancelled' ? cell.appliedCancellationFeePrice : cell.totalPrice
                )}{' '}
                <br />
                {remapStatus(cell.paymentType)} <br />
                <Tag color={getColor(cell.paymentStatus)}>
                    <b>{remapStatus(cell.paymentStatus)}</b>
                </Tag>
            </div>
        ),
    },
];

export const nestedColumns = () => [
    {
        header: 'Itinerary Number',
        cell: (cell) => <div style={{ color: '#1267fb' }}>{cell.itineraryNumber}</div>,
    },
    {
        header: (
            <>
                Agent&nbsp;/
                <br /> Agency&nbsp;/
                <br /> Surrogate Agency
            </>
        ),
        cell: (cell) => (
            <>
                {cell.agentName}
                <br />
                {cell.agencyName}
                <br />
                {cell.surrogateAgencyName}
            </>
        ),
    },
    {
        header: 'Total Bookings',
        cell: (cell) => cell.itineraryBookingsCount,
    },
    {
        header: (
            <>
                Created&nbsp;/
                <br />
                {$auth.permitted('ViewPaxNames') && (
                    <>
                        Main&nbsp;Guest&nbsp;
                        <br />
                    </>
                )}
            </>
        ),
        cell: (cell) => (
            <>
                {date.format.dots(new Date(cell.created))}
                <br />
                {$auth.permitted('ViewPaxNames') && (
                    <>
                        {cell.mainPassengerName}
                        <br />
                    </>
                )}
            </>
        ),
    },
    {
        header: 'Dates',
        cell: (cell) => (
            <span>
                {date.format.dots(new Date(cell.checkInDate))} /{' '}
                {date.format.dots(new Date(cell.checkOutDate))}
            </span>
        ),
    },
    {
        header: 'Total Price',
        cell: (cell) => (
            <b style={{ minWidth: '140px' }}>
                {cell.total && cell.itineraryBookingsCount === cell.items.length
                    ? price(cell.currency, cell.total)
                    : ''}
            </b>
        ),
    },
];

export const searches = (v) => [
    String(v.agentName),
    String(v.id),
    String(v.htId),
    String(v.referenceCode),
    String(v.paymentStatus),
    String(v.paymentMethod),
    String(v.paymentType),
    String(v.mainPassengerName),
    String(v.status),
    String(v.accommodationName),
    String(v.agencyName),
    String(v.supplierReferenceCode),
    String(v.itineraryNumber),
    String(v.hotelConfirmationCode),
];

export const CustomFilter = ({ apply }) => (
    <Form.Item label="Status Filter">
        <Select
            options={[
                { value: null, label: 'All Bookings' },
                { value: BOOKING_STATUS.Confirmed, label: 'Confirmed only' },
                { value: BOOKING_STATUS.Pending, label: 'Pending only' },
                { value: BOOKING_STATUS.ManualCorrectionNeeded, label: 'Manual Correction Needed' },
                { value: BOOKING_STATUS.Cancelled, label: 'Cancelled only' },
            ]}
            onChange={(value) => apply(value)}
            style={{ minWidth: 200 }}
            defaultValue={null}
        />
    </Form.Item>
);
