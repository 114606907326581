import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { date } from 'legacy';
import apiMapperMethods from 'core/mapper-methods';
import { Link, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import Table from 'components/table';

const columns = [
    {
        header: 'Date',
        cell: ({ dateTime }) => <div style={{ width: 'max-content' }}>{date.format.e(dateTime)}</div>,
    },
    {
        header: 'Description',
        cell: ({ description }) => description,
    },
    {
        header: 'Changed by',
        cell: ({ administratorName, administratorId }) => (
            <div style={{ width: 'max-content' }}>
                <Link target="_blank" className="link" to={`/administrators/${administratorId}/details`}>
                    {administratorName}
                </Link>
            </div>
        ),
    },
];

const AccommodationPreferredHistory = () => {
    const [data, setData] = useState(null);
    const { htId } = useParams();

    const load = () => {
        API.get({
            url: apiMapperMethods.historyPreferredSettings(htId),
            success: setData,
        });
    };

    useEffect(() => {
        if (htId) {
            load();
        }
    }, [htId]);

    if (data === null) {
        return <Spin />;
    }

    if (!data?.length) {
        return 'No preferred settings history found for this Accommodation';
    }

    return <Table list={data} columns={columns} />;
};

export default AccommodationPreferredHistory;
