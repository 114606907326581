import React, { useEffect, useState } from 'react';
import { Loader } from 'legacy';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { Button, Col, Form, InputNumber, Modal, Row, Space } from 'antd';
import CurrencySelector from 'components/selectors/currency-selector';
import { notifications } from 'components/notification';
import { EntitySelector } from 'common/entity-selector';
import { MODAL_TYPES } from './buffers-list';

export const CONVERSION_VALUES = {
    AMOUNT: 1,
    PERCENT: 2,
};

const conversionOptions = [
    {
        value: CONVERSION_VALUES.PERCENT,
        label: 'Fixed %',
    },
    {
        value: CONVERSION_VALUES.AMOUNT,
        label: 'Fixed conversion rate',
    },
];

const BuffersModal = ({
    modalType,
    isExcluded,
    toCurrency,
    sourceCurrencyOptions,
    initialValues,
    getDeleteUrl,
    onSubmitRate,
    onClose,
}) => {
    const [loading, setLoading] = useState(false);
    const [currenciesWithoutExcluded, setCurrenciesWithoutExcluded] = useState(null);
    const [form] = Form.useForm();

    const isEdit = modalType === MODAL_TYPES.EDIT;

    useEffect(() => {
        if (isExcluded) {
            API.get({
                url: apiMethods.currencies,
                success: (data) =>
                    setCurrenciesWithoutExcluded(
                        data.filter((item) => !sourceCurrencyOptions.includes(item))
                    ),
            });
        }
    }, []);

    const onChangeAmount = (value, isPercent) => {
        const fixedValue = value ? Number(value).toFixed(isPercent ? 2 : 4) : value;
        form.setFieldValue('amount', fixedValue);
    };

    const onChangeConversion = (value) => {
        onChangeAmount(form.getFieldValue('amount'), value === CONVERSION_VALUES.PERCENT);
    };

    const onSubmit = async () => {
        setLoading(true);
        form.validateFields().then(async (values) => {
            if (onSubmitRate) {
                await onSubmitRate(values, isEdit);
                onClose();
                setLoading(false);

                return;
            }
            API.post({
                url: isEdit ? apiMethods.rateUpdate : apiMethods.rateCreate,
                body: values,
                success: () => {
                    notifications('Rule Saved!', 'success');
                    onClose();
                },
                after: () => setLoading(false),
            });
        });
    };

    const onDelete = () => {
        setLoading(true);
        API.delete({
            ...(getDeleteUrl
                ? getDeleteUrl(initialValues)
                : { url: apiMethods.rateDelete(initialValues.id) }),
            success: () => {
                notifications('Rate Deleted!', 'success');
                onClose();
            },
            after: () => setLoading(false),
        });
    };

    return (
        <Modal
            open
            title={isEdit ? 'Edit Custom Conversion Rule' : 'Add New Custom Conversion Rule'}
            onCancel={onClose}
            footer={
                <Space>
                    <Button
                        htmlType="submit"
                        type="primary"
                        style={{ width: 'fit-content' }}
                        onClick={onSubmit}
                    >
                        {isEdit ? 'Edit' : 'Create'}
                    </Button>
                    {isEdit && (
                        <Button
                            danger
                            htmlType="button"
                            type="primary"
                            style={{ width: 'fit-content' }}
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    )}
                </Space>
            }
        >
            <Form
                form={form}
                initialValues={{ target: toCurrency || null, ...initialValues }}
                layout="vertical"
                onFinish={onSubmit}
            >
                {loading || (isEdit && !initialValues) ? (
                    <Loader />
                ) : (
                    <>
                        <Row gutter={18}>
                            <Col span={12}>
                                <Form.Item label="From Currency" name="source" rules={[{ required: true }]}>
                                    {!!sourceCurrencyOptions?.length ? (
                                        <EntitySelector
                                            disabled={isEdit}
                                            placeholder="Any Currency"
                                            options={(currenciesWithoutExcluded || sourceCurrencyOptions).map(
                                                (key) => ({
                                                    value: key,
                                                    label: key,
                                                })
                                            )}
                                        />
                                    ) : (
                                        <CurrencySelector
                                            disabled={isEdit}
                                            placeholder="Any Currency"
                                            url={apiMethods.currencies}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="To Currency" name="target" rules={[{ required: true }]}>
                                    <CurrencySelector
                                        disabled={isEdit || !!toCurrency}
                                        placeholder="Any Currency"
                                        url={apiMethods.currencies}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={18}>
                            <Col span={12}>
                                <Form.Item
                                    label="Conversion Type"
                                    name="conversionType"
                                    rules={[{ required: true }]}
                                >
                                    <EntitySelector
                                        placeholder="Any Conversion Type"
                                        options={conversionOptions}
                                        onChange={onChangeConversion}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, nextValues) =>
                                        prevValues.conversionType !== nextValues.conversionType
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        const isPercent =
                                            getFieldValue('conversionType') === CONVERSION_VALUES.PERCENT;
                                        return (
                                            <Form.Item
                                                label="Amount"
                                                name="amount"
                                                rules={[
                                                    { required: true },
                                                    {
                                                        validator: (_, value) => {
                                                            return (value || '').toString()?.length > 20
                                                                ? Promise.reject(new Error('Too long'))
                                                                : Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    type="number"
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    placeholder="0"
                                                    addonAfter={isPercent ? '%' : ''}
                                                    onChange={(value) => onChangeAmount(value, isPercent)}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default BuffersModal;
