import React, { useEffect, useState } from 'react';
import { RequestSelector } from 'common/request-selector';
import apiOsakaMethods from 'core/osaka-methods';
import apiShimabaraMethods from 'core/shimabara-methods';
import { API } from 'htcore';
import { formatBody } from './format-body';

const optionsGenerator = (list = []) =>
    list.map((value) => ({
        value: value.htId,
        label: value.name,
    }));

const defaultRequestBody = {
    countryCodes: [],
    excludedCountryCodes: [],
    supplierCodes: [],
    excludedSupplierCodes: [],
};

const MarkupLocalitySelector = ({ isAccommodation, formValues, options, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(null);

    const [requestBody, setRequestBody] = useState(defaultRequestBody);

    useEffect(() => {
        const restore = [].concat(props.value).filter((v) => v);
        if (restore.length) {
            Promise.all(
                restore.map((htId) =>
                    API.get({
                        osaka: apiOsakaMethods.predictionsRestore(htId),
                    })
                )
            ).then((results) => {
                const newOptions = restore
                    .map((item, index) =>
                        results[index]?.predictionText
                            ? {
                                  value: item,
                                  label: results[index].predictionText,
                              }
                            : null
                    )
                    .filter((v) => v);
                setDefaultOptions(newOptions);
            });
        }
    }, []);

    // Change the data format according to the task HP-6865
    const createRequestBody = () => {
        let body = defaultRequestBody;

        const {
            agencyMarketIds,
            agencyCountryCodes,
            agencyCountryCodesType,
            accommodationMarketIds,
            accommodationCountryCodes,
            accommodationCountryCodesType,
            accommodationSupplierTypes,
            accommodationSupplierCodes,
            accommodationSupplierCodesType,
        } = formValues || {};

        if (isAccommodation) {
            body = {
                ...body,
                // format market and country fields
                ...formatBody(
                    accommodationMarketIds,
                    accommodationCountryCodes,
                    accommodationCountryCodesType,
                    options.accommodationCountryCodes
                ),
                // format supplier type and supplier codes fields
                ...formatBody(
                    accommodationSupplierTypes,
                    accommodationSupplierCodes,
                    accommodationSupplierCodesType,
                    options.accommodationSupplierCodes,
                    'supplierCodes',
                    'excludedSupplierCodes'
                ),
            };
        } else {
            body = {
                ...body,
                // format market and country fields for agencies
                ...formatBody(
                    agencyMarketIds,
                    agencyCountryCodes,
                    agencyCountryCodesType,
                    options.agencyCountryCodes
                ),
            };
        }

        return body;
    };

    useEffect(() => {
        setRequestBody(createRequestBody());
    }, [formValues, options]);

    return (
        <RequestSelector
            clearCache
            requestMethod="post"
            request={(value) => ({
                shimabara: apiShimabaraMethods.localities(value),
                body: requestBody,
            })}
            defaultOptions={defaultOptions}
            optionsGenerator={optionsGenerator}
            placeholder="Any Locality"
            {...props}
        />
    );
};

export default MarkupLocalitySelector;
