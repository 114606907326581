import { date } from 'legacy';

const safeString = (val) => val.replace(/'/g, "''");

export const serializeRequest = (values, top, skip) => {
    const filters = [];
    let orderBy = 'Created desc';

    if (values.nameQuery) {
        filters.push(`contains(tolower(companyName),tolower('${safeString(values.nameQuery)}'))`);
    }

    if (values.status) {
        filters.push(`status eq '${values.status}'`);
    }

    if (values.rangeDates) {
        filters.push(
            `created ge ${date.format.api(values.rangeDates[0])} and created lt ${date.format.api(
                date.addDay(values.rangeDates[1], 1)
            )}`
        );
    }

    if (values.accountManagerId) {
        filters.push(
            `accountManagerId eq ${values.accountManagerId === 'Unassigned' ? null : values.accountManagerId}`
        );
    }

    if (values.sortField) {
        orderBy = values.sortField + ' asc';
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $orderBy: orderBy,
        $top: top,
        $skip: skip,
    };
};
