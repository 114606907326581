import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import { Col, Form } from 'antd';
import ServiceTypeSelector from 'components/selectors/service-type-selector';
import { EntitySelector } from 'common/entity-selector';
import usePages from 'components/use-pages';
import TablePages from 'components/table-pages';
import BookingSearchForm from '../../booking-search-form';
import { serializeRequest } from '../../serializer';
import { columns } from '../../bookings-table-settings';

const BookingsService = ({ searchForm }) => {
    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    useEffect(() => {
        searchForm.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <BookingSearchForm isService bookingSearchForm={searchForm} onSubmit={onSubmit}>
                <Col span={12} xl={6}>
                    <Form.Item name="serviceType" label="Service Type">
                        <EntitySelector
                            options={[{ value: 'FoodSupplement', label: 'Food Supplement' }]}
                            placeholder="Service Type"
                        />
                    </Form.Item>
                </Col>
                <Col span={12} xl={6}>
                    <Form.Item name="serviceName" label="Service Name">
                        <ServiceTypeSelector placeholder="Any Service Name" />
                    </Form.Item>
                </Col>
            </BookingSearchForm>
            <TablePages
                columns={(data) => columns(data, true)}
                formValues={formValues}
                route={{ url: apiMethods.servicesList }}
                serializeRequest={(...rest) => serializeRequest(...rest, false, true)}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default BookingsService;
