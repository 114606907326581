import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Form } from 'antd';
import usePages from 'components/use-pages';
import TablePages from 'components/table-pages';
import AgencyHistorySearchForm from './agency-history-search-form';
import { columns, serializeRequest } from './history-table-settings';

const AgencyHistory = () => {
    const { id } = useParams();

    const [form] = Form.useForm();

    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    useEffect(() => {
        form.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <AgencyHistorySearchForm form={form} onSubmit={onSubmit} />
            <TablePages
                pageSize={20}
                columns={columns}
                formValues={formValues}
                route={{ url: apiMethods.agencyHistory(id) }}
                serializeRequest={serializeRequest}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default AgencyHistory;
