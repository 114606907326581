import React from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useTitle } from 'htcore';
import { Menu } from 'antd';
import AccommodationPreferredSettings from './accommodation-prefered-settings';
import AccommodationPreferredHistory from './accommodation-preferred-history';

const PreferredSettingsWrap = () => {
    const location = useLocation();
    const { htId } = useParams();

    useTitle(htId);

    const allMenuItems = [
        {
            label: <Link to="./">Settings</Link>,
            key: '',
        },
        {
            label: <Link to="./history">History</Link>,
            key: 'history',
        },
    ];

    return (
        <>
            <Menu
                mode="horizontal"
                items={allMenuItems}
                selectedKeys={location.pathname.split('/')[5] || ''}
            />

            <Routes>
                <Route path="/" element={<AccommodationPreferredSettings htId={htId} />} />
                <Route path="/history" element={<AccommodationPreferredHistory />} />
            </Routes>
        </>
    );
};

export default PreferredSettingsWrap;
