import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import apiMapperMethods from 'core/mapper-methods';
import { Button, Form, Space } from 'antd';
import { notifications } from 'components/notification';
import PaymentTypeSelector, { PAYMENT_TYPE_SUPPLIERS } from 'components/selectors/payment-type-selector';

const AccommodationPaymentTypeSettings = ({ htId, supplier, supplierData, allSupplersData, getData }) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.setFieldValue('paymentType', supplierData?.paymentType);
    }, [htId, supplier, supplierData]);

    const onSubmit = ({ paymentType }) => {
        setLoading(true);

        const accommodationSuppliersNames = Object.keys(allSupplersData);
        const suppliersForUpdating = PAYMENT_TYPE_SUPPLIERS.filter((item) =>
            accommodationSuppliersNames.includes(item)
        );

        Promise.all(
            suppliersForUpdating.map((supplierName) =>
                API.post({
                    shimabara: apiMapperMethods.updateAccommodationPaymentTypeSettings(htId, supplierName),
                    body: paymentType,
                })
            )
        ).then(() => {
            notifications('Settings Saved!', 'success');
            setLoading(false);
            getData();
        });
    };

    if (loading) {
        return <Loader />;
    }

    if (!PAYMENT_TYPE_SUPPLIERS.includes(supplier)) return null;

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onSubmit} style={{ marginTop: 20 }}>
                <Space align="end">
                    <Form.Item
                        label="Payment type with hotel"
                        name="paymentType"
                        style={{ margin: 0, width: 364 }}
                    >
                        <PaymentTypeSelector />
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                        Save
                    </Button>
                </Space>
            </Form>
        </>
    );
};

export default AccommodationPaymentTypeSettings;
