import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Form, Row, Col } from 'antd';
import AgencySelector from 'components/selectors/agency-selector';

const FILTERS = {
    surrogateAgencyId: "verificationState eq 'ReadOnly'",
    allSurrogateAgencies: 'isHappyTravelAgency eq true',
};

const SurrogateAgenciesForm = () => {
    const [happyTravelAgencies, setHappyTravelAgencies] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.agencies,
            body: { $filter: FILTERS.allSurrogateAgencies, $top: 100, $skip: 0 },
            success: (result) => setHappyTravelAgencies(result.map((item) => item.id)),
        });
    }, []);

    return (
        <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.agencyId !== currentValues.agencyId}
        >
            {({ getFieldValue }) =>
                happyTravelAgencies?.includes(getFieldValue('agencyId')) && (
                    <Row size="large">
                        <Col span={12} style={{ paddingRight: 30 }}>
                            <Form.Item name="surrogateAgencyId" label="Surrogate Agency">
                                <AgencySelector
                                    filter={FILTERS.surrogateAgencyId}
                                    placeholder="Select"
                                    onlyActiveAgencies
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )
            }
        </Form.Item>
    );
};

export default SurrogateAgenciesForm;
