import React from 'react';
import { ArrowDown2, ArrowRight2 } from 'iconsax-react';

const TableExpandIcon = ({ hide, expanded, onExpand, record }) => {
    if (hide) return;
    const onClick = (e) => onExpand(record, e);

    return (
        <td onClick={onClick} style={{ display: 'inline-table' }}>
            <div className="arrow-holder">
                <span>{expanded ? <ArrowDown2 size={14} /> : <ArrowRight2 size={14} />}</span>
            </div>
        </td>
    );
};

export default TableExpandIcon;
