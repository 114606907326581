import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import { API, useTitle } from 'htcore';
import { Form } from 'antd';
import { serializeRequest } from './serializer';
import RegistrationRequestsSearchForm from './requests-search-form';
import { columns } from './requests-table-settings';
import TablePages from 'components/table-pages';
import usePages from 'components/use-pages';
import { useSelector } from 'common/entity-selector';
import { notifications } from 'components/notification';
import { accountManagerOptionsGenerator } from 'components/selectors/account-manager-selector';

const AgenciesRequestsList = ({ registrationRequestSearchForm }) => {
    useTitle('Agencies Registrations');

    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();
    const [form] = Form.useForm();

    const [savingLoading, setSavingLoading] = useState(false);

    const { options: accountManagesOptions, loading: accountManagerLoading } = useSelector(
        { url: apiMethods.agencyAccountManagers() },
        (data) => accountManagerOptionsGenerator(data, true)
    );

    useEffect(() => {
        registrationRequestSearchForm.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    const updateAccountManager = (value) => {
        setSavingLoading(true);

        const [requestId, accountManagerId] = Object.entries(value)[0];

        API.post({
            url: apiMethods.requestAccountManager(requestId),
            body: accountManagerId === 'Unassigned' ? null : accountManagerId,
            success: () => notifications('New Account Manager Saved!', 'success'),
            after: () => setSavingLoading(false),
        });
    };

    return (
        <>
            <RegistrationRequestsSearchForm
                registrationRequestSearchForm={registrationRequestSearchForm}
                onSubmit={onSubmit}
            />
            <Form form={form} onValuesChange={updateAccountManager}>
                <TablePages
                    columns={() => columns(accountManagesOptions, accountManagerLoading || savingLoading)}
                    formValues={formValues}
                    route={{ url: apiMethods.agencyRequests }}
                    serializeRequest={serializeRequest}
                    page={page}
                    setPage={setPage}
                />
            </Form>
        </>
    );
};

export default AgenciesRequestsList;
