import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { Button } from 'antd';

const DatesCell = ({ dates }) => {
    if (!dates.length) return '-';

    return dates.map(({ fromDate, toDate }) => (
        <p style={{ marginBottom: 0, width: 'max-content' }}>
            {date.format.dots(fromDate)} - {date.format.dots(toDate)}
        </p>
    ));
};

export const columns = [
    {
        title: 'Booking Period',
        render: ({ bookingDates }) => <DatesCell dates={bookingDates} />,
    },
    {
        title: 'Travel Period',
        render: ({ travelDates }) => <DatesCell dates={travelDates} />,
    },
    {
        title: 'Description',
        render: ({ description }) => <div style={{ maxWidth: 600 }}>{description}</div>,
    },
    {
        title: 'Activity',
        render: (cell) => (cell.isActive ? 'Active' : 'Inactive'),
    },
    {
        title: '',
        render: (cell) => (
            <div style={{ display: 'flex' }}>
                <Link to={`./${cell.supplierRestrictionId}`} style={{ marginLeft: 'auto' }}>
                    <Button type="primary">Edit</Button>
                </Link>
            </div>
        ),
    },
];
