import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { Typography, Button, Upload, Modal, List, Popconfirm, Form, Input } from 'antd';
import { FileOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ConfirmationModal from 'components/confirms/confirmation-modal';

const { Title } = Typography;
const { confirm } = Modal;

const AgencyContract = ({ agency, loadAgency }) => {
    const [renameModalDocumentData, setRenameModalDocumentData] = useState(null);

    const uploadContract = (file) => {
        return new Promise((resolve, reject) => {
            confirm({
                title: `The "${file.name}" will be uploaded as agency contract.`,
                onOk: () => {
                    resolve(true);
                    const data = new FormData();
                    data.append('file', file);

                    API.put({
                        url: apiMethods.contractFiles(agency.id),
                        formDataBody: data,
                        success: () => {
                            loadAgency();
                            notifications('Contract uploaded!', 'success');
                        },
                    });
                },
                onCancel: () => {
                    reject(true);
                    notifications('Canceled', 'warning');
                },
            });
        });
    };

    const downloadContract = (file) => {
        API.get({
            url: apiMethods.contractFile(agency.id, file.id),
            response: (res) => {
                res.blob().then((blobby) => {
                    let anchor = document.createElement('a');
                    document.body.appendChild(anchor);

                    let objectUrl = window.URL.createObjectURL(blobby);
                    anchor.href = objectUrl;
                    anchor.download = String(file.fileName) + String(file.extension);
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                });
            },
        });
    };

    const deleteContract = (document) => {
        API.delete({
            url: apiMethods.contractFile(agency.id, document.id),
            after: loadAgency,
        });
    };

    const renameContract = (values) =>
        API.put({
            url: apiMethods.contractFile(agency.id, renameModalDocumentData.id),
            body: values.fileName,
            after: () => {
                loadAgency();
                setRenameModalDocumentData(null);
            },
        });

    return (
        <div style={{ marginTop: 70 }}>
            <Title level={4}>Contract Details</Title>
            <List
                itemLayout="horizontal"
                dataSource={agency?.contractFiles}
                locale={{
                    emptyText: (
                        <div style={{ textAlign: 'left', margin: '-15px -25px' }}>No documents uploaded</div>
                    ),
                }}
                header={
                    <Upload
                        action={uploadContract}
                        customRequest={() => null}
                        multiple={false}
                        showUploadList={false}
                        accept=".pdf,.jpg,.jpeg,.png,.csv,.xls,.doc"
                        name="file"
                    >
                        <Button type="secondary">Upload Document</Button>
                    </Upload>
                }
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <a onClick={() => setRenameModalDocumentData(item)} title="Rename">
                                <EditOutlined />
                            </a>,

                            <Popconfirm
                                title={<div>The document will be deleted! This action can't be undone</div>}
                                okText="Delete"
                                cancelText="Cancel"
                                onConfirm={() => deleteContract(item)}
                                placement="left"
                            >
                                <a title="Delete">
                                    <DeleteOutlined />
                                </a>
                            </Popconfirm>,
                        ]}
                    >
                        <Button
                            className="link"
                            onClick={() => downloadContract(item)}
                            style={{ padding: 0, border: 0, boxShadow: 'none', height: 'auto' }}
                        >
                            <FileOutlined /> {item.fileName}
                        </Button>
                    </List.Item>
                )}
            />

            <ConfirmationModal
                visible={renameModalDocumentData}
                onCancel={() => setRenameModalDocumentData(null)}
                headerText="Enter New Name For File"
                submitText="Rename"
                onSubmit={renameContract}
                reason={false}
                key={renameModalDocumentData?.id}
            >
                <Form.Item
                    name="fileName"
                    label="New File Name"
                    rules={[{ required: true }]}
                    style={{ marginTop: '20px' }}
                    initialValue={renameModalDocumentData?.fileName}
                >
                    <Input placeholder="Enter New File Name" />
                </Form.Item>
            </ConfirmationModal>
        </div>
    );
};

export default AgencyContract;
