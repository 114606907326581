import React, { useMemo } from 'react';
import { Row, Button, Tag, Popover, Space, Dropdown } from 'antd';
import { Back, InfoCircle } from 'iconsax-react';
import SuppliersPopover from './suppliers-popover';
import { DownOutlined } from '@ant-design/icons';
import { Tabs } from './localities-table-settings';

const MAXIMUM_LOCALITIES_NUMBER = 50;

const LocalitiesTableCell = ({
    cell,
    tab,
    tableData,
    primaryLocality,
    localityState,
    setPrimaryLocality,
    setLocalityState,
}) => {
    const undoPrimary = () => {
        if (primaryLocality) {
            setLocalityState((prevState) => {
                const state = prevState[primaryLocality];
                return {
                    ...prevState,
                    [primaryLocality]: {
                        ...state,
                        children: state?.savedChildren || [],
                        isNewPrimary: false,
                    },
                };
            });

            setPrimaryLocality(null);
        }
    };

    const makePrimary = ({ htId }) => {
        undoPrimary();
        setLocalityState((prevState) => {
            const state = prevState[htId];
            return {
                ...prevState,
                [htId]: { ...state, children: state?.savedChildren || [], isNewPrimary: true },
            };
        });

        setPrimaryLocality(htId);
    };

    const deleteAllRelations = ({ htId }) =>
        setLocalityState((prevState) => ({
            ...prevState,
            [htId]: { ...prevState[htId], children: [] },
        }));

    const mapLocality = ({ htId }) =>
        setLocalityState((prevState) => ({
            ...prevState,
            [primaryLocality]: {
                ...prevState[primaryLocality],
                children: [...(prevState[primaryLocality]?.children || []), htId],
            },
        }));

    const unmapChild = ({ htId }) =>
        setLocalityState((prevState) => {
            const newChildren = prevState[primaryLocality].children.filter((item) => item !== htId);

            return {
                ...prevState,
                [primaryLocality]: {
                    ...prevState[primaryLocality],
                    children: newChildren,
                },
            };
        });

    const mappedLocalities = useMemo(() => {
        if (tab !== Tabs.Unmapped) return null;
        const mappedData = tableData.filter(
            (item) =>
                (item?.children?.length || item.htId === primaryLocality) &&
                item?.children?.length !== MAXIMUM_LOCALITIES_NUMBER
        );

        return mappedData?.length
            ? mappedData
                  .map(({ htId: key, name: label }) => ({ key, label }))
                  .sort((a, b) => a.label.localeCompare(b.label))
            : null;
    }, [tab, tableData]);

    const mapWithLocality = ({ htId }, parentLocality) => {
        setPrimaryLocality(parentLocality);
        setLocalityState((prevState) => ({
            ...prevState,
            [parentLocality]: {
                ...prevState[parentLocality],
                isNewPrimary: true,
                children: [...(prevState[parentLocality]?.children || []), htId],
            },
        }));
    };

    const disabledMapping = useMemo(() => {
        const { children } = localityState[primaryLocality] || {};

        return children?.length === MAXIMUM_LOCALITIES_NUMBER;
    }, [primaryLocality, localityState]);

    const getLocalityButton = (cellData, primaryId) => {
        const { htId: id, isSaved, isNewPrimary, parentId, children } = cellData;
        const commonProps = { type: 'primary', ghost: true, size: 'small' };

        if (isSaved && children?.length && !isNewPrimary && !primaryId) {
            return (
                <Button onClick={() => makePrimary(cellData)} {...commonProps}>
                    Edit Relations
                </Button>
            );
        }

        if (isSaved && children?.length && isNewPrimary) {
            return (
                <Button danger onClick={() => deleteAllRelations(cellData)} {...commonProps}>
                    Delete All Relations
                </Button>
            );
        }

        if (parentId === primaryId) {
            return (
                <Button danger onClick={() => unmapChild(cellData)} {...commonProps}>
                    Unmap
                </Button>
            );
        }

        if (!isSaved && !primaryId && !parentId) {
            return (
                <Space>
                    <Button onClick={() => makePrimary(cellData)} {...commonProps}>
                        Make primary
                    </Button>
                    {mappedLocalities && (
                        <Dropdown
                            menu={{
                                items: mappedLocalities,
                                onClick: ({ key }) => mapWithLocality(cell, key),
                            }}
                            overlayClassName="scroll-dropdown"
                        >
                            <Button icon={<DownOutlined />} {...commonProps}>
                                Map with
                            </Button>
                        </Dropdown>
                    )}
                </Space>
            );
        }

        if (primaryId === id) {
            return (
                <Button onClick={undoPrimary} {...commonProps}>
                    Primary Undo
                </Button>
            );
        }

        if (!disabledMapping && !parentId && primaryId && !children?.length) {
            return (
                <Button onClick={() => mapLocality(cell)} {...commonProps}>
                    Map
                </Button>
            );
        }
    };

    const { htId, name, isSaved, isNewPrimary, parentId, parentName } = cell;

    const isChild = !!parentId;

    return (
        <Row justify="space-between" align="middle">
            <Space align="center">
                {isChild && (
                    <Back
                        size="24"
                        color="#A4AAB3"
                        style={{ marginTop: 6, rotate: '180deg', height: '24px' }}
                    />
                )}

                <Popover content={<SuppliersPopover id={htId} />} trigger="hover" placement="bottom">
                    <Space>
                        <span>{name}</span>
                        <InfoCircle size="18" color="#016FD0" variant="Bold" style={{ marginTop: 6 }} />
                    </Space>
                </Popover>

                {(isSaved || isNewPrimary) && !isChild && (
                    <Tag color={isSaved ? '#59BA71' : '#DADADA'}>Primary</Tag>
                )}
                {isChild && <Tag color={isSaved ? '#F9A61B' : '#DADADA'}>Mapped With {parentName}</Tag>}
            </Space>

            {getLocalityButton(cell, primaryLocality)}
        </Row>
    );
};

export default LocalitiesTableCell;
