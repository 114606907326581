import React from 'react';
import { Link } from 'react-router-dom';
import { Button, PageHeader } from 'antd';
import Table from 'components/table';
import { remapStatus } from 'legacy';

const AdministratorRolesListPage = ({ roles }) => (
    <>
        <PageHeader
            title="Administrator Roles Editor"
            extra={
                <Link to="./create">
                    <Button>Create New Role</Button>
                </Link>
            }
        />
        <Table
            list={roles}
            columns={[
                {
                    header: 'ID',
                    cell: (cell) => (
                        <>
                            <Link to={`/administrator-roles/${cell.id}`}>{cell.id}</Link>
                        </>
                    ),
                },
                {
                    header: 'Name',
                    cell: (cell) => (
                        <>
                            <Link to={`/administrator-roles/${cell.id}`}>{cell.name}</Link>
                        </>
                    ),
                },
                {
                    header: 'Permissions',
                    cell: (cell) =>
                        cell.permissions.length
                            ? cell.permissions.map((permission) => (
                                  <div key={permission}>{remapStatus(permission)}</div>
                              ))
                            : 'None',
                },
                {
                    header: 'Notifications',
                    cell: (cell) =>
                        cell.notificationTypes.length
                            ? cell.notificationTypes.map((notification) => (
                                  <div key={notification}>{remapStatus(notification)}</div>
                              ))
                            : 'None',
                },
            ]}
            textEmptyResult="Empty"
        />
    </>
);

export default AdministratorRolesListPage;
