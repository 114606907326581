const getValueFromAllOptions = (options) => (options || []).map(({ code }) => code);

export const formatBody = (
    marketIds,
    countryCodes,
    countryCodesType,
    options,
    fieldName = 'countryCodes',
    excludedFieldName = 'excludedCountryCodes'
) => {
    const isSelectMarkets = !!marketIds?.length;
    const isSelectCountries = !!countryCodes?.length;
    const isIncludeCountries = countryCodesType === 'include';
    const formatedOptions = getValueFromAllOptions(options);

    const values = {};

    if (isSelectCountries && isSelectMarkets) {
        if (isIncludeCountries) {
            values[fieldName] = countryCodes;
        } else {
            values[fieldName] = formatedOptions;
            values[excludedFieldName] = countryCodes;
        }
    }

    if (isSelectCountries && !isSelectMarkets) {
        if (isIncludeCountries) {
            values[fieldName] = countryCodes;
        } else {
            values[excludedFieldName] = countryCodes;
        }
    }

    if (!isSelectCountries && isSelectMarkets) {
        values[fieldName] = formatedOptions;
    }

    return values;
};
