import React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Form, Input, Select, Space, Typography } from 'antd';
import { generateLabel } from 'legacy/generate-label';

const { Option } = Select;

const InvitationForm = observer(({ onSubmit, roles, rolesTitle, alternativeSubmit, beforeSubmit }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} onFinish={onSubmit} layout="vertical" style={{ maxWidth: 700, marginTop: 30 }}>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: 'Please enter Email' },
                    { type: 'email', message: 'Please enter a valid email address.' },
                ]}
            >
                <Input type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="title"
                label="Salutation"
                rules={[{ required: true, message: 'Please enter Title' }]}
            >
                <Select placeholder="Select">
                    <Option value="Mr">Mr.</Option>
                    <Option value="Ms">Ms.</Option>
                    <Option value="Miss">Miss</Option>
                    <Option value="Mrs">Mrs.</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    { required: true, message: 'Please enter First Name' },
                    { max: 50, message: 'Too long' },
                    { whitespace: true, message: "Can't be empty" },
                ]}
            >
                <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    { required: true, message: 'Please enter Last Name' },
                    { max: 50, message: 'Too long' },
                    { whitespace: true, message: "Can't be empty" },
                ]}
            >
                <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
                name="position"
                label="Position (Designation)"
                rules={[
                    { required: true, message: 'Please enter Position' },
                    { whitespace: true, message: "Can't be empty" },
                ]}
            >
                <Input placeholder="Position (Designation)" />
            </Form.Item>
            <Typography.Title level={5} style={{ marginTop: 50 }}>
                {rolesTitle}
            </Typography.Title>
            {roles.map((role) => (
                <div key={role.id}>
                    <Form.Item name={['roleIds', String(role.id)]} valuePropName="checked">
                        <Checkbox defaultChecked={false}>{role.name}</Checkbox>
                    </Form.Item>
                    <div style={{ margin: '-25px 0 15px' }}>
                        {role.permissions.map((permission) => generateLabel(permission)).join()}
                    </div>
                </div>
            ))}
            <Space>
                <Button htmlType="submit" style={{ marginTop: 20 }} onClick={beforeSubmit}>
                    Send Invitation
                </Button>
                {Boolean(alternativeSubmit) && (
                    <Button htmlType="submit" style={{ marginTop: 20 }} onClick={alternativeSubmit}>
                        Generate Invitation Link
                    </Button>
                )}
            </Space>
        </Form>
    );
});

export default InvitationForm;
