import React from 'react';
import { Input } from 'antd';

export const Tabs = {
    Localities: 'localities',
    Mapped: 'mapped',
    Unmapped: 'unmapped',
};

export const menuItems = [
    {
        label: 'All Localities',
        key: Tabs.Localities,
    },
    {
        label: 'Only Mapped',
        key: Tabs.Mapped,
    },
    {
        label: 'Only Unmapped',
        key: Tabs.Unmapped,
    },
];

export const localitiesColumns = (Header, Cell, onChangeSearch) => [
    {
        title: Header,
        dataIndex: 'name',
        key: 'name',
        children: [
            {
                title: () => <Input placeholder="Search" onChange={onChangeSearch} />,
                render: (_, cell) => Cell(cell),
            },
        ],
    },
];
