import React from 'react';
import { HOTEL_STARS } from 'htcore/enum';
import { StarFilled } from '@ant-design/icons';

const HotelStars = ({ count }) => {
    let result = HOTEL_STARS.indexOf(count);
    if (parseInt(count) >= 1) result = parseInt(count);
    if (result < 1) return null;

    return (
        <span style={{ color: '#f9a51a', display: 'inline-block', minWidth: 75 }}>
            {[...Array(result)].map((value, index) => (
                <StarFilled key={index} />
            ))}
        </span>
    );
};

export default HotelStars;
