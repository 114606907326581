import React, { useState } from 'react';
import moment from 'moment';
import { date } from 'legacy';
import { Button, DatePicker, Form, Row } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const MAXIMUM_DATES_NUMBER = 3;

export const getUTCDate = () => {
    return moment().subtract(moment().parseZone().utcOffset(), 'minutes');
};

const getActiveDatesNumber = (datesArray) => datesArray.filter(({ isPastPeriod }) => !isPastPeriod).length;

const PreferredSettingsDateSelector = ({ disableFirstSelectedDate, fieldName, getFieldValue, rules }) => {
    const [firstDate, setFirstDate] = useState(null);
    const [showPastPeriods, setShowPastPeriods] = useState(false);

    const onCalendarChange = (value) => {
        disableFirstSelectedDate && setFirstDate(value?.[0] || value?.[1]);
    };

    const getIsSelectedDate = (current, index) => {
        const value = getFieldValue();

        return !!value.find(({ dates }, dateIndex) => {
            if (!dates || index === dateIndex) return false;

            const [dateFrom, dateTo] = dates || [];
            if (dateFrom && dateTo) {
                const startDate = dateFrom.clone().subtract(1, 'days').set({ hour: 0, minute: 0 });
                const endDate = dateTo.clone().add(1, 'days').set({ hour: 23, minute: 59 });

                return current >= startDate && current <= endDate;
            }
            return false;
        });
    };

    const disabledDate = (current, index) => {
        if (moment(current).set({ hour: 23, minute: 59 }).isBefore(getUTCDate())) return true;

        if (firstDate && current.isSame(firstDate)) {
            return true;
        }

        return getIsSelectedDate(current, index);
    };

    return (
        <>
            <Form.List name={fieldName} label="Select dates">
                {(fields, { add, remove }) => {
                    return fields.map((field, index) => {
                        const isFirst = index === 0;
                        const allDatesValue = getFieldValue();

                        const { disabledDates, isPastPeriod } = allDatesValue[index];

                        const isDisableMinusButton = (disabledDates || []).some((item) => item);
                        const isDisablePlusButton =
                            getActiveDatesNumber(allDatesValue) >= MAXIMUM_DATES_NUMBER;

                        const newDateIndex = allDatesValue.findIndex((item) => item.isPastPeriod);

                        return (
                            <Row
                                key={field.key}
                                style={{ display: isPastPeriod && !showPastPeriods && 'none', marginTop: 14 }}
                            >
                                <Form.Item
                                    name={[field.name, 'dates']}
                                    style={{ marginRight: 15, flex: 1, marginBottom: 0 }}
                                    rules={rules}
                                >
                                    <RangePicker
                                        placeholder={['From', 'Till']}
                                        format={date.format.c}
                                        disabled={disabledDates}
                                        disabledDate={(current) => disabledDate(current, index)}
                                        onCalendarChange={onCalendarChange}
                                        onOpenChange={() => setFirstDate(null)}
                                    />
                                </Form.Item>
                                {isFirst ? (
                                    <Button
                                        disabled={isDisablePlusButton}
                                        onClick={() => add([], newDateIndex)}
                                    >
                                        <PlusOutlined />
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={isDisableMinusButton}
                                        onClick={() => remove(field.name)}
                                    >
                                        <MinusOutlined />
                                    </Button>
                                )}
                            </Row>
                        );
                    });
                }}
            </Form.List>

            {getFieldValue()?.some(({ isPastPeriod }) => isPastPeriod) && (
                <Button
                    type="link"
                    style={{ color: '#1890FF', padding: 0 }}
                    onClick={() => setShowPastPeriods(!showPastPeriods)}
                >
                    {showPastPeriods ? 'Hide past periods' : 'View past periods'}
                </Button>
            )}
        </>
    );
};

export default PreferredSettingsDateSelector;
