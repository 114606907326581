import React, { useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Form, Input } from 'antd';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

const BookingDiscardButton = ({ booking, loadBooking }) => {
    const [confirmationDiscardModalVisible, setConfirmationDiscardModalVisible] = useState(false);
    const { refCode } = useParams();

    const confirmationDiscard = (values) =>
        API.post({
            url: apiMethods.bookingDiscard(booking.bookingId),
            body: {
                reason: values.reason,
            },
            success: () => {
                notifications('Booking Discarded', 'success');
                loadBooking();
            },
            after: () => {
                setConfirmationDiscardModalVisible(false);
            },
        });

    return (
        <>
            <ConfirmationModal
                visible={confirmationDiscardModalVisible}
                onCancel={() => setConfirmationDiscardModalVisible(false)}
                headerText="You are about to discard a booking"
                submitText="Discard"
                onSubmit={confirmationDiscard}
            >
                <div>
                    This action <b>cannot</b> be undone. This will permanently close the booking{' '}
                    <b>{refCode}</b> in the system. Use the discard feature only when you absolutely sure the
                    booking has cancelled on a suppliers's side.
                </div>
                <Form.Item
                    name="confirmation"
                    label="Enter ref code"
                    rules={[
                        { required: true },
                        {
                            validator(rule, value) {
                                if (String(value).toLowerCase() !== refCode.toLowerCase()) {
                                    return Promise.reject('Please enter Booking Reference Code to confirm');
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                    style={{ marginTop: '20px' }}
                >
                    <Input placeholder={`Please type ${refCode} to discard`} />
                </Form.Item>
            </ConfirmationModal>
            <Button onClick={() => setConfirmationDiscardModalVisible(true)}>Discard</Button>
        </>
    );
};

export default BookingDiscardButton;
