import React, { useState } from 'react';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { price } from 'legacy';
import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import { date as formatterDate } from 'legacy';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import moment from 'moment';

const { Option } = Select;
const { Title } = Typography;

const TransferBalanceAccountOperations = ({ loadAgencyAccounts, agencyAccounts }) => {
    const [balanceOperationConfirmModalVisible, setBalanceOperationConfirmModalVisible] = useState(false);
    const [transferBalanceValues, setTransferBalanceValues] = useState({});
    const [transferBalanceForm] = Form.useForm();

    const openBalanceOperationConfirmModal = (values) => {
        setBalanceOperationConfirmModalVisible(true);
        setTransferBalanceValues(values);
    };

    const submitTransfer = (values) => {
        const { amount, reason, operation, account, date, bookingReferenceNumber, invoiceNumber } = values;
        API.post({
            url: apiMethods.accountOperation(account, operation),
            body: {
                amount: amount.replace(/,/g, '.'),
                currency: agencyAccounts.find((acc) => acc.id === parseInt(account)).currency,
                reason,
                date: formatterDate.format.apiDateOnly(date),
                bookingReferenceNumber,
                invoiceNumber,
            },
            success: () => {
                notifications('Done', 'success');
                loadAgencyAccounts();
                setBalanceOperationConfirmModalVisible(false);
                transferBalanceForm.resetFields();
            },
        });
    };

    return (
        <>
            <Title level={4}>Account Operations</Title>
            <Form
                form={transferBalanceForm}
                onFinish={openBalanceOperationConfirmModal}
                layout="vertical"
                style={{ maxWidth: 500 }}
                initialValues={{
                    date: moment(),
                }}
            >
                <Form.Item
                    name="account"
                    label="Account"
                    rules={[{ required: true, message: 'Please select Account' }]}
                >
                    <Select placeholder="Please Select">
                        {agencyAccounts?.map((agencyAccount, index) => (
                            <Option key={index} value={agencyAccount.id}>
                                {price(agencyAccount.balance)}{' '}
                                <span style={{ color: '#aaa' }}>at Account #{agencyAccount.id}</span>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="operation"
                    label="Type Operation"
                    rules={[{ required: true, message: 'Please select Operation' }]}
                >
                    <Select placeholder="Please Select">
                        <Select.OptGroup label="Debit Operations">
                            <Option value="decrease-manually">Decrease Manually</Option>
                        </Select.OptGroup>
                        <Select.OptGroup label="Credit Operations">
                            <Option value="increase-manually">Increase Manually</Option>
                            <Option value="replenish">Replenish</Option>
                            <Option value="payment-receipt">Payment Receipt</Option>
                        </Select.OptGroup>
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.account !== currentValues.account}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('account') ? (
                            <>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: 'Please enter Amount' }]}
                                >
                                    <Input
                                        type="number"
                                        placeholder="Amount"
                                        addonAfter={
                                            agencyAccounts.find(
                                                (acc) => acc.id === parseInt(getFieldValue('account'))
                                            ).currency
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="reason"
                                    label="Description"
                                    rules={[{ required: true, message: 'Please enter Description' }]}
                                >
                                    <Input placeholder="Description" />
                                </Form.Item>
                                <Form.Item
                                    name="date"
                                    label="Operation Date"
                                    rules={[{ required: true, message: 'Please select Date' }]}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item name="bookingReferenceNumber" label="Booking Reference Code">
                                    <Input placeholder="Booking Reference Code" />
                                </Form.Item>
                                <Form.Item name="invoiceNumber" label="Invoice Number">
                                    <Input placeholder="Invoice Number" />
                                </Form.Item>
                            </>
                        ) : null
                    }
                </Form.Item>
                <Button htmlType="submit">Process</Button>
            </Form>
            <ConfirmationSimpleModal
                visible={balanceOperationConfirmModalVisible}
                onCancel={() => setBalanceOperationConfirmModalVisible(false)}
                headerText="Are you sure?"
                submitText="Confirm"
                onConfirm={() => submitTransfer(transferBalanceValues)}
            >
                Manual operations are for correction of mistakes only
            </ConfirmationSimpleModal>
        </>
    );
};

export default TransferBalanceAccountOperations;
