import React from 'react';
import { Button, Dropdown, message, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';

const params = {
    invoice: {
        one: {
            url: apiMethods.bookingInvoice,
            errorMessage: 'Unable to download an invoice',
            format: 'pdf',
            buttonText: 'Download Invoice',
            filePrefix: 'Invoice-',
        },
        all: {
            url: apiMethods.bookingAllInvoices,
            errorMessage: 'Unable to download invoices archive',
            format: 'zip',
            buttonText: 'Download All Invoices',
            filePrefix: 'Invoices-',
        },
    },
    voucher: {
        one: {
            url: apiMethods.bookingVoucher,
            errorMessage: 'Unable to download a voucher',
            format: 'pdf',
            buttonText: 'Download Voucher',
            filePrefix: 'Voucher-',
        },
        all: {
            url: apiMethods.bookingAllVouchers,
            errorMessage: 'Unable to download vouchers archive',
            format: 'zip',
            buttonText: 'Download All Vouchers',
            filePrefix: 'Vouchers-',
        },
    },
};

export const downloadResponse = (res, fileName, errorMessage) => {
    if (res.status === 400) {
        try {
            res.text().then((rawData) => {
                const data = JSON.parse(rawData);
                notifications(data?.detail, 'warning');
            });
        } catch (e) {
            notifications(errorMessage, 'warning');
        }
    }
    if (res.status === 200) {
        res.blob().then((blobby) => {
            let anchor = document.createElement('a');
            document.body.appendChild(anchor);

            const objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
            message.success('Downloaded');
        });
    }
};

const downloadFile = (booking, { url, errorMessage, format, filePrefix }, isService) => {
    API.get({
        url: url(booking.bookingId, isService),
        response: (res) =>
            downloadResponse(
                res,
                `${filePrefix}${booking.bookingDetails.referenceCode}.${format}`,
                errorMessage
            ),
    });
};

const DownloadDocumentButton = ({ isService, booking, allButton, type }) => {
    if (allButton) {
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: 'one',
                            label: params[type].one.buttonText,
                        },
                        {
                            key: 'all',
                            label: params[type].all.buttonText,
                        },
                    ],
                    onClick: ({ key }) => downloadFile(booking, params[type][key]),
                }}
            >
                <Button>
                    <Space>
                        {params[type].one.buttonText}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        );
    }

    return (
        <Button onClick={() => downloadFile(booking, params[type].one, isService)}>
            {params[type].one.buttonText}
        </Button>
    );
};

export default DownloadDocumentButton;
