import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useParams } from 'react-router-dom';
import apiMethods from 'core/methods';
import { API } from 'htcore';

const createCode = (code) => {
    return code.split('-')?.pop();
};

const formatDataForTabs = (data) =>
    data
        .map(({ referenceCode, services, id }) => {
            const serviceData = services.map((service) => ({
                isService: true,
                key: service.referenceCode,
                id: service.id,
            }));

            return [{ key: referenceCode, id }, ...serviceData];
        })
        .flat()
        .sort((a, b) => a.id - b.id);

const createItemsArray = (itnData) => {
    const itnItems = formatDataForTabs(itnData);

    const result = [];

    let serviceIndex = 1;
    let bookingIndex = 1;

    itnItems.forEach(({ isService, key }) => {
        const index = isService ? serviceIndex++ : bookingIndex++;

        result.push({
            key,
            label: (
                <Link to={`/booking/${key}`}>{`${isService ? 'Service Booking' : 'Booking'} #${index}`}</Link>
            ),
        });
    });

    return result;
};

const BookingTabs = ({ itineraryNumber }) => {
    const { refCode } = useParams();
    const [items, setItems] = useState([]);

    useEffect(() => {
        API.get({
            url: apiMethods.bookingsAll,
            body: {
                $filter: `itineraryNumber eq '${createCode(itineraryNumber)}'`,
                $orderBy: 'checkInDate asc',
            },
            success: (result) => setItems(createItemsArray(result)),
        });
    }, []);

    return (
        <Menu
            mode="horizontal"
            items={items}
            selectedKeys={refCode}
            style={{ marginTop: -40, opacity: 1, height: 'auto', position: 'static' }}
        />
    );
};

export default BookingTabs;
