import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import { serializeRequest } from './serializer';
import AccommodationSearchForm from './accommodation-search-form';
import TablePages from 'components/table-pages';
import { columns } from './accommodation-table-settings';
import usePages from 'components/use-pages';
import { useTitle } from '../../../htcore';

const AccommodationSearch = ({ accommodationSearchForm }) => {
    useTitle('Mapper');

    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    useEffect(() => {
        accommodationSearchForm.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <AccommodationSearchForm accommodationSearchForm={accommodationSearchForm} onSubmit={onSubmit} />
            <TablePages
                columns={columns(Boolean(formValues?.rangeDates))}
                formValues={formValues}
                route={{ url: apiMethods.accommodationsSearch }}
                serializeRequest={serializeRequest}
                rowKey="htId"
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default AccommodationSearch;
