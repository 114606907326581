import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import mapperMethods from 'core/mapper-methods';
import { getValidation, mapFieldsToPayload } from 'legacy/excluded-form-utils';
import { Typography, Space, Row, Col, Form } from 'antd';
import { CloseCircle } from 'iconsax-react';
import { typesOptions } from 'components/selectors/type-selector';
import { EntitySelector } from 'common/entity-selector';
import PreferredSettingsDateSelector from './preferred-settings-date-selector';
import { FIELDS_WITH_EXCLUDE } from './accommodation-prefered-settings';

const { Title } = Typography;

const optionsGenerator = (list = []) =>
    list.map(({ code, id, name: label }) => ({ value: code || id, label }));

const getMarketOptions = (list = []) => list.filter(({ name }) => !name.includes('Unknown'));

const isEmptyDates = (datesArray) => {
    return !datesArray.find(({ dates }) => (dates && dates?.[0]) || dates?.[1]);
};

const RuleForm = ({ form, field, onRemove }) => {
    const [options, setOptions] = useState({});

    const getOptions = () => {
        const value = form.getFieldValue(['rules', field.name]);

        API.post({
            url: mapperMethods.preferredAvailableOptions,
            body: mapFieldsToPayload(FIELDS_WITH_EXCLUDE, value),
            success: setOptions,
        });
    };

    const isValidDates = () => {
        const { travelPeriods, bookingPeriods } = form.getFieldValue(['rules', field.name]);
        return !(isEmptyDates(travelPeriods) && isEmptyDates(bookingPeriods));
    };

    useEffect(() => {
        getOptions();
    }, []);

    return (
        <>
            <Row style={{ width: '100%', marginBottom: 20 }} align="middle">
                <Space align="baseline">
                    <Title level={4}>Rule {field.name + 1}</Title>
                    <div style={{ cursor: 'pointer', paddingTop: 4 }} onClick={onRemove}>
                        <CloseCircle size="20" color="#eb001b" variant="Bold" />
                    </div>
                </Space>
            </Row>

            <Row gutter={60}>
                <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                        {({ setFieldValue }) => (
                            <Row>
                                <Form.Item noStyle shouldUpdate>
                                    {({ validateFields }) => (
                                        <Form.Item
                                            name={[field.name, 'agencyMarketIdsType']}
                                            label="Type"
                                            style={{ marginRight: 15 }}
                                        >
                                            <EntitySelector
                                                allowClear={false}
                                                options={typesOptions}
                                                onChange={() => {
                                                    getOptions();
                                                    validateFields([[field.name, 'agencyMarketIds']]);
                                                    setFieldValue(
                                                        ['rules', field.name, 'agencyCountryCodes'],
                                                        []
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>

                                <Form.Item
                                    name={[field.name, 'agencyMarketIds']}
                                    label="Agency Market"
                                    style={{ flex: 1 }}
                                    rules={[
                                        getValidation(
                                            ['rules', field.name, 'agencyMarketIds'],
                                            ['rules', field.name, 'agencyMarketIdsType']
                                        ),
                                    ]}
                                >
                                    <EntitySelector
                                        multiple
                                        placeholder="ALL"
                                        options={optionsGenerator(getMarketOptions(options.agencyMarketIds))}
                                        onChange={() => {
                                            getOptions();
                                            setFieldValue(['rules', field.name, 'agencyCountryCodes'], []);
                                        }}
                                    />
                                </Form.Item>
                            </Row>
                        )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            return (
                                getFieldValue(['rules', field.name, 'agencyMarketIds'])?.length === 1 &&
                                getFieldValue(['rules', field.name, 'agencyMarketIdsType']) === 'include' && (
                                    <Row>
                                        <Form.Item noStyle shouldUpdate>
                                            {({ validateFields }) => (
                                                <Form.Item
                                                    name={[field.name, 'agencyCountryCodesType']}
                                                    label="Type"
                                                    style={{ marginRight: 15 }}
                                                >
                                                    <EntitySelector
                                                        allowClear={false}
                                                        options={typesOptions}
                                                        onChange={() =>
                                                            validateFields([
                                                                [field.name, 'agencyCountryCodes'],
                                                            ])
                                                        }
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            name={[field.name, 'agencyCountryCodes']}
                                            label="Agency Country"
                                            style={{ flex: 1 }}
                                            rules={[
                                                getValidation(
                                                    ['rules', field.name, 'agencyCountryCodes'],
                                                    ['rules', field.name, 'agencyCountryCodesType']
                                                ),
                                            ]}
                                        >
                                            <EntitySelector
                                                multiple
                                                placeholder="ALL"
                                                options={optionsGenerator(options.agencyCountryCodes)}
                                            />
                                        </Form.Item>
                                    </Row>
                                )
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={60} style={{ marginBottom: 40 }}>
                <Col span={12}>
                    <Title level={5}>Travel Dates</Title>
                    <PreferredSettingsDateSelector
                        disableFirstSelectedDate
                        fieldName={[field.name, 'travelPeriods']}
                        getFieldValue={() => form.getFieldValue(['rules', field.name, 'travelPeriods'])}
                    />
                </Col>
                <Col span={12}>
                    <Title level={5}>Booking Dates</Title>
                    <PreferredSettingsDateSelector
                        disableFirstSelectedDate
                        fieldName={[field.name, 'bookingPeriods']}
                        getFieldValue={() => form.getFieldValue(['rules', field.name, 'bookingPeriods'])}
                        rules={[
                            {
                                validator: () =>
                                    isValidDates()
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Please select Travel or Booking period')),
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default RuleForm;
