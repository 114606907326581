import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';

const AgentChangeEmail = () => {
    const [changeEmailModalVisible, setChangeEmailModalVisible] = useState(false);
    const [agentChangeEmailForm] = Form.useForm();
    const { id, agentId } = useParams();

    const openChangeEmailModal = () => {
        setChangeEmailModalVisible(true);
    };

    const submit = () => {
        agentChangeEmailForm.validateFields().then((values) => {
            API.post({
                url: apiMethods.agentChangeEmail(id, agentId),
                body: values,
                success: () => {
                    notifications('Email Change Submitted, Confirm the email now', 'success');
                    setChangeEmailModalVisible(false);
                },
            });
        });
    };

    return (
        <>
            <Button onClick={() => setChangeEmailModalVisible(true)} style={{ marginBottom: 10 }}>
                Set New Email
            </Button>
            <ConfirmationSimpleModal
                visible={changeEmailModalVisible}
                onCancel={() => setChangeEmailModalVisible(false)}
                headerText="Enter Agent New Email"
                submitText="Change Email"
                onConfirm={submit}
            >
                <Form
                    form={agentChangeEmailForm}
                    onFinish={openChangeEmailModal}
                    layout="vertical"
                    style={{ maxWidth: 500 }}
                >
                    <Form.Item
                        name="email"
                        label="Agent New Email"
                        rules={[
                            { required: true, message: 'Please enter Agent new Email' },
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input placeholder="Agent New Email" />
                    </Form.Item>
                </Form>
            </ConfirmationSimpleModal>
        </>
    );
};

export default AgentChangeEmail;
