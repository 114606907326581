import React from 'react';
import { Link } from 'react-router-dom';
import { HotelStars } from 'legacy';
import { ArrowRight } from 'iconsax-react';

export const columns = (withCounters) => (navigationState) =>
    [
        {
            header: <div style={{ paddingLeft: 42 }}>Name</div>,
            cell: (cell) => (
                <a href={`/mapper/accommodation/${cell.htId}`} target="_blank" className="row-link">
                    <div className="arrow-holder">
                        <span>
                            <ArrowRight size={14} />
                        </span>
                    </div>
                    <div>
                        {cell.name}
                        <br />
                        <small>{cell.htId}</small>
                        {Boolean(cell.giataId) && (
                            <>
                                <br />
                                <small>GIATA {cell.giataId}</small>
                            </>
                        )}
                    </div>
                </a>
            ),
        },
        {
            header: 'Country',
            cell: 'countryName',
        },
        {
            header: 'Locality',
            cell: 'localityName',
        },
        {
            header: 'Rating',
            cell: (cell) => <HotelStars count={cell.rating} />,
        },
        ...(withCounters
            ? [
                  {
                      header: 'Bookings (Nights)',
                      cell: (cell) =>
                          cell.bookingsCount + (cell.bookingsCount ? `(${cell.roomNightsCount})` : ''),
                  },
              ]
            : []),
    ];
