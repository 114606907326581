import React, { useState } from 'react';
import moment from 'moment';
import { date } from 'legacy';
import { Button, DatePicker, Form, Row } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export const getUTCDate = () => {
    return moment().subtract(moment().parseZone().utcOffset(), 'minutes');
};

const DateRangesSelector = ({
    maxDatesNumber,
    disableFirstSelectedDate,
    fieldName,
    getFieldValue,
    disabledFields,
    rules,
}) => {
    const [firstDate, setFirstDate] = useState(null);

    const onCalendarChange = (value) => {
        disableFirstSelectedDate && setFirstDate(value?.[0] || value?.[1]);
    };

    const getIsSelectedDate = (current, index) => {
        const value = getFieldValue();

        return !!value.find((date, dateIndex) => {
            if (!date || index === dateIndex) return false;

            const [dateFrom, dateTo] = date;
            if (dateFrom && dateTo) {
                const startDate = dateFrom.clone().subtract(1, 'days').set({ hour: 0, minute: 0 });
                const endDate = dateTo.clone().add(1, 'days').set({ hour: 23, minute: 59 });

                return current >= startDate && current <= endDate;
            }
            return false;
        });
    };

    const disabledDate = (current, index) => {
        if (moment(current).set({ hour: 23, minute: 59 }).isBefore(getUTCDate())) return true;

        if (firstDate && current.isSame(firstDate)) {
            return true;
        }

        return getIsSelectedDate(current, index);
    };

    return (
        <Form.List name={fieldName} label="Select dates">
            {(fields, { add, remove }) => {
                return fields.map((field, index) => {
                    const isFirst = index === 0;
                    const disabledFieldsArray = disabledFields?.(field.key) || null;
                    const isDisableMinusButton = (disabledFieldsArray || []).some((item) => item);

                    const isDisablePlusButton = maxDatesNumber && fields.length === maxDatesNumber;

                    return (
                        <Row key={field.key}>
                            <Form.Item {...field} style={{ marginRight: 15, flex: 1 }} rules={rules}>
                                <RangePicker
                                    placeholder={['From', 'Till']}
                                    format={date.format.c}
                                    disabled={disabledFieldsArray}
                                    disabledDate={(current) => disabledDate(current, index)}
                                    onCalendarChange={onCalendarChange}
                                    onOpenChange={() => setFirstDate(null)}
                                />
                            </Form.Item>
                            {isFirst ? (
                                <Button disabled={isDisablePlusButton} onClick={() => add([])}>
                                    <PlusOutlined />
                                </Button>
                            ) : (
                                <Button disabled={isDisableMinusButton} onClick={() => remove(field.name)}>
                                    <MinusOutlined />
                                </Button>
                            )}
                        </Row>
                    );
                });
            }}
        </Form.List>
    );
};

export default DateRangesSelector;
