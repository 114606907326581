import React, { useEffect, useState } from 'react';
import { EntitySelector } from 'common/entity-selector';
import apiMethods from 'core/methods';
import { API } from 'htcore';

const optionsGenerator = (list) =>
    list.map((key) => ({
        value: key,
        label: key,
    }));

const CurrencySelector = ({ url, ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        API.get({
            url: url || apiMethods.companyCurrencies(),
            success: (data) => setOptions(optionsGenerator(data)),
            after: () => setLoading(false),
        });
    }, [url]);

    return (
        <EntitySelector
            placeholder="None"
            {...props}
            maxTagCount={options?.length ? options.length - 1 : 3}
            options={options}
            loading={loading}
        />
    );
};

export default CurrencySelector;
