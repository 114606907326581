import React from 'react';
import moment from 'moment/moment';
import { date } from 'legacy';
import { BOOKING_STATUS } from 'htcore/enum';
import { Card, Descriptions } from 'antd';

const ServiceDetailsRoom = ({ booking, room, index }) => {
    const details = booking.bookingDetails;
    const isCancelled = details.status === BOOKING_STATUS.Cancelled;

    return (
        <Card
            type="inner"
            title={
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <b>
                        {isCancelled ? <span style={{ color: 'red' }}>Cancelled Room: </span> : ''}{' '}
                        {details.roomDetails.length > 0 && 'Room ' + (index + 1) + ': '}
                        {room.roomName}
                    </b>
                    <span>{room.roomCode}</span>
                </div>
            }
            style={{ minWidth: 600, ...(isCancelled ? { borderColor: '#ffbbbb' } : {}) }}
        >
            <Descriptions column={1} labelStyle={{ width: 180 }}>
                {isCancelled && (
                    <Descriptions.Item label="Cancelled">
                        {date.format.e(booking.cancellationDate)}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="Service Type">{details.serviceName}</Descriptions.Item>
                <Descriptions.Item label="Guests">
                    <div>
                        <strong>
                            {`${room.adults} Adults` + (room.children ? `, ${room.children} Children` : '')}
                        </strong>
                        <p>
                            {room.title} {room.firstName} {room.lastName}
                        </p>
                    </div>
                </Descriptions.Item>

                <Descriptions.Item label="Deadline">
                    {details.isNonRefundable ? (
                        <strong>Non Refundable</strong>
                    ) : details?.deadlineDate ? (
                        <div>{moment(details?.deadlineDate).format('dddd, DD MMMM yyyy, HH:mm')}</div>
                    ) : (
                        <div>No data</div>
                    )}
                </Descriptions.Item>

                {booking.invoiceNumber && (
                    <Descriptions.Item label="Invoice number">{booking.invoiceNumber}</Descriptions.Item>
                )}
            </Descriptions>
        </Card>
    );
};

export default ServiceDetailsRoom;
