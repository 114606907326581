import React, { useEffect, useState } from 'react';
import { RequestSelector } from 'common/request-selector';
import apiOsakaMethods from 'core/osaka-methods';
import apiShimabaraMethods from 'core/shimabara-methods';
import { API } from 'htcore';
import { mapFieldsToPayload } from 'legacy/excluded-form-utils';
import { formatBody } from './format-body';

const optionsGenerator = (list = []) =>
    list.map((value) => ({
        value: value.htId,
        label: value.name,
    }));

const defaultRequestBody = {
    countryCodes: [],
    excludedCountryCodes: [],
    supplierCodes: [],
    excludedSupplierCodes: [],
    localityHtIds: [],
    excludedLocalityHtIds: [],
    ratings: [],
    excludedRatings: [],
    chainIds: [],
    excludedChainIds: [],
    brandIds: [],
    excludedBrandIds: [],
};

const MarkupAccommodationSelector = ({ formValues, options, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(null);
    const [requestBody, setRequestBody] = useState(defaultRequestBody);

    useEffect(() => {
        const restore = [].concat(props.value).filter((v) => v);
        if (restore.length) {
            Promise.all(
                restore.map((htId) =>
                    API.get({
                        osaka: apiOsakaMethods.predictionsRestore(htId),
                    })
                )
            ).then((results) => {
                const newOptions = restore
                    .map((item, index) =>
                        results[index]?.predictionText
                            ? {
                                  value: item,
                                  label: results[index].predictionText,
                              }
                            : null
                    )
                    .filter((v) => v);
                setDefaultOptions(newOptions);
            });
        }
    }, []);

    // Change the data format according to the task HP-6865
    const createRequestBody = () => {
        const {
            accommodationMarketIds,
            accommodationCountryCodes,
            accommodationCountryCodesType,
            accommodationSupplierTypes,
            accommodationSupplierCodes,
            accommodationSupplierCodesType,
        } = formValues || {};

        const formattedValues = mapFieldsToPayload(
            [
                'accommodationLocalityHtIds',
                'accommodationRatings',
                'accommodationBrandIds',
                'accommodationChainIds',
            ],
            formValues || {}
        );

        return {
            // format market and country fields
            ...formatBody(
                accommodationMarketIds,
                accommodationCountryCodes,
                accommodationCountryCodesType,
                options.accommodationCountryCodes
            ),
            // format supplier type and supplier codes fields
            ...formatBody(
                accommodationSupplierTypes,
                accommodationSupplierCodes,
                accommodationSupplierCodesType,
                options.accommodationSupplierCodes,
                'supplierCodes',
                'excludedSupplierCodes'
            ),
            localityHtIds: formattedValues?.accommodationLocalityHtIds || [],
            excludedLocalityHtIds: formattedValues?.excludedAccommodationLocalityHtIds || [],
            ratings: formattedValues?.accommodationRatings || [],
            excludedRatings: formattedValues?.excludedAccommodationRatings || [],
            chainIds: formattedValues?.accommodationChainIds || [],
            excludedChainIds: formattedValues?.excludedAccommodationChainIds || [],
            brandIds: formattedValues?.accommodationBrandIds || [],
            excludedBrandIds: formattedValues?.excludedAccommodationBrandIds || [],
        };
    };

    useEffect(() => {
        setRequestBody(createRequestBody());
    }, [formValues, options]);

    return (
        <RequestSelector
            clearCache
            requestMethod="post"
            request={(value) => ({
                shimabara: apiShimabaraMethods.accommodations(value),
                body: requestBody,
            })}
            defaultOptions={defaultOptions}
            optionsGenerator={optionsGenerator}
            placeholder="Any Locality"
            {...props}
        />
    );
};

export default MarkupAccommodationSelector;
