const a = '/admin/reports';

const apiReportsMethods = {
    agencyConnectivityReport: `${a}/direct-connectivity-report/agency-wise`,
    agencyProductivityReport: `${a}/agencies-productivity-report`,
    pendingSupplierReferenceReport: `${a}/pending-supplier-reference-report`,
    confirmedBookingsReport: `${a}/confirmed-bookings-report`,
    finalizedBookingsReport: `${a}/finalized-bookings-report`,
    completedBookingsReport: `${a}/completed-bookings-report`,
    hotelWiseBookingReport: `${a}/hotel-wise-booking-report`,
    cancellationDeadlineReport: `${a}/cancellation-deadline-report`,
    thirdPartySuppliersReport: `${a}/third-party-suppliers-report`,
    vccBookingsReport: `${a}/vcc-bookings-report`,
    hotelProductivityReport: `${a}/hotel-productivity-report`,
    cancelledBookingsReport: `${a}/cancelled-bookings-report`,
    fullBookingsReport: `${a}/full-bookings-report`,
    paymentLinkReport: `${a}/payment-link-report`,
    agenciesReport: `${a}/agencies-report`,
    markupAndProfitReport: `${a}/booked-cancelled-24h`,
};

export default apiReportsMethods;
