import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import { Loader, HotelStars } from 'legacy';
import { Button } from 'antd';
import Amenities from 'components/legacy/amenities';
import Gallery from 'components/legacy/gallery';
import apiMapperMethods from 'core/mapper-methods';
import AccommodationVccSettings from './accommodation-vcc-settings';
import AccommodationActions from './accommodation-actions';
import AccommodationPaymentTypeSettings from './accommodation-payment-type-settings';

const VCC_SETTINGS_SUPPLIERS = ['hyperGuest', 'roibosJuniper'];

const AccommodationDetailsTemporaryPage = () => {
    const [supplier, setSupplier] = useState(null);
    const [data, setData] = useState(null);
    const { htId } = useParams();

    const getData = () => {
        if (!data) {
            return;
        }
        if (supplier) {
            return data.suppliersRawAccommodationData[supplier].data;
        } else {
            return data.data;
        }
    };

    const load = () => {
        API.get({
            url: apiMapperMethods.accommodationDetailedData(htId),
            success: setData,
        });
    };

    useEffect(() => {
        if (htId) {
            load();
        }
    }, []);

    const details = getData();

    if (!details) return <Loader page />;

    const description =
        details.textualDescriptions?.[0]?.description || details.textualDescriptions?.[1]?.description;

    return (
        <div className="search-results block" style={{ paddingRight: 30 }}>
            <h3>
                HtId: <strong>{data.htId}</strong>
                <br />
                Status: <strong>{data.isActive ? 'Active' : 'Inactive'}</strong>
            </h3>

            <AccommodationActions htId={data.htId} isActive={data.isActive} reload={load} />

            {data.manualCorrectedData && (
                <div style={{ margin: '20px 0' }}>
                    Manual corrected data: {JSON.stringify(data.manualCorrectedData)}
                </div>
            )}

            <div style={{ margin: '25px 0 10px', display: 'flex', flexWrap: 'wrap' }}>
                <Button
                    type={supplier ? 'default' : 'primary'}
                    onClick={() => setSupplier(null)}
                    style={{ marginRight: 20, marginBottom: 15, height: 'auto' }}
                >
                    Result
                </Button>
                {Object.keys(data.suppliersRawAccommodationData)
                    .sort()
                    .map((item) => {
                        const itemData = data.suppliersRawAccommodationData[item];
                        const availabilityDescription = itemData?.availabilityDescription;
                        const isAvailable = itemData?.isAvailable;

                        return (
                            <Button
                                type={supplier !== item ? 'default' : 'primary'}
                                onClick={() => setSupplier(item)}
                                key={item}
                                style={{ marginRight: 20, marginBottom: 15, height: 'auto' }}
                            >
                                <strong>{item}</strong>:{' '}
                                {data.suppliersRawAccommodationData[item].supplierCode}
                                {availabilityDescription && !isAvailable && (
                                    <p style={{ fontSize: 10, margin: 0 }}>
                                        <strong>{availabilityDescription}</strong>
                                    </p>
                                )}
                            </Button>
                        );
                    })}
            </div>

            {VCC_SETTINGS_SUPPLIERS.includes(supplier) && (
                <AccommodationVccSettings
                    htId={data.htId}
                    supplier={supplier}
                    supplierCode={data?.suppliersRawAccommodationData?.[supplier]?.supplierCode}
                />
            )}

            <AccommodationPaymentTypeSettings
                htId={data.htId}
                supplier={supplier}
                supplierData={data?.suppliersRawAccommodationData?.[supplier]}
                allSupplersData={data?.suppliersRawAccommodationData}
                getData={load}
            />

            <div className="head" id="overview" style={{ marginTop: 30 }}>
                <div className="title">
                    <h1>{details.name}</h1>
                    <HotelStars count={details.rating} />
                </div>
                <div style={{ lineHeight: '24px', fontSize: 16 }}>
                    <a
                        href={`https://www.google.com/maps/place/${details.location.coordinates.latitude},${details.location.coordinates.longitude}`}
                        className="link"
                        target="_blank"
                    >
                        {details.location.coordinates.latitude}, {details.location.coordinates.longitude}
                    </a>
                    <br />
                    {details.location.address}
                </div>
                <div style={{ lineHeight: '24px', fontSize: 14, margin: '16px 0' }}>
                    Phones: {details.contacts.phones.join(', ') || 'None'}
                    <br />
                    Websites: {details.contacts.webSites.join(', ') || 'None'}
                    <br />
                    Emails: {details.contacts.emails.join(', ') || 'None'}
                    <br />
                    {Boolean(details.contacts.faxes.length) && (
                        <>
                            Faxes: {details.contacts.faxes.join(', ')}
                            <br />
                        </>
                    )}
                </div>
            </div>
            <div className="text">
                <div dangerouslySetInnerHTML={{ __html: description }} />
                {details.schedule.checkInTime && (
                    <div className="checkin-time">
                        Check-in from{' '}
                        {details.schedule.checkInTime?.substr(0, 5) || details.schedule.checkInTime}
                    </div>
                )}
                {details.schedule.checkInTime && (
                    <div className="checkin-time">
                        Check-out till{' '}
                        {details.schedule.checkOutTime?.substr(0, 5) || details.schedule.checkOutTime}
                    </div>
                )}
            </div>

            <div className="billet-wrapper contract" style={{ marginTop: 30 }}>
                <div className="another" id="information">
                    <h2>Accommodation Amenities</h2>
                    <Amenities hotel={details} />
                </div>
            </div>
            <Gallery pictures={details?.photos} />
        </div>
    );
};

export default AccommodationDetailsTemporaryPage;
