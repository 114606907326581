import React from 'react';
import { Form } from 'antd';
import { getTypeFieldName } from 'legacy/excluded-form-utils';
import { EntitySelector } from 'common/entity-selector';

export const typesOptions = [
    { label: 'Include', value: 'include' },
    { label: 'Except', value: 'except' },
];

const TypeSelector = ({ name }) => (
    <Form.Item noStyle shouldUpdate>
        {({ validateFields }) => (
            <Form.Item name={getTypeFieldName(name)} label="Type" style={{ marginRight: 15 }}>
                <EntitySelector
                    allowClear={false}
                    options={typesOptions}
                    onChange={() => validateFields([name])}
                />
            </Form.Item>
        )}
    </Form.Item>
);

export default TypeSelector;
