import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import { Form } from 'antd';
import SearchTable from './search-table';

const SupplierTable = ({ url, id, form }) => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            API.get({
                url: url(id),
                success: (result) => {
                    setList(Object.keys(result).map((item) => ({ name: item, id: item })));
                    form.setFieldsValue(result);
                },
                after: () => setLoading(false),
            });
        }
    }, [id]);

    return (
        <Form layout="vertical" form={form}>
            <SearchTable
                loading={loading}
                withCheckbox
                tableTitle={`Supplier List (${list?.length || 0})`}
                list={list}
                locale={{
                    emptyText: loading ? (
                        <Loader />
                    ) : (
                        <b style={{ color: '#231f20' }}>Please Select Location First</b>
                    ),
                }}
            />
        </Form>
    );
};

export default SupplierTable;
