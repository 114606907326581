import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import OidcInstance from 'htcore/auth/oidc-instance';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from './methods';
import { notifications } from 'components/notification';
import { Loader } from 'legacy';
import $auth from 'stores/auth';

const { Title } = Typography;

const AccountInvite = () => {
    const { email, code } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const process = (register) => {
        const processToRegister = register
            ? {
                  redirectToRegister: true,
              }
            : {};

        OidcInstance().signinRedirect({
            state: window.location.pathname,
            extraQueryParams: {
                userMail: email,
                ...processToRegister,
            },
        });
    };

    if (code && email) {
        OidcInstance()
            .getUser()
            .then((auth) => {
                if (auth?.access_token) {
                    API.post({
                        url: apiMethods.adminRegister,
                        body: code,
                        success: () => {
                            notifications('Registered', 'success');
                            OidcInstance()
                                .signinSilent()
                                .then(() => {
                                    API.get({
                                        url: apiMethods.adminCurrentAuth,
                                        success: (result) => {
                                            $auth.setInformation(result);
                                            navigate('/');
                                        },
                                    });
                                });
                        },
                        error: () => {
                            notifications('Unable to accept invitation', 'warning');
                            navigate('/');
                        },
                    });
                } else {
                    setLoading(false);
                }
            });
    }

    if (loading) {
        return <Loader page />;
    }

    return (
        <div style={{ margin: '0 30px 30px 30px' }}>
            <img src="/images/logo/logo.png" alt="Happytravel.com" style={{ width: 130, marginBottom: 40 }} />

            <Title level={4}>Hello! You was invited as Administrator</Title>

            <div style={{ marginTop: 60 }}>
                <Button type="primary" onClick={() => process(false)}>
                    Process with Existing Login
                </Button>
            </div>
            <div style={{ marginTop: 20 }}>
                <Button onClick={() => process(true)}>Create a New Account</Button>
            </div>
        </div>
    );
};

export default AccountInvite;
