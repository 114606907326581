import React from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiGifuMethods from 'core/gifu-methods';
import { notifications } from 'components/notification';
import { Button, Descriptions } from 'antd';
import $auth from 'stores/auth';

const AccountSummary = ({ data, updateData }) => {
    const { id } = useParams();

    const changeStatus = () => {
        const url = data?.isActive
            ? apiGifuMethods.vendorAccountDeactivate
            : apiGifuMethods.vendorAccountActivate;

        API.post({
            gifu: url(...id.split('-')),
            body: { administratorName: $auth.fullName, administratorId: $auth?.information?.id },
            success: () => {
                notifications('Status Changed!', 'success');
                updateData();
            },
        });
    };

    return (
        <Descriptions
            bordered
            layout="vertical"
            size="small"
            style={{ margin: '30px 0', position: 'relative' }}
        >
            <Descriptions.Item label="VCC Currency">{data?.currency}</Descriptions.Item>
            <Descriptions.Item label="Active CURs for Account">
                {data?.isExcluded && 'Exclude '}{' '}
                {data?.convertedCurrencies?.length ? data?.convertedCurrencies?.join(', ') : 'All'}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
                <span style={{ marginRight: 18 }}>{data?.isActive ? 'Active' : 'Inactive'}</span>
                <Button onClick={changeStatus}>{data?.isActive ? 'Deactivate' : 'Activate'}</Button>
            </Descriptions.Item>
        </Descriptions>
    );
};

export default AccountSummary;
