import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiGifuMethods from 'core/gifu-methods';
import { Loader } from 'legacy';
import { Button, Row, Table, Typography } from 'antd';
import { columns } from './accounts-list-table-settings';
import NewAccountModal from './new-account-modal';
import AccountsHistory from './accounts-history';

const { Title } = Typography;

const getColumns = (list, columnsFunc) => {
    return list?.length
        ? columnsFunc().map((column) => ({
              title: column.header,
              ...(typeof column.cell === 'string'
                  ? { dataIndex: column.cell }
                  : { render: (text, record) => column.cell(record) }),
              key: typeof column.cell === 'string' ? column.cell : column.title,
          }))
        : null;
};

const createHistoryData = (data) =>
    data
        .map(({ logs, accountNumber }) => logs.map((log) => ({ accountNumber, ...log })))
        .flat()
        .sort((a, b) => new Date(b.created) - new Date(a.created))
        .slice(0, 15);

const AccountsListPage = () => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const getData = () => {
        setLoading(true);
        API.get({
            gifu: apiGifuMethods.vendors,
            success: (result) => {
                setList(result);
                setHistory(createHistoryData(result));
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Active VCC Accounts
                </Title>

                <Button type="primary" onClick={() => setOpenModal(true)}>
                    Add New VCC Account
                </Button>
            </Row>

            <Table
                pagination={false}
                dataSource={list || []}
                columns={getColumns(list, columns)}
                loading={loading}
                rowKey={(_, index) => index}
                locale={{
                    emptyText: loading ? <Loader /> : 'Nothing to Show',
                }}
                style={{ minHeight: 1000 }}
            />

            {openModal && <NewAccountModal onClose={() => setOpenModal(false)} updateData={getData} />}

            <AccountsHistory history={history} />
        </>
    );
};

export default AccountsListPage;
