import React, { useState } from 'react';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Space } from 'antd';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const SupplierControlButtons = ({ supplier, loadSupplier, code }) => {
    const [enabled, setEnabled] = useState(null);
    const [enabledHeaderText, setEnabledHeaderText] = useState(null);
    const [toggleSuppliersActivationModalVisible, setToggleActivationModalVisible] = useState(false);

    const openToggleSuppliersActivationModal = (activationType) => {
        if (activationType === 'Disabled') {
            setEnabledHeaderText(
                'Selected Supplier will be deactivated for all agencies in our system. Do you really wish to process?'
            );
        } else if (activationType === 'Enabled') {
            setEnabledHeaderText('Selected Supplier will be activated. Are you sure?');
        } else if (activationType === 'TestOnly') {
            setEnabledHeaderText('Supplier will be enabled only for tests.');
        } else {
            setEnabledHeaderText('Supplier that was enabled only for tests will be disabled.');
        }
        setToggleActivationModalVisible(true);
        setEnabled(activationType);
    };

    const toggleEnabled = (value) => {
        return API.put({
            sunpu: sunpuMethods.supplierEnableState(code),
            body: {
                state: enabled,
                reason: value.reason,
                adminName: [$auth.information.firstName, $auth.information.lastName]
                    .filter(Boolean)
                    .join(' '),
            },
            success: () => {
                notifications('Success', 'success');
                loadSupplier();
            },
            after: () => {
                setToggleActivationModalVisible(false);
                setEnabled(null);
                setEnabledHeaderText('');
            },
        });
    };

    if (!$auth.permitted('SupplierManagement') || !supplier) {
        return null;
    }

    return (
        <>
            <Space direction="horizontal" style={{ marginBottom: 40 }}>
                {supplier?.status !== 'Enabled' ? (
                    <Button onClick={() => openToggleSuppliersActivationModal('Enabled')}>
                        Enable Supplier
                    </Button>
                ) : null}
                {supplier?.status !== 'Disabled' ? (
                    <Button onClick={() => openToggleSuppliersActivationModal('Disabled')}>
                        Disable Supplier
                    </Button>
                ) : null}
                {supplier?.status !== 'TestOnly' ? (
                    <Button onClick={() => openToggleSuppliersActivationModal('TestOnly')}>
                        Enable For Only Test
                    </Button>
                ) : null}
            </Space>
            <ConfirmationModal
                visible={toggleSuppliersActivationModalVisible}
                onCancel={() => setToggleActivationModalVisible(false)}
                headerText={enabledHeaderText}
                submitText="Confirm"
                onSubmit={toggleEnabled}
            />
        </>
    );
};

export default SupplierControlButtons;
