import React from 'react';
import Table from 'components/table';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const BankAccountsList = ({ accounts }) => (
    <>
        <Link to="./create">
            <Button style={{ marginBottom: 25 }}>Add an Account</Button>
        </Link>
        <Table
            list={accounts}
            columns={[
                {
                    header: 'ID',
                    cell: (cell) => <Link to={`./${cell.id}`}>{cell.id}</Link>,
                },
                {
                    header: 'Currency',
                    cell: (cell) => <Link to={`./${cell.id}`}>{cell.currency}</Link>,
                },
                {
                    header: 'Default',
                    cell: (cell) => (cell.isDefault ? 'Default' : 'No'),
                },
                {
                    header: 'Account Number',
                    cell: 'accountNumber',
                },
                {
                    header: 'Intermediary Bank',
                    cell: (cell) => cell.intermediaryBank?.bankName || 'No',
                },
            ]}
            textEmptyResult="Empty"
        />
    </>
);

export default BankAccountsList;
