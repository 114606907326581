import React from 'react';
import { getValidation } from 'legacy/excluded-form-utils';
import { Form, Row } from 'antd';
import { EntitySelector } from 'common/entity-selector';
import TypeSelector from './type-selector';

const FieldWithExclude = ({ name, label, options, ...props }) => (
    <Row>
        <TypeSelector name={name} />
        <Form.Item name={name} label={label} style={{ flex: 1 }} rules={[getValidation(name)]}>
            <EntitySelector multiple placeholder="ALL" options={options} {...props} />
        </Form.Item>
    </Row>
);

export default FieldWithExclude;
