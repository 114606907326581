import React from 'react';
import { Tree } from 'antd';

const DraggableArray = ({ list, updateList }) => {
    const onDrop = ({ dragNode, dropPosition }) => {
        updateList([
            ...list
                .slice(0, dropPosition === -1 ? 0 : dropPosition)
                .filter((item) => item !== dragNode.title),
            dragNode.title,
            ...list.slice(dropPosition === -1 ? 0 : dropPosition).filter((item) => item !== dragNode.title),
        ]);
    };

    return (
        <Tree
            draggable
            blockNode
            onDrop={onDrop}
            treeData={list.map((el, index) => ({
                title: el,
                key: index,
            }))}
        />
    );
};

export default DraggableArray;
