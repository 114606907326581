import React from 'react';
import { Radio } from 'antd';

const APRSelector = ({ agency, ...props }) => (
    <Radio.Group buttonStyle="solid" {...props}>
        <Radio.Button value="Hide">Hide</Radio.Button>
        <Radio.Button value="DisplayOnly">Display Only</Radio.Button>
        <Radio.Button value="CardPurchasesOnly">Card Purchases Only</Radio.Button>
        {agency.contractKind === 'VirtualAccountOrCreditCardPayments' && (
            <Radio.Button value="CardAndAccountPurchases">Card And Account Purchases</Radio.Button>
        )}
    </Radio.Group>
);

export default APRSelector;
