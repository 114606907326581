import React from 'react';
import { Loader } from 'legacy';
import { Table } from 'antd';
import TableExpandIcon from 'components/table-expand-icon';
import { nestedColumns, columns } from '../../bookings-table-settings';

const groupData = (list) => {
    const resultObject = {};

    const createList = (listData, isService) => {
        listData.forEach((item) => {
            const { itineraryNumber, totalPrice, currency } = item;
            const group = resultObject?.[itineraryNumber];

            if (group) {
                group.items.push({ ...item, isServiceCell: isService });

                const isSameCurrency = group.currency === currency && group.total;
                resultObject[itineraryNumber].total = isSameCurrency ? group.total + totalPrice : null;
            } else {
                resultObject[itineraryNumber] = {
                    ...item,
                    total: totalPrice,
                    currency,
                    items: [{ ...item, isServiceCell: isService }],
                };
            }

            item?.services && createList(item.services, true);
        });
    };

    createList(list);

    return Object.values(resultObject)
        .reverse()
        .map(({ items, ...rest }) => {
            return { ...rest, items: items.sort((a, b) => b.id - a.id) };
        });
};

const getColumns = (list, columnsFunc) => {
    return list?.length
        ? columnsFunc().map((column) => ({
              title: column.header,
              ...(typeof column.cell === 'string'
                  ? { dataIndex: column.cell }
                  : { render: (text, record) => column.cell(record) }),
              key: typeof column.cell === 'string' ? column.cell : column.title,
          }))
        : null;
};

const ExpandedRowRender = ({ bookingCount, list }) => {
    const hiddenRows = bookingCount - list.length;

    return (
        <>
            <Table
                pagination={false}
                rowKey="id"
                dataSource={list}
                columns={getColumns(list, (data) => columns(data, true))}
            />
            {!!hiddenRows && <b>And {hiddenRows} more bookings that are not matching the filters</b>}
        </>
    );
};

const BookingsItineraryList = ({ list, ...props }) => {
    return (
        <Table
            expandRowByClick
            pagination={false}
            dataSource={groupData(list || [])}
            columns={getColumns(list, nestedColumns)}
            expandable={{
                expandedRowRender: (row) => (
                    <ExpandedRowRender list={row.items} bookingCount={row.itineraryBookingsCount} />
                ),
                expandIcon: TableExpandIcon,
            }}
            loading={!list}
            rowKey="id"
            locale={{
                emptyText: list === null ? <Loader /> : 'Nothing to Show',
            }}
            style={{ minHeight: 1000 }}
            {...props}
        />
    );
};

export default BookingsItineraryList;
