import React, { useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

const ConfirmManuallyButton = ({ booking, loadBooking }) => {
    const [confirmManuallyModalVisible, setConfirmManuallyModalVisible] = useState(false);
    const { refCode } = useParams();

    const confirmManually = ({ reason }) => {
        API.post({
            url: apiMethods.confirmManually(booking.bookingId),
            body: {
                reason,
                confirmationDate: new Date().toISOString(),
            },
            success: () => {
                notifications('Booking Confirmed', 'success');
            },
            after: () => {
                loadBooking();
                setConfirmManuallyModalVisible(false);
            },
        });
    };

    return (
        <>
            <ConfirmationModal
                visible={confirmManuallyModalVisible}
                onCancel={() => setConfirmManuallyModalVisible(false)}
                headerText="You are about to confirm"
                submitText="Confirm"
                onSubmit={confirmManually}
            >
                <div>
                    This action <b>cannot</b> be undone. This will mark a payment for the booking{' '}
                    <b>{refCode}</b> as <b>paid</b> in the system.
                </div>
            </ConfirmationModal>
            <Button onClick={() => setConfirmManuallyModalVisible(true)}>Confirm Manually</Button>
        </>
    );
};

export default ConfirmManuallyButton;
