import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, DatePicker, Descriptions, Form, Input, InputNumber, Timeline } from 'antd';
import { JSONTree } from 'react-json-tree';
import { API } from 'htcore';
import { date, Loader } from 'legacy';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { notifications } from 'components/notification';
import SupplierConnectorInventory from './supplier-connector-inventory';

const SupplierMapperUpdater = ({ supplier }) => {
    const [status, setStatus] = useState(null);
    const [history, setHistory] = useState(null);
    const [isStartModalVisible, setStartModalVisibility] = useState(false);

    const loadHistory = () => {
        if (supplier.connectorUpdaterEndpoint) {
            API.get({
                urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'updater/history',
                success: (data) => setHistory(data),
            });
        }
    };

    const loadStatusAndHistory = () => {
        const timeout = setTimeout(() => {
            setStatus(false);
        }, 500);
        if (supplier.connectorUpdaterEndpoint) {
            API.get({
                urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'updater/status',
                success: (data) => {
                    setStatus(data);
                    loadHistory();
                    clearTimeout(timeout);
                },
                error: () => {
                    setStatus(null);
                },
            });
        }
    };

    useEffect(() => {
        if (supplier) {
            loadStatusAndHistory();
        }
    }, [supplier]);

    const onStart = (body) => {
        if (body.modifiedDate) {
            body.modifiedDate = date.format.api(body.modifiedDate);
        }
        return API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'updater/start',
            body,
            success: () => {
                setTimeout(loadStatusAndHistory, 200);
                notifications('Connector Updater Launched', 'success');
            },
        });
    };

    const onStop = () => {
        API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'updater/cancel',
            success: () => {
                setStartModalVisibility(false);
                notifications('Connector Updater Cancelled', 'success');
                setTimeout(loadStatusAndHistory, 200);
            },
        });
    };

    if (!supplier) return <Loader />;

    return (
        <>
            {status === false && (
                <Alert
                    message="No response from the Updater yet"
                    description="You need an active Open VPN connection to access this tab"
                    type="info"
                    showIcon
                    style={{ marginBottom: 30 }}
                />
            )}
            <Descriptions bordered layout="vertical" size="small" style={{ marginBottom: 30 }}>
                <Descriptions.Item label="Updater Status">
                    <span style={{ marginRight: 25 }}>
                        {status ? <b>{status.updaterStatus}</b> : <i>(Unknown)</i>}
                    </span>
                    {status?.updaterStatus === 'Idle' && (
                        <Button onClick={() => setStartModalVisibility(true)}>Start Updater</Button>
                    )}
                    {status?.updaterStatus === 'Updating' && <Button onClick={onStop}>Stop Updater</Button>}
                </Descriptions.Item>
            </Descriptions>
            {Boolean(status) && <SupplierConnectorInventory supplier={supplier} />}
            {Boolean(history) && (
                <Descriptions bordered layout="vertical" size="small" style={{ marginBottom: 30 }}>
                    <Descriptions.Item label="Updater History">
                        <Timeline>
                            {history?.map((item) => (
                                <Timeline.Item key={item.id}>
                                    #{item.id} Started: <b>{date.format.e(item.startTime)}</b>
                                    {item.finishTime ? <> Finished: {date.format.e(item.finishTime)}</> : ''}
                                    <JSONTree
                                        data={item.parameters}
                                        theme="monokai"
                                        hideRoot
                                        invertTheme={true}
                                        getItemString={() => null}
                                        shouldExpandNode={() => true}
                                    />
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Descriptions.Item>
                </Descriptions>
            )}

            <ConfirmationModal
                visible={isStartModalVisible}
                onCancel={() => setStartModalVisibility(false)}
                headerText="Fill Update Request"
                submitText="Start Updater"
                onSubmit={onStart}
                reason={false}
                key="startModal"
            >
                <div>
                    <Form.Item name="isNewPropertyAvailable" valuePropName="checked">
                        <Checkbox>Is New Property Available</Checkbox>
                    </Form.Item>
                    <Form.Item name="batchSize" label="Batch Size" rules={[{ required: true }]}>
                        <InputNumber min={0} style={{ width: '100%' }} placeholder={0} />
                    </Form.Item>
                    <Form.Item name="modifiedDate" label="Modified Date">
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="innerSupplierCode" label="Inner Supplier Code">
                        <Input placeholder="Inner Supplier Code" />
                    </Form.Item>
                </div>
            </ConfirmationModal>
        </>
    );
};

export default SupplierMapperUpdater;
