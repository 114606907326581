import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTitle } from 'htcore';
import { Menu, PageHeader } from 'antd';
import AccountsListPage from './accounts/accounts-list';
import ProvidersList from './providers/providers-list';
import $auth from 'stores/auth';

const allMenuItems = [
    { label: <Link to="./accounts">VCC Forex Conversion Fees</Link>, key: 'accounts' },
    {
        label: <Link to="./providers">VCC Provider Priority</Link>,
        key: 'providers',
        permissions: [
            'AccountReplenish',
            'OfflinePayment',
            'AgencyBalanceObservation',
            'AgencyBalanceReplenishAndSubtract',
            'OfflineBooking',
            'PaymentLinkGeneration',
            'BalanceManualCorrection',
            'BookingReportGeneration',
            'FinanceReportGeneration',
            'MarketingReportGeneration',
            'ViewAgencies',
            'ViewAgents',
            'SupplierManagement',
            'ViewBookings',
            'CompanyAccountManagement',
            'ViewMarkups',
            'CurrencyManagement',
            'ConversionManagement',
            'ApiManagement',
        ],
    },
];

const ConversionFeesWrap = () => {
    useTitle('VCC Forex Conversion Fees');
    const location = useLocation();

    const items = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    return (
        <>
            <PageHeader
                title="VCC Forex Conversion Fees"
                breadcrumb={{
                    routes: [
                        { breadcrumbName: 'Conversion Management' },
                        { breadcrumbName: 'VCC Forex Conversion Fees' },
                    ],
                    itemRender: (route) => route.breadcrumbName,
                }}
            />

            <Menu mode="horizontal" items={items} selectedKeys={location.pathname.split('/')[2] || ''} />

            <Routes>
                <Route path="/accounts" element={<AccountsListPage />} />
                <Route path="/providers" element={<ProvidersList />} />
            </Routes>
        </>
    );
};

export default ConversionFeesWrap;
