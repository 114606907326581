import React, { useState } from 'react';
import { Dropdown, Row } from 'antd';
import { ArrowDown2 } from 'iconsax-react';

const LightDropdown = ({ loading, items = [], initialValue, onSelectCallback }) => {
    const [value, setValue] = useState({
        label: items.find((option) => initialValue === option.key)?.label || initialValue,
    });
    const [internalLoading, setInternalLoading] = useState(false);

    const onSelect = async ({ key }) => {
        setInternalLoading(true);

        const result = await onSelectCallback(key);

        if (result) {
            const option = items.find((item) => item.key === key);
            setValue(option);
        }

        setInternalLoading(false);
    };

    return (
        <Dropdown
            disabled={loading || internalLoading}
            menu={{ items, selectable: true, onSelect, selectedKeys: [value.label] }}
            trigger="click"
        >
            <Row justify="space-between" align="middle" style={{ minWidth: 160 }}>
                <span>{value.label}</span>
                <ArrowDown2 variant="Bold" size={14} color={'#888'} />
            </Row>
        </Dropdown>
    );
};

export default LightDropdown;
