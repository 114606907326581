import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import $auth from 'stores/auth';
import { Button, Table, Space, PageHeader, Pagination } from 'antd';
import { Loader } from 'legacy';
import { columns } from './restrictions-table-settings';
import SuppliersRestrictionsHistory from './suppliers-restrictions-history';

const PAGE_SIZE = 20;

const SuppliersRestrictionsList = () => {
    const [list, setList] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getData = () => {
        setList(null);

        API.get({
            url: apiMethods.supplierRestrictions,
            body: {
                top: PAGE_SIZE,
                skip: (page - 1) * PAGE_SIZE,
            },
            success: (result) => {
                setTotal(result.total);
                setList(result.supplierRestrictions);
            },
        });
    };

    useEffect(() => {
        getData();
    }, [page]);

    return (
        <>
            <PageHeader
                title="List of Restriction"
                extra={
                    <Space>
                        <Pagination
                            showQuickJumper={false}
                            showSizeChanger={false}
                            pageSize={PAGE_SIZE}
                            total={total}
                            current={page}
                            onChange={setPage}
                            showTotal={(total, range) => (
                                <div style={{ marginRight: 20 }}>
                                    {`${range[0]}-${range[1]}`} of <b>{total} items</b>
                                </div>
                            )}
                        />
                        {$auth.permitted('SupplierManagement') && (
                            <Link to="./create">
                                <Button type="primary">Add New Restriction</Button>
                            </Link>
                        )}
                    </Space>
                }
                style={{ paddingBottom: 16, paddingTop: 0 }}
            />

            <Table
                loading={!list}
                pagination={false}
                rowKey="supplierRestrictionId"
                dataSource={list}
                columns={columns}
                locale={{
                    emptyText: !list ? <Loader /> : 'Nothing to Show',
                }}
            />

            <SuppliersRestrictionsHistory />
        </>
    );
};

export default SuppliersRestrictionsList;
