import React, { useState, useEffect } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Checkbox, Form, PageHeader, Typography } from 'antd';
import $auth from 'stores/auth';
import { remapStatus } from 'legacy';
import { generateLabel } from 'legacy/generate-label';

const { Title } = Typography;

const NotificationSettingsPage = () => {
    useTitle('Notification Settings');

    const [notifications, setNotifications] = useState(null);
    const [notificationSettingsPageForm] = Form.useForm();

    useEffect(() => {
        API.get({
            url: apiMethods.adminCurrentNotificationSettings,
            success: setNotifications,
        });
    }, []);

    const submit = (values) => {
        const body = JSON.parse(JSON.stringify(notifications));
        Object.keys(values).forEach((notify) => {
            body[notify].enabledProtocols.Email = values[notify];
        });
        API.put({
            url: apiMethods.adminCurrentNotificationSettings,
            body,
            success: () => {
                notifications('Settings Saved!', 'success');
            },
        });
    };

    if (!notifications) {
        return null;
    }

    const list = Object.keys(notifications)
        .filter((notify) => notifications[notify].isMandatory === false)
        .filter((notify) => notifications[notify].enabledProtocols.Email !== undefined);

    return (
        <>
            <PageHeader title="Notifications Subscriptions" />
            <Title level={4}>You permitted to receive following notifications</Title>
            <ul>
                {$auth.notificationsList?.map((notification) => (
                    <li key={notification}>{remapStatus(notification)}</li>
                ))}
            </ul>
            <Title level={4} style={{ marginTop: 60 }}>
                You can select notifications you want to receive
            </Title>
            <Form
                form={notificationSettingsPageForm}
                initialValues={list.reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur]: notifications[cur].enabledProtocols.Email,
                    };
                }, {})}
                onFinish={submit}
            >
                {list?.map((role, index) => (
                    <Form.Item key={index} name={role} valuePropName="checked" style={{ marginBottom: 5 }}>
                        <Checkbox defaultChecked={false}>{generateLabel(role)}</Checkbox>
                    </Form.Item>
                ))}
                <Button htmlType="submit" style={{ marginTop: 30 }}>
                    Save Notifications Settings
                </Button>
            </Form>
        </>
    );
};

export default NotificationSettingsPage;
