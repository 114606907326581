import React from 'react';
import { Select } from 'antd';
import { RequestSelector } from 'common/request-selector';
import apiOsakaMethods from 'core/osaka-methods';

const { Option, OptGroup } = Select;

const optionsGenerator = (list = []) =>
    list.map((group) =>
        group.predictions.length ? (
            <OptGroup label={group.type}>
                {group.predictions.map((item) => (
                    <Option value={item.htId}>{item.predictionText}</Option>
                ))}
            </OptGroup>
        ) : null
    );

const AccommodationSelector = (props) => (
    <RequestSelector
        request={(value) => ({
            osaka: apiOsakaMethods.predictionsSearch,
            body: {
                text: value,
                ...(props.filter ? { types: props.filter } : {}),
            },
        })}
        groups
        optionsGenerator={optionsGenerator}
        placeholder="Any Accommodation"
        {...props}
    />
);

export default AccommodationSelector;
