import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import BuffersList from '../../buffers-list';

const remapData = (data) => {
    return data.map(({ currencyExchangeRate, auditLogs }) => {
        const mainLog = auditLogs?.[0];
        const { source, target } = currencyExchangeRate;

        return {
            ...currencyExchangeRate,
            ...mainLog,
            eventType: mainLog?.eventType || 'Create',
            id: currencyExchangeRate.id,
            children: auditLogs
                .filter((_, index) => !!index)
                .map((item) => ({
                    ...item,
                    source,
                    target,
                })),
        };
    });
};

const AccountBuffersListWrap = ({ disableBuffer, accountData, setCustomBufferCurrencies }) => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = () => {
        setLoading(true);

        API.get({
            url: apiMethods.vccRate(
                accountData.vendor,
                accountData.cardType,
                accountData.currency,
                accountData.accountNumber
            ),
            success: (result) => {
                setList(remapData(result));
                setCustomBufferCurrencies(result.map((item) => item?.currencyExchangeRate?.source));
            },
            after: () => setLoading(false),
        });
    };

    const onSubmitRate = (values, isEdit) => {
        API.post({
            url: isEdit ? apiMethods.rateUpdate : apiMethods.rateCreate,
            body: {
                ...values,
                vccProvider: accountData.vendor,
                cardType: accountData.cardType,
                accountNumber: accountData.accountNumber,
            },
            success: () => notifications('Saved!', 'success'),
            after: getData,
        });
    };

    return (
        <BuffersList
            loading={loading}
            disableBuffer={disableBuffer}
            list={list}
            updateData={getData}
            modalProps={{
                onSubmitRate,
                toCurrency: accountData.currency,
                sourceCurrencyOptions: accountData?.convertedCurrencies,
                isExcluded: accountData.isExcluded,
                getDeleteUrl: ({ id, userId }) => ({ tsutsujigasaki: apiMethods.deleteVccRate(id, userId) }),
            }}
        />
    );
};

export default AccountBuffersListWrap;
