import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import {
    Button,
    Checkbox,
    Descriptions,
    Form,
    Input,
    InputNumber,
    Menu,
    message,
    PageHeader,
    Popconfirm,
    Select,
    Space,
} from 'antd';
import LocalitySelector from 'components/selectors/locality-selector';
import { notifications } from 'components/notification';
import { isPhoneValid } from 'components/phone-masks';
import { Loader, copyToClipboard } from 'legacy';

const LETTERS = new RegExp(/^[0-9A-Za-zÀ-ÖØ-öø-ÿ $-)+-/:-?{-~!"^_`\[\]]+$/);

const AgenciesRequestItem = () => {
    const [request, setRequest] = useState(null);
    const [invite, setInvite] = useState(null);
    const [selectedKey, setSelectedKey] = useState('company');
    const [selectedLocality, setSelectedLocality] = useState('');
    const [id, setId] = useState(useParams().id);
    const [fulfillRequestForm] = Form.useForm();
    const [history, setHistory] = useState(null);
    const navigate = useNavigate();

    const creation = id === 'create';

    const loadRequest = (requestId) => {
        setRequest(null);
        setInvite(null);
        if (requestId) {
            setId(requestId);
        }
        API.get({
            url: apiMethods.agencyRequest(requestId || id),
            success: (result) => {
                setRequest(result);
                loadInvite(result);
            },
        });
        API.get({
            url: apiMethods.agencyRequestHistory(requestId || id),
            ignoreErrors: true,
            success: (result) => {
                setHistory(result);
            },
        });
    };

    const rejectRequest = () => {
        if (creation) return;

        API.post({
            url: apiMethods.agencyRequestReject(id),
            success: () => {
                notifications('Request Rejected!', 'success');
                loadRequest();
            },
            error: () => {
                message.warn('Error occurred during the rejection');
            },
        });
    };

    const recoverRequest = () => {
        API.post({
            url: apiMethods.agencyRequestRecover(id),
            success: () => {
                notifications('Request Recovered!', 'success');
                loadRequest();
            },
            error: () => {
                message.warn('Error occurred during the recovering');
            },
        });
    };

    const loadInvite = ({ agencyId, invitationCodeHash }) => {
        if (!invitationCodeHash) {
            return;
        }
        API.get({
            url: apiMethods.inviteMasterAgentDetails(agencyId, invitationCodeHash),
            ignoreErrors: true,
            success: (result) => {
                setInvite(result);
            },
        });
    };

    const resendInvite = () => {
        const { agencyId, invitationCodeHash } = request;
        API.post({
            url: apiMethods.inviteMasterAgentResend(agencyId, invitationCodeHash),
            success: () => {
                notifications('Master Agent Invitation Resent', 'success');
                setRequest((current) => ({ ...current, status: 'Done' }));
                loadRequest();
            },
        });
    };

    useEffect(() => {
        if (creation) {
            setRequest({});
            return;
        }
        loadRequest();
    }, []);

    const approveRequest = async (form) => {
        const {
            companyName,
            location,
            localityHtId,
            phoneNumber,
            companyEmail,
            companyAddress,
            legalAddress,
            zipPostalCode,
            fax,
            paymentMethod,
            notificationEmail,
            billingEmail,
            taxRegistrationNumber,
            vatNumber,
            website,
            agentFirstName: firstName,
            agentLastName: lastName,
            agentTitle: title,
            agentEmail: email,
            agentMobilePhone,
        } = form;

        const {
            requestId,
            agencyInfo: { id: agencyId },
        } = await API.post({
            url: apiMethods.agencies,
            body: {
                companyName,
                location,
                localityHtId,
                phoneNumber,
                companyEmail,
                companyAddress,
                legalAddress,
                zipPostalCode,
                fax,
                paymentMethod,
                notificationEmail,
                billingEmail,
                taxRegistrationNumber,
                vatNumber,
                website,
                agentEmail: email,
                ...(!creation ? { requestId: id } : { location: selectedLocality }),
            },
        });

        notifications(`Agency ${agencyId} created`, 'success');

        const inviteMasterAgent = await API.post({
            url: apiMethods.inviteMasterAgent(agencyId),
            body: {
                firstName,
                lastName,
                title,
                email,
                mobilePhone: String(agentMobilePhone).replace(/[^0-9]/g, ''),
            },
        });

        notifications(`Master Agent Invitation Submitted to ${email}`, 'success');

        loadRequest(requestId);
    };

    if (!request) return <Loader />;

    if (request.agencyId) {
        let invitationLink = 'https://happytravel.com';
        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'manage-dev.happytravel.com'
        ) {
            invitationLink = 'https://dev.happytravel.com';
        }
        invitationLink += `/signup/invite/${request.agentEmail}/${request.invitationCodeHash}`;
        return (
            <div className="block" style={{ paddingRight: 20 }}>
                <PageHeader onBack={() => navigate(-1)} title={`#${id}: ${request.companyName}`} />
                This request was fulfilled.
                <br />
                <Link to={`/agency/${request.agencyId}/agents`}>Agency {request.agencyId}</Link> was created.
                {'Created' === request.status && (
                    <div style={{ marginTop: 40 }}>
                        <div>
                            Master Agent: {request.agentFirstName} {request.agentLastName}
                            <br />
                            Invitation sent to {request.agentEmail} was not accepted yet
                        </div>
                        {invite ? <div>Invitation is not expired</div> : <div>Invitation Expired</div>}

                        <div style={{ marginTop: 40 }}>
                            <Form layout="vertical">
                                <Form.Item label="Invitation Link">
                                    <Input readOnly value={invitationLink} />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            copyToClipboard(
                                                `Here is a link for activating your profile on Happytravel.com: ${invitationLink} You should complete the registration by adding your personal details (Name, Second Name, Position). After that, you will receive a Verification Code to your email, which you will need to copy and use to complete the registration process.`
                                            )
                                        }
                                    >
                                        Copy Invitation for mail/messenger
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                        <div style={{ marginTop: 60 }}>
                            <Popconfirm
                                title="The previous invite links will become inactive"
                                onConfirm={resendInvite}
                                okText="Proceed"
                                cancelText="Discard"
                                placement="bottom"
                            >
                                <Button size="small">Generate and Send New Invitation</Button>
                            </Popconfirm>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (request.status === 'Rejected') {
        return (
            <div className="block" style={{ paddingRight: 20 }}>
                <PageHeader onBack={() => navigate(-1)} title={`#${id}: ${request.companyName}`} />
                <h2>This Request was Rejected</h2>
                {history ? (
                    <h3>
                        by{' '}
                        <Link to={`/administrators/${history.administratorId}/details`}>
                            {history.administrator}
                        </Link>{' '}
                        at {history.created}
                    </h3>
                ) : null}
                <div style={{ marginTop: 20 }}>
                    <Button onClick={recoverRequest}>Recover Request</Button>
                </div>
                <Descriptions
                    bordered
                    layout="horizontal"
                    column={1}
                    size="small"
                    style={{ margin: '40px 0' }}
                >
                    <Descriptions.Item label="Company Name">{request.companyName}</Descriptions.Item>
                    <Descriptions.Item label="Address">{request.companyAddress}</Descriptions.Item>
                    <Descriptions.Item label="Agent Name">
                        {request.agentFirstName} {request.agentLastName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">{request.phoneNumber}</Descriptions.Item>
                    <Descriptions.Item label="Email">{request.companyEmail}</Descriptions.Item>
                    <Descriptions.Item label="Website">{request.website}</Descriptions.Item>
                    <Descriptions.Item label="Created">{request.created}</Descriptions.Item>
                </Descriptions>
            </div>
        );
    }

    return (
        <div className="block" style={{ paddingRight: 20 }}>
            <PageHeader
                onBack={() => navigate(-1)}
                title={!creation ? `#${id}: ${request.companyName}` : 'Register New Agency'}
            />
            {history ? (
                <h3 style={{ margin: '-20px 0 50px', position: 'relative', zIndex: 10 }}>
                    This Request was previously Rejected by{' '}
                    <Link to={`/administrators/${history.administratorId}/details`}>
                        {history.administrator}
                    </Link>{' '}
                    at {history.created}
                </h3>
            ) : null}
            <Form
                form={fulfillRequestForm}
                initialValues={{
                    ...request,
                    localityHtId: null,
                    paymentMethod: null,
                    nonTRN: !Boolean(request.taxRegistrationNumber),
                }}
                layout="vertical"
                onFinish={approveRequest}
                style={{ maxWidth: 600, marginTop: -30 }}
            >
                <Menu
                    mode="horizontal"
                    items={[
                        {
                            label: 'Company Information',
                            key: 'company',
                        },
                        {
                            label: 'Payment Information',
                            key: 'payment',
                        },
                        {
                            label: 'Master Agent',
                            key: 'agent',
                        },
                    ]}
                    selectedKeys={selectedKey}
                    onSelect={({ key }) => setSelectedKey(key)}
                />
                <div hidden={'company' !== selectedKey}>
                    <div style={{ marginBottom: 20 }}>
                        Agent Name: {request.agentFirstName} {request.agentLastName}
                    </div>
                    <Form.Item
                        name="companyName"
                        label="Company Name"
                        rules={[
                            { required: true },
                            {
                                pattern: LETTERS,
                                message: 'No symbols allowed!',
                            },
                        ]}
                    >
                        <Input placeholder="Company Name / Agency Name" />
                    </Form.Item>
                    {!creation && (
                        <Form.Item name="location" label="Location" rules={[{ required: true }]}>
                            <Input placeholder="Location" />
                        </Form.Item>
                    )}
                    <Form.Item name="localityHtId" label="Locality" rules={[{ required: true }]}>
                        <LocalitySelector setSelectedLabel={setSelectedLocality} />
                    </Form.Item>
                    <Form.Item
                        name="companyAddress"
                        label="Address"
                        rules={[
                            { required: true },
                            {
                                pattern: LETTERS,
                                message: 'No symbols allowed!',
                            },
                        ]}
                    >
                        <Input placeholder="Address" />
                    </Form.Item>
                    <Form.Item name="zipPostalCode" label="Zip / Postal Code">
                        <Input placeholder="Postal Code" />
                    </Form.Item>
                    <Form.Item
                        name="legalAddress"
                        label="Legal Address"
                        rules={[
                            { required: true },
                            {
                                pattern: LETTERS,
                                message: 'No symbols allowed!',
                            },
                        ]}
                    >
                        <Input placeholder="Legal Address" />
                    </Form.Item>
                    <Space>
                        <Form.Item name="phoneNumber" label="Phone" rules={[{ required: true }]}>
                            <InputNumber
                                type="number"
                                prefix="+"
                                placeholder="Phone"
                                style={{ width: '100% ' }}
                                controls={false}
                            />
                        </Form.Item>
                        <Form.Item name="fax" label="Fax">
                            <InputNumber
                                type="number"
                                prefix="+"
                                placeholder="Fax"
                                style={{ width: '100% ' }}
                                controls={false}
                            />
                        </Form.Item>
                    </Space>
                    <Form.Item
                        name="companyEmail"
                        label="Company Email"
                        rules={[
                            { required: true },
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input placeholder="Company Email" />
                    </Form.Item>
                    <Form.Item name="website" label="Website">
                        <Input placeholder="Company Website" />
                    </Form.Item>
                    <Form.Item
                        name="notificationEmail"
                        label="Email For Notifications"
                        rules={[{ type: 'email', message: 'Please enter a valid email address.' }]}
                    >
                        <Input placeholder="Email For Agency Notifications" />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() =>
                                    fulfillRequestForm.validateFields().then(() => setSelectedKey('payment'))
                                }
                            >
                                Fill Payment Details
                            </Button>
                            {!creation ? (
                                <Popconfirm
                                    title={
                                        <div>Are you sure you want to reject the registration request</div>
                                    }
                                    okText="Confirm"
                                    cancelText="No"
                                    onConfirm={rejectRequest}
                                    placement="top"
                                >
                                    <Button>Reject Request</Button>
                                </Popconfirm>
                            ) : null}
                        </Space>
                    </Form.Item>
                </div>
                {'company' !== selectedKey && (
                    <div hidden={'payment' !== selectedKey}>
                        <Form.Item
                            name="billingEmail"
                            label="Billing Email"
                            rules={[
                                { required: true },
                                {
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input placeholder="Billing Email" />
                        </Form.Item>
                        <Form.Item
                            name="paymentMethod"
                            label="Preferred Payment Method"
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={[
                                    { label: 'Credit Card', value: 'CreditCard' },
                                    { label: 'Virtual Account', value: 'VirtualAccount' },
                                    { label: 'Offline', value: 'Offline' },
                                ]}
                                placeholder="Not Specified"
                            />
                        </Form.Item>
                        <Form.Item name="vatNumber" label="Vat Number">
                            <Input placeholder="Vat Number" />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.localityHtId !== currentValues.localityHtId ||
                                prevValues.nonTRN !== currentValues.nonTRN
                            }
                        >
                            {({ getFieldValue, setFieldValue }) =>
                                selectedLocality?.includes('United Arab Emirates') && (
                                    <>
                                        <div style={{ marginBottom: 10 }}>
                                            <Form.Item noStyle name="nonTRN" valuePropName="checked">
                                                <Checkbox
                                                    onChange={(event) => {
                                                        event.target.checked &&
                                                            setFieldValue('taxRegistrationNumber', '');
                                                    }}
                                                >
                                                    Non VAT Registered Agency
                                                </Checkbox>
                                            </Form.Item>
                                        </div>
                                        {!getFieldValue('nonTRN') && (
                                            <Form.Item
                                                name="taxRegistrationNumber"
                                                label="TRN"
                                                rules={[
                                                    { required: true },
                                                    { min: 15, max: 15, message: 'TRN is 15 digits long' },
                                                ]}
                                            >
                                                <Input maxLength={15} placeholder="Tax Registration Number" />
                                            </Form.Item>
                                        )}
                                    </>
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                onClick={() =>
                                    fulfillRequestForm.validateFields().then(() => setSelectedKey('agent'))
                                }
                            >
                                Fill Master Agent Details
                            </Button>
                        </Form.Item>
                    </div>
                )}
                {'company' !== selectedKey && 'payment' !== selectedKey && (
                    <div>
                        <Space>
                            <Form.Item
                                name="agentTitle"
                                label="Salutation"
                                rules={[{ required: true, message: 'Please enter Title' }]}
                            >
                                <Select placeholder="Select">
                                    <Option value="Mr">Mr.</Option>
                                    <Option value="Ms">Ms.</Option>
                                    <Option value="Miss">Miss</Option>
                                    <Option value="Mrs">Mrs.</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="agentFirstName"
                                label="Agent First Name"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: LETTERS,
                                        message: 'No symbols allowed!',
                                    },
                                ]}
                            >
                                <Input placeholder="Agent First Name" />
                            </Form.Item>
                            <Form.Item
                                name="agentLastName"
                                label="Agent Last Name"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: LETTERS,
                                        message: 'No symbols allowed!',
                                    },
                                ]}
                            >
                                <Input placeholder="Agent Last Name" />
                            </Form.Item>
                        </Space>
                        <Form.Item
                            name="agentMobilePhone"
                            label="Agent Mobile Phone"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        !value || isPhoneValid(String(value))
                                            ? Promise.resolve()
                                            : Promise.reject(new Error(`Mobile Phone Number is not correct`)),
                                },
                            ]}
                        >
                            <InputNumber
                                type="number"
                                prefix="+"
                                style={{ width: '100%' }}
                                placeholder="Mobile Phone"
                                controls={false}
                            />
                        </Form.Item>
                        <Form.Item
                            name="agentEmail"
                            label="Agent Email"
                            rules={[
                                { required: true },
                                {
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Fulfill Registration Request
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default AgenciesRequestItem;
