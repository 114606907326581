import moment from 'moment';

const format = {
    api: (date) => moment(date).format('YYYY-MM-DDT00:00:00') + 'Z',
    apiDateOnly: (date) => moment(date).format('YYYY-MM-DD'),
    apiTime: (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    a: (date) => (!date ? '' : moment(date).format('ddd, DD MMMM YYYY')),
    c: (date) => (!date ? '' : moment(date).format('DD-MM-YYYY')),
    e: (date) => (!date ? '' : moment(date).format('DD.MM.YYYY HH:mm:ss')),
    dots: (date) => (!date ? '' : moment(date).format('DD.MM.YYYY')),
    time: (date) => (!date ? '' : moment(date).format('HH:mm:ss')),
    shortTime: (date) => (!date ? '' : moment(date).format('HH:mm')),
    day: (date) => (!date ? '' : moment(date).format('MMMM Do')),
    shortDay: (date) => (!date ? '' : moment(date).format('MMM Do')),
    shortDayInverted: (date) => (!date ? '' : moment(date).format('DD MMM')),
    fullDate: (date) => (!date ? '' : moment(date).format('DD MMMM YYYY')),
};

const addMonth = (date, amount) => {
    let result = new Date(date),
        previous = result.getMonth();
    result.setMonth(previous + amount);
    return result;
};

const addDay = (date, amount) => {
    let result = new Date(date),
        previous = result.getDate();
    result.setDate(previous + amount);
    return result;
};

const isPast = (date) => {
    if (!date) return true;
    let result = new Date() > new Date(date);
    if (format.api(new Date()) === format.api(date)) result = false;
    return result;
};

const isFuture = (date) => {
    if (!date) return true;
    let result = new Date() < new Date(date);
    if (format.api(new Date()) === format.api(date)) result = false;
    return result;
};

export default {
    format,
    addDay,
    addMonth,
    isPast,
    isFuture,
};
