import React from 'react';
import { API } from 'htcore';
import { useNavigate } from 'react-router-dom';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Form, Input, Row, Col, Select } from 'antd';
import { notifications } from 'components/notification';
import AgencySelector from 'components/selectors/agency-selector';
import CurrencyNoRequestSelector from 'components/selectors/currency-norequest-selector';

const SupplierCreationPage = () => {
    const navigate = useNavigate();

    const [supplierCreationForm] = Form.useForm();

    const submit = (values) => {
        if (values.activeAgencyId) {
            values.activeAgencyId = String(values.activeAgencyId);
        }

        API.post({
            sunpu: sunpuMethods.suppliersData,
            body: values,
            success: () => {
                notifications('Supplier Added!', 'success');
                navigate('../');
            },
        });
    };

    return (
        <Form
            form={supplierCreationForm}
            initialValues={{}}
            layout="vertical"
            onFinish={submit}
            style={{ maxWidth: 1000 }}
        >
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="contractType"
                        label="Contract Type"
                        rules={[{ required: true, message: 'Please enter Contract Type' }]}
                    >
                        <Select placeholder="Select">
                            <Option value="OnlineAndOffline">Online / Offline</Option>
                            <Option value="Offline">Offline</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="currency"
                        label="Supplier Currency"
                        rules={[{ required: true, message: 'Please Select Currency' }]}
                    >
                        <CurrencyNoRequestSelector />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={5} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="isCrossSell"
                        label="Cross-Sell"
                        rules={[{ required: true, message: 'Please Select' }]}
                        style={{ width: 150 }}
                    >
                        <Select placeholder="Select">
                            <Option value={false}>No</Option>
                            <Option value={true}>Yes</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7} style={{ paddingRight: 30 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.isCrossSell !== currentValues.isCrossSell
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('isCrossSell') ? (
                                <Form.Item name="activeAgencyId" label="Active Agency Id">
                                    <AgencySelector placeholder="Select" onlyActiveAgencies />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="supplierName"
                        label="Supplier Name"
                        rules={[{ required: true, message: 'Please enter Supplier Name' }]}
                    >
                        <Input placeholder="Supplier Name" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.contractType !== currentValues.contractType
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('contractType') !== 'Offline' ? (
                        <>
                            <Row size="large">
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item name="supplierCode" label="Supplier Code">
                                        <Input placeholder="Will be added by Tech Team" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="connectivityPlatform" label="Connectivity Platform">
                                        <Select placeholder="Select">
                                            <Option value="NotSpecified">Not Specified</Option>
                                            <Option value="Travelgate">Travelgate</Option>
                                            <Option value="Netstorming">Netstorming</Option>
                                            <Option value="Juniper">Juniper</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row size="large">
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item
                                        name="connectorUrl"
                                        label="Connector URL"
                                        rules={[{ required: true, message: 'Please enter Connector URL' }]}
                                    >
                                        <Input placeholder="Will be added by Tech Team" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="giataSupplierCode" label="GIATA Supplier Code">
                                        <Input placeholder="Will be added by Tech Team" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    ) : null
                }
            </Form.Item>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="channelType"
                        label="Channel Type"
                        rules={[{ required: true, message: 'Please Select' }]}
                    >
                        <Select placeholder="Select">
                            <Option value="NotApplicable">N/A</Option>
                            <Option value="SWITCH">SWITCH</Option>
                            <Option value="DirectConnect">Direct Connect</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.contractType !== currentValues.contractType
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('contractType') !== 'Offline' ? (
                                <Form.Item
                                    name="supplierType"
                                    label="Supplier Type / Channel"
                                    rules={[{ required: true, message: 'Please Select' }]}
                                >
                                    <Select placeholder="Select Supplier Type">
                                        <Option value="StaticContract">Static Contract</Option>
                                        <Option value="DirectConnectivity">Direct Connectivity</Option>
                                        <Option value="ChannelManager">Channel Manager</Option>
                                        <Option value="Party">3rd Party</Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="status" label="Status">
                        <Select placeholder="Select">
                            <Option value="Enabled">Active</Option>
                            <Option value="Disabled">Inactive</Option>
                            <Option value="TestOnly">Active for Test Only</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Button htmlType="submit" type="primary">
                Add New Supplier
            </Button>
        </Form>
    );
};

export default SupplierCreationPage;
