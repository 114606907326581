import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Space } from 'antd';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import BookingDetailsRoom from 'components/legacy/booking-details-room';
import BookingSummary from 'components/legacy/booking-summary';
import BookingRemarks from './booking-parts/booking-remarks';
import ServiceDetailsRoom from './booking-parts/service-details-room';

const TABS = {
    INFORMATION: 'information',
    REMARKS: 'remarks',
};

const BookingInfoTabs = ({ isService, booking }) => {
    const { refCode } = useParams();
    const [selectedKey, setSelectedKey] = useState(TABS.INFORMATION);
    const [selectedRoomKey, setSelectedRoomKey] = useState('0');

    const [remarks, setRemarks] = useState(null);
    const [loading, setLoading] = useState(true);

    const getRemarks = () => {
        setLoading(true);
        API.get({
            url: apiMethods.bookingRemarks(refCode),
            success: (result) => {
                setRemarks(
                    result.map((remark) => ({
                        ...remark,
                        files: (remark.files || []).map((file) => ({
                            ...file,
                            name: file.filename,
                            status: 'done',
                        })),
                    }))
                );
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getRemarks();
    }, [refCode]);

    const selectedRoom = useMemo(
        () => booking.bookingDetails.roomDetails[selectedRoomKey],
        [selectedRoomKey, booking]
    );

    return (
        <>
            <Menu
                mode="horizontal"
                items={[
                    {
                        label: 'Information',
                        key: TABS.INFORMATION,
                    },
                    !isService && {
                        label: `Remarks ${remarks?.length ? `(${remarks.length})` : ''}`,
                        key: TABS.REMARKS,
                    },
                ]}
                selectedKeys={selectedKey}
                onSelect={({ key }) => setSelectedKey(key)}
            />
            {selectedKey === TABS.INFORMATION && (
                <>
                    <Menu
                        mode="horizontal"
                        items={booking.bookingDetails.roomDetails.map((room, index) => ({
                            label: `${isService ? 'Service' : 'Room'} ${index + 1}`,
                            key: index,
                        }))}
                        selectedKeys={selectedRoomKey}
                        onSelect={({ key }) => setSelectedRoomKey(key)}
                    />

                    <Space align="start" size="middle">
                        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                            {selectedRoom &&
                                (isService ? (
                                    <ServiceDetailsRoom
                                        room={selectedRoom}
                                        booking={booking}
                                        index={Number(selectedRoomKey)}
                                    />
                                ) : (
                                    <BookingDetailsRoom
                                        room={selectedRoom}
                                        booking={booking}
                                        index={Number(selectedRoomKey)}
                                    />
                                ))}
                        </Space>
                        <BookingSummary isService={isService} booking={booking} />
                    </Space>
                </>
            )}

            {selectedKey === TABS.REMARKS && (
                <BookingRemarks remarks={remarks} loading={loading} update={getRemarks} />
            )}
        </>
    );
};

export default BookingInfoTabs;
