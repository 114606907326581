import React, { useMemo } from 'react';
import { Loader } from 'legacy';
import apiMethods from 'core/methods';
import apiGifuMethods from 'core/gifu-methods';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { EntitySelector, useSelector } from 'common/entity-selector';
import CurrencySelector from 'components/selectors/currency-selector';
import AccountCurrencySelector from './account-currency-selector';

export const PROVIDER_VALUES = { AMEX: 'AmericanExpress', APISO: 'Apiso' };

const CARDS_OPTIONS = [
    { value: 'Visa', label: 'Visa' },
    { value: 'MasterCard', label: 'MasterCard' },
];

const providerCards = {
    [PROVIDER_VALUES.AMEX]: [{ value: 'AmericanExpress', label: 'AmericanExpress' }],
    [PROVIDER_VALUES.APISO]: CARDS_OPTIONS,
};

const optionsGenerator = (list = []) =>
    list.map((item) => ({ value: item, label: item.replace(/([A-Z])/g, ' $1') }));

const AccountForm = ({ isEdit, loading, form, initialValues, formStyle, onSubmit }) => {
    const isExcluded = Form.useWatch('isExcluded', form);
    const convertedCurrencies = Form.useWatch('convertedCurrencies', form);
    const currency = Form.useWatch('currency', form);

    const { options: vendorsOptions, loading: vendorsOptionsLoading } = useSelector(
        { gifu: apiGifuMethods.vendorsList },
        optionsGenerator
    );

    const isHideBuffer = useMemo(() => {
        if (isExcluded) return false;

        const activeCurrency = convertedCurrencies?.[0];
        const isHide = convertedCurrencies?.length === 1 && activeCurrency === currency;

        if (isHide) form.setFieldValue('conversionBuffer', 0);

        return isHide;
    }, [isExcluded, currency, convertedCurrencies]);

    const isHasEqualCurrencies = useMemo(() => {
        const isIncludesCurrency = convertedCurrencies?.includes(currency);

        if ((isExcluded && !isIncludesCurrency) || (!isExcluded && isIncludesCurrency)) {
            return currency;
        }
    }, [isExcluded, currency, convertedCurrencies]);

    const onChangeBuffer = (value) => {
        form.setFieldValue('conversionBuffer', value ? value.toFixed(2) : value);
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues || { isExcluded: false }}
                onFinish={onSubmit}
            >
                <div style={formStyle}>
                    <Row gutter={18}>
                        <Col span={12}>
                            <Form.Item label="VCC Provider" name="vendor" rules={[{ required: true }]}>
                                <EntitySelector
                                    disabled={isEdit}
                                    loading={vendorsOptionsLoading}
                                    placeholder="Select VCC Provider"
                                    options={vendorsOptions}
                                    onChange={() => form.setFieldValue('cardType', null)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const provider = getFieldValue('vendor');
                                    const cardOptions = providerCards?.[provider] || CARDS_OPTIONS;

                                    return (
                                        <Form.Item
                                            label="Card type"
                                            name="cardType"
                                            rules={[{ required: true }]}
                                        >
                                            <EntitySelector
                                                disabled={!provider || isEdit}
                                                placeholder="Select Card Type"
                                                options={cardOptions}
                                                onChange={() => form.setFieldValue('convertedCurrencies', [])}
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={18}>
                        <Col span={12}>
                            <Form.Item
                                label="Account number"
                                name="accountNumber"
                                rules={[{ required: true }]}
                            >
                                <Input disabled={isEdit} placeholder="Account number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Currency of Account"
                                name="currency"
                                rules={[{ required: true }]}
                            >
                                <CurrencySelector
                                    disabled={isEdit}
                                    placeholder="Currency of Account"
                                    url={apiMethods.currencies}
                                    onChange={() => form.setFieldValue('convertedCurrencies', [])}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <AccountCurrencySelector />
                </div>

                {!isEdit && !isHideBuffer && (
                    <>
                        <Form.Item
                            name="conversionBuffer"
                            label="Default Conversion Buffer"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 8 }}
                        >
                            <InputNumber
                                type="number"
                                placeholder="0"
                                min={0}
                                addonAfter="%"
                                onChange={onChangeBuffer}
                            />
                        </Form.Item>
                        {!!isHasEqualCurrencies && (
                            <span>
                                The default conversion buffer will not be applied to {currency}-{currency},
                                for other pairs default conversion buffer will be applied
                            </span>
                        )}
                    </>
                )}

                {isEdit && (
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </Row>
                )}
            </Form>
        </>
    );
};

export default AccountForm;
