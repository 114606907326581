import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MarketsListPage from './markets-list';
import MarketPage from './market';

const MarketsPage = () => (
    <Routes>
        <Route path="/:id" element={<MarketPage />} />
        <Route path="/" element={<MarketsListPage />} />
    </Routes>
);

export default MarketsPage;
