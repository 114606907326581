import React, { useState, useEffect } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';

const CompletePaymentManuallyButton = ({ booking, loadBooking, isService }) => {
    const [completePaymentManuallyModalVisible, setCompleteManuallyModalVisible] = useState(false);
    const [agency, setAgency] = useState(null);
    const { refCode } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.agency(booking.agencyId),
            success: setAgency,
        });
    }, []);

    const bookingConfirmPayment = () => {
        API.post({
            url: isService
                ? apiMethods.manuallyCompletePaymentOfflineService(booking.bookingId)
                : apiMethods.manuallyCompletePayment(booking.bookingId),
            success: () => {
                notifications('Success', 'success');
                setCompleteManuallyModalVisible(false);
                loadBooking();
            },
        });
    };

    if (agency?.contractKind !== 'OfflineOrCreditCardPayments') {
        return null;
    }

    return (
        <>
            <ConfirmationSimpleModal
                visible={completePaymentManuallyModalVisible}
                onCancel={() => setCompleteManuallyModalVisible(false)}
                headerText="Are you absolutely sure?"
                submitText="Confirm"
                onConfirm={bookingConfirmPayment}
            >
                This action <b>cannot</b> be undone. This will mark a payment for the booking <b>{refCode}</b>{' '}
                as <b>paid</b> in the system.
            </ConfirmationSimpleModal>
            <Button onClick={() => setCompleteManuallyModalVisible(true)}>Manually Confirm Payment</Button>
        </>
    );
};

export default CompletePaymentManuallyButton;
