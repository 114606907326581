import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { Typography } from 'antd';
import Table from 'components/table';
import DownloadHotelsList from './download-hotels-list';

const { Title } = Typography;

const columns = () => [
    {
        header: 'ID and Name',
        cell: ({ agentId, agentName }) => `${agentId} ${agentName}`,
    },

    {
        header: 'Enabled Suppliers',
        cell: ({ suppliers }) => suppliers.join(', '),
    },

    {
        header: 'Number of available hotels',
        cell: ({ agentId, accommodationsCount }) => <Link to={`./${agentId}`}>{accommodationsCount}</Link>,
    },

    {
        header: 'Actions',
        cell: ({ agentId }) => <DownloadHotelsList agentId={agentId} />,
    },
];

const HotelsList = () => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const getData = () => {
        setLoading(true);

        API.get({
            url: apiMethods.hotels(id),
            success: setList,
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Hotels List
            </Title>

            <Table list={list} columns={columns()} />
        </>
    );
};

export default HotelsList;
