import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { API } from 'htcore';
import Table from 'components/table';
import { Link } from 'react-router-dom';
import $auth from 'stores/auth';

const columns = [
    {
        header: 'Id',
        cell: (cell) => <Link to={`./${cell.id}`}>{cell.id}</Link>,
    },
    {
        header: 'Name',
        cell: (cell) => <Link to={`./${cell.id}`}>{cell.name}</Link>,
    },
];

const MarketsListPage = observer(() => {
    const [list, setList] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.markets(),
            success: (result) =>
                setList(result.sort((a, b) => a.id - b.id).filter((market) => market.id !== 1)),
        });
    }, []);

    if (!list) {
        return <Loader />;
    }

    return (
        <>
            {$auth.permitted('MarkupManagement') && (
                <div style={{ textAlign: 'right', marginBottom: 25 }}>
                    <Link to="./create">
                        <Button>Create New Market</Button>
                    </Link>
                </div>
            )}
            <Table list={list} columns={columns} />
        </>
    );
});

export default MarketsListPage;
