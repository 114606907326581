import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu, PageHeader } from 'antd';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import InviteAdministratorPage from './invite';
import AdministratorsListPage from './list';
import AdministratorPage from './administrator';
import $auth from 'stores/auth';

const items = [
    { label: <Link to="./">Administrators List</Link>, key: '' },
    { label: <Link to="./invite">Send Invitation</Link>, key: 'invite' },
];

const AdministratorsPage = observer(() => {
    useTitle('Administrators');

    const [list, setList] = useState(null);
    const location = useLocation();

    const loadList = () => {
        API.get({
            url: apiMethods.administrators,
            success: (result) => {
                setList(result.sort((a, b) => (b.isActive - a.isActive) * 10000 + a.id - b.id));
            },
        });
    };

    useEffect(() => {
        if ($auth.permitted('AdministratorManagement')) {
            loadList();
        } else {
            setList([]);
        }
    }, [$auth.permitted('AdministratorManagement')]);

    return (
        <>
            <PageHeader title="Administrators" />
            <Menu mode="horizontal" items={items} selectedKeys={location.pathname.split('/')[2] || ''} />
            <Routes>
                <Route path="/" element={<AdministratorsListPage list={list} />} />
                <Route path="/invite" element={<InviteAdministratorPage />} />
                <Route
                    path="/:id/details"
                    element={<AdministratorPage list={list} reloadList={loadList} />}
                />
            </Routes>
        </>
    );
});

export default AdministratorsPage;
