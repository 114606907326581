import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import { Loader } from 'legacy';
import { Button, Form, Space, Typography } from 'antd';
import apiMapperMethods from 'core/mapper-methods';
import Table from 'components/table';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import { notifications } from 'components/notification';
import { AVAILABILITY_VALUES, columns } from './sales-table-settings';
import { CloseCircle, TickCircle } from 'iconsax-react';

const { Title } = Typography;

const getFieldsToPayload = (oldValues, newValues) => {
    const values = {};
    Object.keys(newValues).forEach((field) => {
        if (newValues[field] !== oldValues[field]) {
            values[field] = newValues[field];
        }
    });

    return values;
};

const formatDataToFormValue = (data) => {
    const value = {};
    data.forEach(
        ({ name, restrictionAvailabilityOperation }) => (value[name] = restrictionAvailabilityOperation)
    );

    return value;
};

const SalesSettings = () => {
    const [data, setData] = useState(null);
    const [initialFormValue, setInitialFormValue] = useState(null);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const [form] = Form.useForm();
    const { htId } = useParams();

    const formValues = Form.useWatch([], form);

    const load = () => {
        API.get({
            url: apiMapperMethods.accommodationRestriction(htId),
            success: (result) => {
                setData(result);
                setInitialFormValue(formatDataToFormValue(result.suppliers));
            },
        });
    };

    useEffect(() => {
        if (htId) {
            load();
        }
    }, []);

    const disableButton = useMemo(
        () => JSON.stringify(formValues) === JSON.stringify(initialFormValue),
        [initialFormValue, formValues]
    );

    const getInfoStrings = (suppliersData) => {
        const suppliersString = suppliersData.join(', ');
        return {
            [AVAILABILITY_VALUES.ON]: (
                <>
                    <TickCircle
                        size="16"
                        color="#59BA71"
                        variant="Bold"
                        style={{ verticalAlign: 'middle', marginRight: 8 }}
                    />
                    <b>Enabled on:</b> {suppliersString}.
                </>
            ),
            [AVAILABILITY_VALUES.OFF]: (
                <>
                    <CloseCircle
                        size="16"
                        color="#EB001B"
                        variant="Bold"
                        style={{ verticalAlign: 'middle', marginRight: 8 }}
                    />
                    <b>Disabled on:</b> {suppliersString}.
                </>
            ),
            [AVAILABILITY_VALUES.INHERITED]: `Inherited settings from other levels: ${suppliersString}.`,
        };
    };

    const getModalText = () => {
        const fields = Object.entries(formValues).reduce((acc, [key, value]) => {
            acc[value] = [...(acc[value] || []), key];
            return acc;
        }, {});

        const str = Object.entries(fields).map(
            ([field, suppliersData]) => getInfoStrings(suppliersData)[field]
        );

        return (
            <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                {Object.entries(fields).map(([field, suppliersData]) => (
                    <div>{getInfoStrings(suppliersData)[field]}</div>
                ))}
            </div>
        );
    };

    const onSubmit = () => {
        const body = getFieldsToPayload(initialFormValue, formValues);

        API.post({
            url: apiMapperMethods.accommodationRestriction(htId),
            body,
            success: () => {
                load();
                setIsOpenConfirm(false);
                notifications('Settings Saved', 'success');
            },
        });
    };

    if (!data) return <Loader page />;

    const SaveButton = (
        <Button
            disabled={disableButton}
            type="primary"
            style={{ marginTop: 26 }}
            onClick={() => setIsOpenConfirm(true)}
        >
            Save Changes
        </Button>
    );

    return (
        <>
            <Title level={5}>Accommodation Sales</Title>

            <Space>
                <h3 style={{ marginRight: 30 }}>
                    HtId: <strong>{htId}</strong>
                    <br />
                    Status:{' '}
                    <strong style={{ color: data.isActive ? '#59BA71' : '#EB001B' }}>
                        {data.isActive ? 'Active' : 'Deactivated'}
                    </strong>
                </h3>

                {/*todo return after BE changes*/}
                {/*<AccommodationActions htId={htId} isActive={data.isActive} reload={load} />*/}
            </Space>

            <Title level={5} style={{ marginTop: 20 }}>
                Suppliers Sales
            </Title>
            <Form
                form={form}
                initialValues={initialFormValue}
                style={{ display: 'flex', flexDirection: 'column', gap: 30 }}
            >
                <Table
                    list={data.suppliers}
                    columns={columns()}
                    textEmptyResult="No suppliers found"
                    textEmptyList="No suppliers found (empty)"
                    searches={(v) => [v.code, v.name]}
                    rowKey="name"
                    CustomFilter={SaveButton}
                />
            </Form>
            {SaveButton}

            {isOpenConfirm && (
                <ConfirmationSimpleModal
                    visible
                    onCancel={() => setIsOpenConfirm(false)}
                    headerText="Upon confirmation, the accommodation will be"
                    submitText="Confirm"
                    onConfirm={onSubmit}
                >
                    {getModalText()}
                </ConfirmationSimpleModal>
            )}
        </>
    );
};

export default SalesSettings;
