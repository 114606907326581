import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMapperMethods from 'core/mapper-methods';
import { useParams } from 'react-router-dom';
import { Spin, Timeline } from 'antd';
import { date } from 'legacy';
import { JSONTree } from 'react-json-tree';

const AccommodationHistory = () => {
    const [data, setData] = useState(null);
    const { htId } = useParams();

    const load = () => {
        API.get({
            nakijin: apiMapperMethods.accommodationChangeHistory(htId),
            success: (result) => {
                if (result?.changeHistory?.length) {
                    setData(result.changeHistory);
                } else {
                    setData(false);
                }
            },
        });
    };

    useEffect(() => {
        if (htId) {
            load();
        }
    }, []);

    if (data === null) {
        return <Spin />;
    }

    if (!data) {
        return 'No change history found for this Accommodation';
    }

    return (
        <div className="search-results block" style={{ paddingRight: 30 }}>
            <Timeline>
                {data.map((item) => {
                    let parsedDetails = null;
                    try {
                        parsedDetails = JSON.parse(item.details);
                    } catch (e) {}

                    return (
                        <Timeline.Item>
                            {date.format.c(item.date) + ' ' + date.format.time(item.date)}
                            <br />
                            <b>{item.eventType}</b> by <b>{item.changeSource}</b>
                            {parsedDetails ? (
                                <JSONTree
                                    data={parsedDetails}
                                    theme="monokai"
                                    hideRoot
                                    invertTheme={true}
                                    getItemString={() => null}
                                    shouldExpandNode={() => false}
                                />
                            ) : (
                                item.details
                            )}
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </div>
    );
};

export default AccommodationHistory;
