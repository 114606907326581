import React, { useState } from 'react';
import { Button, Form, InputNumber, Space, Typography } from 'antd';
import { InfoCircle } from 'iconsax-react';

const { Title } = Typography;

const DefaultBufferForm = ({ initialBufferValue, disableBuffer, message, onSave }) => {
    const [editMode, setEditMode] = useState(false);

    const [form] = Form.useForm();

    const onChangeBuffer = (value) => form.setFieldValue('defaultBuffer', value ? value.toFixed(2) : value);

    const onSubmit = async (values) => {
        if (editMode) {
            await onSave(values);
            setEditMode(false);
        } else setEditMode(true);
    };

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Default Conversion Buffer
            </Title>

            <Form form={form} initialValues={{ defaultBuffer: initialBufferValue }} onFinish={onSubmit}>
                <Space align="start">
                    <Form.Item
                        name="defaultBuffer"
                        rules={[
                            { required: true },
                            {
                                validator: (_, value) => {
                                    return (value || '').toString()?.length > 20
                                        ? Promise.reject(new Error('Too long'))
                                        : Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            type="number"
                            min={0}
                            disabled={!editMode}
                            status={message ? 'warning' : null}
                            addonAfter="%"
                            onChange={onChangeBuffer}
                        />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" disabled={disableBuffer}>
                        {editMode ? 'Save' : 'Edit'}
                    </Button>
                    {message && (
                        <Space style={{ marginLeft: 10, alignItems: 'center' }}>
                            <InfoCircle color="#f9a51a" />
                            <p style={{ color: '#faad14', margin: 0, width: 500 }}>{message}</p>
                        </Space>
                    )}
                </Space>
            </Form>
        </>
    );
};

export default DefaultBufferForm;
