import apiReportsMethods from './reports-methods';

const ReportsList = [
    {
        name: 'Direct Connectivity Agency Wise Report',
        method: apiReportsMethods.agencyConnectivityReport,
        description: '',
    },
    {
        name: 'Agencies Productivity Report',
        method: apiReportsMethods.agencyProductivityReport,
    },
    {
        name: 'Full Bookings Report',
        method: apiReportsMethods.fullBookingsReport,
        extendedFilters: true,
    },
    {
        name: 'Pending Supplier Reference Report',
        method: apiReportsMethods.pendingSupplierReferenceReport,
        past: true,
        description: 'A report which identifies all bookings where in supplier reference is Not updated',
    },
    {
        name: 'Confirmed Bookings Report',
        method: apiReportsMethods.confirmedBookingsReport,
        past: true,
        description:
            'A report should give all bookings with Confirmed status where in supplier reference is already updated',
    },
    {
        name: 'Finalized Bookings Report',
        method: apiReportsMethods.finalizedBookingsReport,
        past: true,
        excludeToday: true,
        customEndpointParameters: true,
    },
    {
        name: 'Completed Bookings Report',
        method: apiReportsMethods.completedBookingsReport,
        past: true,
        excludeToday: true,
        customEndpointParameters: true,
    },
    {
        name: 'Hotel-wise Booking Report',
        method: apiReportsMethods.hotelWiseBookingReport,
        past: true,
        description:
            'A report will give all bookings which are booked for a particular hotel. All bookings status-wise',
    },
    {
        name: 'Cancellation Deadline Report',
        method: apiReportsMethods.cancellationDeadlineReport,
        past: true,
        description: 'All bookings as per cancellation deadline which are yet to be paid by the customer',
    },
    {
        name: 'Third Party Suppliers Report',
        method: apiReportsMethods.thirdPartySuppliersReport,
        past: true,
        description: 'All bookings done using third part as a supplier',
    },
    {
        name: 'VCC Bookings Report',
        method: apiReportsMethods.vccBookingsReport,
    },
    {
        name: 'Hotel Productivity Report',
        method: apiReportsMethods.hotelProductivityReport,
        past: true,
        description: 'Booked room nights & room revenue',
    },
    {
        name: 'Cancelled Bookings Report',
        method: apiReportsMethods.cancelledBookingsReport,
        past: true,
    },
    {
        name: 'Payment Links Report',
        method: apiReportsMethods.paymentLinkReport,
        past: true,
    },
    {
        name: 'Agencies Report',
        method: apiReportsMethods.agenciesReport,
        past: true,
    },
    {
        name: 'Markup & Profit Report',
        method: apiReportsMethods.markupAndProfitReport,
        description: 'Last 24 hours report of booked and cancelled',
        noDates: true,
    },
];

export default ReportsList;
