import React from 'react';
import { observer } from 'mobx-react';
import $auth from 'stores/auth';
import PageDenied from 'pages/cabinet/page-denied';
import { PageHeader, Space, Typography } from 'antd';
import { useTitle } from 'htcore';
import { Loader } from 'legacy';

const { Title } = Typography;

const UedaMainPage = observer(() => {
    useTitle(null);

    if (!$auth.initialized) {
        return <Loader white page />;
    }

    if (!$auth.information || $auth.information?.isActive === false) {
        return <PageDenied />;
    }

    return (
        <Space direction="vertical" className="main-page">
            <PageHeader
                title={`Hi ${
                    ($auth.information?.firstName || '') + ' ' + ($auth.information?.lastName || '')
                },`}
            />
            <Title level={2}>Welcome to Happytravel.com Manage Panel</Title>
            <Title level={4} style={{ marginTop: 30 }}>
                You have following roles
            </Title>
            <ul>
                {$auth?.rolesNames.map((role) => (
                    <li key={role}>{role}</li>
                ))}
            </ul>
        </Space>
    );
});

export default UedaMainPage;
