import { API } from 'htcore';
import apiMethods from 'core/methods';
import $auth from 'stores/auth';

export const initAdmin = (auth) => {
    if (window.location.href.includes('/auth')) return;

    $auth.setToken(auth.profile?.email);

    API.get({
        url: apiMethods.adminCurrentAuth,
        success: (result) => {
            $auth.setInformation(result);
            API.get({
                url: apiMethods.administratorRoles,
                success: (roles) => $auth.setRolesCompleteList(roles),
            });
        },
        error: () => {
            $auth.setInformation(null);
        },
    });
};

export const logoutAdmin = () => {
    $auth.setToken(null);
    $auth.setInformation(null);
};
