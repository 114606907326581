import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import apiMethods from 'core/methods';
import { Loader, date } from 'legacy';
import { API } from 'htcore';
import Table from 'components/table';
import { Link } from 'react-router-dom';
import MarkupActivitySwitcher from './markup-activity-switcher';
import $auth from 'stores/auth';

export const columns = (isReadOnlyActivity) => [
    {
        header: 'Id',
        cell: (cell) => <Link to={`./${cell.id}`}>{cell.id}</Link>,
    },
    {
        header: 'Description',
        cell: (cell) => <Link to={`./${cell.id}`}>{cell.description}</Link>,
    },
    {
        header: 'Created / Edited',
        cell: (cell) => (
            <>
                {date.format.c(cell.created) + ' ' + date.format.time(cell.created)}&nbsp;/&nbsp;
                {cell.created === cell.modified ? (
                    '-'
                ) : (
                    <>
                        <br />
                        {date.format.c(cell.modified) + ' ' + date.format.time(cell.modified)}
                    </>
                )}
            </>
        ),
    },
    {
        header: 'Amount',
        cell: (cell) => cell.value + '%',
    },
    {
        header: 'Exclusive',
        cell: (cell) => (cell.isExclusive ? 'Yes' : '-'),
    },
    {
        header: 'Mandatory',
        cell: (cell) => (cell.isMandatory ? 'Yes' : '-'),
    },
    ...($auth.permitted('MarkupManagement')
        ? [
              {
                  header: 'Activity',
                  cell: (cell) =>
                      isReadOnlyActivity ? (
                          cell.isActive ? (
                              'Yes'
                          ) : (
                              '-'
                          )
                      ) : (
                          <MarkupActivitySwitcher
                              markupId={cell.id}
                              initialValue={cell.isActive}
                              markup={cell}
                          />
                      ),
              },
          ]
        : []),
];

const MarkupsList = observer(() => {
    const [list, setList] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.markups(),
            success: setList,
        });
    }, []);

    if (!list) {
        return <Loader />;
    }

    return (
        <>
            {$auth.permitted('MarkupManagement') && (
                <div style={{ textAlign: 'right', marginBottom: 25 }}>
                    <Link to="./create">
                        <Button>Create New Markup</Button>
                    </Link>
                </div>
            )}
            <Table list={list} columns={columns()} />
        </>
    );
});

export default MarkupsList;
