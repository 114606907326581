import React, { useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { Button } from 'antd';

const RejectManuallyButton = ({ booking, loadBooking }) => {
    const [rejectManuallyModalVisible, setRejectManuallyModalVisible] = useState(false);

    const rejectManually = ({ reason }) => {
        API.post({
            url: apiMethods.bookingReject(booking.bookingId),
            body: { reason },
            success: () => {
                notifications('Booking Rejected', 'success');
            },
            after: () => {
                setRejectManuallyModalVisible(false);
                loadBooking();
            },
        });
    };

    return (
        <>
            <ConfirmationModal
                visible={rejectManuallyModalVisible}
                onCancel={() => setRejectManuallyModalVisible(false)}
                headerText="You are about to reject a booking"
                submitText="Reject"
                onSubmit={rejectManually}
            >
                Rejects a booking that as rejected by supplier
            </ConfirmationModal>
            <Button onClick={() => setRejectManuallyModalVisible(true)}>Reject Manually</Button>
        </>
    );
};

export default RejectManuallyButton;
