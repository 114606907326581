import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { date, Loader } from 'legacy';
import { Form, Select, Typography, Row, Col, Input, DatePicker, Button, Space, Radio } from 'antd';
import AgentSelector from 'components/selectors/agent-selector';
import AgencySelector from 'components/selectors/agency-selector';
import SupplierSelector from 'components/selectors/supplier-selector';
import CountrySelector from 'components/selectors/country-selector';
import LocalitySelector from 'components/selectors/locality-selector';
import { getUTCDate } from 'components/selectors/date-ranges-selector';
import ItinerarySelector from 'components/selectors/itinerary-selector';
import RoomPricing from './room-pricing';
import PassengerDetails from './passenger-details';
import RateDetails from './rate-details';
import useOfflineBookingData from './use-offline-booking-data';
import SurrogateAgenciesForm from './surrogate-agencies-form';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const FILTERS = {
    agencyId:
        "(ContractKind eq 'OfflineOrCreditCardPayments' or ContractKind eq 'VirtualAccountOrCreditCardPayments')",
};

const OfflineBookingPage = ({ serviceName, serviceForm }) => {
    const [form] = Form.useForm();

    const {
        isLoading,
        creation,
        duplicate,
        booking,
        exchangeRate,
        confirmBooking,
        duplicateBooking,
        submit,
        getCurrenciesData,
    } = useOfflineBookingData({ form, serviceName, serviceForm });

    const [firstDate, setFirstDate] = useState(null);

    const getNights = (dates) => {
        if (dates) {
            const [dateFrom, dateTo] = dates;
            return dateTo.diff(dateFrom, 'days');
        }
        return 0;
    };

    const onCalendarChange = (value) => {
        setFirstDate(value?.[0] || value?.[1]);
    };

    const onChangeRoomsCount = (value) => {
        const rooms = form.getFieldValue('rooms');
        const newRooms = (rooms || []).filter((_, index) => index < value);

        form.setFieldValue('rooms', newRooms);
    };

    const disabledDate = (current, selectedDates) => {
        const [checkIn, checkOut] = selectedDates || [];

        if ((checkIn && current.isSame(checkIn)) || (checkOut && current.isSame(checkOut))) {
            return true;
        }

        return !!(firstDate && current.isSame(firstDate));
    };

    const validateRangeDates = (_, value) => {
        if (!value) return Promise.resolve();

        const [checkIn, checkOut] = value;

        const dateNow = getUTCDate().set({ hour: 23, minute: 59 });
        const checkoutDate = checkOut.clone().set({ hour: 0, minute: 0 });

        if (checkoutDate.isBefore(dateNow))
            return Promise.reject(new Error('Check Out date cannot be selected in the past'));

        if (getNights(value) > 30) return Promise.reject(new Error('You cannot choose more than 30 nights'));

        return Promise.resolve();
    };

    const getIsShowPassengersDetails = (rooms) =>
        (rooms || []).some((item) => item?.adultsNumber > 1 || item?.childrenNumber > 0);

    const getIsShowDuplicate = (getFieldsValue) => Object.values(getFieldsValue()).find((item) => !!item);

    const onChangeInt = (value) => {
        if (value) {
            API.get({
                url: apiMethods.customerCurrencyByItn(value),
                success: (result) => form.setFieldValue('customerCurrency', result),
            });
        }
    };

    if ((!creation || duplicate) && !booking) {
        return <Loader />;
    }

    return (
        <>
            {isLoading && <Loader page />}
            <Form
                form={form}
                initialValues={
                    creation && !duplicate
                        ? {
                              contracted: 'contracted',
                              isItn: false,
                          }
                        : {
                              ...booking,
                              isItn: !!booking.selectedItineraryNumber,
                              contracted:
                                  booking.supplierCode === 'notContracted' ? 'nonContracted' : 'contracted',
                          }
                }
                layout="vertical"
                onFinish={submit}
            >
                <Title level={4}>Booking Details</Title>

                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue, setFieldValue }) => (
                                <Form.Item
                                    name="agencyId"
                                    label="Agency"
                                    rules={[{ required: true, message: 'Please select' }]}
                                >
                                    <AgencySelector
                                        disabled={!creation || !!getFieldValue('selectedItineraryNumber')}
                                        filter={FILTERS.agencyId}
                                        placeholder="Select"
                                        onlyActiveAgencies
                                        onChange={() => {
                                            setFieldValue('agentId', []);
                                            setFieldValue('customerCurrency', '');
                                        }}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.agencyId !== currentValues.agencyId
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('agencyId') && (
                                    <Form.Item
                                        name="agentId"
                                        label={`Agent in Agency ${getFieldValue('agencyId')}`}
                                        key={getFieldValue('agentAgencyId')}
                                        rules={[{ required: true, message: 'Please select' }]}
                                    >
                                        <AgentSelector
                                            agencyId={getFieldValue('agencyId')}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>

                <SurrogateAgenciesForm />

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.agencyId !== currentValues.agencyId
                    }
                >
                    {({ getFieldValue, setFieldValue }) =>
                        getFieldValue('agencyId') && (
                            <Row size="large">
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Space align="baseline">
                                        <p>Do you want to add this booking to itinerary?</p>
                                        <Form.Item name="isItn">
                                            <Radio.Group
                                                onChange={() =>
                                                    setFieldValue('selectedItineraryNumber', null)
                                                }
                                            >
                                                <Radio value={false}>No</Radio>
                                                <Radio value={true}>Yes</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Space>
                                </Col>
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues.agencyId !== currentValues.agencyId ||
                                            prevValues.isItn !== currentValues.isItn
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('isItn') && (
                                                <Form.Item
                                                    name="selectedItineraryNumber"
                                                    label="ITN"
                                                    rules={[{ required: true, message: 'Please select' }]}
                                                >
                                                    <ItinerarySelector
                                                        agencyId={getFieldValue('agencyId')}
                                                        onChange={onChangeInt}
                                                    />
                                                </Form.Item>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }
                </Form.Item>

                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            name="contracted"
                            label="Supplier Type"
                            rules={[{ required: true, message: 'Please select' }]}
                        >
                            <Select placeholder="Select">
                                <Option value="contracted">Contracted</Option>
                                <Option value="nonContracted">Non-Contracted</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.contracted !== currentValues.contracted
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('contracted') === 'contracted' ? (
                                    <Form.Item
                                        name="supplierCode"
                                        label="Select a Supplier"
                                        rules={[{ required: true, message: 'Please select' }]}
                                    >
                                        <SupplierSelector onlyEnabled />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            name="htId"
                            label="Accommodation"
                            rules={[{ required: true, message: 'Please select' }]}
                        >
                            <LocalitySelector
                                filter={['Accommodation']}
                                placeholder="Please enter Accommodation name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="roomsCount" label="Number of Rooms" rules={[{ required: true }]}>
                            <Select placeholder="Select" onChange={onChangeRoomsCount}>
                                {Array(5)
                                    .fill()
                                    .map((_, index) => (
                                        <Option value={index + 1} key={index}>
                                            {index + 1} Room{index ? 's' : ''}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name="rangeDates"
                                    label="Booking Dates"
                                    rules={[
                                        { required: true },
                                        {
                                            validator: validateRangeDates,
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        format={date.format.c}
                                        placeholder={['Check In', 'Check Out']}
                                        disabledDate={(current) =>
                                            disabledDate(current, getFieldValue('rangeDates'))
                                        }
                                        onCalendarChange={onCalendarChange}
                                        onOpenChange={() => setFirstDate(null)}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.rangeDates !== currentValues.rangeDates
                            }
                        >
                            {({ getFieldValue }) => (
                                <Form.Item label="Number of Nights">
                                    <Input
                                        disabled
                                        value={getNights(getFieldValue('rangeDates'))}
                                        placeholder="Number of Nights"
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="nationality" label="Nationality" rules={[{ required: true }]}>
                            <CountrySelector
                                idName="code"
                                onChange={(_, item) => form.setFieldValue('nationality', item)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item name="residency" label="Residency" rules={[{ required: true }]}>
                            <CountrySelector
                                idName="code"
                                onChange={(_, item) => form.setFieldValue('residency', item)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.roomsCount !== currentValues.roomsCount ||
                        JSON.stringify(prevValues.rooms) !== JSON.stringify(currentValues.rooms) ||
                        prevValues.agencyId !== currentValues.agencyId
                    }
                >
                    {({ getFieldValue }) => (
                        <>
                            {!!getFieldValue('roomsCount') && (
                                <>
                                    <RoomPricing creation={creation} />
                                    <RateDetails
                                        creation={creation}
                                        form={form}
                                        exchangeRate={exchangeRate}
                                        getCurrenciesData={getCurrenciesData}
                                    />
                                </>
                            )}
                            {!!getIsShowPassengersDetails(getFieldValue('rooms')) && <PassengerDetails />}
                        </>
                    )}
                </Form.Item>

                <Title level={4}>Description</Title>

                <Row size="large">
                    <Form.Item name="description" label="Description" style={{ width: '100%' }}>
                        <TextArea placeholder="Enter Description" />
                    </Form.Item>
                </Row>

                <Space>
                    <Button onClick={submit}>{creation ? 'Create Booking' : 'Save Changes'}</Button>

                    <Button onClick={confirmBooking}>Confirm</Button>

                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsValue }) =>
                            getIsShowDuplicate(getFieldsValue) && (
                                <Link
                                    to="/offline-bookings/booking/duplicate"
                                    className="button small"
                                    target="_blank"
                                >
                                    <Button htmlType="button" onClick={duplicateBooking}>
                                        Duplicate Booking
                                    </Button>
                                </Link>
                            )
                        }
                    </Form.Item>
                </Space>
            </Form>
        </>
    );
};

export default OfflineBookingPage;
