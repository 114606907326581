import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OfflineBookingPageWrap from './offline-booking-page-wrap';
import OfflineBookingsSearch from './offline-bookings-search';

const OfflineBookings = ({ bookingSearchForm, serviceSearchForm }) => (
    <Routes>
        <Route
            path="/*"
            element={
                <OfflineBookingsSearch
                    bookingSearchForm={bookingSearchForm}
                    serviceSearchForm={serviceSearchForm}
                />
            }
        />
        <Route path="/:service/:action/:referenceCode?" element={<OfflineBookingPageWrap />} />
    </Routes>
);

export default OfflineBookings;
