import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { API } from 'htcore';
import { date, Loader } from 'legacy';
import { getSavedValues, mapFieldsToPayload } from 'legacy/excluded-form-utils';
import apiMapperMethods from 'core/mapper-methods';
import { Button, Form, Typography, Space, Switch } from 'antd';
import { notifications } from 'components/notification';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import RuleForm from './rule-form';

const { Title } = Typography;

export const FIELDS_WITH_EXCLUDE = ['agencyMarketIds', 'agencyCountryCodes'];

const defaultRuleValue = {
    agencyCountryCodesType: 'include',
    agencyMarketIdsType: 'include',
    agencyCountryCodes: [],
    agencyMarketIds: [],
    travelPeriods: [{ dates: [] }],
    bookingPeriods: [{ dates: [] }],
};

const getIsPastDate = (date) => moment(date).isBefore(moment().add(-1, 'day'));

const mapDatesToView = (dates) => {
    if (!dates.length) {
        return [[]];
    }

    const datesData = dates.map((item) => {
        const dates = [moment(item.startDate), moment(item.endDate)];
        const disabledDates = dates.map((date) => getIsPastDate(date));

        return {
            id: item.id,
            isActive: item.isActive,
            dates,
            disabledDates,
            isPastPeriod: disabledDates.every((disableDate) => disableDate),
        };
    });

    if (datesData.every(({ isPastPeriod }) => isPastPeriod)) datesData.push({ dates: [] });

    return datesData.sort((a) => (a.isPastPeriod ? 0 : -1));
};

const mapDatesToPayload = (datesData) => {
    const filteredDates = datesData.filter(({ dates }) => dates && dates?.length);

    return filteredDates.map(({ id, dates, isPastPeriod }) => ({
        id: id,
        startDate: date.format.apiDateOnly(dates[0]),
        endDate: date.format.apiDateOnly(dates[1]),
        isActive: !isPastPeriod,
    }));
};

const mapDataToPayload = ({ rules }, htId) => ({
    isActive: true,
    accommodationId: htId,
    rules: rules.map((rule) => ({
        ...mapFieldsToPayload(FIELDS_WITH_EXCLUDE, rule),
        travelPeriods: mapDatesToPayload(rule.travelPeriods),
        bookingPeriods: mapDatesToPayload(rule.bookingPeriods),
    })),
});

const mapRulesToView = (rules) => {
    return rules.map(({ travelPeriods, bookingPeriods, ...rest }) => ({
        ...rest,
        travelPeriods: mapDatesToView(travelPeriods),
        bookingPeriods: mapDatesToView(bookingPeriods),
        ...getSavedValues(rest, FIELDS_WITH_EXCLUDE),
    }));
};

const AccommodationPreferredSettings = ({ htId }) => {
    const [form] = Form.useForm();

    const [data, setData] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);

    const getData = () => {
        API.get({
            url: apiMapperMethods.accommodationPreferred(htId),
            success: (result) => {
                if (result) {
                    const rules = mapRulesToView(result.rules);
                    setData({
                        isExistData: true,
                        isActive: result?.isActive,
                        rules,
                    });
                    form.setFieldValue('rules', rules);
                    setIsActive(result?.isActive);
                }
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        if (htId) {
            getData();
        }
    }, [htId]);

    const activatePreferred = () => {
        API.post({
            url: apiMapperMethods.activatePreferredAccommodation(htId),
            success: () => {
                setIsActive(true);
                getData();
                notifications('Preferred Settings Activated!', 'success');
            },
        });
    };

    const onSubmit = (values) => {
        setLoading(true);
        API[data?.isExistData ? 'put' : 'post']({
            url: data?.isExistData
                ? apiMapperMethods.accommodationPreferred(htId)
                : apiMapperMethods.addAccommodationPreferred,

            body: mapDataToPayload(values, htId),
            success: () => notifications('Preferred Settings Saved!', 'success'),
            after: () => {
                if (!data?.isActive) activatePreferred();

                setLoading(false);
                getData();
            },
        });
    };

    const confirmDeactivation = ({ reason }) => {
        setLoading(true);
        setOpenModal(false);

        return API.post({
            url: apiMapperMethods.deactivatePreferredAccommodation(htId),
            body: { reason },
            success: () => {
                setIsActive(false);
                notifications('Preferred Settings Deactivated!', 'success');
            },
            after: () => {
                setLoading(false);
                getData();
            },
        });
    };

    const onChangeSwitch = (value) => {
        if (!value) {
            if (data?.isExistData) {
                setOpenModal(true);
            } else setIsActive(false);

            return;
        }

        if (data?.isExistData) {
            activatePreferred();
        } else setIsActive(true);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={data}>
                <Space align="baseline" style={{ marginBottom: 10 }}>
                    <Title level={4}>Make Preferred</Title>

                    <Switch checked={isActive} onChange={onChangeSwitch} style={{ marginLeft: 10 }} />
                </Space>

                {isActive && (
                    <>
                        <Form.List name="rules">
                            {(fields, { add, remove }) => {
                                return (
                                    <div style={{ marginBottom: 30 }}>
                                        {fields.map((field, index) => (
                                            <RuleForm
                                                key={index}
                                                form={form}
                                                field={field}
                                                onRemove={() => remove(field.name)}
                                            />
                                        ))}

                                        <Button
                                            onClick={() => add(defaultRuleValue)}
                                            style={{ marginTop: 20 }}
                                            disabled={fields.length >= 10}
                                        >
                                            Add New Rule
                                        </Button>
                                    </div>
                                );
                            }}
                        </Form.List>

                        <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => (
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={!getFieldValue('rules')?.length}
                                >
                                    Save Preferred Settings
                                </Button>
                            )}
                        </Form.Item>
                    </>
                )}
            </Form>

            <ConfirmationModal
                visible={openModal}
                headerText="Preferred settings will be deactivated"
                submitText="Confirm"
                onCancel={() => setOpenModal(false)}
                onSubmit={confirmDeactivation}
            />
        </>
    );
};

export default AccommodationPreferredSettings;
