import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Typography, Row, Col, Form } from 'antd';
import { notifications } from 'components/notification';
import SearchTable from './search-table';
import SupplierTable from './supplier-table';
import RestrictionsSaveModal from './restrictions-save-modal';

const { Title } = Typography;

const RestrictionsMarkets = () => {
    const [form] = Form.useForm();

    const [list, setList] = useState(null);
    const [selectedMarket, setSelectedMarket] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const { code } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.markets(),
            success: setList,
        });
    }, []);

    const onSelectRow = ({ id }) => setSelectedMarket(id);

    useEffect(() => {
        if (code) onSelectRow({ id: code });
    }, []);

    const saveChanges = (keepRestrictions) => {
        API.post({
            url: apiMethods.marketRestrictions(selectedMarket, keepRestrictions),
            body: form.getFieldsValue(true),
            success: () => {
                setOpenModal(false);
                notifications('Saved!', 'success');
            },
        });
    };

    return (
        <>
            <Row justify="space-between">
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Markets
                </Title>

                <Button disabled={!selectedMarket} onClick={() => setOpenModal(true)}>
                    Save Changes
                </Button>
            </Row>

            <Row gutter={30}>
                <Col span={12}>
                    <SearchTable
                        selectedRow={selectedMarket}
                        loading={list === null}
                        tableTitle={`Markets List (${list?.length || 0})`}
                        list={list}
                        onRow={(record) => ({ onClick: () => onSelectRow(record) })}
                    />
                </Col>
                <Col span={12}>
                    <SupplierTable url={apiMethods.marketRestrictions} id={selectedMarket} form={form} />
                </Col>
            </Row>

            <RestrictionsSaveModal
                open={openModal}
                saveChanges={saveChanges}
                onClose={() => setOpenModal(false)}
            />
        </>
    );
};

export default RestrictionsMarkets;
