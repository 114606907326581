import React from 'react';
import { API } from 'htcore';
import { Button, Form, Typography } from 'antd';
import apiMethods from 'core/methods';
import AccountManagerSelector from 'components/selectors/account-manager-selector';
import { notifications } from 'components/notification';

const { Title } = Typography;

const AgencyAccountManager = ({ agency, loadAgency }) => {
    const [agencyForm] = Form.useForm();

    const submitAccountManager = (values) => {
        API.put({
            url: apiMethods.agencyUpdateAccountManagers(agency.id),
            body: {
                accountManagerId: values.accountManagerId || null,
            },
            success: () => loadAgency(),
            after: () => {
                notifications('Saved', 'success');
            },
        });
    };

    return (
        <>
            <Title level={4} style={{ marginBottom: 30 }}>
                Account Manager
            </Title>
            <Form
                form={agencyForm}
                initialValues={agency || {}}
                onFinish={submitAccountManager}
                layout="vertical"
                style={{ maxWidth: 500, marginBottom: 50 }}
            >
                <Form.Item name="accountManagerId" label="Account Manager">
                    <AccountManagerSelector placeholder="Please Select Account Manager" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Save Account Manager
                </Button>
            </Form>
        </>
    );
};

export default AgencyAccountManager;
