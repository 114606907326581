import React from 'react';
import { Button, message } from 'antd';
import { notifications } from 'components/notification';
import { API } from 'htcore';
import mapperMethods from 'core/mapper-methods';
import { DocumentDownload } from 'iconsax-react';
import { serializeRequest } from './serializer';

const AccommodationSearchCSVButton = ({ accommodationSearchForm }) => {
    const downloadReport = () => {
        accommodationSearchForm.validateFields().then((values) => {
            if (
                !Object.keys(serializeRequest(values)).filter((v) => !['isActive', 'sortField'].includes(v))
                    .length
            ) {
                notifications('Please select any criteria to download Accommodations List', 'failed');
                return;
            }

            const hideMessage = message.loading('Accommodations List is downloading...', 0);
            API.post({
                url: mapperMethods.accommodationsReportCsv,
                body: serializeRequest(values),
                response: (res) => {
                    if (res.status === 400) {
                        hideMessage();
                        notifications('Unable to download Accommodations List', 'failed');
                    }
                    if (res.status === 200) {
                        res.blob().then((blobby) => {
                            let anchor = document.createElement('a');
                            document.body.appendChild(anchor);

                            const objectUrl = window.URL.createObjectURL(blobby);
                            anchor.href = objectUrl;
                            anchor.download = `accommodations-${Math.round(
                                (new Date() - new Date(2023, 1, 1)) / 5000
                            )}.csv`;
                            anchor.click();

                            window.URL.revokeObjectURL(objectUrl);
                            hideMessage();
                            message.success('Accommodations List Downloaded');
                        });
                    }
                },
            });
        });
    };

    return (
        <Button
            onClick={downloadReport}
            icon={<DocumentDownload style={{ verticalAlign: 'bottom', marginRight: 12 }} />}
        >
            Download .CSV
        </Button>
    );
};

export default AccommodationSearchCSVButton;
