import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'iconsax-react';
import { date, remapStatus } from 'legacy';
import $auth from 'stores/auth';

export const columns = (navigationState) => [
    {
        header: <div style={{ paddingLeft: 42 }}>Name</div>,
        cell: (cell) => (
            <Link
                to={
                    $auth.permitted('AgentManagement')
                        ? `/agency/${cell.id}/agents`
                        : `/agency/${cell.id}/bookings`
                }
                state={navigationState}
                className="row-link"
            >
                <div className="arrow-holder">
                    <span>
                        <ArrowRight size={14} />
                    </span>
                </div>
                <div>
                    {cell.name}
                    <br />
                    <small>{cell.id}</small>
                </div>
            </Link>
        ),
    },
    {
        header: 'Location',
        cell: (cell) => cell.city + ', ' + cell.countryName,
    },
    {
        header: 'Verification',
        cell: (cell) => remapStatus(cell.verificationState),
    },
    {
        header: 'Account Manager',
        cell: (cell) => cell.accountManagerName || <i>None</i>,
    },
    {
        header: 'Created',
        cell: (cell) => date.format.a(cell.created),
    },
    {
        header: 'Status',
        cell: (cell) => (cell.isActive ? 'Active' : 'Inactive'),
    },
];
