import React, { useState } from 'react';
import { Card, Form, Select, Space } from 'antd';
import { InfoCircle } from 'iconsax-react';
import { date } from '../../legacy';

const DeadlineShift = () => {
    const [isExpanded, setExpansion] = useState(false);

    const exampleDay = new Date('01/01/2024');
    const agencyBase = date.addDay(exampleDay, -2);

    return (
        <div className="ant-form-vertical" style={{ marginBottom: 15 }}>
            <Space
                style={{
                    alignItems: 'flex-start',
                }}
            >
                <Form.Item name="customDeadlineShift" label="Deadline Shift">
                    <Select placeholder="Select" style={{ width: 250 }}>
                        <Option value={0}>No special changes (Default)</Option>
                        <Option value={1}>Remove 1 buffer day</Option>
                        <Option value={2}>Remove 2 buffer days</Option>
                    </Select>
                </Form.Item>
                <a
                    onClick={() => setExpansion((v) => !v)}
                    style={{ marginTop: 10, display: 'inline-block', lineHeight: '30px' }}
                >
                    <InfoCircle style={{ verticalAlign: 'middle', transform: 'rotate(180deg)' }} />{' '}
                    {isExpanded ? 'Hide' : 'Show'} Example
                </a>
                <Card style={{ marginLeft: 20, marginTop: -74 }} hidden={!isExpanded}>
                    <table>
                        <tr>
                            <td>Supplier Deadline</td>
                            <td style={{ paddingLeft: 30 }}>
                                {date.format.shortDayInverted(exampleDay)}, 15:00
                            </td>
                        </tr>
                        <tr>
                            <td>Base Agency Deadline (Default)</td>
                            <td style={{ paddingLeft: 30 }}>
                                {date.format.shortDayInverted(agencyBase)}, 15:00
                            </td>
                        </tr>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.customDeadlineShift !== currentValues.customDeadlineShift
                            }
                        >
                            {({ getFieldValue }) => (
                                <tr>
                                    <td>Custom Agency Deadline</td>
                                    <td style={{ paddingLeft: 30 }}>
                                        {date.format.shortDayInverted(
                                            date.addDay(agencyBase, getFieldValue('customDeadlineShift'))
                                        )}
                                        , 15:00
                                    </td>
                                </tr>
                            )}
                        </Form.Item>
                    </table>
                </Card>
            </Space>
        </div>
    );
};

export default DeadlineShift;
