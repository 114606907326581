import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Menu, PageHeader } from 'antd';
import BankDetails from './bank-details';
import BankAccountsList from './bank-accounts-list';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import BankAccountPage from './bank-account';

const items = [
    { label: <Link to="./">Bank Details</Link>, key: '' },
    { label: <Link to="./accounts">Accounts</Link>, key: 'accounts' },
];

const BankIndexPage = ({ banks, reloadBanks }) => {
    const { id } = useParams();
    const bank = banks?.find((item) => parseInt(item.id) === parseInt(id));

    const location = useLocation();
    const navigate = useNavigate();

    const [accounts, setAccounts] = useState(null);

    const reloadAccounts = () => {
        if (id !== 'create') {
            API.get({
                url: apiMethods.companyBankAccounts(id),
                success: setAccounts,
            });
        }
    };
    useEffect(reloadAccounts, []);

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={bank ? `Bank #${bank.id}: ${bank.name}` : id === 'create' ? 'Bank Creation' : ' '}
            />
            {id !== 'create' && (
                <Menu mode="horizontal" items={items} selectedKeys={location.pathname.split('/')[3] || ''} />
            )}
            <Routes>
                <Route path="/" element={<BankDetails bank={bank} id={id} reloadBanks={reloadBanks} />} />
                <Route path="/accounts/" element={<BankAccountsList accounts={accounts} />} />
                <Route
                    path="/accounts/:accountId"
                    element={
                        <BankAccountPage bankId={id} accounts={accounts} reloadAccounts={reloadAccounts} />
                    }
                />
            </Routes>
        </>
    );
};

export default BankIndexPage;
