import React, { useEffect, useState } from 'react';
import { windowSessionStorage } from 'htcore/misc/storage';
import { RequestSelector } from 'common/request-selector';
import apiMapperMethods from 'core/mapper-methods';

const CACHE_STORE_KEY = 'country_list_cache';

const optionsGenerator = (list = [], idName = 'id') => {
    const result = list.map((value) => ({
        value: value[idName],
        label: value.countryName,
    }));
    windowSessionStorage.set(CACHE_STORE_KEY, JSON.stringify(result));
    return result;
};

const CountrySelector = ({ idName, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(null);

    useEffect(() => {
        let cache;
        try {
            cache = JSON.parse(windowSessionStorage.get(CACHE_STORE_KEY));
        } catch (e) {}

        if (cache) {
            setDefaultOptions(cache);
        }
    }, []);

    return (
        <RequestSelector
            request={(value) => ({
                nakijin: apiMapperMethods.searchCountries,
                body: {
                    query: value,
                },
            })}
            optionsGenerator={(list) => optionsGenerator(list, idName)}
            placeholder="Any Country"
            defaultOptions={defaultOptions}
            {...props}
        />
    );
};

export default CountrySelector;
