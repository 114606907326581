import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import $auth from 'stores/auth';
import { Loader } from 'legacy';
import { Button, Checkbox, Form, Radio, Table, Typography } from 'antd';
import { notifications } from './notification';

const { Title } = Typography;

const SupplierSettings = ({ method, agencyMethod }) => {
    const [suppliers, setSuppliers] = useState();
    const [agencySuppliers, setAgencySuppliers] = useState();
    const [commonSuppliers, setCommonSuppliers] = useState();

    const [form] = Form.useForm();

    const getData = () => {
        if ($auth.permitted(['SupplierManagement', 'SupplierView'])) {
            API.get({
                url: method,
                success: (suppliers) => {
                    setSuppliers(suppliers);
                    form.setFieldsValue(suppliers);
                },
            });
            if (agencyMethod) {
                API.get({
                    url: agencyMethod,
                    success: (suppliers) => setAgencySuppliers(suppliers),
                });
            }
            API.get({
                sunpu: sunpuMethods.suppliersData,
                success: (suppliers) =>
                    setCommonSuppliers(suppliers.map(({ technicalDetails }) => technicalDetails)),
            });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const submit = (body) => {
        API.put({
            url: method,
            body,
            success: () => notifications('Suppliers Settings Saved', 'success'),
            after: getData,
        });
    };

    if (!$auth.permitted(['SupplierManagement', 'SupplierView'])) {
        return null;
    }

    if (!suppliers) {
        return <Loader />;
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => commonSuppliers?.find((item) => item.supplierCode === text).supplierName,
        },
        {
            title: 'Code',
            dataIndex: 'name',
            key: 'name',
            render: (text) =>
                text +
                (commonSuppliers?.find((item) => item.supplierCode === text).enableState === 'Disabled'
                    ? ' (Disabled)'
                    : ''),
        },
        {
            title: 'Active Status',
            dataIndex: 'name',
            key: 'name',
            render: (text, cell) =>
                commonSuppliers?.find((item) => item.supplierCode === text).enableState !== 'Disabled' ? (
                    !agencySuppliers || agencySuppliers[cell.name].isActive ? (
                        <Form.Item
                            name={[cell.name, 'isActive']}
                            valuePropName="checked"
                            key={cell.name}
                            style={{ marginBottom: 0 }}
                        >
                            <Checkbox defaultChecked={cell.isActive}>{cell.isActive}</Checkbox>
                        </Form.Item>
                    ) : (
                        'Disabled on Agency Level'
                    )
                ) : (
                    'Disabled Supplier'
                ),
        },
        ...(agencyMethod
            ? [
                  {
                      title: 'Agency Level',
                      key: 'agencyLevel',
                      render: (text, cell) => agencySuppliers?.[cell.name].ratesSettings,
                  },
              ]
            : []),
        {
            title: 'Rates Settings',
            dataIndex: 'name',
            key: 'name',
            render: (text, cell) => (
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const agencyValue = agencySuppliers?.[cell.name]?.ratesSettings;
                        const value = getFieldValue([cell.name, 'ratesSettings']);
                        const higherPriorityOptions = ['RefundableRatesOnly', 'NonRefundableRatesOnly'];

                        const higherPriorityValue =
                            value !== 'Default' && higherPriorityOptions.includes(agencyValue)
                                ? agencyValue
                                : null;

                        return (
                            commonSuppliers?.find((item) => item.supplierCode === text).enableState !==
                                'Disabled' &&
                            (!agencySuppliers || agencySuppliers[cell.name].isActive) && (
                                <Form.Item
                                    name={[cell.name, 'ratesSettings']}
                                    key={cell.name}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Radio.Group
                                        buttonStyle="solid"
                                        size="small"
                                        className={!higherPriorityValue && 'radio-buttons'}
                                    >
                                        <Radio.Button
                                            disabled={
                                                agencySuppliers &&
                                                (agencyValue === 'NonRefundableRatesOnly' ||
                                                    agencyValue === 'AllRatesAreNonRefundable')
                                            }
                                            value="RefundableRatesOnly"
                                            className={
                                                higherPriorityValue === 'RefundableRatesOnly' &&
                                                'higher-priority-button'
                                            }
                                        >
                                            Refundable Rates Only
                                        </Radio.Button>
                                        <Radio.Button
                                            value="NonRefundableRatesOnly"
                                            disabled={
                                                agencySuppliers &&
                                                (agencyValue === 'RefundableRatesOnly' ||
                                                    agencyValue === 'AllRatesAreNonRefundable')
                                            }
                                            className={
                                                higherPriorityValue === 'NonRefundableRatesOnly' &&
                                                'higher-priority-button'
                                            }
                                        >
                                            Non-Refundable Rates Only
                                        </Radio.Button>
                                        <Radio.Button
                                            value="AllRatesAreNonRefundable"
                                            disabled={
                                                agencySuppliers &&
                                                (agencyValue === 'NonRefundableRatesOnly' ||
                                                    agencyValue === 'RefundableRatesOnly')
                                            }
                                        >
                                            All Rates Are Non-Refundable
                                        </Radio.Button>
                                        <Radio.Button value="Default">
                                            {agencyMethod ? 'Same as Agency' : 'By Default'}
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        );
                    }}
                </Form.Item>
            ),
        },
    ];

    const list = Object.keys(suppliers).map((supplier) => ({
        name: supplier,
        isActive: suppliers[supplier].isActive,
        ratesSettings: suppliers[supplier].ratesSettings,
    }));

    if (list && commonSuppliers) {
        list.sort((a, b) =>
            commonSuppliers.find((item) => item.supplierCode === a.name).status <
            commonSuppliers.find((item) => item.supplierCode === b.name).status
                ? -1
                : 1
        );
    }

    return (
        <>
            <Title level={4} style={{ marginBottom: 20 }}>
                Available Suppliers
            </Title>
            <Form form={form} onFinish={submit} style={{ marginBottom: 50 }}>
                <Table
                    columns={columns}
                    dataSource={list}
                    loading={list === null}
                    rowKey="name"
                    locale={{
                        emptyText: list === null ? <Loader /> : 'Nothing to Show',
                    }}
                    pagination={false}
                />
                <Button type="primary" style={{ marginTop: 20 }} htmlType="submit">
                    Save Suppliers Settings
                </Button>
            </Form>
        </>
    );
};

export default SupplierSettings;
