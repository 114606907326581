import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { notifications } from 'components/notification';
import BuffersListWrap from './buffers-list-wrap';
import DefaultBufferHistory from './default-buffer-history';
import DefaultBufferForm from '../default-buffer-form';

const RateManagementPage = () => {
    useTitle('Forex Rate Management');

    const [defaultBuffer, setDefaultBuffer] = useState(null);
    const [loading, setLoading] = useState(true);

    const [history, setHistory] = useState(null);

    const getHistory = () => {
        API.get({
            url: apiMethods.defaultBufferHistory,
            success: setHistory,
        });
    };

    useEffect(() => {
        API.get({
            url: apiMethods.defaultBuffer,
            success: setDefaultBuffer,
            after: () => setLoading(false),
        });
        getHistory();
    }, []);

    const saveBuffer = (values) => {
        return API.post({
            url: apiMethods.updateDefaultBuffer(values.defaultBuffer),
            success: () => {
                notifications('Default Conversion Buffer saved!', 'success');
            },
            after: getHistory,
        });
    };

    return (
        <>
            <PageHeader
                title="Forex Rate Management"
                breadcrumb={{
                    routes: [
                        { breadcrumbName: 'Conversion Management' },
                        { breadcrumbName: 'Forex Rate Management' },
                    ],
                    itemRender: (route) => route.breadcrumbName,
                }}
            />

            {loading ? (
                <Loader />
            ) : (
                <DefaultBufferForm initialBufferValue={defaultBuffer} onSave={saveBuffer} />
            )}

            <BuffersListWrap />

            <DefaultBufferHistory history={history} />
        </>
    );
};

export default RateManagementPage;
