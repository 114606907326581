import React, { useEffect, useState } from 'react';
import DraggableArray from 'components/draggable-array';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Col, Row, Space, Typography } from 'antd';
import { notifications } from 'components/notification';
import { Loader, remapStatus } from 'legacy';

const { Title } = Typography;

const SuppliersPrioritiesPage = () => {
    const [suppliersPriorities, setSuppliersPriorities] = useState(null);

    useEffect(() => {
        API.get({
            sunpu: sunpuMethods.suppliersPriorities,
            success: setSuppliersPriorities,
        });
    }, []);

    const updateList = (dataKey, list) => {
        setSuppliersPriorities({
            ...suppliersPriorities,
            [dataKey]: list,
        });
    };

    const saveChanges = () => {
        API.put({
            sunpu: sunpuMethods.suppliersPriorities,
            body: suppliersPriorities,
            success: () => notifications('Saved', 'success'),
        });
    };

    if (!suppliersPriorities) {
        return <Loader />;
    }

    return (
        <>
            <Button onClick={saveChanges} style={{ margin: '20px 0' }} type="primary">
                Save Order Changes
            </Button>
            <Row justify="flex-start">
                {Object.keys(suppliersPriorities).map((dataKey) => (
                    <Col
                        key={dataKey}
                        style={{
                            borderRight: '1px dashed #eaeaea',
                            width: '350px',
                            paddingLeft: '20px',
                            marginTop: '30px',
                        }}
                    >
                        <Space direction="vertical">
                            <Title level={4}>{remapStatus(dataKey)}</Title>
                            <DraggableArray
                                list={suppliersPriorities[dataKey]}
                                updateList={(list) => updateList(dataKey, list)}
                            />
                        </Space>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default SuppliersPrioritiesPage;
