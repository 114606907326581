import React from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { notifications } from 'components/notification';
import { serializeRequest } from '../../../mapper/search/serializer';
import { formatDataToPayload } from './custom-hotels-list';

const DownloadHotelsList = ({ agentId, getFieldsValue }) => {
    const { id } = useParams();

    const download = (res, hideMessage) => {
        if (res.status !== 200) {
            hideMessage();
            notifications("Couldn't download", 'warning');
        } else {
            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = `hotels-agency-${id}-agent-${agentId}.csv`;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success('Downloaded');
            });
        }
    };

    const getFile = () => {
        const hideMessage = message.loading(' Downloading...', 0);

        API.post({
            url: apiMethods.hotelsList(id, agentId),
            body: serializeRequest(formatDataToPayload(getFieldsValue ? getFieldsValue() : {})),
            response: (result) => download(result, hideMessage),
        });
    };

    return (
        <Button icon={<DownloadOutlined />} onClick={getFile}>
            Download
        </Button>
    );
};

export default DownloadHotelsList;
