import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMapperMethods from 'core/mapper-methods';
import { remapStatus } from 'legacy';

const optionsGenerator = (list) =>
    Object.keys(list).map((key) => ({
        value: list[key],
        label: remapStatus(list[key]),
    }));

const DeactivationReasonSelector = (props) => {
    const { options, loading } = useSelector(
        { nakijin: apiMapperMethods.deactivationReasonTypes },
        optionsGenerator
    );

    return (
        <EntitySelector
            placeholder="Any Deactivation Reason"
            {...props}
            options={options}
            loading={loading}
        />
    );
};

export default DeactivationReasonSelector;
