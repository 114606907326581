import React from 'react';
import { API } from 'htcore';
import { useNavigate } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Button, Form, Input } from 'antd';
import { notifications } from 'components/notification';

const safeString = (val) => val.replace(/'/g, "''");

const FindBooking = () => {
    const navigate = useNavigate();
    const [findBookingForm] = Form.useForm();

    const submit = (values) => {
        const refCode = String(values.refCode).toUpperCase();
        const supplierRefCode = values.supplierRefCode;

        if (!supplierRefCode) {
            API.get({
                url: refCode.includes('MPS')
                    ? apiMethods.serviceByReferenceCode(refCode)
                    : apiMethods.bookingsByReferenceCode(refCode),
                success: () => {
                    navigate(`/booking/${refCode}`);
                },
            });
            return;
        }

        API.get({
            url: apiMethods.bookingsAll,
            body: {
                $filter: `tolower(supplierReferenceCode) eq tolower('${safeString(supplierRefCode)}')`,
                $orderBy: 'Created desc',
                $top: 10,
                $skip: 0,
            },
            success: (data) => {
                if (data?.length > 0) {
                    navigate(`/booking/${data[0].referenceCode}`);

                    if (data?.length > 1) {
                        notifications('Careful! More than one booking found!');
                    }
                } else {
                    notifications('Booking not found!');
                }
            },
            error: () => {
                notifications('Booking not found!');
            },
        });
    };

    return (
        <Form form={findBookingForm} onFinish={submit} layout="vertical" style={{ maxWidth: 500 }}>
            <Form.Item name="refCode" label="Reference code / Itinerary Number">
                <Input placeholder="HTL-NYC-AE-2311T0N1N / ITN-0606-00001" />
            </Form.Item>
            <Form.Item name="supplierRefCode" label="Supplier Reference code">
                <Input placeholder="REF-SUP-1234" />
            </Form.Item>
            <Button htmlType="submit">Search</Button>
        </Form>
    );
};

export default FindBooking;
