import React from 'react';
import apiMethods from 'core/methods';
import { EntitySelector, useSelector } from 'common/entity-selector';

const optionsGenerator = (list) =>
    list.map((key) => ({
        value: key.replaceAll(' ', '').replaceAll('-', ''),
        label: key,
    }));

const MealPlanSelector = (props) => {
    const { options, loading } = useSelector({ url: apiMethods.boardBasisTypes }, optionsGenerator);

    return <EntitySelector loading={loading} placeholder="None" {...props} options={options} />;
};

export default MealPlanSelector;
