import React, { useState, useEffect } from 'react';
import { API, useTitle } from 'htcore';
import { date, Loader } from 'legacy';
import apiMethods from 'core/methods';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import AgentChangeAgency from './agent-change-agency';
import AgentBookings from './agent-bookings';
import AgentSettings from './agent-settings';
import AgentCredentials from './agent-credentials';
import AgentDetails from './agent-details';
import $auth from 'stores/auth';
import { Descriptions, Menu, PageHeader } from 'antd';

const allMenuItems = [
    {
        label: <Link to="./bookings/all">Bookings</Link>,
        key: 'bookings',
        permissions: ['ViewBookings'],
    },
    {
        label: <Link to="./change-agency">Change Agency</Link>,
        key: 'change-agency',
        permissions: ['AgentManagement'],
    },
    {
        label: <Link to="./settings">Settings</Link>,
        key: 'settings',
        permissions: ['SupplierManagement', 'AgentManagement', 'SupplierView'],
    },
    {
        label: <Link to="./credentials">Credentials</Link>,
        key: 'credentials',
        permissions: ['AgentManagement'],
    },
    {
        label: <Link to="./details">Agent Details</Link>,
        key: 'details',
        permissions: ['AgentManagement'],
    },
];

const AgentPage = () => {
    useTitle('Agent');

    const navigate = useNavigate();
    const [agent, setAgent] = useState(null);
    const [agency, setAgency] = useState(null);
    const { id, agentId } = useParams();

    const location = useLocation();
    const menuItems = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    useEffect(() => {
        if ($auth.permitted('ViewAgents')) {
            API.get({
                url: apiMethods.agencyAgents(id),
                success: (list) => {
                    setAgent(list.filter((item) => item.agentId === Number(agentId))[0]);
                },
            });
        }
        API.get({
            url: apiMethods.agency(id),
            success: (data) => setAgency(data),
        });
    }, []);

    if (!agent && $auth.permitted('ViewAgents')) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate($auth.permitted('AgentManagement') ? './..' : './../../bookings/all')}
                title={`Agent #${agentId} in Agency #${id}`}
            />
            {agent && (
                <Descriptions
                    bordered
                    layout="vertical"
                    size="small"
                    style={{ margin: '-20px 0 30px', position: 'relative' }}
                >
                    <Descriptions.Item label="Name">{agent.name}</Descriptions.Item>
                    <Descriptions.Item label="Created">
                        {date.format.a(agent.created * 1000)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {agent.isActive ? 'Active' : 'Inactive'}
                    </Descriptions.Item>
                </Descriptions>
            )}
            <Menu mode="horizontal" items={menuItems} selectedKeys={location.pathname.split('/')[5] || ''} />
            <Routes>
                <Route path="/change-agency" element={<AgentChangeAgency />} />
                <Route path="/bookings/*" element={<AgentBookings agency={agency} />} />
                <Route path="/settings" element={<AgentSettings agency={agency} />} />
                <Route path="/credentials" element={<AgentCredentials />} />
                <Route path="/details" element={<AgentDetails agency={agency} />} />
            </Routes>
        </>
    );
};

export default AgentPage;
