import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTitle } from 'htcore';
import { Menu, PageHeader } from 'antd';
import SuppliersListPage from './suppliers-list';
import SupplierPage from './supplier-page-wrap';
import SuppliersPrioritiesPage from './suppliers-priorities';
import SuppliersRestrictionsPage from './restrictions/suppliers-resrictions';

const SuppliersPage = () => {
    useTitle('Suppliers');
    const location = useLocation();

    const allMenuItems = [
        {
            label: <Link to="./">General</Link>,
            key: '',
        },
        {
            label: <Link to="./suppliers-priorities">Suppliers Priority</Link>,
            key: 'suppliers-priorities',
        },
        {
            label: <Link to="./suppliers-restrictions">Restrictions</Link>,
            key: 'suppliers-restrictions',
        },
    ];

    return (
        <>
            <PageHeader title="Suppliers/Channels" style={{ paddingBottom: 16 }} />
            <Menu mode="horizontal" items={allMenuItems} selectedKeys={location.pathname.split('/')[2]} />
            <Routes>
                <Route path="/" element={<SuppliersListPage />} />
                <Route path="/:code/*" element={<SupplierPage />} />
                <Route path="/suppliers-priorities" element={<SuppliersPrioritiesPage />} />
                <Route path="/suppliers-restrictions/*" element={<SuppliersRestrictionsPage />} />
            </Routes>
        </>
    );
};

export default SuppliersPage;
