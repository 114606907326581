import React, { useState, useEffect } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { useParams } from 'react-router-dom';
import { Loader } from 'legacy';
import { Checkbox, Descriptions, Form, Typography } from 'antd';
import { generateLabel } from '../../../legacy/generate-label';

const { Title } = Typography;

const AgentDetails = ({ agency }) => {
    const { agentId } = useParams();
    const [details, setDetails] = useState(null);

    const loadDetails = () => {
        API.get({
            url: apiMethods.agencyAgent(agency.id, agentId),
            success: setDetails,
        });
    };

    useEffect(() => {
        loadDetails();
    }, []);

    if (!details) {
        return <Loader />;
    }

    return (
        <>
            <Descriptions bordered layout="vertical" size="small">
                <Descriptions.Item label="Identity Username">{details.identityUsername}</Descriptions.Item>
                <Descriptions.Item label="Identity Email">{details.identityEmail}</Descriptions.Item>
                <Descriptions.Item label="Agent Email">{details.agentEmail}</Descriptions.Item>
            </Descriptions>

            <Title level={5} style={{ marginTop: 30 }}>
                Agent Roles
            </Title>
            <ul>
                {details.roles.map((role) => (
                    <li>
                        <strong style={{ fontSize: 15 }}>{role.name}</strong>
                        <div style={{ marginBottom: 10 }}>
                            {role.permissions.map((permission) => generateLabel(permission)).join()}
                        </div>
                    </li>
                ))}
            </ul>

            <Title level={5} style={{ marginTop: 30 }}>
                Agent Notifications
            </Title>
            <ul>
                {Object.keys(details.notifications).map((notification) => (
                    <li>
                        {generateLabel(notification)}
                        {!details.notifications[notification].enabledProtocols.Email &&
                            details.notifications[notification].enabledProtocols.WebSocket &&
                            ' (Web only)'}
                        {details.notifications[notification].enabledProtocols.Email &&
                            !details.notifications[notification].enabledProtocols.WebSocket &&
                            ' (Email only)'}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default AgentDetails;
