import React, { useState } from 'react';
import { Button, Checkbox, Form, Select, Space } from 'antd';
import moment from 'moment';
import { date, Loader } from 'legacy';
import Table from 'components/table';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import { columns, CustomFilter, searches } from '../../bookings-table-settings';
import $auth from 'stores/auth';
import { API } from 'htcore';
import { BOOKING_STATUS } from 'htcore/enum';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import AdminOfflineSelector from 'components/selectors/admin-offline-selector';

const AgencyBookingsList = ({ bookings, agency, loadBookings }) => {
    const [statusFilter, setStatusFilter] = useState(null);
    const [deadlineFilter, setDeadlineFilter] = useState(0);
    const [adminFilter, setAdminFilter] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [isSaveModalVisible, setSaveModalVisibility] = useState(false);
    const [noHotelConfirmationFilter, setNoHotelConfirmationFilter] = useState(false);
    const [form] = Form.useForm();

    const contractKind = agency?.contractKind;

    const isAutoCancellationManagementAvailable =
        (contractKind === 'VirtualAccountOrCreditCardPayments' ||
            contractKind === 'OfflineOrCreditCardPayments') &&
        $auth.permitted('BookingAutoCancellationManagement');

    const getRequestBody = ({ isAutoCancellationDisabled }) => {
        let body = {};
        for (let i in isAutoCancellationDisabled) {
            if (
                isAutoCancellationDisabled[i] !== undefined &&
                isAutoCancellationDisabled[i] !==
                    bookings.find((booking) => booking.referenceCode === i).isAutoCancellationDisabled
            )
                body[i] = isAutoCancellationDisabled[i];
        }
        return body;
    };

    const onButtonClick = () => {
        form.validateFields().then((values) => {
            const body = getRequestBody(values);
            if (Object.keys(body).some((key) => body[key])) {
                setSaveModalVisibility(Object.keys(body).filter((key) => body[key]).length);
            } else {
                setSaveModalVisibility(true);
            }
        });
    };

    const onAutoCancellationStatusesSubmit = () => {
        form.validateFields().then((values) => {
            API.post({
                url: apiMethods.autoCancellationStatuses(),
                body: getRequestBody(values),
                success: () => {
                    notifications('Auto Cancellation Statuses Updated!', 'success');
                    setSaveModalVisibility(false);
                    loadBookings();
                },
            });
        });
    };

    const getIsEnableAutoCancel = (booking) =>
        booking.status === BOOKING_STATUS.PendingCancellation ||
        booking.status === BOOKING_STATUS.ManualCorrectionNeeded ||
        (booking.status === BOOKING_STATUS.Confirmed && date.isFuture(booking.deadlineDate));

    const allToggle = (e) => {
        setAllSelected(e.target.checked);
        form.setFieldsValue({
            isAutoCancellationDisabled: bookings.reduce(
                (res, val) => ({
                    ...res,
                    ...(getIsEnableAutoCancel(val) && { [val.referenceCode]: e.target.checked }),
                }),
                {}
            ),
        });
    };

    if (!bookings) {
        return <Loader />;
    }

    if (statusFilter) {
        bookings = bookings.filter((item) => item.status === statusFilter);
    }

    if (deadlineFilter) {
        bookings = bookings
            .filter((item) => moment(item.deadlineDate).add(1, 'day') > moment())
            .filter((item) => moment(item.deadlineDate) < moment().add(deadlineFilter, 'day'));
    }

    if (adminFilter) {
        bookings = bookings.filter((item) => item.adminId === Number(adminFilter));
    }

    if (noHotelConfirmationFilter) {
        bookings = bookings.filter(({ hotelConfirmationCode }) => !hotelConfirmationCode);
    }

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{
                isAutoCancellationDisabled: bookings.reduce(
                    (res, val) => ({ ...res, [val.referenceCode]: val.isAutoCancellationDisabled }),
                    {}
                ),
            }}
        >
            {isAutoCancellationManagementAvailable && (
                <Space style={{ width: '100%', justifyContent: 'space-between', marginBottom: 20 }}>
                    <div>
                        <Checkbox
                            onChange={(event) => {
                                setNoHotelConfirmationFilter(event?.target?.checked);
                            }}
                        >
                            No Hotel Confirmation Code
                        </Checkbox>
                    </div>
                    <Button type="primary" onClick={onButtonClick}>
                        Save Auto Cancellation States Changes
                    </Button>
                </Space>
            )}
            <Table
                list={bookings}
                columns={[
                    ...columns(null),
                    ...(isAutoCancellationManagementAvailable
                        ? [
                              {
                                  header: (
                                      <Checkbox value={allSelected} onChange={allToggle}>
                                          No Auto Cancellation
                                      </Checkbox>
                                  ),
                                  cell: (cell) => (
                                      <>
                                          <Form.Item
                                              noStyle
                                              name={['isAutoCancellationDisabled', cell.referenceCode]}
                                              valuePropName="checked"
                                          >
                                              <Checkbox disabled={!getIsEnableAutoCancel(cell)} />
                                          </Form.Item>
                                          {Boolean(cell.autoCancellationDisabledBy?.trim()) && (
                                              <span style={{ marginLeft: 8 }}>
                                                  by {cell.autoCancellationDisabledBy}
                                              </span>
                                          )}
                                      </>
                                  ),
                              },
                          ]
                        : []),
                ]}
                CustomFilter={
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', marginTop: 8 }}>
                        <CustomFilter apply={setStatusFilter} />
                        <Form.Item label="Deadline">
                            <Select
                                options={[
                                    { value: 0, label: 'Any Time' },
                                    { value: 3, label: 'In 3 days' },
                                    { value: 5, label: 'In 5 days' },
                                    { value: 7, label: 'In 7 days' },
                                ]}
                                onChange={(value) => setDeadlineFilter(value)}
                                style={{ minWidth: 200 }}
                                defaultValue={0}
                            />
                        </Form.Item>
                        <Form.Item label="Created by">
                            <AdminOfflineSelector
                                onChange={(value) => setAdminFilter(value)}
                                style={{ minWidth: 200 }}
                            />
                        </Form.Item>
                    </div>
                }
                textEmptyResult="No bookings match your search"
                textEmptyList="No bookings found"
                searches={searches}
                searchText="Find on this Page"
            />
            <ConfirmationSimpleModal
                visible={isSaveModalVisible}
                onCancel={() => setSaveModalVisibility(false)}
                headerText="Are you sure?"
                submitText="Approve"
                onConfirm={onAutoCancellationStatusesSubmit}
            >
                {isSaveModalVisible === true ? (
                    <>You are going to enable auto-cancellation for some bookings,</>
                ) : (
                    <>
                        You are going to cancel auto-cancellation for {isSaveModalVisible} booking
                        {isSaveModalVisible > 1 ? 's' : ''} for {agency?.name},
                    </>
                )}{' '}
                kindly ask you to approve your action
            </ConfirmationSimpleModal>
        </Form>
    );
};

export default AgencyBookingsList;
