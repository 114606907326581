import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, PageHeader, Select, Typography, Row, Col } from 'antd';
import OfflineBookingPage from './offline-booking-page';
import OfflineBookingService from './offline-booking-service/offline-booking-service';

const { Title } = Typography;

const OfflineBookingPageWrap = () => {
    const navigate = useNavigate();
    const [serviceForm] = Form.useForm();
    const { action, service, referenceCode } = useParams();

    const creation = useMemo(() => action === 'create' || action === 'duplicate', [action]);
    const duplicate = useMemo(() => action === 'duplicate', [action]);

    return (
        <>
            <PageHeader
                title={creation ? 'Create a New Offline Booking' : 'Edit Offline Booking ' + referenceCode}
                onBack={() => navigate(creation ? '/offline-bookings' : `/booking/${referenceCode}`)}
            />
            <Form
                form={serviceForm}
                initialValues={
                    creation && !duplicate
                        ? {}
                        : { serviceName: service === 'food-supplement' ? 'FoodSupplement' : null }
                }
                layout="vertical"
            >
                <Title level={4}>Service</Title>
                <Row size="large">
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item
                            name="serviceName"
                            label="Service Name"
                            rules={[{ required: true, message: 'Please select' }]}
                        >
                            <Select placeholder="Select">
                                <Option value="Accommodation">Accommodation</Option>
                                <Option value="AccommodationExtension">Accommodation Extension</Option>
                                <Option value="AccommodationRoomUpgrade">Accommodation Room Upgrade</Option>
                                <Option value="FoodSupplement">Food Supplement</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item shouldUpdate>
                    {({ getFieldValue }) =>
                        getFieldValue('serviceName') === 'FoodSupplement' ? (
                            <OfflineBookingService
                                serviceName={getFieldValue('serviceName')}
                                serviceForm={serviceForm}
                            />
                        ) : (
                            <OfflineBookingPage
                                serviceName={getFieldValue('serviceName')}
                                serviceForm={serviceForm}
                            />
                        )
                    }
                </Form.Item>
            </Form>
        </>
    );
};

export default OfflineBookingPageWrap;
