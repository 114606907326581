import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import $auth from 'stores/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Descriptions, Typography, Space, PageHeader } from 'antd';
import { notifications } from 'components/notification';
import { generateLabel } from 'legacy/generate-label';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';

const AdministratorPage = ({ list, reloadList }) => {
    const [confirmationActivateModalVisible, setConfirmationActivateModalVisible] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    let administrator = null;
    const [administratorForm] = Form.useForm();

    if (list) {
        administrator = list.filter((item) => item.id === Number(id))[0];
    }

    const submitRoles = (values) => {
        API.put({
            url: apiMethods.rolesOfAdministrator(id),
            body: Object.keys(values.roleIds)
                .map((key) => (values.roleIds[key] ? parseInt(key) : false))
                .filter((item) => item),
            success: () => {
                notifications('Roles saved', 'success');
                reloadList();
            },
        });
    };

    const openConfirmationActivateModal = () => {
        setConfirmationActivateModalVisible(true);
    };

    const confirmationActivate = () => {
        API.post({
            url: administrator.isActive
                ? apiMethods.administratorDeactivate(id)
                : apiMethods.administratorActivate(id),
            success: () => {
                notifications(
                    administrator.isActive ? 'Administrator Deactivated' : 'Administrator Activated',
                    'success'
                );
                setConfirmationActivateModalVisible(false);
                reloadList();
            },
        });
    };

    if (!administrator) {
        return <Loader />;
    }

    return (
        <Space direction="vertical" size="large">
            <PageHeader onBack={() => navigate('../')} title={`Administrator #${id}`} />
            <Descriptions bordered layout="vertical" size="small" style={{ margin: '-30px 0 20px' }}>
                <Descriptions.Item label="Name">
                    {administrator.firstName} {administrator.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Position">{administrator.position}</Descriptions.Item>
                <Descriptions.Item label="Status">
                    {administrator.isActive ? 'Active' : 'Inactive'}
                    <Button onClick={openConfirmationActivateModal} size="small" style={{ marginLeft: 15 }}>
                        {administrator.isActive ? 'Deactivate' : 'Activate'}
                    </Button>
                </Descriptions.Item>
            </Descriptions>
            <Form
                form={administratorForm}
                initialValues={{
                    roleIds: administrator.administratorRoleIds.reduce(
                        (obj, key) => ({ ...obj, [key]: administrator.administratorRoleIds.includes(key) }),
                        {}
                    ),
                }}
                onFinish={submitRoles}
            >
                <Typography.Title level={5}>Administrator`s Roles</Typography.Title>
                {$auth.rolesCompleteList.map((role) => (
                    <div key={role.id}>
                        <Form.Item name={['roleIds', String(role.id)]} valuePropName="checked">
                            <Checkbox defaultChecked={false}>{role.name}</Checkbox>
                        </Form.Item>
                        <div style={{ margin: '-25px 0 15px' }}>
                            {role.permissions.map((permission) => generateLabel(permission)).join()}
                        </div>
                    </div>
                ))}
                <Button htmlType="submit" style={{ marginTop: 20 }}>
                    Save Roles
                </Button>
            </Form>
            <ConfirmationSimpleModal
                visible={confirmationActivateModalVisible}
                onCancel={() => setConfirmationActivateModalVisible(false)}
                headerText="Are you sure?"
                submitText={administrator.isActive ? 'Deactivate' : 'Activate'}
                onConfirm={confirmationActivate}
            >
                {`You are about to ${administrator.isActive ? 'deactivate' : 'activate'} this administrator`}
            </ConfirmationSimpleModal>
        </Space>
    );
};

export default AdministratorPage;
