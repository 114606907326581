import React, { useEffect, useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import BigNumber from 'bignumber.js';
import { Loader } from 'legacy';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button, Form, InputNumber } from 'antd';
import { EntitySelector } from 'common/entity-selector';

const PENALTY_VALUES = {
    WITH: 'with',
    WITHOUT: 'without',
};

const getOptions = [
    {
        value: PENALTY_VALUES.WITH,
        label: 'With charges',
    },
    {
        value: PENALTY_VALUES.WITHOUT,
        label: 'Without charges',
    },
];

const OfflineBookingCancelButton = ({ isService, onlyWithoutCharges, loadBooking }) => {
    const [bookingCancelModalVisible, setBookingCancelModalVisible] = useState(false);
    const { refCode } = useParams();

    const [rate, setRate] = useState();
    const [currencies, setCurrencies] = useState();
    const [markup, setMarkup] = useState();

    const [loading, setLoading] = useState(false);

    const getRates = (sourceCurrency, targetCurrency, creationDate) => {
        if (sourceCurrency && targetCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${sourceCurrency}/${targetCurrency}`,
                body: {
                    date: creationDate || '',
                },
                success: (result) => setRate(result.toFixed(2)),
            });
        }
    };

    const getBookingData = () => {
        API.get({
            url: isService
                ? apiMethods.mealService(refCode)
                : apiMethods.offlineBookingsByReferenceCode(refCode),
            success: (result) => {
                const rateDetails = result?.rooms?.[0]?.rateDetails;
                const supplier = rateDetails?.supplierPrice?.currency;
                const customer = rateDetails?.customerPrice?.currency;

                getRates(supplier, customer, result?.creationDate);
                setCurrencies({ supplier, customer });
                setMarkup(result.markupValue);
            },
        });
    };

    useEffect(() => {
        if (bookingCancelModalVisible) {
            getBookingData();
        }
    }, [bookingCancelModalVisible]);

    const onChangeSupplier = (setFieldValue, getFieldValue) => {
        const supplierValue = getFieldValue('supplier');

        setFieldValue('supplier', supplierValue ? supplierValue.toFixed(2) : supplierValue);

        const newCustomerValue = BigNumber(supplierValue || 0).multipliedBy(rate);

        const markupValue = newCustomerValue.multipliedBy(new BigNumber(markup || 0).dividedBy(100));
        const totalWithMarkup = newCustomerValue.plus(markupValue).toNumber().toFixed(2);

        setFieldValue('customer', totalWithMarkup);
    };

    const onChangePenalty = (setFieldValue) => {
        setFieldValue('supplier', 0);
        setFieldValue('customer', 0);
    };

    const bookingCancel = async ({ supplier, customer }) => {
        setLoading(true);
        API.post({
            url: isService ? apiMethods.cancelMealService(refCode) : apiMethods.cancelOfflineBooking(refCode),
            body: {
                agencyCancellationFee: customer
                    ? {
                          amount: customer,
                          currency: currencies?.customer || 'NotSpecified',
                      }
                    : null,
                supplierCancellationFee: supplier
                    ? {
                          amount: supplier,
                          currency: currencies?.supplier || 'NotSpecified',
                      }
                    : null,
            },
            success: () => {
                notifications('Cancelled', 'success');
                loadBooking();
                setLoading(false);
                setBookingCancelModalVisible(false);
            },
            error: () => setLoading(false),
        });
    };

    return (
        <>
            <ConfirmationModal
                visible={bookingCancelModalVisible}
                onCancel={() => setBookingCancelModalVisible(false)}
                headerText={isService ? 'Cancel Service Booking' : 'Cancel Booking'}
                submitText="Confirm"
                reason={false}
                initialValues={{ penalty: PENALTY_VALUES.WITHOUT, customer: 0, supplier: 0 }}
                onSubmit={bookingCancel}
            >
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Form.Item noStyle shouldUpdate>
                            {({ setFieldValue }) => (
                                <Form.Item
                                    label="Cancellation Penalty"
                                    name="penalty"
                                    rules={[{ required: true }]}
                                >
                                    <EntitySelector
                                        disabled={onlyWithoutCharges}
                                        options={getOptions}
                                        onChange={() => onChangePenalty(setFieldValue)}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            style={{ width: '100%' }}
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.penalty !== currentValues.penalty
                            }
                        >
                            {({ getFieldValue, setFieldValue }) =>
                                getFieldValue('penalty') === PENALTY_VALUES.WITH && (
                                    <>
                                        <Form.Item name="supplier" label="Amount to Supplier">
                                            <InputNumber
                                                min={0}
                                                addonBefore={currencies?.supplier}
                                                style={{ width: '100%' }}
                                                onChange={() =>
                                                    onChangeSupplier(setFieldValue, getFieldValue)
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer" label="Amount for Customer">
                                            <InputNumber
                                                min={0}
                                                addonBefore={currencies?.customer}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Exchange Rate Supplier Currency to Customer Currency">
                                            <InputNumber value={rate} disabled style={{ width: '100%' }} />
                                        </Form.Item>
                                    </>
                                )
                            }
                        </Form.Item>
                    </>
                )}
            </ConfirmationModal>
            <Button onClick={() => setBookingCancelModalVisible(true)}>Cancel</Button>
        </>
    );
};

export default OfflineBookingCancelButton;
