import React from 'react';
import apiMethods from 'core/methods';
import SupplierSettings from 'components/supplier-settings';
import AgencyAccountManager from './agency-account-manager';
import AgencySearchingAndBooking from './agency-searching-and-booking';
import { Loader } from 'legacy';

const AgencySettings = ({ agency, loadAgency }) => {
    if (!agency) {
        return <Loader />;
    }

    return (
        <>
            <AgencyAccountManager agency={agency} loadAgency={loadAgency} />
            <AgencySearchingAndBooking agency={agency} />
            <SupplierSettings method={apiMethods.agencySupplierSettings(agency.id)} />
        </>
    );
};

export default AgencySettings;
