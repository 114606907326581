import React, { useEffect, useState } from 'react';
import { serializeFilename, serializeRequest } from './serializer';
import BookingSearchForm from './booking-search-form';
import apiMethods from 'core/methods';
import TablePages from 'components/table-pages';
import { columns } from './bookings-table-settings';
import usePages from 'components/use-pages';
import $auth from 'stores/auth';

const BookingSearch = ({ bookingSearchForm, isOfflineBookings }) => {
    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();
    const isPermittedBookingManagement = $auth.permitted('BookingManagement');

    useEffect(() => {
        bookingSearchForm.validateFields().then(setFormValues);
    }, [isOfflineBookings]);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <BookingSearchForm
                bookingSearchForm={bookingSearchForm}
                onSubmit={onSubmit}
                isOfflineBookings={isOfflineBookings}
            />
            <TablePages
                columns={columns}
                formValues={formValues}
                route={{ url: apiMethods.bookingsAll }}
                downloadRoute={
                    isPermittedBookingManagement ? { url: apiMethods.bookingsAllReportDownload } : null
                }
                serializeRequest={(values, ...rest) =>
                    serializeRequest(
                        { ...values, ...(isOfflineBookings ? { isOfflineOnly: isOfflineBookings } : {}) },
                        ...rest
                    )
                }
                serializeFilename={serializeFilename}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default BookingSearch;
