import React, { useMemo } from 'react';
import { remapStatus } from 'legacy';
import { BOOKING_STATUS } from 'htcore/enum';

const statusesColors = {
    '#1CBE69': [BOOKING_STATUS.Confirmed, BOOKING_STATUS.Completed],
    '#E25141': [
        BOOKING_STATUS.Cancelled,
        BOOKING_STATUS.Rejected,
        BOOKING_STATUS.Invalid,
        BOOKING_STATUS.PendingCancellation,
        BOOKING_STATUS.Discarded,
    ],
    '#231f20': [BOOKING_STATUS.Created, BOOKING_STATUS.Pending, BOOKING_STATUS.ManualCorrectionNeeded],
};

const BookingStatus = ({ status }) => {
    const color = useMemo(() => {
        let statusColor = '';

        Object.entries(statusesColors).forEach(([itemColor, statuses]) => {
            if (statuses.includes(status)) statusColor = itemColor;
        });

        return statusColor;
    }, [status]);

    return <b style={{ color }}>{remapStatus(status)}</b>;
};

export default BookingStatus;
