import { message } from 'antd';

export const copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        return clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        let textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            message.success('Copied to Clipboard!');
            return true;
        } catch (ex) {
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
};
