import React, { useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import moment from 'moment';
import { notifications } from 'components/notification';
import { Button, DatePicker, Form } from 'antd';

const CancelManuallyButton = ({ booking, loadBooking }) => {
    const [cancelManuallyModalVisible, setCancelManuallyModalVisible] = useState(false);

    const cancelManually = (values) => {
        API.post({
            url: apiMethods.bookingCancelManually(booking.bookingId),
            body: {
                cancellationDate: moment(values.date._d).format('MM/DD/YYYY'),
                reason: values.reason,
            },
            success: () => {
                notifications('Canceled', 'success');
            },
            after: () => {
                setCancelManuallyModalVisible(false);
                loadBooking();
            },
        });
    };

    return (
        <>
            <ConfirmationModal
                visible={cancelManuallyModalVisible}
                onCancel={() => setCancelManuallyModalVisible(false)}
                headerText="You are about to cancel"
                submitText="Cancel Manually"
                onSubmit={cancelManually}
            >
                <div>
                    This action <b>cannot</b> be undone.
                </div>
                <Form.Item name="date" label="Please select date">
                    <DatePicker />
                </Form.Item>
            </ConfirmationModal>
            <Button onClick={() => setCancelManuallyModalVisible(true)}>Cancel Manually</Button>
        </>
    );
};

export default CancelManuallyButton;
