import React, { useState } from 'react';
import { Button, Modal, DatePicker, TimePicker, Form } from 'antd';
import { date } from 'legacy';
import ConfirmationModal from 'components/confirms/confirmation-modal';

const { fullDate, shortTime, c } = date.format;

const NotificationsModal = ({ isOpen, title, startMessage, endMessage, handleClose, sendEmail }) => {
    const [form] = Form.useForm();
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

    const onClose = () => {
        form.resetFields();
        handleClose();
    };

    const onFinish = () => setIsOpenConfirmation(true);

    const submitConfirm = async () => {
        const { date: dateValue, startTime, endTime } = form.getFieldsValue();

        sendEmail({
            date: fullDate(dateValue),
            startTime: shortTime(startTime),
            endTime: shortTime(endTime),
        });

        setIsOpenConfirmation(false);
        onClose();
    };

    return (
        <>
            <Modal
                destroyOnClose
                title={title}
                open={isOpen}
                width={600}
                footer={
                    <Button
                        htmlType="submit"
                        form="modal-submit-notification"
                        type="primary"
                        style={{ width: 'fit-content', borderRadius: 2 }}
                    >
                        Send email
                    </Button>
                }
                onCancel={onClose}
            >
                {startMessage}

                <Form
                    form={form}
                    id="modal-submit-notification"
                    onFinish={onFinish}
                    style={{ display: 'flex', gap: 20, alignItems: 'center' }}
                >
                    <div>
                        <p style={{ marginBottom: 8 }}>On Date</p>
                        <Form.Item name="date" rules={[{ required: true }]}>
                            <DatePicker format={c} />
                        </Form.Item>
                    </div>
                    <span>between</span>
                    <div>
                        <p style={{ marginBottom: 8 }}>From</p>
                        <Form.Item name="startTime" rules={[{ required: true }]}>
                            <TimePicker showNow={false} format="HH:mm" placeholder="Start Time" />
                        </Form.Item>
                    </div>
                    <div>
                        <p style={{ marginBottom: 8 }}>To</p>
                        <Form.Item name="endTime" rules={[{ required: true }]}>
                            <TimePicker showNow={false} format="HH:mm" placeholder="End Time" />
                        </Form.Item>
                    </div>
                </Form>
                {endMessage}
            </Modal>
            <ConfirmationModal
                reason={false}
                visible={isOpenConfirmation}
                headerText="Are you sure?"
                submitText="Send Email"
                onCancel={() => setIsOpenConfirmation(false)}
                onSubmit={submitConfirm}
            >
                You are going to send a messages to all the agents, kindly ask you to approve your action
            </ConfirmationModal>
        </>
    );
};

export default NotificationsModal;
