import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Typography } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { copyToClipboard } from 'legacy';
import InvitationForm from 'components/invitation-form';
import { notifications } from 'components/notification';

const { Title } = Typography;

const AgentInvitePage = ({ agency }) => {
    const [allAgentRolesList, setAgentRoles] = useState([]);
    const [result, setResult] = useState();
    const [isSendByEmail, setIsSendByEmail] = useState(true);

    useEffect(() => {
        API.get({
            url: apiMethods.allAgentRolesList,
            success: (roles) => {
                setAgentRoles(roles);
            },
        });
    }, []);

    const submit = (values) => {
        const route = isSendByEmail ? apiMethods.agencyInviteAgentSend : apiMethods.agencyInviteAgentCreate;

        API.post({
            url: route(agency.id),
            body: {
                userRegistrationInfo: values,
                roleIds: Object.keys(values.roleIds)
                    .map((key) => (values.roleIds[key] ? parseInt(key) : false))
                    .filter((item) => item),
            },
            success: (details) => {
                setResult({ details, values });
                window.scrollTo(0, 0);
                notifications(
                    isSendByEmail ? 'Invitation Email was sent' : 'Invitation Link was generated',
                    'success'
                );
            },
        });
    };

    if (result) {
        let origin = 'https://happytravel.com';
        if (window.location.host !== 'manage.happytravel.com') {
            origin = 'https://dev.happytravel.com';
        }
        const invite = origin + '/signup/invite/' + result.values.email + '/' + result.details;

        return (
            <>
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Agent Invitation Created{' '}
                    {isSendByEmail ? ' and email was sent to ' + result.values.email : ''}
                </Title>

                <Space direction="vertical">
                    <Input value={invite} style={{ minWidth: '700px' }} />
                    <Space>
                        <Button onClick={() => copyToClipboard(invite)}>Copy to Clipboard</Button>
                    </Space>
                </Space>
            </>
        );
    }

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Invite New Agent
            </Title>
            <InvitationForm
                rolesTitle="Roles that Agent will own"
                roles={allAgentRolesList}
                onSubmit={submit}
                beforeSubmit={() => setIsSendByEmail(true)}
                alternativeSubmit={() => setIsSendByEmail(false)}
            />
        </>
    );
};

export default AgentInvitePage;
