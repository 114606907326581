import React, { useState, useEffect } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import AgencyBookingsList from 'pages/bookings/tabs/agency-bookings/agency-bookings-list';
import AgencyBookingsItineraryList from '../../bookings/tabs/agency-bookings/agency-bookings-itinerary-list';
import { agentBookingsMenuItems } from '../agent/agent-bookings';

const AgencyBookings = ({ agency }) => {
    const [bookings, setBookings] = useState(null);
    const { id } = useParams();

    const location = useLocation();
    const currentTab = location.pathname.split('/')[4] || '';

    const loadBookings = () => {
        API.get({
            url: apiMethods.bookingsByAgency(id),
            success: (bookings) => {
                setBookings(
                    bookings.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                );
            },
        });
    };

    useEffect(() => {
        loadBookings();
    }, []);

    return (
        <>
            <Menu mode="horizontal" items={agentBookingsMenuItems} selectedKeys={currentTab} />
            <Routes>
                <Route
                    path="/all"
                    element={
                        <AgencyBookingsList bookings={bookings} agency={agency} loadBookings={loadBookings} />
                    }
                />

                <Route path="/itinerary" element={<AgencyBookingsItineraryList bookings={bookings} />} />
            </Routes>
        </>
    );
};

export default AgencyBookings;
