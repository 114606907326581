import React, { useEffect, useMemo, useState } from 'react';
import { API } from 'htcore';
import shimabaraMethods from 'core/shimabara-methods';
import { Loader } from 'legacy';
import { Table, Menu, Row, Button } from 'antd';
import { notifications } from 'components/notification';
import LocalitiesTableCell from './localities-table-cell';
import { Tabs, localitiesColumns, menuItems } from './localities-table-settings';
import LocalitiesConfirmModal from './localities-confirm-modal';

const TableHeader = ({ tableTitle, tab, onChangeTab }) => (
    <Row justify="space-between">
        <span>{tableTitle}</span>
        <Menu
            style={{ margin: 0, padding: 0, background: 'none', borderBottom: 0, lineHeight: '22px' }}
            mode="horizontal"
            items={menuItems}
            selectedKeys={tab}
            onSelect={({ key }) => onChangeTab(key)}
        />
    </Row>
);

const isEqualArrays = (first, second) => {
    if (!first && !second) return true;
    if (!first || !second) return false;

    return JSON.stringify(first.sort()) === JSON.stringify(second.sort());
};

const sortArray = (arr = []) => {
    const sortedArray = arr.sort((a, b) => {
        if (a.isNewPrimary !== b.isNewPrimary) return a.isNewPrimary ? -1 : 1;

        const aHasChildren = !!a.children?.length;
        const bHasChildren = !!b.children?.length;

        if (aHasChildren !== bHasChildren) return aHasChildren ? -1 : 1;

        return a.name.localeCompare(b.name);
    });

    return sortedArray.map(({ children, ...rest }) => ({
        ...rest,
        ...(children?.length && {
            children: children.sort((childA, childB) => childA.name.localeCompare(childB.name)),
        }),
    }));
};

const createTableData = (data, localityState) => {
    if (!data) return null;
    const dataSchema = {};
    data.forEach((item) => (dataSchema[item.htId] = item));

    const exclude = new Set();

    const fulfilledData = data.map((item) => {
        const { children, savedChildren, isNewPrimary } = localityState[item.htId] || {};

        if (children) {
            const isSaved = isEqualArrays(savedChildren, children);
            children.forEach((child) => exclude.add(child));

            const mappedChildren = children.map((childId) => ({
                ...dataSchema[childId],
                isSaved: isSaved,
                parentId: item.htId,
                parentName: item.name,
            }));

            return { ...item, isSaved, isNewPrimary, children: mappedChildren };
        }

        return item;
    });

    return fulfilledData.filter((item) => !exclude.has(item.htId));
};

const mapLocalitiesDataToView = ({ primaryLocalitiesWithMergeIno, notMergedLocalities }) => {
    const mergedData = primaryLocalitiesWithMergeIno.reduce(
        (acc, { primaryLocalityName: name, primaryLocalityHtId: htId, mergedLocalities }) => {
            acc.push({ name, htId });
            acc.push(...mergedLocalities);

            return acc;
        },
        []
    );

    return [...mergedData, ...notMergedLocalities];
};

const createLocalityState = ({ primaryLocalitiesWithMergeIno }) =>
    primaryLocalitiesWithMergeIno.reduce((obj, { primaryLocalityHtId, mergedLocalities }) => {
        const children = mergedLocalities.map(({ htId }) => htId);
        obj[primaryLocalityHtId] = { children, savedChildren: children };
        return obj;
    }, {});

const createDataForPayload = (state, primaryLocalityHtId) => {
    const data = state[primaryLocalityHtId];
    return {
        primaryLocalityHtId,
        mergedLocalitiesHtIds: data.children,
    };
};

const LocalitiesTable = ({ selectedCountry, selectedDestination }) => {
    const [tab, setTab] = useState(Tabs.Localities);

    // type: { [id: string]: { children: Array<string>, savedChildren: Array<string>, isNewPrimary: bool }}
    const [localityState, setLocalityState] = useState(null);
    // type: Array<{ htId: string, name: string }>
    const [data, setData] = useState(null);
    const [searchString, setSearchString] = useState('');
    const [loading, setLoading] = useState(false);

    const [primaryLocality, setPrimaryLocality] = useState(null);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const getLocalities = () => {
        setLoading(true);

        API.get({
            shimabara: shimabaraMethods.localitiesSearch(selectedCountry.id, selectedDestination?.id || ''),
            success: (result) => {
                setData(mapLocalitiesDataToView(result));

                setLocalityState(createLocalityState(result));
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        setPrimaryLocality(null);
        setLocalityState(null);
        setData(null);
        getLocalities();
    }, [selectedCountry, selectedDestination]);

    const undoPrimary = () => {
        if (primaryLocality) {
            setLocalityState((prevState) => {
                const state = prevState[primaryLocality];
                return {
                    ...prevState,
                    [primaryLocality]: {
                        ...state,
                        children: state?.savedChildren || [],
                        isNewPrimary: false,
                    },
                };
            });

            setPrimaryLocality(null);
        }
    };

    const onChangeTab = (tab) => {
        undoPrimary();
        setTab(tab);
    };

    const tableData = useMemo(() => createTableData(data, localityState), [data, localityState]);

    const searchData = (dataArray, searchStr) =>
        (dataArray || []).filter(
            ({ name, htId }) =>
                name.toLowerCase().includes(searchStr.toLowerCase()) || htId === primaryLocality
        );

    const filteredList = useMemo(() => {
        const sortedData = sortArray(searchData(tableData, searchString));

        if (tab === Tabs.Mapped)
            return sortedData.filter((item) => item?.children?.length || item.htId === primaryLocality);
        if (tab === Tabs.Unmapped)
            return sortedData.filter((item) => !item?.children?.length || item.htId === primaryLocality);

        return sortedData;
    }, [tableData, tab, searchString]);

    const onChangeSearch = (event) => {
        const { value } = event.target;
        setSearchString(value);
    };

    const onSave = () => {
        API.post({
            shimabara: shimabaraMethods.mergeLocalities,
            body: createDataForPayload(localityState, primaryLocality),
            success: () => {
                notifications('Saved!', 'success');
                setOpenConfirmModal(false);
                undoPrimary();
                getLocalities();
            },
        });
    };

    const disableButton = useMemo(() => {
        if (!primaryLocality) return true;
        const { children, savedChildren } = localityState[primaryLocality] || {};

        return isEqualArrays(children, savedChildren);
    }, [localityState, primaryLocality]);

    return (
        <>
            <Table
                scroll={{ y: '35vh' }}
                pagination={false}
                rowKey="htId"
                columns={localitiesColumns(
                    <TableHeader
                        tableTitle={`Localities for ${selectedCountry?.name} (${filteredList?.length || 0})`}
                        tab={tab}
                        onChangeTab={onChangeTab}
                    />,
                    (cell) => (
                        <LocalitiesTableCell
                            tab={tab}
                            tableData={tableData}
                            cell={cell}
                            localityState={localityState}
                            primaryLocality={primaryLocality}
                            setPrimaryLocality={setPrimaryLocality}
                            setLocalityState={setLocalityState}
                        />
                    ),

                    onChangeSearch
                )}
                dataSource={filteredList || []}
                loading={loading}
                locale={{
                    emptyText: loading ? <Loader /> : 'Nothing to Show',
                }}
                expandable={{
                    expandIcon: () => <div />,
                    expandedRowKeys: Object.keys(localityState || {}),
                    defaultExpandAllRows: true,
                }}
                rowClassName={(record) => (record.htId.toString() === primaryLocality ? 'primary-row' : '')}
            />

            <Button
                type="primary"
                disabled={disableButton}
                style={{ marginTop: 40 }}
                onClick={() => setOpenConfirmModal(true)}
            >
                Save Changes
            </Button>

            {openConfirmModal && (
                <LocalitiesConfirmModal
                    localityState={localityState}
                    primaryLocality={primaryLocality}
                    data={data}
                    onSave={onSave}
                    onCancel={() => {
                        undoPrimary();
                        setOpenConfirmModal(false);
                    }}
                />
            )}
        </>
    );
};

export default LocalitiesTable;
