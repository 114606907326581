import React, { useState, useEffect } from 'react';
import apiMethods from 'core/methods';
import { price, remapStatus } from 'legacy';
import { API } from 'htcore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { Button, Descriptions, PageHeader } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import { Loader } from 'legacy';
import $auth from 'stores/auth';

const AgencyHeader = ({ accounts, agency, setAgency, availableCurrencies }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [locationState, setLocationState] = useState(null);

    useEffect(() => {
        setLocationState(location?.state);
    }, []);

    const onBack = () => {
        navigate('/agency/list', {
            state: locationState,
        });
    };

    const activate = (reason) =>
        API.post({
            url: apiMethods.activateAgency(agency.id),
            body: { reason },
            success: () => {
                notifications('Agency activated', 'success');
            },
        });

    const deactivate = (reason) =>
        API.post({
            url: apiMethods.deactivateAgency(agency.id),
            body: { reason },
            success: () => {
                notifications('Agency deactivated', 'success');
            },
        });

    const submitConfirm = (values) => {
        if (agency?.isActive) {
            setVisible(false);
            setAgency({
                ...agency,
                isActive: false,
            });
            return deactivate(values.reason);
        } else {
            setVisible(false);
            setAgency({
                ...agency,
                isActive: true,
            });
            return activate(values.reason);
        }
    };

    const statusChange = () => {
        setVisible(true);
    };

    const removeAgency = () => {
        API.delete({
            url: apiMethods.agency(agency.id),
            success: onBack,
        });
    };

    if (!agency) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={onBack}
                breadcrumb={{
                    routes: [
                        {
                            path: '/agency/list',
                            breadcrumbName: 'All Agencies',
                        },
                        {
                            breadcrumbName: agency.name,
                        },
                    ],
                    itemRender: (route) =>
                        !route.path ? (
                            <span>{route.breadcrumbName}</span>
                        ) : (
                            <Link to={route.path}>{route.breadcrumbName}</Link>
                        ),
                }}
                title={`${agency.name} | Agency #${agency.id}`}
            />
            {$auth.permitted(['AgencyBalanceObservation', 'AgencyVerification', 'AgencyManagement']) && (
                <Descriptions bordered layout="vertical" size="small" style={{ marginBottom: 30 }}>
                    {$auth.permitted('AgencyBalanceObservation') && (
                        <Descriptions.Item label="Account Balance / Limit">
                            {accounts?.length
                                ? accounts.map((account) => (
                                      <>
                                          <strong className={account.balance.amount > 0 ? 'green' : ''}>
                                              {price(account.balance)}
                                          </strong>
                                          {Boolean(account.creditLimit) && (
                                              <span style={{ color: '#888' }}>
                                                  {' '}
                                                  / Limit: {price(account.currency, account.creditLimit)}
                                              </span>
                                          )}
                                          <br />
                                      </>
                                  ))
                                : 'Accounts are not created yet'}
                        </Descriptions.Item>
                    )}
                    {$auth.permitted('AgencyManagement') && (
                        <Descriptions.Item label="Verification State / Contract Kind">
                            <div>{remapStatus(agency.verificationState)}</div>
                            <div>{agency.contractKind && remapStatus(agency.contractKind)}</div>
                            <div>{Boolean(availableCurrencies) && availableCurrencies.join(', ')}</div>
                            {$auth.permitted('AgencyManagement') &&
                                agency.isActive === false &&
                                agency.verificationState !== 'FullAccess' && (
                                    <ConfirmationPopup
                                        title={
                                            <div>
                                                The <b>{agency.name}</b> will be deleted.
                                            </div>
                                        }
                                        okText="Confirm"
                                        cancelText="Cancel"
                                        onConfirm={removeAgency}
                                    >
                                        <Button size="small" style={{ marginTop: 10 }}>
                                            Delete Agency
                                        </Button>
                                    </ConfirmationPopup>
                                )}
                        </Descriptions.Item>
                    )}
                    {$auth.permitted('AgencyVerification') && agency && agency.isActive !== null && (
                        <Descriptions.Item label="Status">
                            {agency.isActive ? 'Active' : 'Inactive'}
                            <Button onClick={statusChange} size="small" style={{ marginLeft: 15 }}>
                                {agency.isActive ? 'Deactivate' : 'Activate'}
                            </Button>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            )}
            <ConfirmationModal
                visible={visible}
                onCancel={() => setVisible(false)}
                headerText={`You are about to ${
                    agency.isActive ? 'deactivate' : 'activate'
                } an agency access`}
                submitText={`${agency.isActive ? 'Deactivate' : 'Activate'}`}
                onSubmit={submitConfirm}
            >
                <div>
                    All its agents loose an ability to use the system until the agency will be re-activated.
                    Please enter a reason to {agency.isActive ? 'deactivate' : 'activate'}.
                </div>
            </ConfirmationModal>
        </>
    );
};

export default AgencyHeader;
