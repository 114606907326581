import React from 'react';
import { EntitySelector } from 'common/entity-selector';

const TitleSelector = (props) => {
    const options = ['Mr', 'Ms', 'Miss', 'Mrs'].map((item) => ({ label: item, value: item }));

    return <EntitySelector placeholder="Title" {...props} options={options} />;
};

export default TitleSelector;
