import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Space, DatePicker, Select } from 'antd';
import RatingSelector from 'components/selectors/rating-selector';
import SupplierSelector from 'components/selectors/supplier-selector';
import DeactivationReasonSelector from 'components/selectors/deactivation-reason-selector';
import CountrySelector from 'components/selectors/country-selector';
import LocalitySelector from 'components/selectors/locality-selector';
import AccommodationSearchCsvButton from './accommodation-search-csv-button';
import { date } from 'legacy';
import moment from 'moment/moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const AccommodationSearchForm = ({ accommodationSearchForm, onSubmit }) => {
    return (
        <Form
            form={accommodationSearchForm}
            onFinish={onSubmit}
            layout="vertical"
            initialValues={{ isActive: true }}
        >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} xl={6}>
                    <Form.Item name="nameQuery" label="Name Query">
                        <Input placeholder="Part of name" allowClear />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="addressLineQuery" label="Address Line Query">
                        <Input placeholder="Part of address" allowClear />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="countryId" label="Country">
                        <CountrySelector
                            onChange={() => accommodationSearchForm.setFieldValue('localityId', null)}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.countryId !== currentValues.countryId
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('countryId') ? (
                                <Form.Item name="localityId" label="Locality">
                                    <LocalitySelector countryId={getFieldValue('countryId')} />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="ratings" label="Rating">
                        <RatingSelector multiple />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="suppliers" label="Merged Suppliers">
                        <SupplierSelector multiple />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="deactivationReasons" label="Deactivation Reasons">
                        <DeactivationReasonSelector multiple />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}></Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="rangeDates" label="Check-Out Date Period">
                        <RangePicker
                            format={date.format.c}
                            placeholder={['From', 'Till']}
                            disabledDate={(current) => current && current > moment()}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.rangeDates !== currentValues.rangeDates
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('rangeDates') ? (
                                <Form.Item name="sortField" label="Sort Results">
                                    <Select placeholder="Not Sorted" allowClear>
                                        <Option value="turnover">Turnover</Option>
                                        <Option value="bookingsCount">Bookings Count</Option>
                                        <Option value="roomNightsCount">Room Nights Count</Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <Space>
                            <Form.Item noStyle name="isActive" valuePropName="checked">
                                <Checkbox>Only Active Accommodations</Checkbox>
                            </Form.Item>
                            <Form.Item noStyle name="hasDirectContract" valuePropName="checked">
                                <Checkbox>Only with Direct Contract</Checkbox>
                            </Form.Item>
                        </Space>
                        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                            <Space size="large">
                                <Button type="primary" htmlType="submit">
                                    Search Accommodations
                                </Button>
                                <Button
                                    htmlType="button"
                                    onClick={() => accommodationSearchForm.resetFields()}
                                >
                                    Clear Filters
                                </Button>
                            </Space>

                            <AccommodationSearchCsvButton accommodationSearchForm={accommodationSearchForm} />
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default AccommodationSearchForm;
