export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getExcludedFieldName = (field) => `excluded${capitalizeFirstLetter(field)}`;
export const getTypeFieldName = (field) => `${field}Type`;

export const getInitialValues = (fieldsWithExclude) =>
    fieldsWithExclude.reduce((values, item) => {
        values[getTypeFieldName(item)] = 'include';
        return values;
    }, {});

export const getSavedValues = (values, fieldsWithExclude) => {
    const data = {};

    fieldsWithExclude.forEach((field) => {
        const excludedFieldName = getExcludedFieldName(field);
        const typeFieldName = getTypeFieldName(field);
        // The "type" field for each field does not come from the backend.
        // So, to find out the type "includes" or "exclude" it is necessary to check in which of the arrays there are elements.
        const isExcludedType = !!values[excludedFieldName]?.length;

        data[field] = isExcludedType ? values[excludedFieldName] : values[field];
        data[typeFieldName] = isExcludedType ? 'except' : 'include';
    });

    return data;
};

export const mapFieldsToPayload = (fields, values) => {
    const newValues = { ...values };
    fields.forEach((field) => {
        const excludedFieldName = getExcludedFieldName(field);
        const typeFiledName = getTypeFieldName(field);

        if (newValues[typeFiledName] === 'except') {
            newValues[excludedFieldName] = newValues[field];
            newValues[field] = [];
        } else {
            newValues[excludedFieldName] = [];
        }

        delete newValues[typeFiledName];
    });

    return newValues;
};

export const getValidation =
    (field, typeFieldName) =>
    ({ getFieldValue }) => ({
        validator(_, value) {
            const typeFieldValue = getFieldValue(typeFieldName || getTypeFieldName(field));
            if (!value.length && typeFieldValue === 'except') {
                return Promise.reject(new Error('Please enter value'));
            }
            return Promise.resolve();
        },
    });
