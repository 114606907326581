import React from 'react';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'htcore';
import { Menu, PageHeader } from 'antd';
import AccommodationDetails from './accommodation-details';
import AccommodationHistory from './accommodation-history';
import SalesSettings from '../sales-settings/sales-settings';
import PreferredSettingsWrap from './preferred-settings/preferred-settings-wrap';

const AccommodationPageWrap = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { htId } = useParams();
    useTitle(htId);

    const allMenuItems = [
        {
            label: <Link to="./">Accommodation Details</Link>,
            key: '',
        },
        {
            label: <Link to="./sales">Sales Settings</Link>,
            key: 'sales',
        },
        {
            label: <Link to="./history">Mapping History</Link>,
            key: 'history',
        },
        {
            label: <Link to="./preferred-settings">Preferred Settings</Link>,
            key: 'preferred-settings',
        },
    ];

    return (
        <>
            <PageHeader onBack={() => navigate('../')} title={htId} />
            {
                <Menu
                    mode="horizontal"
                    items={allMenuItems}
                    selectedKeys={location.pathname.split('/')[4] || ''}
                />
            }
            <Routes>
                <Route path="/" element={<AccommodationDetails />} />
                <Route path="/sales" element={<SalesSettings />} />
                <Route path="/history" element={<AccommodationHistory />} />
                <Route path="/preferred-settings/*" element={<PreferredSettingsWrap />} />
            </Routes>
        </>
    );
};

export default AccommodationPageWrap;
