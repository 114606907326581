import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import BanksListPage from './banks-list';
import BankIndexPage from './bank-index';

const BankDetailsIndex = () => {
    useTitle('Company Accounts');

    const [banks, setBanks] = useState(null);

    const reloadBanks = () => {
        setBanks(null);
        API.get({
            url: apiMethods.companyBanks(),
            success: setBanks,
        });
    };

    useEffect(reloadBanks, []);

    return (
        <Routes>
            <Route path="/" element={<BanksListPage banks={banks} />} />
            <Route path="/:id/*" element={<BankIndexPage banks={banks} reloadBanks={reloadBanks} />} />
        </Routes>
    );
};

export default BankDetailsIndex;
