import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { date, Loader } from 'legacy';
import { Button, Checkbox, Col, DatePicker, Form, PageHeader, Row, Select, Space } from 'antd';
import RatingSelector from 'components/selectors/rating-selector';
import SupplierSelector from 'components/selectors/supplier-selector';
import DownloadHotelsList from './download-hotels-list';
import { serializeRequest } from '../../../mapper/search/serializer';
import CountriesSelector from './countries-selector';

const { RangePicker } = DatePicker;

export const formatDataToPayload = (values) => {
    const { countryIds, ...restValues } = values;

    const localityValues = (countryIds || []).reduce((newValues, { country, locality }) => {
        if (country) {
            newValues.countryIds = [...(newValues?.countryIds || []), country];
            if (locality?.length)
                newValues.localityIds = [...(newValues?.localityIds || []), ...(locality || [])];
        }

        return newValues;
    }, {});

    return { ...restValues, ...localityValues };
};

const CustomHotelsList = () => {
    const navigate = useNavigate();
    const { id, agentId } = useParams();
    const [form] = Form.useForm();

    const [initialValues, setInitialValues] = useState(null);
    const [hotelsNumber, setHotelsNumber] = useState(null);
    const [loading, setLoading] = useState(true);
    const [countLoading, setCountLoading] = useState(false);

    const calculate = (values) => {
        setCountLoading(true);
        API.post({
            url: apiMethods.hotelsCount(id, agentId),
            body: serializeRequest(formatDataToPayload(values || form.getFieldsValue())),
            success: (result) => setHotelsNumber(result.toString()),
            after: () => setCountLoading(false),
        });
    };

    const getData = () => {
        setLoading(true);

        API.get({
            url: apiMethods.agentSuppliers(id, agentId),
            body: { shouldIntersect: true },
            success: (result) => {
                const suppliers = Object.entries(result)
                    .map(([key, { isActive }]) => isActive && key)
                    .filter((item) => item);

                const agentData = { suppliers: suppliers };

                setInitialValues(agentData);
                calculate(agentData);
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const reset = () => form.setFieldValue('suppliers', initialValues.suppliers);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader title={`The List of Hotels for Agent ${agentId}`} onBack={() => navigate('../')} />

            <Form
                form={form}
                initialValues={{ ...initialValues, countryIds: [{}] }}
                layout="vertical"
                onValuesChange={() => setHotelsNumber(null)}
            >
                <Row>
                    <Form.Item
                        name="suppliers"
                        label="Merged Suppliers"
                        style={{ width: '100%', marginBottom: 8 }}
                    >
                        <SupplierSelector multiple />
                    </Form.Item>
                    <div className="link" style={{ marginBottom: 16, cursor: 'pointer' }} onClick={reset}>
                        Reset according to agent settings
                    </div>
                </Row>

                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name="ratings" label="Rating">
                            <RatingSelector multiple />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name="rangeDates" label="Check-Out Date Period">
                            <RangePicker
                                format={date.format.c}
                                placeholder={['From', 'Till']}
                                disabledDate={(current) => current && current > moment()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.rangeDates !== currentValues.rangeDates
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('rangeDates') ? (
                                    <Form.Item name="sortField" label="Sort Results">
                                        <Select placeholder="Not Sorted" allowClear>
                                            <Option value="turnover">Turnover</Option>
                                            <Option value="bookingsCount">Bookings Count</Option>
                                            <Option value="roomNightsCount">Room Nights Count</Option>
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                </Row>

                <CountriesSelector form={form} />

                <Row gutter={20}>
                    <Col span={24}>
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <Space>
                                <Form.Item noStyle name="hasDirectContract" valuePropName="checked">
                                    <Checkbox>Only with Direct Contract</Checkbox>
                                </Form.Item>
                                <Form.Item noStyle name="considerRestrictions" valuePropName="checked">
                                    <Checkbox>
                                        Consider the Market/Country/Accommodation restrictions
                                    </Checkbox>
                                </Form.Item>
                            </Space>

                            <Space>
                                <Button
                                    disabled={countLoading}
                                    onClick={() => calculate()}
                                    loading={countLoading}
                                >
                                    Count
                                </Button>
                                <DownloadHotelsList agentId={agentId} getFieldsValue={form.getFieldsValue} />
                            </Space>

                            {hotelsNumber && (
                                <Space>
                                    Number of available hotels: <strong>{hotelsNumber}</strong>
                                </Space>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default CustomHotelsList;
