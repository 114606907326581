import React, { useMemo } from 'react';
import { Button, Modal, Space } from 'antd';

const LocalitiesConfirmModal = ({ localityState, primaryLocality, data, onSave, onCancel }) => {
    const getLocalitiesNames = (localityIds) => {
        const localitiesNames = {};
        data.forEach((item) => (localitiesNames[item.htId] = item.name));

        return localityIds.map((id) => localitiesNames[id] || id);
    };

    const findChanges = (savedData = [], newData = []) => {
        const removedItems = savedData.filter((item) => !newData.includes(item));
        const addedItems = newData.filter((item) => !savedData.includes(item));
        const withoutChanges = newData.filter(
            (item) => !addedItems.includes(item) && !removedItems.includes(item)
        );

        return {
            removed: getLocalitiesNames(removedItems),
            added: getLocalitiesNames(addedItems),
            withoutChanges: getLocalitiesNames(withoutChanges),
        };
    };

    const confirmData = useMemo(() => {
        const { savedChildren, children } = localityState[primaryLocality] || {};
        const { removed, added, withoutChanges } = findChanges(savedChildren, children);

        const primaryLocalityName = data.find((item) => item.htId === primaryLocality)?.name || '';

        return {
            isRemovedAll: removed.length === savedChildren?.length,
            withoutChanges: withoutChanges.join(', '),
            primaryLocalityName,
            removedLocalities: removed.join(', '),
            addedLocalities: added.join(', '),
        };
    }, [localityState, primaryLocality, data]);

    const { isRemovedAll, withoutChanges, primaryLocalityName, removedLocalities, addedLocalities } =
        confirmData;

    return (
        <Modal
            open
            title="Confirm changes"
            onCancel={onCancel}
            footer={
                <Space>
                    <Button type="primary" style={{ width: 'fit-content' }} onClick={onSave}>
                        Confirm
                    </Button>
                    <Button style={{ width: 'fit-content' }} onClick={onCancel}>
                        Discard
                    </Button>
                </Space>
            }
        >
            <>
                {isRemovedAll ? (
                    <span>
                        Upon saving changes, all relations associated with{' '}
                        <strong>{primaryLocalityName}</strong> will be deleted. This includes removing{' '}
                        <strong>{removedLocalities}</strong> from the list of related localities.{' '}
                        <strong>{primaryLocalityName}</strong> will be independent without any related
                        localities and all searches will start work in the HappyTravel under unmerged
                        localities separately: <strong>{primaryLocalityName}</strong>,{' '}
                        <strong>{removedLocalities}</strong>.
                    </span>
                ) : (
                    <>
                        Upon saving changes,
                        {addedLocalities && (
                            <span>
                                all offers related to <strong>{addedLocalities}</strong> will be displayed
                                under <strong>{primaryLocalityName}</strong>.
                            </span>
                        )}
                        {removedLocalities && (
                            <span>
                                {' '}
                                <strong>{removedLocalities}</strong> is removed from the list of related
                                localities (cities) under <strong>{primaryLocalityName}</strong>.
                            </span>
                        )}
                        {withoutChanges && (
                            <span>
                                {' '}
                                The relations between <strong>{primaryLocalityName}</strong> and{' '}
                                <strong>{withoutChanges}</strong> remains intact as expected.
                            </span>
                        )}
                    </>
                )}
            </>
        </Modal>
    );
};

export default LocalitiesConfirmModal;
