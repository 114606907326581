import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Typography } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import InvitationForm from 'components/invitation-form';
import $auth from 'stores/auth';

const InviteAdministratorPage = observer(() => {
    const [success, setSuccess] = useState(false);

    const submit = (values) => {
        API.post({
            url: apiMethods.adminSendInvitation,
            body: {
                registrationInfo: {
                    title: values.title,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    position: values.position,
                    email: values.email,
                },
                roleIds: Object.keys(values.roleIds)
                    .map((key) => (values.roleIds[key] ? parseInt(key) : false))
                    .filter((item) => item),
            },
            success: () => {
                setSuccess(true);
            },
        });
    };

    return (
        <>
            <Typography.Title level={4}>Invite Administrator</Typography.Title>
            {success === null && <Loader />}
            {success === true && <Typography.Title level={3}>Your invitation sent</Typography.Title>}

            {false === success && (
                <InvitationForm
                    rolesTitle="Roles that Administrator will own"
                    roles={$auth.rolesCompleteList}
                    onSubmit={submit}
                />
            )}
        </>
    );
});

export default InviteAdministratorPage;
