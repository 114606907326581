import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Typography, Row, Col, Form } from 'antd';
import { notifications } from 'components/notification';
import SearchTable from './search-table';
import SupplierTable from './supplier-table';
import RestrictionsSaveModal from './restrictions-save-modal';

const { Title } = Typography;

const RestrictionsCountries = () => {
    const [form] = Form.useForm();

    const [list, setList] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const { code } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.allCountries(),
            success: (result) => setList(result.map((item) => ({ ...item, id: item.code }))),
        });
    }, []);

    const onSelectRow = ({ id }) => setSelectedCountry(id);

    useEffect(() => {
        if (code) onSelectRow({ id: code });
    }, []);

    const saveChanges = (keepRestrictions) => {
        API.post({
            url: apiMethods.countriesRestrictions(selectedCountry, keepRestrictions),
            body: form.getFieldsValue(true),
            success: () => {
                setOpenModal(false);
                notifications('Saved!', 'success');
            },
        });
    };

    return (
        <>
            <Row justify="space-between">
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Countries
                </Title>

                <Button onClick={() => setOpenModal(true)} disabled={!selectedCountry}>
                    Save Changes
                </Button>
            </Row>

            <Row gutter={30}>
                <Col span={12}>
                    <SearchTable
                        loading={list === null}
                        selectedRow={selectedCountry}
                        tableTitle={`Countries List (${list?.length || 0})`}
                        list={list}
                        onRow={(record) => ({ onClick: () => onSelectRow(record) })}
                    />
                </Col>
                <Col span={12}>
                    <SupplierTable url={apiMethods.countriesRestrictions} id={selectedCountry} form={form} />
                </Col>
            </Row>

            <RestrictionsSaveModal
                open={openModal}
                keepButtonText="Keep restriction on accommodation level"
                questionText="Do you want to keep the previously added restrictions at the accommodation level?"
                saveChanges={saveChanges}
                onClose={() => setOpenModal(false)}
            />
        </>
    );
};

export default RestrictionsCountries;
