import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SuppliersRestrictionsList from './suppliers-resrictions-list';
import Restriction from './restriction';

const SuppliersRestrictionsPage = () => {
    return (
        <Routes>
            <Route path="/" element={<SuppliersRestrictionsList />} />
            <Route path="/:id?" element={<Restriction />} />
        </Routes>
    );
};

export default SuppliersRestrictionsPage;
