import React, { useEffect, useState } from 'react';
import { Loader } from 'legacy';
import { Button, Row, Table, Typography } from 'antd';
import TableExpandIcon from 'components/table-expand-icon';
import { columns } from './buffers-table-settings';
import BuffersModal, { CONVERSION_VALUES } from './buffers-modal';

const { Title } = Typography;

export const MODAL_TYPES = {
    DEFAULT: '',
    EDIT: 'edit',
    ADD: 'add',
};

const getColumns = (list, columnsFunc) => {
    return list?.length
        ? columnsFunc().map((column) => ({
              title: column.header,
              ...(typeof column.cell === 'string'
                  ? { dataIndex: column.cell }
                  : { render: (text, record) => column.cell(record) }),
              key: typeof column.cell === 'string' ? column.cell : column.title,
          }))
        : null;
};

const BuffersList = ({ loading, disableBuffer, list, updateData, modalProps }) => {
    const [modalType, setModalType] = useState(MODAL_TYPES.DEFAULT);
    const [modalData, setModalData] = useState(null);

    const addModalData = (data) => {
        setModalData({
            ...data,
            conversionType:
                data?.conversionType === 'FixedPercent'
                    ? CONVERSION_VALUES.PERCENT
                    : CONVERSION_VALUES.AMOUNT,
        });
        setModalType(MODAL_TYPES.EDIT);
    };

    const onCloseModal = () => {
        updateData();
        setModalType(MODAL_TYPES.DEFAULT);
        setModalData(null);
    };

    useEffect(() => {
        updateData();
    }, []);

    return (
        <>
            <Row justify="space-between" align="middle" style={{ margin: '60px 0 30px' }}>
                <Title level={4}>Custom Conversion Buffers</Title>
                <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => setModalType(MODAL_TYPES.ADD)}
                    disabled={disableBuffer}
                >
                    Add New Rule
                </Button>
            </Row>

            <Table
                pagination={false}
                dataSource={list || []}
                columns={getColumns(list, () => columns(addModalData))}
                expandable={{
                    expandIcon: ({ ...iconProps }) => (
                        <TableExpandIcon hide={!iconProps.record?.children?.length} {...iconProps} />
                    ),
                }}
                loading={loading}
                rowKey={(record) => `${record.id}${record.source}${record.target}`}
                locale={{
                    emptyText: loading ? <Loader /> : 'Nothing to Show',
                }}
            />

            {modalType && (
                <BuffersModal
                    modalType={modalType}
                    initialValues={modalData}
                    onClose={onCloseModal}
                    {...modalProps}
                />
            )}
        </>
    );
};

export default BuffersList;
