import React, { useEffect, useState } from 'react';
import { Loader } from 'legacy';
import { Table, Input, Checkbox, Form, Space } from 'antd';

const columns = (tableTitle, withCheckbox, onChangeSearch) => [
    {
        title: tableTitle,
        dataIndex: 'name',
        key: 'name',
        children: [
            {
                title: () => <Input placeholder="Search" onChange={onChangeSearch} />,
                render: (_, cell) => {
                    if (withCheckbox)
                        return (
                            <Space>
                                <Form.Item noStyle name={cell.name} valuePropName="checked">
                                    <Checkbox />
                                </Form.Item>
                                <span>{cell?.name}</span>
                            </Space>
                        );

                    return cell?.name;
                },
            },
        ],
    },
];

const SearchTable = ({ loading, selectedRow, withCheckbox, tableTitle, list, ...props }) => {
    const [filteredList, setFilteredList] = useState(null);

    useEffect(() => {
        if (list) setFilteredList(list);
    }, [list]);

    const onChangeSearch = (event) => {
        const { value } = event.target;
        const newList = list.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));

        setFilteredList(newList);
    };

    return (
        <Table
            pagination={false}
            rowKey="id"
            columns={columns(tableTitle, withCheckbox, onChangeSearch)}
            dataSource={filteredList}
            loading={loading}
            locale={{
                emptyText: loading ? <Loader /> : 'Nothing to Show',
            }}
            rowClassName={(record) => (record.id.toString() === selectedRow ? 'selected-row' : '')}
            {...props}
        />
    );
};

export default SearchTable;
