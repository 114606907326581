import React, { useEffect, useState } from 'react';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import { price } from 'legacy';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button, DatePicker, Form } from 'antd';

const BookingCancelButton = ({ booking, loadBooking }) => {
    const [bookingCancelModalVisible, setBookingCancelModalVisible] = useState(false);
    const [penalty, setPenalty] = useState(null);
    const { refCode } = useParams();

    const serviceCode = refCode.includes('MPS') ? refCode : null;
    const isService = !!serviceCode;

    useEffect(() => {
        if (bookingCancelModalVisible === true && !isService) {
            API.get({
                url: apiMethods.bookingCancellationPenalty(booking.bookingId),
                success: setPenalty,
            });
        }
    }, [bookingCancelModalVisible]);

    const bookingCancel = () => {
        return API.post({
            url: isService
                ? apiMethods.cancelMealService(serviceCode)
                : apiMethods.bookingCancel(booking.bookingId),
            success: () => {
                notifications('Cancelled', 'success');
                loadBooking();
                setBookingCancelModalVisible(false);
            },
        });
    };

    return (
        <>
            <ConfirmationModal
                visible={bookingCancelModalVisible}
                onCancel={() => setBookingCancelModalVisible(false)}
                headerText="Are you absolutely sure?"
                submitText="Confirm"
                reason={false}
                onSubmit={bookingCancel}
            >
                {isService ? (
                    <div>
                        You are cancel a booking service. This action <b>cannot</b> be undone. This will
                        request a cancellation on supplier's side and permanently cancel the booking service{' '}
                        <b>{refCode}</b> in the system in case of success.
                    </div>
                ) : (
                    <>
                        <div>
                            You are about to cancel a booking. This action <b>cannot</b> be undone. This will
                            request a cancellation on supplier's side and permanently cancel the booking{' '}
                            <b>{refCode}</b> in the system in case of success.
                        </div>
                        <Form.Item name="date" label="Please select date">
                            <DatePicker />
                        </Form.Item>
                        {penalty ? <p className="red">Cancellation cost: {price(penalty)}</p> : <p>...</p>}
                    </>
                )}
            </ConfirmationModal>
            <Button onClick={() => setBookingCancelModalVisible(true)}>Cancel</Button>
        </>
    );
};

export default BookingCancelButton;
