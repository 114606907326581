import React from 'react';
import { Popconfirm } from 'antd';

// todo: Deprecate
const ConfirmationPopup = ({ children, title, onConfirm, okText, cancelText }) => {
    return (
        <Popconfirm
            title={title}
            onConfirm={onConfirm}
            okText={okText}
            cancelText={cancelText}
            placement="left"
        >
            {children}
        </Popconfirm>
    );
};

export default ConfirmationPopup;
