import React, { useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import { Loader } from 'legacy';
import BookingsItineraryList from '../itinerary/bookings-itinerary-list';
import { searches } from '../../bookings-table-settings';

const AgencyBookingsList = ({ bookings }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const searcher = (result) => {
        if (searchQuery) {
            result = result.filter((item) => {
                if (typeof item != 'object') {
                    return false;
                }
                const found = (values) => {
                    const queries = searchQuery
                        .toLowerCase()
                        .split(' ')
                        .filter((v) => v.length);
                    for (let i = 0; i < queries.length; i++) {
                        if (
                            !values.some(
                                (v = '') => typeof v == 'string' && v.toLowerCase().indexOf(queries[i]) >= 0
                            )
                        ) {
                            return false;
                        }
                    }
                    return true;
                };
                return found(searches(item));
            });
        }
        return result;
    };

    const list = useMemo(() => {
        const intBookings = (bookings || [])
            .filter((item) => item.itineraryBookingsCount > 1)
            .sort((a, b) => a.id - b.id);

        return searcher(intBookings);
    }, [bookings, searchQuery]);

    if (!bookings) {
        return <Loader />;
    }

    return (
        <>
            <Form layout="vertical" style={{ marginLeft: 'auto', width: '220px' }}>
                <Form.Item label="Find on this page">
                    <Input
                        allowClear
                        onChange={(event) => setSearchQuery(event.target.value)}
                        placeholder="Enter Search Text"
                    />
                </Form.Item>
            </Form>

            <BookingsItineraryList list={list} />
        </>
    );
};

export default AgencyBookingsList;
