import React from 'react';
import { Space, Typography } from 'antd';

const { Title } = Typography;

const PageDenied = () => (
    <Space direction="vertical" size="large" style={{ marginTop: 150 }}>
        <Title level={2} style={{ marginBottom: 40 }}>
            Denied
        </Title>
        <a className="link" href="https://happytravel.com">
            Return to Happytravel Application as Agent
        </a>
        <a className="link" href="/logout">
            Sign Out
        </a>
    </Space>
);

export default PageDenied;
