import React, { useState } from 'react';
import { Button, Card, Descriptions } from 'antd';
import { date } from 'legacy';
import Allotments from './allotments';

const BookingDetailsRoom = ({ booking, room, index }) => {
    const [isExpanded, setExpansion] = useState(false);
    const details = booking.bookingDetails;

    const PassengerName = ({ passenger, hideTitle }) =>
        passenger
            ? (passenger.title && !hideTitle ? passenger.title + '. ' : '') +
              passenger.firstName +
              ' ' +
              passenger.lastName
            : '';

    return (
        <Card
            type="inner"
            title={
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <b>
                        {room.cancelled ? <span style={{ color: 'red' }}>Cancelled Room: </span> : ''}{' '}
                        {details.roomDetails.length > 0 && 'Room ' + (index + 1) + ': '}
                        {room.contractName}
                        {room.contractDescription && ` (${room.contractDescription})`}
                    </b>
                    <span>{room.roomReferenceCode}</span>
                </div>
            }
            style={{ minWidth: 600, ...(room.cancelled ? { borderColor: '#ffbbbb' } : {}) }}
            actions={[
                <Button
                    type="text"
                    onClick={() => setExpansion(!isExpanded)}
                    style={{ width: '100%', margin: '-12px 0' }}
                >
                    Show {isExpanded ? 'Less' : 'More'}
                </Button>,
            ]}
        >
            <Descriptions column={1} labelStyle={{ width: 180 }}>
                {Boolean(room.cancelled) && (
                    <Descriptions.Item label="Cancelled">{date.format.e(room.cancelled)}</Descriptions.Item>
                )}
                {room.passengers && (
                    <Descriptions.Item label="Guests">
                        <div>
                            <div>
                                <strong>
                                    {`${
                                        room.passengers.filter(
                                            (passenger) => passenger.age === null || passenger.age >= 18
                                        ).length
                                    } Adults` +
                                        (room.passengers.filter((passenger) => passenger.age !== null)
                                            .length !== 0
                                            ? `, ${
                                                  room.passengers.filter(
                                                      (passenger) =>
                                                          passenger.age !== null && passenger.age < 18
                                                  ).length
                                              } Children`
                                            : '')}
                                </strong>
                            </div>
                            {room.passengers.map((item, index) => (
                                <div key={index}>
                                    {index + 1}.{' '}
                                    <PassengerName passenger={item} hideTitle={item.age !== null} />
                                    {item.age !== null && item.age < 18 && ` (${item.age} years)`}
                                </div>
                            ))}
                        </div>
                    </Descriptions.Item>
                )}

                {Boolean(details.agentReference) && (
                    <Descriptions.Item label="Supplier Reference Code">
                        {details.agentReference}
                    </Descriptions.Item>
                )}
                {Boolean(room.supplierRoomReferenceCode) && booking.supplier !== 'Jumeirah Hotels' && (
                    <Descriptions.Item label="Supplier Inner Room Code">
                        {room.supplierRoomReferenceCode}
                    </Descriptions.Item>
                )}
                {Boolean(room.roomConfirmationCode) && (
                    <Descriptions.Item label="Hotel Confirmation Code">
                        {room.roomConfirmationCode}
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Allotments booking={booking} room={room} contract={details} isExpanded={isExpanded} />
        </Card>
    );
};

export default BookingDetailsRoom;
