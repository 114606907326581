import React, { useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';

const ChangeHotelConfirmationCode = ({ visible, booking, serviceCode, onFinish, onClose }) => {
    const [disabled, setDisabled] = useState(false);

    const [form] = Form.useForm();

    const submit = () => {
        setDisabled(true);
        form.validateFields().then(
            (values) => {
                API.put({
                    url: apiMethods.bookingHotelConfirmationCode(
                        serviceCode || booking.bookingDetails.referenceCode,
                        !!serviceCode
                    ),
                    body: values,
                    success: () => {
                        onClose();
                        onFinish();
                        setDisabled(false);
                        form.resetFields();
                    },
                });
            },
            () => {
                setDisabled(false);
            }
        );
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={
                <>
                    <Button key="back" onClick={onClose} className="modal-cancel-btn">
                        Cancel
                    </Button>
                    <Button htmlType="submit" onClick={submit} disabled={disabled}>
                        Save
                    </Button>
                </>
            }
        >
            <Form
                form={form}
                initialValues={{
                    hotelConfirmationCode: booking.bookingDetails.hotelConfirmationCode,
                }}
                layout="vertical"
                onFinish={submit}
            >
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Form.Item
                        name="hotelConfirmationCode"
                        label="Hotel Confirmation Code"
                        rules={[
                            { required: true, message: 'Please enter Hotel Confirmation Code' },
                            { min: 2, message: 'Too short' },
                        ]}
                    >
                        <Input placeholder="Enter Hotel Confirmation Code" />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default ChangeHotelConfirmationCode;
