import React, { useState, useEffect } from 'react';
import AgencyBookingsList from 'pages/bookings/tabs/agency-bookings/agency-bookings-list';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import $auth from 'stores/auth';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import AgencyBookingsItineraryList from '../../bookings/tabs/agency-bookings/agency-bookings-itinerary-list';

export const agentBookingsMenuItems = [
    {
        label: <Link to="./all">All</Link>,
        key: 'all',
    },
    {
        label: <Link to="./itinerary">Itinerary</Link>,
        key: 'itinerary',
    },
];

const AgentBookings = ({ agency }) => {
    const { agentId } = useParams();
    const [bookings, setBookings] = useState(null);

    const location = useLocation();
    const currentTab = location.pathname.split('/')[6] || '';

    const loadBookings = () => {
        API.get({
            url: apiMethods.bookingsByAgent(agentId),
            success: (bookings) => {
                setBookings(
                    bookings.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                );
            },
        });
    };

    useEffect(() => {
        if ($auth.permitted('BookingManagement')) {
            loadBookings();
        }
    }, []);

    if (!$auth.permitted('BookingManagement')) {
        return null;
    }

    return (
        <>
            <Menu mode="horizontal" items={agentBookingsMenuItems} selectedKeys={currentTab} />
            <Routes>
                <Route
                    path="/all"
                    element={
                        <AgencyBookingsList bookings={bookings} loadBookings={loadBookings} agency={agency} />
                    }
                />

                <Route path="/itinerary" element={<AgencyBookingsItineraryList bookings={bookings} />} />
            </Routes>
        </>
    );
};

export default AgentBookings;
