import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Typography, Timeline, Space } from 'antd';

const { Title } = Typography;

const SuppliersRestrictionsHistory = () => {
    const [history, setHistory] = useState(null);

    const getHistory = () => {
        API.get({
            url: apiMethods.supplierRestrictionsHistory,
            success: (result) => setHistory(result),
        });
    };

    useEffect(() => {
        getHistory();
    }, []);

    if (!history?.length) return null;

    return (
        <Space direction="vertical" size="large" style={{ marginTop: 60 }}>
            <Title level={4}>History of Restriction Changes</Title>

            <Timeline>
                {history?.map((item, index) => {
                    const { Description: editDescription } = item.eventData;

                    return (
                        <Timeline.Item key={index} color={index ? 'gray' : 'green'}>
                            <b>{date.format.a(item.created)}</b>{' '}
                            <span style={{ color: '#888', marginRight: 3 }}>
                                at {date.format.time(item.created)}.
                            </span>
                            <b>{item.type}</b>{' '}
                            <span>
                                {editDescription ? (
                                    <span>
                                        <b>{editDescription.oldValue}</b> to{' '}
                                        <b>{editDescription.updatedValue}</b>
                                    </span>
                                ) : (
                                    <b>{item.description}</b>
                                )}
                            </span>
                            {!!item.adminName && (
                                <span>
                                    {' '}
                                    by{' '}
                                    <Link
                                        target="_blank"
                                        className="link"
                                        to={`/administrators/${item.adminId}/details`}
                                    >
                                        Admin: {item.adminName}
                                    </Link>
                                </span>
                            )}
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </Space>
    );
};

export default SuppliersRestrictionsHistory;
