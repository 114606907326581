import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import { date, Loader } from 'legacy';
import Table from 'components/table';

const { Title } = Typography;

const SupplierHistory = ({ code }) => {
    const [history, setHistory] = useState(null);

    const loadHistory = () => {
        if (code) {
            API.get({
                sunpu: sunpuMethods.supplierHistory(code),
                success: (data) => setHistory(data),
            });
        }
    };

    useEffect(() => {
        loadHistory();
    }, []);

    if (!history) return <Loader />;

    return (
        <>
            <Title level={4}>Change History</Title>
            <Table
                list={history}
                columns={[
                    {
                        header: 'Date',
                        cell: (note) => date.format.e(note.date),
                    },
                    {
                        header: 'Action',
                        cell: 'state',
                    },
                    {
                        header: 'Admin Name',
                        cell: ({ adminName }) => adminName || '—',
                    },
                    {
                        header: 'Reason',
                        cell: 'reason',
                    },
                ]}
                textEmptyResult="No history for this supplier"
            />
        </>
    );
};

export default SupplierHistory;
