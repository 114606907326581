import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Transfer } from 'antd';
import { Loader } from 'legacy';
import $auth from 'stores/auth';

const MarketCountries = ({ marketName, countriesList, marketCountries, setMarketCountries }) => {
    const [selectedAndFreeCountries, setSelectedAndFreeCountries] = useState(null);

    useEffect(() => {
        if (!countriesList) {
            return;
        }

        API.get({
            url: apiMethods.marketCountries(1),
            success: (result) => {
                setSelectedAndFreeCountries(
                    [...result, ...countriesList].sort((a, b) => (a.name < b.name ? -1 : 1))
                );
            },
        });
    }, [countriesList]);

    const filterOption = (query, item) => {
        if (!query) {
            return true;
        }
        return item.title.toLowerCase().includes(query.toLowerCase());
    };

    if (!selectedAndFreeCountries) {
        return <Loader />;
    }

    return (
        <Transfer
            dataSource={selectedAndFreeCountries.map((country) => ({
                key: country.code,
                title: country.name,
            }))}
            titles={['No Markets', marketName]}
            targetKeys={marketCountries}
            onChange={setMarketCountries}
            render={(item) => item.title}
            listStyle={{
                width: '100%',
                height: 400,
            }}
            showSelectAll={false}
            className="rotated"
            showSearch
            filterOption={filterOption}
            disabled={!$auth.permitted('MarkupManagement')}
        />
    );
};

export default MarketCountries;
