import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Button, Form, Input, PageHeader } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import MarketCountries from './market-countries';
import { Loader } from 'legacy';
import $auth from 'stores/auth';

const MarketPage = observer(() => {
    const [market, setMarket] = useState(null);
    const [marketCountries, setMarketCountries] = useState(null);
    const [countriesList, setCountriesList] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.markets(),
            success: (list) => {
                setMarket(list.find((item) => String(item.id) === id));
            },
        });
        if (id !== 'create') {
            API.get({
                url: apiMethods.marketCountries(id),
                success: (result) => {
                    setMarketCountries(
                        result.sort((a, b) => (a.name < b.name ? -1 : 1)).map((country) => country.code)
                    );
                    setCountriesList(result);
                },
            });
        }
    }, []);

    const submit = (values) => {
        if (id === 'create') {
            API.post({
                url: apiMethods.markets(),
                body: values,
                success: () => {
                    notifications('Market Created!', 'success');
                    navigate('../');
                },
            });
        } else {
            API.put({
                url: apiMethods.market(id),
                body: values,
            });
            API.put({
                url: apiMethods.marketCountries(id),
                body: {
                    marketId: id,
                    countryCodes: marketCountries,
                },
                success: () => {
                    notifications('Market Saved', 'success');
                    navigate('../');
                },
            });
        }
    };

    const remove = () => {
        API.delete({
            url: apiMethods.market(id),
            success: () => {
                notifications('Market Deleted!', 'success');
                navigate('../');
            },
        });
    };

    if (!market && id !== 'create') {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={market ? `Market #${id}: ${market.name}` : id === 'create' ? 'Market Creation' : ' '}
            />
            <Form form={form} initialValues={market} layout="vertical" onFinish={submit}>
                <Form.Item
                    name="name"
                    label="Market Name"
                    style={{ pointerEvents: $auth.permitted('MarkupManagement') ? 'auto' : 'none' }}
                >
                    <Input placeholder="Market Name" />
                </Form.Item>
                {id !== 'create' && Boolean(market) && (
                    <MarketCountries
                        marketName={market.name}
                        marketCountries={marketCountries}
                        setMarketCountries={setMarketCountries}
                        countriesList={countriesList}
                    />
                )}
                {$auth.permitted('MarkupManagement') && (
                    <Button htmlType="submit">{id === 'create' ? 'Create a Market' : 'Save Changes'}</Button>
                )}
            </Form>
            {id !== 'create' && Boolean(market) && $auth.permitted('MarkupManagement') && (
                <ConfirmationPopup
                    title={
                        <div>
                            The Market will be deleted. Don't do it if you don't understand consequences.
                        </div>
                    }
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={remove}
                >
                    <Button style={{ marginTop: 300 }}>
                        Delete Market #{id}: {market.name}
                    </Button>
                </ConfirmationPopup>
            )}
        </>
    );
});

export default MarketPage;
