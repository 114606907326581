import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Typography } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import Table from 'components/table';
import { Loader } from 'legacy';
import MarkupPage from '../../markups/markup-management/markup';
import { columns } from '../../markups/markup-management/markups-list';

const { Title } = Typography;

const AgencyMarkups = ({ agency }) => {
    const [list, setList] = useState(null);

    const getListData = () => {
        API.get({
            url: apiMethods.agencyMarkups(agency.id),
            success: setList,
        });
    };

    useEffect(() => {
        if (!agency) {
            return;
        }
        getListData();
    }, [agency]);

    if (!agency || !list) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Agency Markups
            </Title>
            <Routes>
                <Route path="/:id" element={<MarkupPage updateList={getListData} />} />
                <Route path="/" element={<Table list={list} columns={columns(true)} />} />
            </Routes>
        </>
    );
};

export default AgencyMarkups;
