import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import apiMapperMethods from 'core/mapper-methods';
import { Button, Form, Space } from 'antd';
import { notifications } from 'components/notification';
import { EntitySelector } from 'common/entity-selector';

const CARDS_OPTIONS = [
    { value: 'AmericanExpress', label: 'AmericanExpress' },
    { value: 'MasterCard', label: 'MasterCard' },
    { value: 'Visa', label: 'Visa' },
];

const AccommodationVCCSettings = ({ htId, supplier, supplierCode }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const getData = () => {
        API.get({
            url: apiMapperMethods.accommodationVCCSettings(supplierCode, supplier),
            success: (result) => form.setFieldValue('cardVendors', result),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        if (htId) {
            getData();
        }
    }, [htId, supplier, supplierCode]);

    const onSubmit = ({ cardVendors }) => {
        setLoading(true);
        API.post({
            url: apiMapperMethods.updateAccommodationVCCSettings(supplier),
            body: { propertyId: supplierCode, cardVendors },
            success: () => notifications('Settings Saved!', 'success'),
            after: () => {
                setLoading(false);
                getData();
            },
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onSubmit} style={{ marginTop: 20 }}>
                <Space align="end">
                    <Form.Item
                        label="Preference VCC Cards"
                        name="cardVendors"
                        style={{ margin: 0, width: 364 }}
                    >
                        <EntitySelector
                            multiple
                            placeholder="Select Preference VCC Cards"
                            options={CARDS_OPTIONS}
                        />
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                        Save
                    </Button>
                </Space>
            </Form>
        </>
    );
};

export default AccommodationVCCSettings;
