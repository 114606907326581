import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { date, Loader } from 'legacy';
import { Typography, Timeline, Space, Card, Table } from 'antd';
import { InfoCircle } from 'iconsax-react';
import { historyConfig } from './markup-history-config';
import { FIELDS_WITH_EXCLUDE } from './markup';

const renderCell = (cell, { field: fieldName }) => {
    if (!cell?.value) return '-';

    const isWithExcludeField = FIELDS_WITH_EXCLUDE.find(
        (field) => field.toLowerCase() === fieldName.toLowerCase()
    );

    return (
        <>
            {isWithExcludeField && <span>{cell.isExcluded ? 'Exclude ' : 'Include '}</span>}
            {cell?.value}
        </>
    );
};

const columns = (create) => [
    {
        title: 'Field',
        dataIndex: 'fieldName',
        key: 'fieldName',
    },
    {
        title: 'New Value',
        dataIndex: 'updatedValue',
        key: 'updatedValue',
        render: renderCell,
    },

    ...(!create
        ? [
              {
                  title: 'Old Value',
                  dataIndex: 'oldValue',
                  key: 'oldValue',
                  render: renderCell,
              },
          ]
        : []),
];

const { Title } = Typography;

const MarkupHistory = () => {
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openedDetailsItem, setOpenedDetailsItem] = useState(null);

    const [list, setList] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.markupHistory(id),
            success: setHistory,
        });
    }, []);

    const openDetails = (index) => {
        setOpenedDetailsItem((prevState) => (prevState === index ? null : index));
        setLoading(true);
    };

    const joinExcludedValues = (tableData) => {
        const joinedData = tableData.reduce((obj, item) => {
            if (!obj?.[item.field]) {
                obj[item.field] = item;
            } else {
                const { updatedValue, oldValue } = obj[item.field];
                obj[item.field] = {
                    ...item,
                    updatedValue: updatedValue?.value ? updatedValue : item.updatedValue,
                    oldValue: oldValue?.value ? oldValue : item.oldValue,
                };
            }

            return obj;
        }, {});

        return Object.values(joinedData);
    };

    const getIsHasValue = (value) => (typeof value === 'object' ? !!value?.length : value !== null);

    const createTableObject = (selected) => {
        const tableData = selected
            .map(([field, value]) => {
                const fieldName = historyConfig?.[field.replace('Excluded', '')]?.field;

                if (!fieldName) return null;

                const oldValue = getIsHasValue(value.oldValue) ? value.oldValue : null;
                const updatedValue = getIsHasValue(value.updatedValue) ? value.updatedValue : null;

                if (oldValue === null && updatedValue === null) return null;

                const isExcluded = field.includes('Excluded');

                return {
                    field: field.replace('Excluded', ''),
                    oldValue: { value: oldValue, isExcluded },
                    updatedValue: { value: updatedValue, isExcluded },
                    fieldName,
                };
            })
            .filter((item) => !!item);

        return joinExcludedValues(tableData);
    };

    const getListData = async (tableData) => {
        const newData = tableData.map(async (item) => {
            const { updatedValue, oldValue, field } = item;
            const getData = historyConfig[field].getData;

            return {
                ...item,
                updatedValue: { ...updatedValue, value: await getData(updatedValue?.value) },
                oldValue: { ...oldValue, value: await getData(oldValue?.value) },
            };
        });

        setList(await Promise.all(newData));
        setLoading(false);
    };

    useEffect(() => {
        if (openedDetailsItem !== null) {
            const tableData = createTableObject(Object.entries(history[openedDetailsItem]?.eventData));
            getListData(tableData);
        }

        setList(null);
    }, [openedDetailsItem]);

    if (!history) return null;

    return (
        <Space size="large" align="start" style={{ marginTop: 50, width: '100%', gap: 40 }}>
            <Space direction="vertical" size="large">
                <Title level={4}>History</Title>

                <Timeline>
                    {history?.map((item, index) => (
                        <Timeline.Item key={index} color={index === openedDetailsItem ? 'green' : 'gray'}>
                            <div
                                style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                                onClick={() => openDetails(index)}
                            >
                                <div>
                                    <b>{date.format.a(item.created)}</b>{' '}
                                    <span style={{ color: '#888', marginRight: 3 }}>
                                        at {date.format.time(item.created)}.
                                    </span>
                                    <span>
                                        <b>{item.type}</b> by <b>{item.adminName}</b> (Event: {item.type})
                                    </span>
                                </div>
                                <InfoCircle
                                    style={{
                                        verticalAlign: 'middle',
                                        transform: 'rotate(180deg)',
                                        color: '#f9a51a',
                                    }}
                                />
                            </div>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </Space>

            <Card hidden={openedDetailsItem === null} style={{ width: 700 }}>
                {loading ? (
                    <Loader />
                ) : (
                    <Table
                        pagination={false}
                        columns={columns(openedDetailsItem === 0)}
                        dataSource={list}
                        rowKey="field"
                    />
                )}
            </Card>
        </Space>
    );
};

export default MarkupHistory;
