import React from 'react';
import { EntitySelector } from 'common/entity-selector';

export const serviceTypeOptions = [
    { label: 'Bed & Breakfast (BB)', value: 'BedAndBreakfast' },
    { label: 'Half Board (HB)', value: 'HalfBoard' },
    { label: 'Full Board (FB)', value: 'FullBoard' },
    { label: 'All Inclusive (AI)', value: 'AllInclusive' },
];

const ServiceTypeSelector = ({ agencyId, ...props }) => (
    <EntitySelector placeholder="Any Service Type" {...props} options={serviceTypeOptions} />
);

export default ServiceTypeSelector;
