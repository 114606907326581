import React from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Form, Input } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import { useNavigate } from 'react-router-dom';

const BankDetailsPage = ({ bank, id, reloadBanks }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const submit = (values) => {
        if (bank) {
            API.put({
                url: apiMethods.companyBank(bank.id),
                body: {
                    ...bank,
                    ...values,
                },
                success: () => {
                    notifications('Bank Saved', 'success');
                    reloadBanks();
                },
            });
        } else {
            API.post({
                url: apiMethods.companyBanks(),
                body: values,
                success: () => {
                    notifications('Bank Created!', 'success');
                    reloadBanks();
                },
            });
        }
        navigate('./../');
    };

    const remove = () => {
        API.delete({
            url: apiMethods.companyBank(bank.id),
            success: () => {
                notifications('Bank Deleted!', 'success');
                reloadBanks();
            },
        });
        navigate('./../');
    };

    return (
        <>
            <Form
                form={form}
                initialValues={bank}
                layout="vertical"
                onFinish={submit}
                style={{ maxWidth: 500 }}
            >
                <Form.Item
                    name="name"
                    label="Bank Name"
                    rules={[{ required: true, message: 'Please enter' }]}
                >
                    <Input placeholder="Bank Name" />
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Bank Address"
                    rules={[{ required: true, message: 'Please enter' }]}
                >
                    <Input placeholder="Bank Address" />
                </Form.Item>
                <Form.Item
                    name="routingCode"
                    label="Routing Code"
                    rules={[{ required: true, message: 'Please enter' }]}
                >
                    <Input placeholder="Routing Code" />
                </Form.Item>
                <Form.Item
                    name="swiftCode"
                    label="SWIFT Code"
                    rules={[{ required: true, message: 'Please enter' }]}
                >
                    <Input placeholder="SWIFT Code" />
                </Form.Item>
                <Button htmlType="submit">{id === 'create' ? 'Create a Bank' : 'Save Changes'}</Button>
            </Form>
            {id !== 'create' && Boolean(bank) && (
                <ConfirmationPopup
                    title={
                        <div>The Bank will be deleted. Don't do it if you don't understand consequences.</div>
                    }
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={remove}
                >
                    <Button style={{ marginTop: 140 }}>
                        Delete Bank #{bank.id}: {bank.name}
                    </Button>
                </ConfirmationPopup>
            )}
        </>
    );
};

export default BankDetailsPage;
