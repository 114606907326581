import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { EntitySelector } from 'common/entity-selector';

const optionsGenerator = (list) =>
    list.map((item) => ({
        value: item,
        label: item,
    }));

const ItinerarySelector = ({ agencyId, ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        API.get({
            url: apiMethods.itineraryCodes(agencyId),
            success: (data) => setOptions(optionsGenerator(data)),
            after: () => setLoading(false),
        });
    }, [agencyId]);

    return <EntitySelector placeholder="Any ITN" {...props} options={options} loading={loading} />;
};

export default ItinerarySelector;
