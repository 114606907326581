import React, { useEffect, useState } from 'react';
import { EntitySelector } from 'common/entity-selector';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { Col, Form, Row } from 'antd';

const optionsGenerator = (list) =>
    list.map((key) => ({
        value: key,
        label: key,
    }));

export const typesOptions = [
    { label: 'Include', value: false },
    { label: 'Except', value: true },
];

const getAvailableCurrencies = (allCurrencies = []) => {
    const commonRules = {
        AmericanExpress: { AED: allCurrencies.filter((item) => item !== 'USD'), USD: ['USD'] },
        MasterCard: { USD: ['USD'], EUR: ['USD'], GBP: ['USD'], SAR: ['SAR'] },
        Visa: {
            AED: ['AED'],
            EUR: ['EUR'],
            GBP: ['GBP'],
            SAR: ['SAR'],
            USD: allCurrencies.filter((item) => !['AED', 'EUR', 'GBP', 'SAR'].includes(item)),
        },
    };

    return {
        AmericanExpress: commonRules,
        Apiso: { ...commonRules, MasterCard: { ...commonRules.MasterCard, EUR: ['EUR'], GBP: ['GBP'] } },
        Ixaris: commonRules,
    };
};

const CurrencySelector = () => {
    const [allCurrencies, setAllCurrencies] = useState([]);
    const [allOptions, setAllOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        API.get({
            url: apiMethods.currencies,
            success: (data) => {
                setAllCurrencies(data);
                setAllOptions(optionsGenerator(data));
            },
            after: () => setLoading(false),
        });
    }, []);

    const getOptions = (getFieldValue) => {
        const vendor = getFieldValue('vendor');
        const currency = getFieldValue('currency');
        const cardType = getFieldValue('cardType');
        const isExcluded = getFieldValue('isExcluded');

        if (isExcluded) {
            return allOptions;
        }

        const availableOptions = getAvailableCurrencies(allCurrencies)?.[vendor]?.[cardType]?.[currency];

        return availableOptions ? optionsGenerator(availableOptions) : allOptions;
    };

    return (
        <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
                const currency = getFieldValue('currency');
                const cardType = getFieldValue('cardType');
                const vendor = getFieldValue('vendor');

                const disabled = !currency || !cardType || !vendor;

                const options = getOptions(getFieldValue);

                return (
                    <Row gutter={18}>
                        <Col span={8}>
                            <Form.Item label="Type" name="isExcluded">
                                <EntitySelector
                                    disabled={disabled}
                                    allowClear={false}
                                    options={typesOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="Active CURs for account" name="convertedCurrencies">
                                <EntitySelector
                                    multiple
                                    placeholder="All"
                                    disabled={disabled}
                                    options={options}
                                    loading={loading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                );
            }}
        </Form.Item>
    );
};

export default CurrencySelector;
