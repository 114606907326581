import React, { useState } from 'react';
import { Button, Descriptions, Form, Input, Space, Upload } from 'antd';
import { API } from 'htcore';
import { Loader } from 'legacy';
import { notifications } from 'components/notification';
import ConfirmationModal from 'components/confirms/confirmation-modal';

const SupplierConnectorInventory = ({ supplier }) => {
    const [modalAction, setModalAction] = useState(null);
    const [lastResult, setLastResult] = useState(null);

    const onEnableAll = () => {
        API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'properties/enable-all',
            success: (result) => {
                notifications('All Supplier Inventory Activated', 'success');
                setLastResult(result);
            },
        });
    };

    const onDisableAll = () => {
        API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + 'properties/disable-all',
            success: (result) => {
                notifications('All Supplier Inventory Disabled', 'success');
                setLastResult(result);
            },
        });
    };

    const processCodes = (form) =>
        API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + `properties/${modalAction}`,
            body: [form.codes],
            success: (result) => {
                notifications(
                    modalAction === 'enable' ? 'Properties Enabled' : 'Properties Disabled',
                    'success'
                );
                setModalAction(null);
                setLastResult(result);
            },
        });

    const uploadCSV = (action) => (file) => {
        const data = new FormData();
        data.append('file', file);

        API.post({
            urlToExecute: supplier.connectorUpdaterEndpoint + '/api/1.0/' + `properties/${action}/upload`,
            formDataBody: data,
            success: (result) => {
                setLastResult(result);
                notifications(action === 'enable' ? 'Properties Enabled' : 'Properties Disabled', 'success');
            },
        });
    };

    if (!supplier) return <Loader />;

    return (
        <>
            <Descriptions bordered layout="vertical" size="small" style={{ marginBottom: 30 }} column={1}>
                <Descriptions.Item label="Inventory (Properties)">
                    <Space direction="vertical">
                        <Space>
                            <Button onClick={onEnableAll}>Activate ALL</Button>
                            <Button onClick={onDisableAll}>Disable ALL</Button>
                        </Space>
                        <Space>
                            <Button onClick={() => setModalAction('enable')}>Activate with Codes</Button>
                            <Button onClick={() => setModalAction('disable')}>Disable with Codes</Button>
                        </Space>
                        <Space>
                            <Upload
                                action={uploadCSV('enable')}
                                customRequest={() => null}
                                multiple={false}
                                showUploadList={false}
                                name="file"
                            >
                                <Button>Activate with CSV</Button>
                            </Upload>
                            <Upload
                                action={uploadCSV('disable')}
                                customRequest={() => null}
                                multiple={false}
                                showUploadList={false}
                                name="file"
                            >
                                <Button>Deactivate with CSV</Button>
                            </Upload>
                        </Space>
                    </Space>
                </Descriptions.Item>
                {Boolean(lastResult) && (
                    <Descriptions.Item label="Inventory Action Status">
                        Total Active: <b>{lastResult.totalActiveCount}</b> <br />
                        Available: <b>{lastResult.availableCount}</b> <br />
                        Unavailable: <b>{lastResult.unavailableCount}</b>
                    </Descriptions.Item>
                )}
            </Descriptions>
            <ConfirmationModal
                visible={modalAction}
                onCancel={() => setModalAction(false)}
                headerText="Enter Properties Codes"
                submitText={modalAction === 'enable' ? 'Activate Properties' : 'Deactivate Properties'}
                onSubmit={processCodes}
                reason={false}
                key="codesModal"
            >
                <Form.Item name="codes" label="Properties Codes" rules={[{ required: true }]}>
                    <Input placeholder="Properties Codes" />
                </Form.Item>
            </ConfirmationModal>
        </>
    );
};

export default SupplierConnectorInventory;
