import React from 'react';
import { BOOKING_STATUS, PAYMENT_TYPE } from 'htcore/enum';
import { date, price, remapStatus } from 'legacy';
import RoomPrices from './room-prices';
import { Card, Descriptions, Typography, Tag } from 'antd';

const { Text, Title } = Typography;

const BookingSummary = ({ isService, booking }) => {
    const details = booking.bookingDetails;
    const { checkInDate, checkOutDate } = details;
    const rooms = booking.rooms || details.roomDetails || [];
    const finalPrice = booking.rate?.finalPrice || booking.totalPrice;
    const photo = details.photo?.sourceUrl || details.accommodationInfo?.photo?.sourceUrl;

    const roomPriceParamName =
        booking.paymentMethod === PAYMENT_TYPE.CreditCard ? 'creditCardPrice' : 'price';

    const bankFee =
        finalPrice.amount -
        details.roomDetails.reduce((res, room) => res + room.price.amount, 0) -
        (booking.appliedCancellationFee?.amount || 0);

    return (
        <Card style={{ width: 400 }}>
            <Text>
                {details.location?.locality ? details.location.locality + ', ' : ''}
                {details.location.address}
            </Text>
            <Title level={4} style={{ margin: 0 }}>
                {details.name || details.accommodationName}
            </Title>
            {!!details?.contactInfo?.phones?.length && (
                <Text>Phone: {details.contactInfo.phones.join(', ')}</Text>
            )}
            <div>
                {booking.isDirectbooking && <Tag>Direct Connectivity</Tag>}
                {booking.isPackageRate && <Tag>Package Rate</Tag>}
            </div>
            {!!photo && (
                <div
                    style={{
                        backgroundImage: `url(${photo})`,
                        height: 200,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        margin: '20px 0',
                    }}
                />
            )}
            <Title level={4}>Reservation Summary</Title>
            <Descriptions column={1} labelStyle={{ width: 130 }} size="small">
                {booking.surrogateAgencyName && (
                    <Descriptions.Item label="Surrogate Agency">
                        {booking.surrogateAgencyName}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="Supplier">
                    {booking.supplier +
                        (booking.subSupplier ? ` (${booking.subSupplier})` : '') +
                        (booking.supplierType ? ` (${booking.supplierType})` : '')}
                </Descriptions.Item>

                <Descriptions.Item label="Arrival">{date.format.a(checkInDate)}</Descriptions.Item>
                <Descriptions.Item label="Departure">{date.format.a(checkOutDate)}</Descriptions.Item>
                <Descriptions.Item label="Rooms">{rooms.length}</Descriptions.Item>
                <Descriptions.Item label="Nights">{details.numberOfNights}</Descriptions.Item>
                <Descriptions.Item label="Guests">{details.numberOfPassengers}</Descriptions.Item>
                <Descriptions.Item label="Nationality & Residency">
                    {details.nationality} & {details.residence}
                </Descriptions.Item>
                {isService ? (
                    <Descriptions.Item label="Service Type">{details.serviceName}</Descriptions.Item>
                ) : (
                    <Descriptions.Item label="Board Basis">
                        {rooms[0].boardBasis}
                        {rooms[0].mealPlan ? ` (${rooms[0].mealPlan})` : null}
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Title level={4}>Room & Total Cost</Title>
            <Descriptions column={1} labelStyle={{ width: 130 }} size="small">
                {!isService &&
                    rooms?.map((room, i) => (
                        <React.Fragment key={i}>
                            {room.dailyRoomRates?.[0]?.finalPrice !== undefined && (
                                <RoomPrices
                                    prices={room.dailyRoomRates}
                                    index={rooms?.length > 1 ? i + 1 : ''}
                                />
                            )}
                            <Descriptions.Item
                                label={'Room' + (rooms?.length > 1 ? ' ' + (i + 1) : '') + ' Cost'}
                                style={room.cancelled ? { textDecoration: 'line-through' } : {}}
                            >
                                {price(room.rate?.finalPrice || room[roomPriceParamName])}
                            </Descriptions.Item>
                        </React.Fragment>
                    ))}

                {isService &&
                    rooms?.map((room, i) => (
                        <React.Fragment key={i}>
                            <Descriptions.Item
                                label={'Service' + (rooms?.length > 1 ? ' ' + (i + 1) : '') + ' Cost'}
                                style={
                                    details.status === BOOKING_STATUS.Cancelled
                                        ? { textDecoration: 'line-through' }
                                        : {}
                                }
                            >
                                {booking.paymentMethod === PAYMENT_TYPE.CreditCard
                                    ? price(room.creditCardPrice)
                                    : price(finalPrice.currency, room.price)}
                            </Descriptions.Item>
                        </React.Fragment>
                    ))}

                {bankFee > 0.01 && (
                    <Descriptions.Item label="Bank Fee">
                        {price(finalPrice.currency, bankFee)}
                    </Descriptions.Item>
                )}
                {Boolean(
                    details.status === BOOKING_STATUS.Cancelled ||
                        rooms.some((room) => room.cancelled) ||
                        booking.appliedCancellationFee?.amount
                ) && (
                    <Descriptions.Item label="Cancellation Fee">
                        <strong>
                            {booking.appliedCancellationFee
                                ? price(booking.appliedCancellationFee)
                                : 'Unknown'}
                        </strong>
                    </Descriptions.Item>
                )}
                {isService ? (
                    <Descriptions.Item label="Total Service Cost">
                        <strong>{price(finalPrice)}</strong>
                    </Descriptions.Item>
                ) : (
                    <Descriptions.Item label="Total Cost">
                        {details.status === BOOKING_STATUS.Cancelled ||
                            (rooms.some((room) => room.awaitingCancellation || room.cancelled) && (
                                <>
                                    {rooms.every((room) => room.price.currency === rooms[0].price.currency)
                                        ? price(
                                              rooms[0].price.currency,
                                              rooms.reduce((sum, room) => sum + room.price.amount, 0)
                                          )
                                        : price(finalPrice)}
                                    {'→'}&nbsp;
                                </>
                            ))}
                        <strong>
                            {details.status === BOOKING_STATUS.Cancelled &&
                            Boolean(booking.appliedCancellationFee)
                                ? price(booking.appliedCancellationFee)
                                : price(finalPrice)}
                        </strong>
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Net Payable to Supplier">
                    {price(booking.supplierPrice)}
                </Descriptions.Item>
                <Descriptions.Item label="Markup">{booking.totalMarkupPercent.toFixed(2)}%</Descriptions.Item>
                {isService && (
                    <Descriptions.Item label="Payment status">
                        {remapStatus(booking.paymentStatus)}
                    </Descriptions.Item>
                )}
            </Descriptions>
        </Card>
    );
};

export default BookingSummary;
