import React from 'react';
import { Button, Space } from 'antd';
import { BOOKING_STATUS } from 'htcore/enum';
import { Link } from 'react-router-dom';

const duplicateStatuses = [BOOKING_STATUS.Created, BOOKING_STATUS.Completed, BOOKING_STATUS.Confirmed];

const editStatuses = [BOOKING_STATUS.Confirmed, BOOKING_STATUS.Completed, BOOKING_STATUS.Cancelled];

const BookingEditButtons = ({ isService, isOffline, details }) => {
    if (isService) {
        return (
            <Space>
                {details.status === BOOKING_STATUS.Created && (
                    <Link to={`/offline-bookings/food-supplement/edit/${details.referenceCode}`}>
                        <Button type="primary">Edit Offline Service Booking</Button>
                    </Link>
                )}
                {duplicateStatuses.includes(details.status) && (
                    <Link
                        to={`/offline-bookings/food-supplement/duplicate/${details.referenceCode}`}
                        target="_blank"
                    >
                        <Button type="primary">Duplicate Offline Service Booking</Button>
                    </Link>
                )}
            </Space>
        );
    }

    return (
        <Space>
            {isOffline && !editStatuses.includes(details.status) && (
                <Link to={`/offline-bookings/booking/edit/${details.referenceCode}`}>
                    <Button type="primary">Edit Offline Booking</Button>
                </Link>
            )}
            {isOffline && duplicateStatuses.includes(details.status) && (
                <Link to={`/offline-bookings/booking/duplicate/${details.referenceCode}`} target="_blank">
                    <Button type="primary">Duplicate Booking</Button>
                </Link>
            )}
        </Space>
    );
};

export default BookingEditButtons;
