import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export const columns = () => [
    {
        header: 'VCC Provider',
        cell: (cell) => cell.vendor?.replace(/([A-Z])/g, ' $1'),
    },
    {
        header: 'Card Type',
        cell: (cell) => cell.cardType,
    },
    {
        header: 'Account Number',
        cell: (cell) => cell.accountNumber,
    },
    {
        header: 'Status',
        cell: (cell) => (cell.isActive ? 'Activated' : 'Deactivated'),
    },
    {
        header: 'VCC Currency',
        cell: (cell) => cell.currency,
    },
    {
        header: 'Active CURs for Account',
        cell: ({ isExcluded, convertedCurrencies }) =>
            `${isExcluded ? 'Excluded' : ''}  ${
                !!convertedCurrencies ? convertedCurrencies.join(', ') : 'All'
            }`,
    },
    {
        header: 'Default Conversion %',
        cell: (cell) => `${cell.conversionBuffer || 0}%`,
    },
    {
        header: '',
        cell: (cell) => (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Link to={`./${cell.vendor}-${cell.currency}-${cell.cardType}-${cell.accountNumber}`}>
                    <Button type="primary" htmlType="button">
                        Edit
                    </Button>
                </Link>
            </div>
        ),
    },
];
