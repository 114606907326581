import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import { Loader } from 'legacy';
import gifuMethods from 'core/gifu-methods';
import apiGifuMethods from 'core/gifu-methods';
import { Form, PageHeader } from 'antd';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import AccountForm from './account-form';
import AccountSummary from './account-summary';
import DefaultBufferForm from '../../default-buffer-form';
import AccountBuffersListWrap from './account-buffers-list-wrap';
import $auth from 'stores/auth';
import AccountHistory from './account-history';

const createHistoryData = (data) =>
    data?.logs?.filter((log) => Object.keys(log?.changes || {}).includes('ConversionBuffer')).slice(0, 15);

const mapAccountDataToPayload = (data) => ({
    ...data,
    convertedCurrencies: data?.convertedCurrencies?.length ? data.convertedCurrencies : null,
    administratorName: $auth.fullName,
    administratorId: $auth?.information?.id,
});

const AccountPage = () => {
    useTitle('VCC Forex Conversion Fees');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { id } = useParams();

    const [accountData, setAccountData] = useState(null);
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);

    const [disableBuffer, setDisableBuffer] = useState(true);

    const [removedCurrenciesForConfirm, setRemovedCurrenciesForConfirm] = useState(null);
    const [customBufferCurrencies, setCustomBufferCurrencies] = useState(null);

    const getIsDisabledBuffer = ({ isExcluded, currency, convertedCurrencies }) => {
        if (isExcluded) return false;
        const activeCurrency = convertedCurrencies?.[0];

        return convertedCurrencies?.length === 1 && activeCurrency === currency;
    };

    const getIds = () => id.split('-');

    const getData = () => {
        setLoading(true);
        API.get({
            gifu: gifuMethods.vendorAccount(...getIds()),
            success: (result) => {
                setAccountData({ ...result, convertedCurrencies: result?.convertedCurrencies || [] });
                setHistory(createHistoryData(result));
                setDisableBuffer(getIsDisabledBuffer(result));
            },
            after: () => setLoading(false),
        });
    };

    const saveData = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            API.put({
                gifu: apiGifuMethods.vendorAccount(...getIds()),
                body: mapAccountDataToPayload({ ...accountData, ...values }),
                success: () => {
                    notifications('Account Saved!', 'success');
                    getData();
                    setRemovedCurrenciesForConfirm(null);
                },
                after: () => setLoading(false),
            });
        });
    };

    const getIncludesBufferCurrencies = ({ isExcluded, convertedCurrencies }) => {
        if (isExcluded) {
            return customBufferCurrencies.filter((item) => !convertedCurrencies.includes(item));
        }

        return customBufferCurrencies.filter((item) => convertedCurrencies.includes(item));
    };

    const getRemovedCurrencies = (values) => {
        const oldValues = getIncludesBufferCurrencies(accountData);
        const newValues = getIncludesBufferCurrencies(values);

        return oldValues.filter((item) => !newValues.includes(item));
    };

    const onSubmit = (values) => {
        const removedCurrencies = getRemovedCurrencies(values);

        if (!!removedCurrencies?.length) {
            setRemovedCurrenciesForConfirm(removedCurrencies);
        } else saveData();
    };

    useEffect(() => {
        getData();
    }, [id]);

    const saveBuffer = ({ defaultBuffer }) => {
        setLoading(true);
        return API.put({
            gifu: apiGifuMethods.vendorAccount(...getIds()),
            body: {
                ...mapAccountDataToPayload(accountData),
                conversionBuffer: Number(defaultBuffer),
            },
            success: () => notifications('Saved!', 'success'),
            after: () => {
                setLoading(false);
                getData();
            },
        });
    };

    const defaultBufferMessage = useMemo(() => {
        if (disableBuffer) return null;
        const { currency, convertedCurrencies, isExcluded } = accountData;

        const isIncludesCurrency = convertedCurrencies?.includes(currency);

        if ((isExcluded && !isIncludesCurrency) || (!isExcluded && isIncludesCurrency)) {
            return `The default conversion buffer will not be applied to ${currency}-${currency}, for other pairs default conversion buffer will be applied`;
        }
    }, [accountData, disableBuffer]);

    if (loading) {
        return <Loader page />;
    }

    return (
        <>
            <PageHeader
                title={
                    accountData
                        ? `${accountData.vendor} | ${accountData.cardType} | ${accountData.accountNumber}`
                        : ''
                }
                onBack={() => navigate('../')}
                breadcrumb={{
                    routes: [
                        { breadcrumbName: 'Conversion Management' },
                        { breadcrumbName: 'VCC Forex Conversion Fees ' },
                    ],
                    itemRender: (route) => route.breadcrumbName,
                }}
            />

            <AccountForm
                isEdit
                loading={loading}
                initialValues={accountData}
                formStyle={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '18px',
                }}
                onSubmit={onSubmit}
                form={form}
            />

            <AccountSummary data={accountData} updateData={getData} />

            {!loading && (
                <DefaultBufferForm
                    disableBuffer={disableBuffer}
                    message={defaultBufferMessage}
                    initialBufferValue={accountData?.conversionBuffer}
                    onSave={saveBuffer}
                />
            )}

            <AccountBuffersListWrap
                disableBuffer={disableBuffer}
                accountData={accountData}
                setCustomBufferCurrencies={setCustomBufferCurrencies}
            />

            <AccountHistory history={history} />

            <ConfirmationSimpleModal
                visible={!!removedCurrenciesForConfirm?.length}
                onCancel={() => setRemovedCurrenciesForConfirm(null)}
                headerText="Are you sure?"
                submitText="Confirm"
                onConfirm={saveData}
            >
                You are going to delete currencies with custom conversion buffer (
                {removedCurrenciesForConfirm?.join(', ')})
            </ConfirmationSimpleModal>
        </>
    );
};

export default AccountPage;
