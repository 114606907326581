import React from 'react';
import { date, price } from 'legacy';
import { Descriptions } from 'antd';

const optimize = (prices) => {
    let sorted = JSON.parse(JSON.stringify(prices));
    sorted = sorted.sort((a, b) => Number(new Date(a.fromDate)) - Number(new Date(b.fromDate)));

    let result = [],
        start = 0;
    for (let i = 0; i < sorted.length + 1; i++) {
        if (sorted[start].finalPrice.amount !== sorted[i]?.finalPrice?.amount) {
            result.push({
                fromDate: sorted[start].fromDate,
                toDate: sorted[i - 1].toDate,
                finalPrice: sorted[start].finalPrice,
            });
            start = i;
        }
    }

    return result;
};

const RoomPrices = ({ prices, index }) => {
    if (!(prices && prices.length)) {
        return null;
    }

    const optimized = optimize(prices);

    if (optimized.length === 1)
        return (
            <Descriptions.Item label={'Room' + ' ' + index}>
                {price(optimized[0].finalPrice) + ' ' + 'per Night'}
            </Descriptions.Item>
        );

    return optimized.map((item, i) => (
        <Descriptions.Item
            label={
                (index ? 'Room' + ' ' + index + ' (' : '') +
                date.format.shortDay(item.fromDate) +
                ' – ' +
                date.format.shortDay(item.toDate) +
                (index ? ')' : '')
            }
            key={i}
        >
            {price(item.finalPrice) + ' ' + 'per Night'}
        </Descriptions.Item>
    ));
};

export default RoomPrices;
