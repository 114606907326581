import React from 'react';
import { Form, Button, Row, Col, DatePicker, Select } from 'antd';
import moment from 'moment/moment';
import { date } from 'legacy';

import AdminOfflineSelector from 'components/selectors/admin-offline-selector';

const { RangePicker } = DatePicker;
const { Option } = Select;

const AgencyHistorySearchForm = ({ form, onSubmit }) => (
    <Form form={form} onFinish={onSubmit} layout="vertical" initialValues={{}} style={{ marginBottom: 30 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} xl={6}>
                <Form.Item name="administratorId" label="Admin">
                    <AdminOfflineSelector />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="category" label="Category of Changes">
                    <Select mode="multiple" placeholder="Any Category" allowClear>
                        <Option value="ContractType">Contract Type</Option>
                        <Option value="Markup">Markup</Option>
                        <Option value="Status">Status</Option>
                        <Option value="AgencyData">Agency Data</Option>
                        <Option value="Supplier">Supplier</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="created" label="Dates">
                    <RangePicker
                        format={date.format.c}
                        placeholder={['From', 'Till']}
                        disabledDate={(current) => current && current > moment()}
                    />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Row gutter={16} style={{ marginTop: 6 }}>
                    <Col span={16}>
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                            Search
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button htmlType="button" onClick={() => form.resetFields()}>
                            Clear Filters
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Form>
);

export default AgencyHistorySearchForm;
