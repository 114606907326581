const phoneMasksRaw = {
    AC: '+247-####',
    AD: '+376-###-###',
    AE: '+971-5#-###-####',
    AE2: '+971-#-###-####',
    AF: '+93-##-###-####',
    AG: '+1(268)###-####',
    AI: '+1(264)###-####',
    AL: '+355(###)###-###',
    AM: '+374-##-###-###',
    AN: '+599-###-####',
    AN2: '+599-###-####',
    AN3: '+599-9###-####',
    AO: '+244(###)###-###',
    AQ: '+672-1##-###',
    AR: '+54(###)###-####',
    AS: '+1(684)###-####',
    AT: '+43(###)###-####',
    AU: '+61-#-####-####',
    AW: '+297-###-####',
    AZ: '+994-##-###-##-##',
    BA: '+387-##-#####',
    BA2: '+387-##-####',
    BB: '+1(246)###-####',
    BD: '+880-##-###-###',
    BE: '+32(###)###-###',
    BF: '+226-##-##-####',
    BG: '+359(###)###-###',
    BH: '+973-####-####',
    BI: '+257-##-##-####',
    BJ: '+229-##-##-####',
    BM: '+1(441)###-####',
    BN: '+673-###-####',
    BO: '+591-#-###-####',
    BR: '+55(##)####-####',
    BR2: '+55(##)7###-####',
    BR3: '+55(##)9####-####',
    BS: '+1(242)###-####',
    BT: '+975-17-###-###',
    BT2: '+975-#-###-###',
    BW: '+267-##-###-###',
    BY: '+375(##)###-##-##',
    BZ: '+501-###-####',
    CA: '+1(###)###-####',
    CD: '+243(###)###-###',
    CF: '+236-##-##-####',
    CG: '+242-##-###-####',
    CH: '+41-##-###-####',
    CI: '+225-##-###-###',
    CK: '+682-##-###',
    CL: '+56-#-####-####',
    CM: '+237-####-####',
    CN: '+86(###)####-####',
    CN2: '+86(###)####-###',
    CN3: '+86-##-#####-#####',
    CO: '+57(###)###-####',
    CR: '+506-####-####',
    CU: '+53-#-###-####',
    CV: '+238(###)##-##',
    CW: '+599-###-####',
    CY: '+357-##-###-###',
    CZ: '+420(###)###-###',
    DE: '+49(####)###-####',
    DE2: '+49(###)###-####',
    DE3: '+49(###)##-####',
    DE4: '+49(###)##-###',
    DE5: '+49(###)##-##',
    DE6: '+49-###-###',
    DJ: '+253-##-##-##-##',
    DK: '+45-##-##-##-##',
    DM: '+1(767)###-####',
    DO: '+1(809)###-####',
    DO2: '+1(829)###-####',
    DO3: '+1(849)###-####',
    DZ: '+213-##-###-####',
    EC: '+593-##-###-####',
    EC2: '+593-#-###-####',
    EE: '+372-####-####',
    EE2: '+372-###-####',
    EG: '+20(###)###-####',
    ER: '+291-#-###-###',
    ES: '+34(###)###-###',
    ET: '+251-##-###-####',
    FI: '+358(###)###-##-##',
    FJ: '+679-##-#####',
    FK: '+500-#####',
    FM: '+691-###-####',
    FO: '+298-###-###',
    FR: '+262-#####-####',
    FR2: '+33(###)###-###',
    FR3: '+508-##-####',
    FR4: '+590(###)###-###',
    GA: '+241-#-##-##-##',
    GD: '+1(473)###-####',
    GE: '+995(###)###-###',
    GF: '+594-#####-####',
    GH: '+233(###)###-###',
    GI: '+350-###-#####',
    GL: '+299-##-##-##',
    GM: '+220(###)##-##',
    GN: '+224-##-###-###',
    GQ: '+240-##-###-####',
    GR: '+30(###)###-####',
    GT: '+502-#-###-####',
    GU: '+1(671)###-####',
    GW: '+245-#-######',
    GY: '+592-###-####',
    HK: '+852-####-####',
    HN: '+504-####-####',
    HR: '+385-##-###-###',
    HT: '+509-##-##-####',
    HU: '+36(###)###-###',
    ID: '+62(8##)###-####',
    ID2: '+62-##-###-##',
    ID3: '+62-##-###-###',
    ID4: '+62-##-###-####',
    ID5: '+62(8##)###-###',
    ID6: '+62(8##)###-##-###',
    IE: '+353(###)###-###',
    IL: '+972-5#-###-####',
    IL2: '+972-#-###-####',
    IN: '+91(####)###-###',
    IO: '+246-###-####',
    IQ: '+964(###)###-####',
    IR: '+98(###)###-####',
    IS: '+354-###-####',
    IT: '+39(###)####-###',
    JM: '+1(876)###-####',
    JO: '+962-#-####-####',
    JP: '+81-##-####-####',
    JP2: '+81(###)###-###',
    KE: '+254-###-######',
    KG: '+996(###)###-###',
    KH: '+855-##-###-###',
    KI: '+686-##-###',
    KM: '+269-##-#####',
    KN: '+1(869)###-####',
    KP: '+850-191-###-####',
    KP2: '+850-##-###-###',
    KP3: '+850-###-####-###',
    KP4: '+850-###-###',
    KP5: '+850-####-####',
    KP6: '+850-####-#############',
    KR: '+82-##-###-####',
    KW: '+965-####-####',
    KY: '+1(345)###-####',
    KZ: '+7(6##)###-##-##',
    KZ2: '+7(7##)###-##-##',
    LA: '+856(20##)###-###',
    LA2: '+856-##-###-###',
    LB3: '+961-##-###-###',
    LB4: '+961-#-###-###',
    LC: '+1(758)###-####',
    LI: '+423(###)###-####',
    LK: '+94-##-###-####',
    LR: '+231-##-###-###',
    LS: '+266-#-###-####',
    LT: '+370(###)##-###',
    LU: '+352(###)###-###',
    LV: '+371-##-###-###',
    LY: '+218-##-###-###',
    LY2: '+218-21-###-####',
    MA: '+212-##-####-###',
    MC: '+377(###)###-###',
    MC2: '+377-##-###-###',
    MD: '+373-####-####',
    ME: '+382-##-###-###',
    MG: '+261-##-##-#####',
    MH: '+692-###-####',
    MK: '+389-##-###-###',
    ML: '+223-##-##-####',
    MM: '+95-##-###-###',
    MM2: '+95-#-###-###',
    MM3: '+95-###-###',
    MN: '+976-##-##-####',
    MO: '+853-####-####',
    MP: '+1(670)###-####',
    MQ: '+596(###)##-##-##',
    MR: '+222-##-##-####',
    MS: '+1(664)###-####',
    MT: '+356-####-####',
    MU: '+230-###-####',
    MV: '+960-###-####',
    MW: '+265-1-###-###',
    MW2: '+265-#-####-####',
    MX: '+52(###)###-####',
    MX2: '+52-##-##-####',
    MY: '+60-##-###-####',
    MY2: '+60(###)###-###',
    MY3: '+60-##-###-###',
    MY4: '+60-#-###-###',
    MZ: '+258-##-###-###',
    NA: '+264-##-###-####',
    NC: '+687-##-####',
    NE: '+227-##-##-####',
    NF: '+672-3##-###',
    NG: '+234(###)###-####',
    NG2: '+234-##-###-###',
    NG3: '+234-##-###-##',
    NG4: '+234(###)###-####',
    NI: '+505-####-####',
    NL: '+31-##-###-####',
    NO: '+47(###)##-###',
    NP: '+977-##-###-###',
    NR: '+674-###-####',
    NU: '+683-####',
    NZ: '+64(###)###-###',
    NZ2: '+64-##-###-###',
    NZ3: '+64(###)###-####',
    OM: '+968-##-###-###',
    PA: '+507-###-####',
    PE: '+51(###)###-###',
    PF: '+689-##-##-##',
    PG: '+675(###)##-###',
    PH: '+63(###)###-####',
    PK: '+92(###)###-####',
    PL: '+48(###)###-###',
    PS: '+970-##-###-####',
    PT: '+351-##-###-####',
    PW: '+680-###-####',
    PY: '+595(###)###-###',
    QA: '+974-####-####',
    RE: '+262-#####-####',
    RO: '+40-##-###-####',
    RS: '+381-##-###-####',
    RU: '+7(###)###-##-##',
    RW: '+250(###)###-###',
    SA: '+966-5-####-####',
    SA2: '+966-#-###-####',
    SB: '+677-###-####',
    SB2: '+677-#####',
    SC: '+248-#-###-###',
    SD: '+249-##-###-####',
    SE: '+46-##-###-####',
    SG: '+65-####-####',
    SH: '+290-####',
    SH2: '+290-####',
    SI: '+386-##-###-###',
    SK: '+421(###)###-###',
    SL: '+232-##-######',
    SM: '+378-####-######',
    SN: '+221-##-###-####',
    SO: '+252-##-###-###',
    SO2: '+252-#-###-###',
    SO3: '+252-#-###-###',
    SR: '+597-###-####',
    SR2: '+597-###-###',
    SS: '+211-##-###-####',
    ST: '+239-##-#####',
    SV: '+503-##-##-####',
    SX: '+1(721)###-####',
    SY: '+963-##-####-###',
    SZ: '+268-##-##-####',
    TC: '+1(649)###-####',
    TD: '+235-##-##-##-##',
    TG: '+228-##-###-###',
    TH: '+66-##-###-####',
    TH2: '+66-##-###-###',
    TJ: '+992-##-###-####',
    TK: '+690-####',
    TL: '+670-###-####',
    TL2: '+670-77#-#####',
    TL3: '+670-78#-#####',
    TM: '+993-#-###-####',
    TN: '+216-##-###-###',
    TO: '+676-#####',
    TR: '+90(###)###-####',
    TT: '+1(868)###-####',
    TV: '+688-90####',
    TV2: '+688-2####',
    TW: '+886-#-####-####',
    TW3: '+886-####-####',
    TZ: '+255-##-###-####',
    UA: '+380(##)###-##-##',
    UG: '+256(###)###-###',
    UK: '+44-##-####-####',
    US: '+1(###)###-####',
    UY: '+598-#-###-##-##',
    UZ: '+998-##-###-####',
    VA: '+39-6-698-#####',
    VC: '+1(784)###-####',
    VE: '+58(###)###-####',
    VG: '+1(284)###-####',
    VI: '+1(340)###-####',
    VN: '+84-##-####-###',
    VN2: '+84(###)####-###',
    VU: '+678-##-#####',
    VU3: '+678-#####',
    WF: '+681-##-####',
    WS: '+685-##-####',
    YE: '+967-###-###-###',
    YE2: '+967-#-###-###',
    YE3: '+967-##-###-###',
    ZA: '+27-##-###-####',
    ZM: '+260-##-###-####',
    ZW: '+263-#-######',
};

export const phoneMasks = [...Object.values(phoneMasksRaw)].map((value) => value.replace(/[^0-9#]/g, ''));

export const isPhoneValid = (phoneRaw) => {
    const phone = phoneRaw.replace(/[^0-9]/g, '');

    for (let i = 0; i < phoneMasks.length; i++) {
        const firstDigit = phoneMasks[i].indexOf('#');
        if (
            phoneMasks[i].length === phone.length &&
            phoneMasks[i].slice(0, firstDigit) === phone.slice(0, firstDigit)
        ) {
            return true;
        }
    }

    return false;
};
