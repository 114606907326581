import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMapperMethods from 'core/mapper-methods';

export const PAYMENT_TYPE_SUPPLIERS = ['juniperDirect', 'juniperDirectPull'];

const OPTIONS = {
    Credit: 'Credit',
    CreditPrepayment: 'Credit Prepayment',
};

const optionsGenerator = (list) =>
    Object.values(list).map((value) => ({ value, label: OPTIONS?.[value] || value }));

const PaymentTypeSelector = (props) => {
    const { options, loading } = useSelector(
        { shimabara: apiMapperMethods.accommodationPaymentTypeSettings },
        optionsGenerator
    );

    return (
        <EntitySelector
            placeholder="Select payment type with hotel"
            {...props}
            options={options}
            loading={loading}
        />
    );
};

export default PaymentTypeSelector;
