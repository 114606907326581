import React, { useEffect, useState } from 'react';
import { RequestSelector } from 'common/request-selector';
import apiMapperMethods from 'core/mapper-methods';
import apiOsakaMethods from 'core/osaka-methods';
import { API } from 'htcore';

const LocalitySelector = ({ filter = ['Locality'], setSelectedLabel, ...props }) => {
    const optionsGenerator = (list = []) =>
        props.countryId
            ? list.map((value) => ({
                  value: value.id,
                  label: value.localityName,
              }))
            : !list.length
            ? null
            : list[0].predictions.map((value) => ({
                  value: value.htId,
                  label: value.predictionText,
              }));

    const [defaultOptions, setDefaultOptions] = useState(null);

    const onSelect = (value, option) => {
        if (setSelectedLabel) {
            setSelectedLabel(option?.label);
        }
    };

    useEffect(() => {
        const restore = [].concat(props.value).filter((v) => v);
        if (restore.length) {
            Promise.all(
                restore.map((htId) =>
                    API.get({
                        osaka: apiOsakaMethods.predictionsRestore(htId),
                    })
                )
            ).then((results) => {
                const newOptions = restore
                    .map((item, index) =>
                        results[index]?.predictionText
                            ? {
                                  value: item,
                                  label: results[index].predictionText,
                              }
                            : null
                    )
                    .filter((v) => v);
                setDefaultOptions(newOptions);
                if (newOptions.length === 1) {
                    onSelect(null, newOptions[0]);
                }
            });
        }
    }, []);

    return (
        <RequestSelector
            request={
                props.countryId
                    ? (value) => ({
                          nakijin: apiMapperMethods.searchLocalities(props.countryId),
                          body: {
                              query: value,
                          },
                      })
                    : (value) => ({
                          osaka: apiOsakaMethods.predictionsSearch,
                          body: {
                              text: value,
                              types: filter,
                          },
                      })
            }
            defaultOptions={defaultOptions}
            optionsGenerator={optionsGenerator}
            placeholder="Any Locality"
            onSelect={onSelect}
            {...props}
        />
    );
};

export default LocalitySelector;
