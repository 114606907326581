import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Menu, PageHeader } from 'antd';
import MarkupsList from './markup-management/markup-management';
import MarketsPage from './markets/markets';
import { useTitle } from 'htcore';
import $auth from 'stores/auth';

const allNavigationItems = [
    {
        label: <Link to="./management">Markups Constructor</Link>,
        key: 'management',
        permissions: ['MarkupManagement', 'ViewMarkups'],
    },
    {
        label: <Link to="./markets">Markets Constructor</Link>,
        key: 'markets',
        permissions: ['MarkupManagement', 'ViewMarkups'],
    },
];

const MarkupsPage = () => {
    useTitle('Markups');

    const location = useLocation();

    const navigationItems = allNavigationItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    return (
        <>
            <PageHeader title="Markup Management" />
            <Menu
                mode="horizontal"
                items={navigationItems}
                selectedKeys={location.pathname.split('/')[2] || ''}
            />
            <Routes>
                <Route path="/markets/*" element={<MarketsPage />} />
                <Route path="/management/*" element={<MarkupsList />} />
            </Routes>
        </>
    );
};

export default MarkupsPage;
