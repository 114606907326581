import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as base16 from 'base16';
import Color from 'color';
import curry from 'lodash.curry';
import { yuv2rgb, rgb2yuv } from './colorConverters';
var DEFAULT_BASE16 = base16.default;
var BASE16_KEYS = Object.keys(DEFAULT_BASE16); // we need a correcting factor, so that a dark, but not black background color
// converts to bright enough inversed color

var flip = function flip(x) {
  return x < 0.25 ? 1 : x < 0.5 ? 0.9 - x : 1.1 - x;
};

var invertColor = function invertColor(hexString) {
  var color = Color(hexString);

  var _rgb2yuv = rgb2yuv(color.array()),
      _rgb2yuv2 = _slicedToArray(_rgb2yuv, 3),
      y = _rgb2yuv2[0],
      u = _rgb2yuv2[1],
      v = _rgb2yuv2[2];

  var flippedYuv = [flip(y), u, v];
  var rgb = yuv2rgb(flippedYuv);
  return Color.rgb(rgb).hex();
};

var merger = function merger(styling) {
  return function (prevStyling) {
    return {
      className: [prevStyling.className, styling.className].filter(Boolean).join(' '),
      style: _objectSpread(_objectSpread({}, prevStyling.style || {}), styling.style || {})
    };
  };
};

var mergeStyling = function mergeStyling(customStyling, defaultStyling) {
  if (customStyling === undefined) {
    return defaultStyling;
  }

  if (defaultStyling === undefined) {
    return customStyling;
  }

  var customType = _typeof(customStyling);

  var defaultType = _typeof(defaultStyling);

  switch (customType) {
    case 'string':
      switch (defaultType) {
        case 'string':
          return [defaultStyling, customStyling].filter(Boolean).join(' ');

        case 'object':
          return merger({
            className: customStyling,
            style: defaultStyling
          });

        case 'function':
          return function (styling) {
            for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
              args[_key - 1] = arguments[_key];
            }

            return merger({
              className: customStyling
            })(defaultStyling.apply(void 0, [styling].concat(args)));
          };
      }

      break;

    case 'object':
      switch (defaultType) {
        case 'string':
          return merger({
            className: defaultStyling,
            style: customStyling
          });

        case 'object':
          return _objectSpread(_objectSpread({}, defaultStyling), customStyling);

        case 'function':
          return function (styling) {
            for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
              args[_key2 - 1] = arguments[_key2];
            }

            return merger({
              style: customStyling
            })(defaultStyling.apply(void 0, [styling].concat(args)));
          };
      }

      break;

    case 'function':
      switch (defaultType) {
        case 'string':
          return function (styling) {
            for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
              args[_key3 - 1] = arguments[_key3];
            }

            return customStyling.apply(void 0, [merger(styling)({
              className: defaultStyling
            })].concat(args));
          };

        case 'object':
          return function (styling) {
            for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
              args[_key4 - 1] = arguments[_key4];
            }

            return customStyling.apply(void 0, [merger(styling)({
              style: defaultStyling
            })].concat(args));
          };

        case 'function':
          return function (styling) {
            for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
              args[_key5 - 1] = arguments[_key5];
            }

            return customStyling.apply(void 0, [defaultStyling.apply(void 0, [styling].concat(args))].concat(args));
          };
      }

  }
};

var mergeStylings = function mergeStylings(customStylings, defaultStylings) {
  var keys = Object.keys(defaultStylings);

  for (var key in customStylings) {
    if (keys.indexOf(key) === -1) keys.push(key);
  }

  return keys.reduce(function (mergedStyling, key) {
    return mergedStyling[key] = mergeStyling(customStylings[key], defaultStylings[key]), mergedStyling;
  }, {});
};

var getStylingByKeys = function getStylingByKeys(mergedStyling, keys) {
  for (var _len6 = arguments.length, args = new Array(_len6 > 2 ? _len6 - 2 : 0), _key6 = 2; _key6 < _len6; _key6++) {
    args[_key6 - 2] = arguments[_key6];
  }

  if (keys === null) {
    return mergedStyling;
  }

  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  var styles = keys.map(function (key) {
    return mergedStyling[key];
  }).filter(Boolean);
  var props = styles.reduce(function (obj, s) {
    if (typeof s === 'string') {
      obj.className = [obj.className, s].filter(Boolean).join(' ');
    } else if (_typeof(s) === 'object') {
      obj.style = _objectSpread(_objectSpread({}, obj.style), s);
    } else if (typeof s === 'function') {
      obj = _objectSpread(_objectSpread({}, obj), s.apply(void 0, [obj].concat(args)));
    }

    return obj;
  }, {
    className: '',
    style: {}
  });

  if (!props.className) {
    delete props.className;
  }

  if (Object.keys(props.style).length === 0) {
    delete props.style;
  }

  return props;
};

export var invertBase16Theme = function invertBase16Theme(base16Theme) {
  return Object.keys(base16Theme).reduce(function (t, key) {
    return t[key] = /^base/.test(key) ? invertColor(base16Theme[key]) : key === 'scheme' ? base16Theme[key] + ':inverted' : base16Theme[key], t;
  }, {});
};
export var createStyling = curry(function (getStylingFromBase16) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var themeOrStyling = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _options$defaultBase = options.defaultBase16,
      defaultBase16 = _options$defaultBase === void 0 ? DEFAULT_BASE16 : _options$defaultBase,
      _options$base16Themes = options.base16Themes,
      base16Themes = _options$base16Themes === void 0 ? null : _options$base16Themes;
  var base16Theme = getBase16Theme(themeOrStyling, base16Themes);

  if (base16Theme) {
    themeOrStyling = _objectSpread(_objectSpread({}, base16Theme), themeOrStyling);
  }

  var theme = BASE16_KEYS.reduce(function (t, key) {
    return t[key] = themeOrStyling[key] || defaultBase16[key], t;
  }, {});
  var customStyling = Object.keys(themeOrStyling).reduce(function (s, key) {
    return BASE16_KEYS.indexOf(key) === -1 ? (s[key] = themeOrStyling[key], s) : s;
  }, {});
  var defaultStyling = getStylingFromBase16(theme);
  var mergedStyling = mergeStylings(customStyling, defaultStyling);

  for (var _len7 = arguments.length, args = new Array(_len7 > 3 ? _len7 - 3 : 0), _key7 = 3; _key7 < _len7; _key7++) {
    args[_key7 - 3] = arguments[_key7];
  }

  return curry(getStylingByKeys, 2).apply(void 0, [mergedStyling].concat(args));
}, 3);

var isStylingConfig = function isStylingConfig(theme) {
  return !!theme.extend;
};

export var getBase16Theme = function getBase16Theme(theme, base16Themes) {
  if (theme && isStylingConfig(theme) && theme.extend) {
    theme = theme.extend;
  }

  if (typeof theme === 'string') {
    var _theme$split = theme.split(':'),
        _theme$split2 = _slicedToArray(_theme$split, 2),
        _themeName = _theme$split2[0],
        modifier = _theme$split2[1];

    if (base16Themes) {
      theme = base16Themes[_themeName];
    } else {
      theme = base16[_themeName];
    }

    if (modifier === 'inverted') {
      theme = invertBase16Theme(theme);
    }
  }

  return theme && Object.prototype.hasOwnProperty.call(theme, 'base00') ? theme : undefined;
};
export var invertTheme = function invertTheme(theme) {
  if (typeof theme === 'string') {
    return "".concat(theme, ":inverted");
  }

  if (theme && isStylingConfig(theme) && theme.extend) {
    if (typeof theme.extend === 'string') {
      return _objectSpread(_objectSpread({}, theme), {}, {
        extend: "".concat(theme.extend, ":inverted")
      });
    }

    return _objectSpread(_objectSpread({}, theme), {}, {
      extend: invertBase16Theme(theme.extend)
    });
  }

  if (theme) {
    return invertBase16Theme(theme);
  }

  return theme;
};
export * from './types';