import { useEffect, useState } from 'react';
import { API } from 'htcore';
import { date } from 'legacy';
import BigNumber from 'bignumber.js';
import { Form } from 'antd';

const RATES_DECIMAL_PLACES = 4;

const useCurrencies = ({ form, duplicate, booking }) => {
    const [supplierUsdRate, setSupplierUsdRate] = useState('');
    const [customerUsdRate, setCustomerUsdRate] = useState('');
    const [exchangeRate, setExchangeRate] = useState('');

    const supplierCurrency = Form.useWatch('supplierCurrency', form);
    const customerCurrency = Form.useWatch('customerCurrency', form);
    const markupValue = Form.useWatch('markupValue', form);

    const getDate = () => {
        if (duplicate || !booking?.creationDate) return '';
        return date.format.apiTime(booking?.creationDate);
    };

    const getUsdRate = () => {
        if (supplierCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${supplierCurrency}/USD`,
                body: { date: getDate() },
                success: (result) => setSupplierUsdRate(result.toFixed(RATES_DECIMAL_PLACES)),
            });
        }
    };

    const getUsdCustomerRate = () => {
        if (customerCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${customerCurrency}/USD`,
                body: { date: getDate() },
                success: (result) => setCustomerUsdRate(result.toFixed(RATES_DECIMAL_PLACES)),
            });
        }
    };

    const getExchangeRate = () => {
        if (customerCurrency && supplierCurrency) {
            API.get({
                tsutsujigasaki: `/Rates/${supplierCurrency}/${customerCurrency}`,
                body: { date: getDate() },
                success: (result) => setExchangeRate(result.toFixed(RATES_DECIMAL_PLACES)),
            });
        }
    };

    useEffect(() => {
        getUsdRate();
        getUsdCustomerRate();
        getExchangeRate();
    }, [supplierCurrency, customerCurrency, booking]);

    const getCurrenciesData = (amount) => {
        const totalInUsd = new BigNumber(amount || 0).multipliedBy(supplierUsdRate || 0);
        const totalInCustomerCurrency = new BigNumber(amount || 0).multipliedBy(exchangeRate || 1);

        const markup = totalInCustomerCurrency.multipliedBy(new BigNumber(markupValue || 0).dividedBy(100));
        const totalWithMarkup = totalInCustomerCurrency.plus(markup);

        return {
            supplierCurrency: supplierCurrency || '',
            customerCurrency: customerCurrency || '',
            supplierUsdRate,
            customerUsdRate,
            totalInUsd: totalInUsd.toFixed(2),
            exchangeRate,
            totalWithoutMarkup: totalInCustomerCurrency.toFixed(2),
            totalInCustomerCurrency: totalWithMarkup.toFixed(2),
        };
    };

    return { getCurrenciesData, exchangeRate };
};

export default useCurrencies;
