import React, { useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';

const ConfirmationModal = ({
    headerText,
    submitText,
    visible,
    children,
    onCancel,
    onSubmit,
    reason = true,
    initialValues = {},
}) => {
    const [disabled, setDisabled] = useState(false);

    const [form] = Form.useForm();

    const submit = () => {
        setDisabled(true);
        form.validateFields().then(
            (values) => {
                onSubmit(values).then(() => {
                    form.resetFields();
                    setDisabled(false);
                });
            },
            () => {
                setDisabled(false);
            }
        );
    };

    return (
        <Modal
            open={visible}
            title={headerText}
            onCancel={onCancel}
            footer={
                <>
                    <Button key="back" onClick={onCancel} className="modal-cancel-btn">
                        Cancel
                    </Button>
                    <Button htmlType="submit" onClick={submit} disabled={disabled}>
                        {submitText}
                    </Button>
                </>
            }
        >
            <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {children}
                    {reason && (
                        <Form.Item
                            name="reason"
                            label="Reason"
                            rules={[{ required: true, message: 'Please enter Reason' }]}
                        >
                            <Input placeholder="Enter a reason" />
                        </Form.Item>
                    )}
                </Space>
            </Form>
        </Modal>
    );
};

export default ConfirmationModal;
