import React from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Form, Input, Typography, Row, Col } from 'antd';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const { Title } = Typography;

const SupplierContactsPage = ({ isReadOnly, supplier, code, loadSupplier }) => {
    const [supplierPageForm] = Form.useForm();

    const submit = (values) => {
        let body = {
            ...values.operationContact,
            ...values.accountsContact,
        };

        API.post({
            sunpu: sunpuMethods.supplierDataContactDetails(code),
            body,
            success: () => {
                loadSupplier();
                notifications('Supplier Saved!', 'success');
            },
        });
    };

    if (code && !supplier) return <Loader />;

    return (
        <Form
            form={supplierPageForm}
            initialValues={supplier}
            layout="vertical"
            onFinish={submit}
            style={{ maxWidth: 1000 }}
            className={isReadOnly && 'disable-full-form'}
            disabled={isReadOnly}
        >
            <Title level={4} style={{ marginBottom: 20 }}>
                Operation Contact
            </Title>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name={['operationContact', 'operationsContactMainContactPerson']}
                        label="Main Contact Person"
                    >
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['operationContact', 'operationsContactDesignation']}
                        label="Designation"
                    >
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name={['operationContact', 'operationsContactEmailId']} label="Email Id">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['operationContact', 'emergencyContactNumber']}
                        label="Emergency Contract Number"
                    >
                        <Input placeholder="+XX (XXX) XXX XX XX" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name={['operationContact', 'tollFreeNumber']} label="Toll Free Number">
                        <Input placeholder="+XX (XXX) XXX XX XX" />
                    </Form.Item>
                </Col>
            </Row>

            <Title level={4} style={{ marginBottom: 20 }}>
                Accounts Contact
            </Title>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name={['accountsContact', 'accountsContactMainContactPerson']}
                        label="Main Contact Person"
                    >
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={['accountsContact', 'accountsContactDesignation']} label="Designation">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name={['accountsContact', 'accountsContactEmailId']} label="Email Id">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={['accountsContact', 'accountsContactPhone']} label="Phone">
                        <Input placeholder="+XX (XXX) XXX XX XX" />
                    </Form.Item>
                </Col>
            </Row>

            {$auth.permitted('SupplierManagement') && (
                <Button htmlType="submit" type="primary">
                    Save Changes
                </Button>
            )}
        </Form>
    );
};

export default SupplierContactsPage;
