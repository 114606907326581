import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import AvailabilitySearchSettings from 'components/selectors/availability-search-settings';

const AgencySearchingAndBooking = ({ agency }) => {
    const [systemSettings, setSystemSettings] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.agencySystemSettings(agency.id),
            success: (data) => {
                setSystemSettings(data);
            },
        });
    }, []);

    return (
        <AvailabilitySearchSettings
            agency={agency}
            method={apiMethods.agencySystemSettings(agency.id)}
            systemSettings={systemSettings}
            setSystemSettings={setSystemSettings}
        />
    );
};

export default AgencySearchingAndBooking;
