import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { date, Loader } from 'legacy';
import { Typography, Table } from 'antd';

const { Title } = Typography;

const columns = [
    {
        title: 'Date',
        dataIndex: 'created',
        key: 'created',
        render: (text) => date.format.e(text),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Changed by',
        dataIndex: 'createdBy',
        key: 'createdBy',
    },
];

const PAGE_SIZE = 30;

const RestrictionsHistory = () => {
    const [history, setHistory] = useState(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    useEffect(() => {
        API.get({
            url: apiMethods.restrictionsHistory((page - 1) * PAGE_SIZE, PAGE_SIZE),
            success: ({ count, items }) => {
                setHistory(items);
                setTotal(count);
            },
        });
    }, [page]);

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Change History
            </Title>

            <Table
                rowKey="id"
                columns={columns}
                dataSource={history}
                loading={history === null}
                locale={{
                    emptyText: history === null ? <Loader /> : 'Nothing to Show',
                }}
                pagination={{
                    current: page,
                    total,
                    showQuickJumper: false,
                    showSizeChanger: false,
                    pageSize: PAGE_SIZE,
                    onChange: setPage,
                    showTotal: (total, range) => (
                        <div style={{ marginRight: 20 }}>
                            {`${range[0]}-${range[1]}`} of <b>{total} items</b>
                        </div>
                    ),
                    position: ['topRight'],
                }}
            />
        </>
    );
};

export default RestrictionsHistory;
