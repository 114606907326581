import { date } from 'legacy';

export const serializeRequest = (values, top, skip) => {
    const filters = [];

    if (values.administratorId) {
        filters.push(`administratorId eq ${values.administratorId}`);
    }

    if (values.category?.length) {
        filters.push('(' + values.category.map((category) => `category eq '${category}'`).join(' or ') + ')');
    }

    if (values.created) {
        filters.push(
            `created ge ${date.format.api(values.created[0])} and created lt ${date.format.api(
                date.addDay(values.created[1], 1)
            )}`
        );
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $orderBy: 'Created desc',
        $top: top,
        $skip: skip,
    };
};

const CategoriesNames = {
    AgencyData: 'Agency Data',
    ContractType: 'Contract Type',
};

const DeadlineShifts = {
    2: 'Remove 2 buffer days',
    1: 'Remove 1 buffer day',
    0: 'No special changes (Default)',
    '-1': '1 day additional buffer',
    '-2': '2 days additional buffer',
    '-3': '3 days additional buffer',
    '-4': '4 days additional buffer',
    '-5': '5 days additional buffer',
};

const FieldsNames = {
    nonRefundableMode: 'Non-Refundable Rate',
    passedDeadlineOffersMode: 'Passed Deadline Offers Mode',
    isSupplierVisible: 'Supplier Visible',
    isDirectContractFlagVisible: 'Direct Contract Mark',
    isBookingAutoCancellationDisabled: 'No Auto-Cancellation',
    isHappyTravelAgency: 'Happy Travel Agency',
    customDeadlineShift: 'Deadline Shift',
    canModifyBookingSupplierReferenceCode: 'Can Modify Booking Supplier Reference Code',
    acceptLastMinuteBookings: 'Accept Last Minute Bookings',
};

const splitDescription = (data) => {
    const startIndex = data.indexOf('{');
    const endIndex = data.lastIndexOf('}') + 1;

    if (startIndex === -1) return { descriptionString: data, updatedObject: null };

    const jsonString = data.slice(startIndex, endIndex);

    return {
        descriptionString: data.slice(0, startIndex).trim(),
        updatedObject: jsonString ? JSON.parse(jsonString) : null,
    };
};

const createValueString = (fieldName, descriptionValue) => {
    if (descriptionValue === null) return 'None';
    if (typeof descriptionValue === 'boolean') return descriptionValue ? 'Yes' : 'No';

    if (fieldName === 'customDeadlineShift') return DeadlineShifts[descriptionValue];

    return descriptionValue;
};

const createDescriptionString = (obj) => {
    if (!obj) {
        return null;
    }

    const description = Object.entries(obj).map(
        ([name, value]) => ` ${FieldsNames[name] || name}: ${createValueString(name, value)}`
    );

    return description.map((item) => <div>{item}</div>);
};

export const columns = () => [
    {
        header: 'Date',
        cell: ({ created }) => date.format.e(created),
    },

    {
        header: 'Category',
        cell: ({ category }) => CategoriesNames[category] || category,
    },

    {
        header: 'Description',
        cell: ({ description }) => {
            const { descriptionString, updatedObject } = splitDescription(description);

            return (
                <p style={{ margin: 0 }}>
                    {descriptionString}
                    <br />
                    {createDescriptionString(updatedObject)}
                </p>
            );
        },
    },

    {
        header: 'Admin Name',
        cell: ({ administrator, description }) => {
            if (administrator) return administrator;

            return description.includes('registered') ? 'By agent invitation' : 'By system';
        },
    },
];
