import React from 'react';
import { Form, Typography, Row, Input, InputNumber, Space } from 'antd';
import Table from 'components/table';
import TitleSelector from 'components/selectors/title-selector';

const { Title } = Typography;

export const passengersValidation = [
    {
        max: 50,
        message: 'Too long',
    },
    {
        validator: (_, value) => {
            if (!value) return Promise.resolve();
            return /^[a-zA-Z \-]+$/.test(value)
                ? Promise.resolve()
                : Promise.reject(new Error(`Only English alphabet is allowed`));
        },
    },
];

const pricingColumns = [
    {
        header: 'Room Name',
        cell: ({ name }) => <b>{name}</b>,
    },
    {
        header: 'Passenger (Optional)',
        cell: ({ index: row, adults }) => (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {adults.map((_, index) => (
                    <Space key={index.toString()}>
                        <Form.Item name={['rooms', row, 'passengers', index, 'title']}>
                            <TitleSelector />
                        </Form.Item>
                        <Form.Item
                            name={['rooms', row, 'passengers', index, 'firstName']}
                            rules={passengersValidation}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            name={['rooms', row, 'passengers', index, 'lastName']}
                            rules={passengersValidation}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Space>
                ))}
            </div>
        ),
    },
    {
        header: 'Children (Optional)',
        cell: ({ index: row, childrenItems }) => (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {childrenItems.map((_, index) => (
                    <Space key={index}>
                        <Form.Item name={['rooms', row, 'children', index, 'age']}>
                            <InputNumber defaultValue={9} placeholder="Age" min={0} max={11} />
                        </Form.Item>
                        <Form.Item
                            name={['rooms', row, 'children', index, 'firstName']}
                            rules={passengersValidation}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            name={['rooms', row, 'children', index, 'lastName']}
                            rules={passengersValidation}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Space>
                ))}
            </div>
        ),
    },
];

const PassengersDetails = () => {
    const createTableList = (getFieldValue) => {
        const data = getFieldValue('rooms');

        return data
            ?.map((room, index) => ({
                index,
                name: `Room ${index + 1}: ${room?.contractName || ''}`,
                adults:
                    room.adultsNumber > 1
                        ? new Array(room.adultsNumber - 1).fill({ firstName: '', lastName: '' })
                        : [],
                childrenItems: room.childrenNumber
                    ? new Array(room.childrenNumber).fill({ firstName: '', lastName: '', age: 9 })
                    : [],
            }))
            .filter((item) => !!(item?.adults?.length || item?.childrenItems?.length));
    };

    return (
        <div>
            <Title level={4}>Passenger Details</Title>

            <Row size="large" style={{ marginBottom: 40 }} className="inputs-table table-top-vertical-align">
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        JSON.stringify(prevValues) !== JSON.stringify(currentValues)
                    }
                >
                    {({ getFieldValue }) => (
                        <Table
                            style={{ width: '100%' }}
                            list={createTableList(getFieldValue)}
                            columns={pricingColumns}
                        />
                    )}
                </Form.Item>
            </Row>
        </div>
    );
};

export default PassengersDetails;
