import React, { useEffect, useState } from 'react';
import { EntitySelector } from 'common/entity-selector';
import apiMethods from 'core/methods';
import { API } from 'htcore';

const optionsGenerator = (list = []) =>
    list
        .filter((item) => item.isActive)
        .map((item) => ({
            value: item.agentId,
            label: `${item.name} (${item.agentId})`,
        }));

const AgentSelector = ({ agencyId, ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!agencyId) {
            setOptions(optionsGenerator([]));
            setLoading(false);
            return;
        }
        API.get({
            url: apiMethods.agencyAgents(agencyId),
            success: (data) => setOptions(optionsGenerator(data)),
            after: () => setLoading(false),
        });
    }, [agencyId]);

    return <EntitySelector placeholder="Select Agent" {...props} options={options} loading={loading} />;
};

export default AgentSelector;
