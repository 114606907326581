import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { API } from 'htcore';

const RequestSelector = ({
    clearCache,
    request,
    requestMethod = 'get',
    optionsGenerator,
    defaultOptions,
    groups,
    onChange,
    onSelect,
    size,
    placeholder,
    value,
    style,
    multiple,
}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empty, setEmpty] = useState(true);

    const onSearch = (value) => {
        if (value) {
            API[requestMethod]({
                success: (data) => {
                    setOptions(optionsGenerator(data));
                    setEmpty(false);
                },
                after: () => setLoading(false),
                ...request(value),
            });
        } else {
            setOptions([]);
            setLoading(false);
            setEmpty(true);
        }
    };

    useEffect(() => {
        if (defaultOptions) setOptions(defaultOptions);
    }, [defaultOptions]);

    const onBlur = () => {
        if (clearCache) {
            setOptions([]);
            setEmpty(true);
        }
    };

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={onSearch}
            size={size}
            showArrow
            value={value}
            options={groups ? null : options}
            loading={loading}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            onSelect={onSelect}
            notFoundContent={empty ? null : 'Nothing found'}
            style={style}
            allowClear
            mode={multiple ? 'multiple' : null}
        >
            {groups ? options : null}
        </Select>
    );
};

export default RequestSelector;
