import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { EntitySelector } from 'common/entity-selector';
import { Form } from 'antd';

export const columns = (accountManagesOptions, accountManagerLoading) => [
    {
        header: 'ID',
        cell: (cell) => <Link to={`./${cell.requestId}`}>{cell.requestId}</Link>,
    },
    {
        header: 'Company Name',
        cell: (cell) => <Link to={`./${cell.requestId}`}>{cell.companyName}</Link>,
    },
    {
        header: 'Location',
        cell: 'location',
    },
    {
        header: 'Agent Name',
        cell: 'agentName',
    },
    {
        header: 'Account manager',
        cell: (cell) => {
            if (cell.status === 'Awaiting') {
                return (
                    <Form.Item
                        initialValue={cell.accountManagerId || 'Unassigned'}
                        name={cell.requestId}
                        noStyle
                    >
                        <EntitySelector
                            allowClear={false}
                            style={{ width: 150 }}
                            options={accountManagesOptions}
                            loading={accountManagerLoading}
                        />
                    </Form.Item>
                );
            }

            if (!cell.accountManagerId) return 'Unassigned';

            return accountManagesOptions.find((item) => item.value === cell.accountManagerId)?.label;
        },
    },
    {
        header: 'Company Email',
        cell: 'companyEmail',
    },
    {
        header: 'Date',
        cell: (cell) => <div style={{ minWidth: 90 }}>{date.format.c(cell.created)}</div>,
    },
    {
        header: 'Status',
        cell: 'status',
    },
];
