import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import RolesListPage from './roles-list';
import RolePage from './role';

const AdministratorRolesPage = () => {
    useTitle('Roles of Administrators');

    const [roles, setRoles] = useState(null);

    const loadRoles = () => {
        API.get({
            url: apiMethods.administratorRoles,
            success: (roles) => setRoles(roles.sort((a, b) => b.permissions.length - a.permissions.length)),
        });
    };

    useEffect(() => {
        loadRoles();
    }, []);

    return (
        <Routes>
            <Route path="/" element={<RolesListPage roles={roles} />} />
            <Route path="/:id" element={<RolePage roles={roles} reloadRoles={loadRoles} />} />
        </Routes>
    );
};

export default AdministratorRolesPage;
