import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import BookingSearchForm from '../../booking-search-form';
import { serializeRequest } from '../../serializer';
import BookingsItineraryList from './bookings-itinerary-list';

const BookingsItinerary = ({ searchForm }) => {
    const [formValues, setFormValues] = useState(null);
    const [list, setList] = useState(null);

    useEffect(() => {
        searchForm.validateFields().then(setFormValues);
    }, []);

    const loadPage = () => {
        setList(null);
        API.get({
            url: apiMethods.bookingsAll,
            body: {
                $filter: serializeRequest(formValues || {}, null, null, true).$filter,
                $orderBy: 'created asc',
            },
            headers: ['count'],
            success: ([result]) => {
                setList(result);
            },
        });
    };

    useEffect(() => {
        loadPage();
    }, [formValues]);

    const onSubmit = (values) => {
        setFormValues(values);
    };

    return (
        <>
            <BookingSearchForm bookingSearchForm={searchForm} onSubmit={onSubmit} />
            <BookingsItineraryList list={list} />
        </>
    );
};

export default BookingsItinerary;
