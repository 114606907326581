import React from 'react';
import { useTitle } from 'htcore';
import ReportCard from './report-card';
import ReportsList from './reports-list';
import { PageHeader, Collapse } from 'antd';

const { Panel } = Collapse;

const ReportsPage = () => {
    useTitle('Reports');

    return (
        <>
            <PageHeader title="Reports Downloading" />
            <Collapse accordion defaultActiveKey={[]} style={{ marginBottom: 250 }}>
                {ReportsList.map((report, id) => (
                    <Panel header={report.name} key={id}>
                        <ReportCard report={report} />
                    </Panel>
                ))}
            </Collapse>
        </>
    );
};

export default ReportsPage;
