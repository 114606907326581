import React, { useState } from 'react';
import { API } from 'htcore';
import apiGifuMethods from 'core/gifu-methods';
import { Button, Form, Modal, Space } from 'antd';
import AccountForm from './account-form';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const NewAccountModal = ({ onClose, updateData }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        form.validateFields().then(({ vendor, currency, cardType, conversionBuffer, ...body }) => {
            setLoading(true);
            API.post({
                gifu: apiGifuMethods.createVendorAccount(vendor, currency, cardType),
                body: {
                    ...body,
                    isActive: true,
                    administratorName: $auth.fullName,
                    administratorId: $auth?.information?.id,
                    conversionBuffer: conversionBuffer || 0,
                },
                success: () => {
                    notifications('Account Created!', 'success');
                    onClose();
                    updateData();
                },
                after: () => setLoading(false),
            });
        });
    };

    return (
        <Modal
            open
            title="Add New VCC Account"
            onCancel={onClose}
            footer={
                <Space>
                    <Button
                        htmlType="submit"
                        type="primary"
                        style={{ width: 'fit-content' }}
                        onClick={onSubmit}
                    >
                        Create
                    </Button>
                </Space>
            }
        >
            <AccountForm loading={loading} form={form} />
        </Modal>
    );
};

export default NewAccountModal;
