import React from 'react';
import { Typography, Row, Form } from 'antd';
import Table from 'components/table';

const { Title } = Typography;

const pricingColumns = (passengers, totalPrice) => [
    {
        header: 'Guests',
        cell: () => `${passengers?.adults || 0} Adults, ${passengers?.children || 0} Children`,
    },
    {
        header: 'Buying Price ',
        cell: () => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => `${getFieldValue('supplierCurrency') || ''} ${totalPrice}`}
            </Form.Item>
        ),
    },
];

const TotalTable = ({ passengers, totalPrice }) => (
    <>
        <Title level={4}>Total</Title>

        <Row size="large" style={{ marginBottom: 40 }} className="inputs-table">
            <Table style={{ width: '100%' }} list={[{}]} columns={pricingColumns(passengers, totalPrice)} />
        </Row>
    </>
);

export default TotalTable;
