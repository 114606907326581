import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { date as dateFormatter, price, Loader } from 'legacy';
import { Button, DatePicker, Form, Menu, Typography } from 'antd';
import apiMethods from 'core/methods';
import Table from 'components/table';
import { Link, Navigate, useParams } from 'react-router-dom';
import { notifications } from '../../../components/notification';
import moment from 'moment';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export const Columns = (currency) => [
    {
        header: 'Date',
        cell: (row) => dateFormatter.format.c(row.date),
    },
    {
        header: 'Booking Reference',
        cell: (row) =>
            row.referenceCode ? (
                <Link className="link" to={`/booking/${row.referenceCode}`}>
                    {row.referenceCode}
                </Link>
            ) : (
                '-'
            ),
    },
    {
        header: 'Invoice No.',
        cell: ({ invoiceNumber }) => (invoiceNumber ? invoiceNumber : '-'),
    },
    {
        header: 'Description',
        cell: ({ operationDescription, description, userName, created }) => (
            <>
                <b>{operationDescription}:</b> {description}
                {userName ? (
                    <>
                        {' '}
                        <b>by</b> {userName} at {dateFormatter.format.time(created)}
                    </>
                ) : null}
            </>
        ),
    },
    {
        header: `Debit (${currency})`,
        cell: (row) => (row.debit ? row.debit.toFixed(2) : '-'),
    },
    {
        header: `Credit (${currency})`,
        cell: (row) => (row.credit ? <span style={{ color: '#1CBE69' }}>{row.credit.toFixed(2)}</span> : '-'),
    },
    {
        header: `Balance (${currency})`,
        cell: (row) => row.balance.toFixed(2),
    },
];

const downloadStatement = (agencyId, accountId, fileType, range, setLoading) => {
    if (!range?.[0] || !range?.[1]) return;

    setLoading(true);
    API.get({
        url: apiMethods.agencyAccountHistoryDownload(agencyId, accountId, fileType),
        body: {
            $orderBy: 'date desc, created desc',
            $filter: `date ge ${dateFormatter.format.api(
                range[0]
            )} and date lt ${dateFormatter.format.apiDateOnly(range[1])}T23:59:59Z`,
        },
        response: (res) => {
            if (res.status === 400) {
                notifications('Unable to download a statement', 'warning');
            }
            if (res.status === 200)
                res.blob().then((blobby) => {
                    let anchor = document.createElement('a');
                    document.body.appendChild(anchor);

                    const objectUrl = window.URL.createObjectURL(blobby);
                    anchor.href = objectUrl;
                    const contentDisposition = res.headers.get('content-disposition');
                    const fileName = contentDisposition
                        ? contentDisposition.split('filename="')[1].split('";')[0]
                        : 'statement.' + fileType;
                    anchor.download = fileName;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                    setLoading(false);
                });
        },
        error: () => {
            notifications('Unable to download a file', 'warning');
            setLoading(false);
        },
    });
};

const AccountsHistory = ({ agency, agencyAccounts }) => {
    const [accountHistory, setAccountHistory] = useState(null);

    const initialRange = [moment().add(-3, 'month'), moment()];
    const [range, setRange] = useState(initialRange);
    const { accountId } = useParams();
    const [loading, setLoading] = useState(false);

    const loadAccountHistory = () => {
        if (!agency || !agencyAccounts?.length || accountId === 'all') return;
        if (!range?.[0] || !range?.[1]) return;

        setAccountHistory(null);
        API.get({
            url: apiMethods.agencyAccountHistory(agency.id, accountId),
            body: {
                $orderBy: 'date desc, created desc',
                $filter: `date ge ${dateFormatter.format.api(
                    range[0]
                )} and date lt ${dateFormatter.format.apiDateOnly(range[1])}T23:59:59Z`,
            },
            success: (data) => {
                setAccountHistory(data);
            },
        });
    };

    useEffect(() => {
        loadAccountHistory();
    }, [agencyAccounts?.length, agency?.id, accountId, range]);

    if (!agencyAccounts?.length) {
        return 'Agency has no accounts (should be verified first)';
    }

    if (accountId === 'all') {
        return <Navigate to={`./../${agencyAccounts[0].id}`} />;
    }

    const account = agencyAccounts.find(({ id }) => String(id) === accountId);

    return (
        <>
            <Menu
                mode="horizontal"
                items={agencyAccounts.map(({ id, currency }) => ({
                    label: (
                        <Link to={`./../${id}`}>
                            {currency} (#{id})
                        </Link>
                    ),
                    key: id,
                }))}
                selectedKeys={accountId || ''}
            />
            <div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 25,
                        gap: 20,
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    <Title level={4}>
                        Account #{accountId}: {price(account.balance)}
                    </Title>

                    <div style={{ display: 'flex', gap: 20 }}>
                        <Form layout="vertical" style={{ width: '300px' }}>
                            <Form.Item label="Date Range">
                                <RangePicker
                                    format={dateFormatter.format.c}
                                    placeholder={['From', 'Till']}
                                    disabledDate={(current) =>
                                        (current && current > moment()) || current < moment().add(-1, 'year')
                                    }
                                    defaultValue={initialRange}
                                    onChange={(range) => {
                                        setRange(range);
                                    }}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Form>
                        <Button
                            type="primary"
                            onClick={
                                !loading
                                    ? () => downloadStatement(agency.id, accountId, 'pdf', range, setLoading)
                                    : null
                            }
                            style={{ marginTop: 6 }}
                            loading={loading}
                        >
                            Load The Statement In PDF
                        </Button>
                        <Button
                            type="primary"
                            onClick={
                                !loading
                                    ? () => downloadStatement(agency.id, accountId, 'xlsx', range, setLoading)
                                    : null
                            }
                            style={{ marginTop: 6 }}
                            loading={loading}
                        >
                            Load The Statement In EXCEL
                        </Button>
                    </div>
                </div>

                {!accountHistory ? (
                    <Loader />
                ) : !accountHistory.length ? (
                    'No events for this account yet.'
                ) : (
                    <Table list={accountHistory} columns={Columns(account.currency)} />
                )}
            </div>
        </>
    );
};

export default AccountsHistory;
