import React from 'react';
import { Space, Typography } from 'antd';
import AgentCredentialsSettings from './agent-credentials-settings';
import AgentChangeEmail from './agent-change-email';

const { Title } = Typography;

const AgentCredentials = () => (
    <>
        <Title level={4} style={{ marginBottom: 20 }}>
            Credentials
        </Title>

        <Space direction="vertical">
            <AgentChangeEmail />
            <AgentCredentialsSettings />
        </Space>
    </>
);

export default AgentCredentials;
