const apiGifuMethods = {
    history: '/history',
    vendors: '/vendors',
    vendorsList: '/vendors/list',
    vendorsPriorities: '/vendors/priorities',
    vendorAccount: (vendor, currency, cardType, accountNumber) =>
        `/vendors/${vendor}/${currency}/${cardType}/${accountNumber}`,
    createVendorAccount: (vendor, currency, cardType) => `/vendors/${vendor}/${currency}/${cardType}`,
    vendorAccountActivate: (vendor, currency, cardType, accountNumber) =>
        `/vendors/${vendor}/${currency}/${cardType}/${accountNumber}/activate`,
    vendorAccountDeactivate: (vendor, currency, cardType, accountNumber) =>
        `/vendors/${vendor}/${currency}/${cardType}/${accountNumber}/deactivate`,
};

export default apiGifuMethods;
