import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import CountrySelector from 'components/selectors/country-selector';
import LocalitySelector from 'components/selectors/locality-selector';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const CountriesSelector = ({ form }) => (
    <Form.List name="countryIds" label="Country">
        {(fields, { add, remove }) => (
            <>
                {fields.map(({ key, name, ...restField }, index) => {
                    const isFirst = index === 0;

                    return (
                        <Row gutter={20} key={key}>
                            <Col span={4}>
                                <Form.Item name={[name, 'country']} label="Country" {...restField}>
                                    <CountrySelector
                                        onChange={(value) => {
                                            form.setFieldValue(['countryIds', name], {
                                                locality: [],
                                                country: value,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Row>
                                    <Form.Item shouldUpdate noStyle>
                                        {({ getFieldValue }) => {
                                            const countryId = getFieldValue(['countryIds', name, 'country']);

                                            return countryId ? (
                                                <Form.Item
                                                    name={[name, 'locality']}
                                                    label="Cities"
                                                    style={{ marginRight: 15, flex: 1 }}
                                                    {...restField}
                                                >
                                                    <LocalitySelector multiple countryId={countryId} />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>

                                    <Button
                                        style={{ marginTop: 6 }}
                                        onClick={() => (isFirst ? add([]) : remove(name))}
                                    >
                                        {isFirst ? <PlusOutlined /> : <MinusOutlined />}
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    );
                })}
            </>
        )}
    </Form.List>
);

export default CountriesSelector;
