import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { localStorage } from 'htcore';
import $auth from 'stores/auth';
import { Layout, Menu } from 'antd';
import {
    Bank,
    Buildings,
    Calendar2,
    Calendar,
    Clipboard,
    ClipboardText,
    Coin1,
    EmptyWallet,
    Layer,
    Logout,
    NotificationBing,
    Profile2User,
    ReceiptDisscount,
    Setting4,
    SmsTracking,
} from 'iconsax-react';

const { Sider } = Layout;

const SideMenu = observer(() => {
    const allNavigationItems = [
        {
            label: <Link to="/agency/list">Agencies</Link>,
            key: 'agency',
            icon: <Buildings />,
            permissions: ['ViewAgencies'],
        },
        {
            label: <Link to="/payment-links">Payment Links</Link>,
            key: 'payment-links',
            icon: <EmptyWallet />,
            permissions: ['PaymentLinkGeneration'],
        },
        {
            label: <Link to="/administrators">Administrators</Link>,
            key: 'administrators',
            icon: <Profile2User />,
            permissions: ['AdministratorInvitation', 'AdministratorManagement'],
        },
        {
            label: 'Products',
            key: 'products',
            icon: <Clipboard />,
            permissions: ['AccommodationsManagement', 'SupplierManagement', 'SupplierView'],
            children: [
                {
                    label: <Link to="/suppliers">Suppliers/Channels</Link>,
                    key: 'suppliers',
                    permissions: ['SupplierManagement', 'SupplierView'],
                },
                {
                    label: <Link to="/mapper">Accommodations</Link>,
                    key: 'mapper',
                    permissions: ['AccommodationsManagement'],
                },
                {
                    label: <Link to="/destination-mapping">Destination Mapping</Link>,
                    key: 'destination-mapping',
                    permissions: ['DestinationMappingManagement'],
                },
            ],
        },
        {
            label: <Link to="/markups/management">Markups</Link>,
            key: 'markups',
            icon: <ReceiptDisscount />,
            permissions: ['MarkupManagement', 'ViewMarkups'],
        },
        {
            label: <Link to="/reports">Reports</Link>,
            key: 'reports',
            icon: <ClipboardText />,
            permissions: [
                'BookingReportGeneration',
                'AccountsReportGeneration',
                'BookingManagement',
                'CompanyReportGeneration',
            ],
        },
        {
            label: <Link to="/bookings">Bookings</Link>,
            key: 'bookings',
            icon: <Calendar2 />,
            permissions: ['ViewBookings'],
        },
        {
            label: <Link to="/offline-bookings">Offline Bookings</Link>,
            key: 'offline-bookings',
            icon: <Calendar />,
            permissions: ['OfflineBooking'],
        },

        {
            label: <Link to="/administrator-roles">Administrator Roles</Link>,
            key: 'administrator-roles',
            icon: <Setting4 />,
            permissions: ['AdministratorRoleManagement'],
        },
        {
            label: <Link to="/bank-details">Bank Details</Link>,
            key: 'bank-details',
            icon: <Bank />,
            permissions: ['CompanyAccountManagement'],
        },
        {
            label: 'Conversion Management',
            key: 'conversion-management',
            icon: <Coin1 />,
            permissions: ['CurrencyManagement', 'ConversionManagement'],
            children: [
                {
                    label: <Link to="/rate-management">Forex Rate Management</Link>,
                    key: 'rate-management',
                    permissions: ['CurrencyManagement'],
                },
                {
                    label: <Link to="/conversion-fees/accounts">VCC Forex Conversion Fees </Link>,
                    key: 'conversion-fees',
                    permissions: ['ConversionManagement'],
                },
            ],
        },
        {
            label: <Link to="/notifications">Notifications</Link>,
            key: 'notifications',
            icon: <SmsTracking />,
            permissions: ['NotificationManagement'],
        },
    ];

    const location = useLocation();
    const [collapsed, setCollapsed] = useState(localStorage.get('menu_collapsed') === 'true');
    const navigationItems = allNavigationItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, children, ...props } = item;
            return {
                ...props,
                ...(children
                    ? { children: children.filter((item) => $auth.permitted(item.permissions)) }
                    : {}),
            };
        });

    let selectedKey = location.pathname.split('/')[1];
    if (selectedKey === 'booking') {
        selectedKey = 'bookings';
    }

    const onCollapse = (newState) => {
        setCollapsed(newState);
        localStorage.set('menu_collapsed', newState);
    };

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width={200} theme="light">
            <Link to="/" className="logo-wrapper">
                <img src="/images/logo/logo.png" alt="Happytravel.com" />
            </Link>

            {Boolean(navigationItems.length) && <div className="navigation-subtitle">Navigation</div>}
            <Menu
                items={navigationItems}
                selectedKeys={selectedKey}
                mode="inline"
                style={{ backgroundColor: '#f7f8fc' }}
            />
            {Boolean($auth.information) && (
                <>
                    <div className="navigation-subtitle" style={{ marginTop: 31 }}>
                        {($auth.information.firstName || '') + ' ' + ($auth.information.lastName || '')}
                    </div>
                    <Menu
                        items={[
                            {
                                label: <Link to="/settings">Notification Settings</Link>,
                                key: 'settings',
                                icon: <NotificationBing />,
                            },
                            {
                                label: <Link to="/logout">Sign Out</Link>,
                                key: 'logout',
                                icon: <Logout />,
                            },
                        ]}
                        selectedKeys={selectedKey}
                    />
                </>
            )}
        </Sider>
    );
});

export default SideMenu;
