import React from 'react';
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const RestrictionsSaveModal = ({ open, keepButtonText, questionText, saveChanges, onClose }) => (
    <Modal
        open={open}
        footer={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <Button type="primary" style={{ width: '100%' }} onClick={() => saveChanges(true)}>
                    {keepButtonText || 'Keep restriction on country/accommodation level'}
                </Button>
                <Button type="primary" style={{ width: '100%' }} onClick={() => saveChanges(false)}>
                    Reset all previously added restrictions
                </Button>
            </div>
        }
        onCancel={onClose}
    >
        <Space style={{ paddingRight: 16 }}>
            <ExclamationCircleOutlined style={{ color: '#f9a51a', fontSize: 24 }} />
            <span>
                {questionText ||
                    'Do you want to keep the previously added restrictions at the country and accommodation level?'}
            </span>
        </Space>
    </Modal>
);

export default RestrictionsSaveModal;
