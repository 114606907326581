import { makeAutoObservable } from 'mobx';
import autosave from 'htcore/misc/autosave';

class AuthStore {
    token = null;
    information = null;
    rolesCompleteList = [];
    initialized = false;

    constructor() {
        makeAutoObservable(this);
        autosave(this, '_auth_store_cache');
    }

    get rolesNames() {
        if (!this.information) return [];
        return this.information.administratorRoleIds?.map(
            (role) => this.rolesCompleteList?.find((item) => item.id === role)?.name
        );
    }

    get notificationsList() {
        if (!this.information) return [];

        const result = [];
        this.information.administratorRoleIds?.map((role) =>
            this.rolesCompleteList
                ?.find((item) => item.id === role)
                ?.notificationTypes.map(
                    (notification) => !result.includes(notification) && result.push(notification)
                )
        );
        return result;
    }

    get fullName() {
        return `${this.information.firstName || ''} ${this.information.lastName || ''}`;
    }

    permitted(permissions) {
        if (typeof permissions === 'boolean') {
            return permissions;
        }

        if (!permissions) {
            return true;
        }

        if (!this.information?.permissions.length) {
            return false;
        }

        if (Array.isArray(permissions)) {
            return permissions.some((item) => this.information.permissions.includes(item));
        }

        return this.information.permissions.includes(permissions);
    }

    setToken(values) {
        this.token = values;
    }
    setInformation(values) {
        this.information = values;
        this.initialized = true;
    }
    setRolesCompleteList(values) {
        this.rolesCompleteList = values;
    }
}

export default new AuthStore();
