import React from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiAkitaMethods from 'core/akita-methods';
import { Button, message, Space } from 'antd';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const LOGS = {
    SUPPLIER: 0,
    CLIENT: 1,
};

const BookingsLogs = () => {
    const { refCode } = useParams();

    const download = (res, hideMessage, logType) => {
        if (res.status !== 200) {
            hideMessage();
            notifications("Couldn't download the logs", 'warning');
        } else {
            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = logType ? "client's-logs.zip" : "supplier's-logs.zip";
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success('Logs Downloaded');
            });
        }
    };

    const getLog = (logType) => {
        const hideMessage = message.loading(' Downloading...', 0);
        API.get({
            akita: apiAkitaMethods.logs(refCode, logType),
            response: (result) => download(result, hideMessage, logType),
        });
    };

    if (!$auth.permitted('ApiManagement')) {
        return null;
    }

    return (
        <Space>
            <Button onClick={() => getLog(LOGS.SUPPLIER)}>Download Supplier's Logs</Button>
            <Button onClick={() => getLog(LOGS.CLIENT)}>Download API Client's Logs</Button>
        </Space>
    );
};

export default BookingsLogs;
