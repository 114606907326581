import React from 'react';
import apiMethods from 'core/methods';
import { EntitySelector, useSelector } from 'common/entity-selector';

export const accountManagerOptionsGenerator = (list = [], isWithEmptyOption) => {
    const emptyOption = { value: 'Unassigned', label: 'Unassigned' };

    const allOptions = list.map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}, ${item.position}`,
    }));

    return isWithEmptyOption ? [emptyOption, ...allOptions] : allOptions;
};

const AccountManagerSelector = ({ isWithEmptyOption, ...props }) => {
    const { options, loading } = useSelector({ url: apiMethods.agencyAccountManagers() }, (data) =>
        accountManagerOptionsGenerator(data, isWithEmptyOption)
    );

    return <EntitySelector {...props} options={options} loading={loading} />;
};

export default AccountManagerSelector;
