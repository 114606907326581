import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'htcore';
import { observer } from 'mobx-react';
import apiMethods from 'core/methods';
import $auth from 'stores/auth';
import moment from 'moment';
import { Loader, date, HotelStars, remapStatus } from 'legacy';
import { Button, Form, Input, PageHeader, InputNumber, Typography, Row, Col, Radio } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import DateRangesSelector from 'components/selectors/date-ranges-selector';
import { EntitySelector } from 'common/entity-selector';
import TypeSelector from 'components/selectors/type-selector';
import FieldWithExclude from 'components/selectors/field-with-exclude';
import {
    getInitialValues,
    getSavedValues,
    mapFieldsToPayload,
    getValidation,
} from 'legacy/excluded-form-utils';

import MarkupHistory from './markup-history';
import MarkupLocalitySelector from './selectors/markup-locality-selector';
import MarkupAccommodationSelector from './selectors/markup-accommodation-selector';

const { Title } = Typography;

const mapToViewDates = (dates) => {
    if (!dates.length) {
        return [[]];
    }

    return dates.map((item) => [moment(item.fromDate), moment(item.toDate)]);
};

const mapToPayloadDates = (dates) =>
    dates
        .filter((item) => item && item.length)
        .map(([fromDate, toDate]) => ({
            fromDate: date.format.apiDateOnly(fromDate),
            toDate: date.format.apiDateOnly(toDate),
        }));

// The fields listed in the arrays must be dependent on each other according to the "HP-5777" task specification
// Fields in arrays are arranged in a certain way: all the following fields depend on an array element.
// For example, when an element with index 2 changes, elements with indexes 3-[end of array] should be cleaned up
const AGENCY_FIELDS = [
    'agencyMarketIds',
    'agencyCountryCodes',
    'agencyLocalityHtIds',
    'agencyIds',
    'agentIds',
];

const ACCOMMODATION_FIELDS = [
    'accommodationSupplierTypes',
    'accommodationSupplierCodes',
    'accommodationMarketIds',
    'accommodationCountryCodes',
    'accommodationLocalityHtIds',
    'accommodationRatings',
    'accommodationHtIds',
];

export const FIELDS_WITH_EXCLUDE = [...AGENCY_FIELDS, ...ACCOMMODATION_FIELDS];

const initialValues = {
    ...getInitialValues(FIELDS_WITH_EXCLUDE),
    description: '',
    value: null,
    agentIds: [],
    agencyIds: [],
    agencyCountryCodes: [],
    agencyLocalityHtIds: [],
    agencyMarketIds: [],
    accommodationHtIds: [],
    accommodationSupplierTypes: [],
    accommodationSupplierCodes: [],
    accommodationRatings: [],
    accommodationLocalityHtIds: [],
    accommodationCountryCodes: [],
    accommodationMarketIds: [],
    travelDates: [[]],
    bookingDates: [[]],
    markupType: 'isMandatory',
};

const optionsGenerator = (list = []) =>
    list.map(({ code, id, name: label }) => ({ value: code || id, label }));

const getMarketOptions = (list = []) => list.filter(({ name }) => !name.includes('Unknown'));

const Markup = observer(({ updateList }) => {
    const [markup, setMarkup] = useState(null);
    const [options, setOptions] = useState({});

    const [form] = Form.useForm();
    const formValues = Form.useWatch([], form);

    const navigate = useNavigate();
    const { id } = useParams();
    const creation = id === 'create';

    const getOptions = (data) =>
        API.post({
            url: apiMethods.markupOptions,
            body: createPayload(data || formValues),
            success: setOptions,
        });

    useEffect(() => {
        if (creation) {
            getOptions(initialValues);
            return;
        }
        API.get({
            url: apiMethods.markup(id),
            success: (item) => {
                const data = {
                    ...item,
                    markupType: item.isExclusive ? 'isExclusive' : 'isMandatory',
                    travelDates: mapToViewDates(item.travelDates),
                    bookingDates: mapToViewDates(item.bookingDates),
                    ...getSavedValues(item, FIELDS_WITH_EXCLUDE),
                };

                setMarkup(data);
                getOptions(data);
            },
        });
    }, []);

    const createPayload = (body) => {
        const values = { ...body };
        if (!values.agentIds) {
            values.agentIds = [];
        }
        values.isExclusive = values.markupType === 'isExclusive';
        values.isMandatory = !values.isExclusive;

        values.travelDates = mapToPayloadDates(values.travelDates);
        values.bookingDates = mapToPayloadDates(values.bookingDates);

        return mapFieldsToPayload(FIELDS_WITH_EXCLUDE, values);
    };

    const submit = async (values) => {
        const body = createPayload(values);

        if (creation) {
            await API.post({
                url: apiMethods.markups(),
                body: {
                    ...body,
                    isActive: true,
                    value: String(body.value).replace(/,/g, '.'),
                },
                success: () => {
                    notifications('Markup Created!', 'success');
                    navigate('../');
                },
            });
        } else {
            await API.post({
                url: apiMethods.markup(id),
                body: {
                    ...body,
                    isActive: markup.isActive,
                },
                success: () => {
                    notifications('Markup Saved', 'success');
                    navigate('../');
                },
            });
        }

        updateList?.();
    };

    const remove = () => {
        API.delete({
            url: apiMethods.markup(id),
            success: () => {
                notifications('Markup Deleted!', 'success');
                navigate('../');
            },
        });
    };

    const clearFields = (changedField) => {
        const allFields = [AGENCY_FIELDS, ACCOMMODATION_FIELDS];
        const fieldsWithDependency = allFields.find((item) => item.includes(changedField));

        if (!fieldsWithDependency) {
            // case when the "Type" fields are changed.
            return clearFields(changedField.slice(0, -4));
        }
        const index = fieldsWithDependency.indexOf(changedField);

        const arrayForCleaning = fieldsWithDependency.slice(index + 1);

        const fields = arrayForCleaning.reduce((acc, item) => {
            acc[item] = [];
            return acc;
        }, {});

        form.setFieldsValue(fields);
        return fields;
    };

    const onValuesChange = (values, allValues) => {
        const fieldName = Object.keys(values || {})?.[0];

        if (FIELDS_WITH_EXCLUDE.find((item) => fieldName.includes(item))) {
            const clearedFields = clearFields(fieldName);

            getOptions({ ...allValues, ...clearedFields });
        }
    };

    if (!markup && !creation) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={
                    markup
                        ? `Markup #${id}: ${markup.description}`
                        : id === 'create'
                        ? 'Markup Creation'
                        : ' '
                }
            />
            <Form
                form={form}
                initialValues={markup || initialValues}
                layout="vertical"
                onFinish={submit}
                onValuesChange={onValuesChange}
                style={{ pointerEvents: $auth.permitted('MarkupManagement') ? 'auto' : 'none' }}
            >
                <div style={{ maxWidth: 600 }}>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter Description' }]}
                    >
                        <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                        name="value"
                        label="Markup Percent"
                        rules={[
                            { required: true, message: 'Please enter Markup' },
                            {
                                type: 'number',
                                max: 100,
                                min: -100,
                                message: 'Percent can`t be greater than 100',
                            },
                        ]}
                    >
                        <InputNumber
                            type="number"
                            placeholder="No Markup"
                            style={{ width: '220px' }}
                            addonAfter="%"
                        />
                    </Form.Item>
                    <Form.Item name="markupType">
                        <Radio.Group>
                            <Radio value="isMandatory">Mandatory Markup</Radio>
                            <Radio value="isExclusive">Exclusive Markup</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>

                <Row size="large" style={{ width: '100%', minWidth: 600 }}>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Travel Dates
                        </Title>

                        <DateRangesSelector
                            disableFirstSelectedDate
                            fieldName="travelDates"
                            getFieldValue={() => form.getFieldValue('travelDates')}
                        />
                    </Col>

                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Booking Dates
                        </Title>
                        <DateRangesSelector
                            fieldName="bookingDates"
                            getFieldValue={() => form.getFieldValue('bookingDates')}
                        />
                    </Col>
                </Row>

                <Row size="large" style={{ width: '100%', minWidth: 600 }}>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Agency
                        </Title>

                        <FieldWithExclude
                            name="agencyIds"
                            label="Agencies"
                            options={(options.agencyIds || []).map((item) => ({
                                value: item.id,
                                label: `${item.name} (${item.id})`,
                            }))}
                        />

                        <FieldWithExclude
                            name="agencyMarketIds"
                            label="Agency Market"
                            options={optionsGenerator(getMarketOptions(options.agencyMarketIds))}
                        />

                        <FieldWithExclude
                            name="agencyCountryCodes"
                            label="Agency Country"
                            options={optionsGenerator(options.agencyCountryCodes)}
                        />

                        <Row>
                            <TypeSelector name="agencyLocalityHtIds" />
                            <Form.Item
                                style={{ flex: 1 }}
                                name="agencyLocalityHtIds"
                                label="Agency Locality"
                                rules={[getValidation('agencyLocalityHtIds')]}
                            >
                                <MarkupLocalitySelector
                                    placeholder="ALL"
                                    multiple
                                    options={options}
                                    formValues={formValues}
                                />
                            </Form.Item>
                        </Row>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.agencyIds?.length !== currentValues.agencyIds?.length
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('agencyIds')?.length === 1 && (
                                    <>
                                        <Title level={4} style={{ marginTop: 30 }}>
                                            Agent
                                        </Title>
                                        <Form.Item
                                            name="agentIds"
                                            label={`Agent in Agency ${getFieldValue('agencyIds')[0]}`}
                                            key={getFieldValue('agentAgencyId')}
                                        >
                                            <EntitySelector
                                                multiple
                                                placeholder="ALL"
                                                options={optionsGenerator(options.agentIds)}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Accommodation
                        </Title>

                        <FieldWithExclude
                            name="accommodationSupplierTypes"
                            label="Supplier Type/Channel"
                            options={(options.accommodationSupplierTypes || []).map((item) => ({
                                label: item,
                                value: item,
                            }))}
                        />

                        <FieldWithExclude
                            name="accommodationSupplierCodes"
                            label="Accommodation Supplier"
                            options={optionsGenerator(options.accommodationSupplierCodes)}
                        />

                        <FieldWithExclude
                            name="accommodationMarketIds"
                            label="Accommodation Market"
                            options={optionsGenerator(getMarketOptions(options.accommodationMarketIds))}
                        />

                        <FieldWithExclude
                            name="accommodationCountryCodes"
                            label="Accommodation Country"
                            options={optionsGenerator(options.accommodationCountryCodes)}
                        />

                        <Row>
                            <TypeSelector name="accommodationLocalityHtIds" />
                            <Form.Item
                                name="accommodationLocalityHtIds"
                                label="Accommodation Locality"
                                style={{ flex: 1 }}
                                rules={[getValidation('accommodationLocalityHtIds')]}
                            >
                                <MarkupLocalitySelector
                                    isAccommodation
                                    placeholder="ALL"
                                    multiple
                                    options={options}
                                    formValues={formValues}
                                />
                            </Form.Item>
                        </Row>

                        <FieldWithExclude
                            name="accommodationRatings"
                            label="Accommodation Rating"
                            options={(options.accommodationRatings || []).map(({ rating }) => ({
                                value: rating,
                                label: HotelStars({ count: rating }) || remapStatus(rating),
                            }))}
                        />

                        <Row>
                            <TypeSelector name="accommodationHtIds" />
                            <Form.Item
                                name="accommodationHtIds"
                                label="Accommodation"
                                style={{ flex: 1 }}
                                rules={[getValidation('accommodationHtIds')]}
                            >
                                <MarkupAccommodationSelector
                                    placeholder="ALL"
                                    multiple
                                    formValues={formValues}
                                    options={options}
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>

                {$auth.permitted('MarkupManagement') && (
                    <Button htmlType="submit">{id === 'create' ? 'Create Markup' : 'Save Changes'}</Button>
                )}
            </Form>

            {id !== 'create' && Boolean(markup) && $auth.permitted('MarkupManagement') && (
                <ConfirmationPopup
                    title={
                        <div>
                            The Markup will be deleted. Don't do it if you don't understand consequences.
                        </div>
                    }
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={remove}
                >
                    <Button style={{ marginTop: 300 }}>
                        Delete Markup #{id}: {markup.description}
                    </Button>
                </ConfirmationPopup>
            )}

            {id !== 'create' && <MarkupHistory />}
        </>
    );
});

export default Markup;
