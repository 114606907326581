import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MarkupsListPage from './markups-list';
import MarkupPage from './markup';

const MarkupManagement = () => (
    <Routes>
        <Route path="/:id" element={<MarkupPage />} />
        <Route path="/" element={<MarkupsListPage />} />
    </Routes>
);

export default MarkupManagement;
