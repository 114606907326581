import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const optionsGenerator = (list) =>
    list
        .filter((item) => item.id !== 1)
        .map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
        }));

const AdminOfflineSelector = (props) => {
    const { options, loading } = useSelector({ url: apiMethods.adminsOffline }, optionsGenerator);

    return <EntitySelector placeholder="Anyone" {...props} options={options} loading={loading} />;
};

export default AdminOfflineSelector;
