import { Link } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import apiOsakaMethods from 'core/osaka-methods';
import sunpuMethods from 'core/sunpu-methods';
import { date, HotelStars, remapStatus } from 'legacy';

const getAgentValue = (value) => {
    if (!value) return null;
    return value.map((id) => `Agent #${id}`).join(', ');
};

const getType = (value) => {
    if (value === null) return null;
    return value ? 'Exclusive' : 'Mandatory';
};

const getRatingData = (value) =>
    (value || []).map((item) => HotelStars({ count: item }) || remapStatus(item));

const getDatesValue = (value) => {
    if (!value) return null;
    return value.map(({ fromDate, toDate }, index) => (
        <span key={index}>
            {date.format.c(fromDate)} / {date.format.c(toDate)} <br />
        </span>
    ));
};

const getActivity = (value) => (value !== null ? (value ? 'Yes' : 'No') : null);

const getAgencyValue = (value) => {
    if (!value) return null;
    return value.map((id, index) => (
        <Link key={index} target="_blank" className="link" to={`/agency/${id}/agents`}>
            Agency #{id}
            {index === value?.length - 1 ? ' ' : ', '}
        </Link>
    ));
};

const getIdsData = (value, savedData, idKey = 'id') => {
    if (savedData && value) {
        return value.map((id) => savedData.find((elem) => elem[idKey] === id)?.name || '').join(', ');
    }
};

const getLocalityData = async (value) => {
    let data;

    await Promise.all(
        (value || []).map((htId) =>
            API.get({
                osaka: apiOsakaMethods.predictionsRestore(htId),
            })
        )
    ).then((results) => {
        data = results.map((item) => ({ id: item.htId, name: item.predictionText }));
    });

    return getIdsData(value, data);
};

const getFieldData = async (route, value, idKey) => {
    let data;
    await API.get({
        ...route,
        success: (result) => {
            data = result;
        },
    });

    return getIdsData(value, data, idKey);
};

const getCountriesData = async (value) =>
    await getFieldData({ url: apiMethods.allCountries() }, value, 'code');

const getSupplierData = async (value) =>
    await getFieldData({ sunpu: sunpuMethods.adminSuppliers }, value, 'code');

const getMarketsData = async (value) => await getFieldData({ url: apiMethods.markets() }, value);

export const historyConfig = {
    Description: { field: 'Description', getData: (value) => value },
    Value: { field: 'Markup Percent', getData: (value) => (value !== null ? value + '%' : null) },
    AgentIds: { field: 'Agent', getData: (value) => getAgentValue(value, 'Agent') },
    AgencyIds: { field: 'Agencies', getData: getAgencyValue },
    AccommodationSupplierTypes: { field: 'Supplier Type', getData: (value) => (value || []).join(', ') },
    AgencyCountryCodes: {
        field: 'Agency Country',
        getData: getCountriesData,
    },
    AgencyLocalityHtIds: {
        field: 'Agency Locality',
        getData: getLocalityData,
    },
    AgencyMarketIds: {
        field: 'Agency Market',
        getData: getMarketsData,
    },
    AccommodationHtIds: {
        field: 'Accommodation',
        getData: getLocalityData,
    },
    AccommodationSupplierCodes: { field: 'Accommodation Supplier', getData: getSupplierData },
    AccommodationRatings: { field: 'Accommodation Rating', getData: getRatingData },
    AccommodationLocalityHtIds: {
        field: 'Accommodation Locality',
        getData: getLocalityData,
    },
    AccommodationCountryCodes: { field: 'Accommodation Country', getData: getCountriesData },
    AccommodationMarketIds: {
        field: 'Accommodation Market',
        getData: getMarketsData,
    },
    TravelDates: { field: 'Travel Dates', getData: getDatesValue },
    BookingDates: { field: 'Booking Dates', getData: getDatesValue },
    IsExclusive: { field: 'Markup Type', getData: getType },
    IsActive: { field: 'Activity', getData: getActivity },
};
