import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMapperMethods from 'core/mapper-methods';
import { remapStatus, HotelStars } from 'legacy';

const optionsGenerator = (list) =>
    Object.keys(list).map((key) => ({
        value: list[key],
        label: HotelStars({ count: list[key] }) || remapStatus(list[key]),
    }));

const RatingSelector = (props) => {
    const { options, loading } = useSelector({ nakijin: apiMapperMethods.ratingTypes }, optionsGenerator);

    return (
        <EntitySelector
            placeholder="Any Rating"
            maxTagCount={5}
            showSearch={false}
            {...props}
            options={options}
            loading={loading}
        />
    );
};

export default RatingSelector;
