import React from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Form, Input, Typography, Row, Col, Select } from 'antd';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const { Title } = Typography;
const { TextArea } = Input;

const SupplierPaymentDetailsPage = ({ isReadOnly, supplier, code, loadSupplier }) => {
    const [supplierPageForm] = Form.useForm();

    const submit = (body) => {
        API.post({
            sunpu: sunpuMethods.supplierDataPaymentDetails(code),
            body,
            success: () => {
                loadSupplier();
                notifications('Supplier Saved!', 'success');
            },
        });
    };

    if (code && !supplier) return <Loader />;

    return (
        <Form
            form={supplierPageForm}
            initialValues={supplier}
            layout="vertical"
            onFinish={submit}
            style={{ maxWidth: 1000 }}
            className={isReadOnly && 'disable-full-form'}
            disabled={isReadOnly}
        >
            <Title level={4} style={{ marginBottom: 20 }}>
                Payment
            </Title>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="paymentType" label="Payment Type">
                        <Select placeholder="Select" allowClear>
                            <Option value="Credit">Credit</Option>
                            <Option value="Prepayment">Prepayment</Option>
                            <Option value="VCC">VCC</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.paymentType !== currentValues.paymentType
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('paymentType') === 'Prepayment' ? (
                        <Row size="large">
                            <Col span={12} style={{ paddingRight: 30 }}>
                                <Form.Item name="paymentOption" label="Prepayment Option">
                                    <Select placeholder="Select" allowClear>
                                        <Option value="BankTransfer">Bank Transfer</Option>
                                        <Option value="CreditCard">Credit Card</Option>
                                        <Option value="BankTransferAndCreditCard">
                                            Bank Transfer And Credit Card
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null
                }
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.paymentType !== currentValues.paymentType
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('paymentType') === 'VCC' ? (
                        <Row size="large">
                            <Col span={12} style={{ paddingRight: 30 }}>
                                <Form.Item name="preferenceCards" label="Preference Card">
                                    <Select placeholder="Select" mode="multiple" allowClear>
                                        <Option value="AmericanExpress">American Express</Option>
                                        <Option value="Visa">Visa</Option>
                                        <Option value="MasterCard">MasterCard</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="vccActivationTerms" label="VCC Activation Terms">
                                    <Input placeholder="Enter" />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null
                }
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.paymentType !== currentValues.paymentType
                }
            >
                {({ getFieldValue }) =>
                    getFieldValue('paymentType') === 'Credit' ? (
                        <>
                            <Form.Item name="bankDetails" label="Bank Details">
                                <TextArea placeholder="Enter" />
                            </Form.Item>
                            <Row size="large">
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item name="creditLimit" label="Credit Limit">
                                        <Input placeholder="Enter" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="creditPeriod" label="Credit Period">
                                <Input placeholder="Enter" />
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            <Form.Item name="paymentRemarks" label="Remarks">
                <TextArea placeholder="Enter" />
            </Form.Item>

            {$auth.permitted('SupplierManagement') && (
                <Button htmlType="submit" type="primary">
                    Save Changes
                </Button>
            )}
        </Form>
    );
};

export default SupplierPaymentDetailsPage;
