import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { API } from 'htcore';
import apiShimabaraMethods from 'core/shimabara-methods';
import { notifications } from 'components/notification';

const MODAL_TYPES = {
    DEFAULT: '',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
};

const AccommodationActions = ({ htId, isActive, reload }) => {
    const [modalType, setModalType] = useState(MODAL_TYPES.DEFAULT);
    const [deactivationForm] = Form.useForm();

    const activate = ({ reason }) => {
        API.post({
            shimabara: apiShimabaraMethods.activateAccommodation(htId, reason),
            success: () => {
                notifications('Accommodation Activated!', 'success');
                reload();
            },
        });
    };

    const deactivate = ({ reason }) => {
        API.post({
            shimabara: apiShimabaraMethods.deactivateAccommodation(htId, reason),
            success: () => {
                notifications('Accommodation Deactivated!', 'success');
                reload();
            },
        });
    };

    const submitForm = () => {
        deactivationForm.validateFields().then((values) => {
            modalType === MODAL_TYPES.ACTIVATE ? activate(values) : deactivate(values);
            setModalType(MODAL_TYPES.DEFAULT);
        });
    };

    return (
        <div style={{ marginBottom: 30, display: 'flex' }}>
            {isActive ? (
                <Button size="small" onClick={() => setModalType(MODAL_TYPES.DEACTIVATE)}>
                    Deactivate
                </Button>
            ) : (
                <Button size="small" onClick={() => setModalType(MODAL_TYPES.ACTIVATE)}>
                    Activate
                </Button>
            )}

            <Modal
                title={
                    modalType === MODAL_TYPES.ACTIVATE
                        ? 'Accommodation will be activated'
                        : 'Accommodation will be deactivated'
                }
                centered
                okText="Confirm"
                cancelText="Discard"
                visible={!!modalType}
                onOk={submitForm}
                onCancel={() => setModalType(MODAL_TYPES.DEFAULT)}
            >
                <Form form={deactivationForm} layout="vertical">
                    <Form.Item
                        name="reason"
                        label="Reason"
                        rules={[{ required: true, message: 'Mandatory' }]}
                    >
                        <Input placeholder="Reason" allowClear />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AccommodationActions;
