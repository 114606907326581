import React from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Checkbox, Form, Input, PageHeader, Select } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import CurrencySelector from 'components/selectors/currency-selector';
import { useNavigate, useParams } from 'react-router-dom';

const { Option } = Select;

const BankAccountDetailsPage = ({ accounts, bankId, reloadAccounts }) => {
    const { accountId } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const account = accounts?.find((item) => parseInt(item.id) === parseInt(accountId));

    const submit = (values) => {
        if (account) {
            API.put({
                url: apiMethods.companyBankAccount(bankId, accountId),
                body: values,
                success: () => {
                    notifications('Bank Account Saved', 'success');
                    reloadAccounts();
                },
            });
            if (values.isDefault && !account.isDefault) {
                API.post({
                    url: apiMethods.companyBankAccountSetDefault(bankId, accountId),
                });
            }
        } else {
            API.post({
                url: apiMethods.companyBankAccounts(bankId),
                body: values,
                success: () => {
                    notifications('Bank Account Created!', 'success');
                    reloadAccounts();
                },
            });
        }
        navigate('./../');
    };

    const remove = () => {
        API.delete({
            url: apiMethods.companyBankAccount(bankId, accountId),
            success: () => {
                notifications('Bank Account Deleted!', 'success');
                reloadAccounts();
            },
        });
        navigate('./../');
    };

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={account ? `Account #${accountId}` : accountId === 'create' ? 'Account Creation' : ' '}
            />
            <Form
                form={form}
                initialValues={account}
                layout="vertical"
                onFinish={submit}
                style={{ maxWidth: 500 }}
            >
                {accountId !== 'create' && (
                    <Form.Item name="isDefault" valuePropName="checked">
                        <Checkbox>Use as Default</Checkbox>
                    </Form.Item>
                )}
                <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please select Currency' }]}
                >
                    <CurrencySelector disabled={accountId !== 'create'} />
                </Form.Item>
                <Form.Item name="accountNumber" label="Account Number">
                    <Input placeholder="Account Number" />
                </Form.Item>
                <Form.Item name="iban" label="IBAN">
                    <Input placeholder="International Bank Account Number" />
                </Form.Item>
                <Form.Item name={['intermediaryBank', 'bankName']} label="Intermediary Bank Name">
                    <Input placeholder="Intermediary Bank Name" />
                </Form.Item>
                <Form.Item
                    name={['intermediaryBank', 'accountNumber']}
                    label="Intermediary Bank Account Number"
                >
                    <Input placeholder="Intermediary Bank Account Number" />
                </Form.Item>
                <Form.Item name={['intermediaryBank', 'swiftCode']} label="Intermediary Bank SWIFT Code">
                    <Input placeholder="Intermediary Bank SWIFT Code" />
                </Form.Item>
                <Form.Item name={['intermediaryBank', 'abaNo']} label="Intermediary Bank ABA No.">
                    <Input placeholder="Intermediary Bank ABA No." />
                </Form.Item>
                <Form.Item name={['intermediaryBank', 'sortCode']} label="Intermediary Bank Sort Code">
                    <Input placeholder="Intermediary Bank Sort Code" />
                </Form.Item>
                <Form.Item name="additionalInformation" label="Additional Information">
                    <Input placeholder="Additional Information" />
                </Form.Item>
                <Button htmlType="submit">
                    {accountId === 'create' ? 'Create a Bank Account' : 'Save Changes'}
                </Button>
            </Form>
            {accountId !== 'create' && Boolean(account) && (
                <ConfirmationPopup
                    title={
                        <div>
                            The Bank Account will be deleted. Don't do it if you don't understand
                            consequences.
                        </div>
                    }
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={remove}
                >
                    <Button style={{ marginTop: 140 }}>
                        Delete Bank Account #{accountId}: {account.currency}
                    </Button>
                </ConfirmationPopup>
            )}
        </>
    );
};

export default BankAccountDetailsPage;
