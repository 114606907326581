import React from 'react';
import Table from 'components/table';
import { Link } from 'react-router-dom';
import $auth from 'stores/auth';

const AdministratorsList = ({ list }) => {
    return (
        <div className="block" style={{ paddingRight: 20 }}>
            <div className="table-wrapper">
                <Table
                    list={list}
                    columns={[
                        {
                            header: 'ID',
                            cell: (cell) => (
                                <>
                                    <Link to={`/administrators/${cell.id}/details`}>{cell.id}</Link>
                                </>
                            ),
                        },
                        {
                            header: 'First Name',
                            cell: (cell) => (
                                <>
                                    <Link to={`/administrators/${cell.id}/details`}>{cell.firstName}</Link>
                                </>
                            ),
                        },
                        {
                            header: 'Last Name',
                            cell: (cell) => (
                                <>
                                    <Link to={`/administrators/${cell.id}/details`}>{cell.lastName}</Link>
                                </>
                            ),
                        },
                        {
                            header: 'Position',
                            cell: 'position',
                        },
                        {
                            header: 'Roles',
                            cell: (cell) => (
                                <div>
                                    {cell.administratorRoleIds.map((role) => (
                                        <div key={role}>
                                            {$auth.rolesCompleteList.find((item) => item.id === role)?.name}
                                        </div>
                                    ))}
                                </div>
                            ),
                        },
                        {
                            header: 'State',
                            cell: (cell) => (cell.isActive ? 'Active' : 'Inactive'),
                        },
                    ]}
                    textEmptyResult="No administrators found"
                    textEmptyList="You don`t have access to view this list"
                    searches={(v) => [
                        String(v.id),
                        v.firstName,
                        v.lastName,
                        v.position,
                        v.isActive ? 'Active' : 'Inactive',
                        v.administratorRoleIds
                            .map((role) => $auth.rolesCompleteList.find((item) => item.id === role)?.name)
                            .join(' '),
                    ]}
                />
            </div>
        </div>
    );
};

export default AdministratorsList;
