import React, { useState } from 'react';
import { Button, Form, Input, Row, Select, Space, PageHeader, InputNumber } from 'antd';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { copyToClipboard } from 'legacy';
import PaymentProcessorSelector from 'components/selectors/payment-processor-selector';
import { notifications } from 'components/notification';

const { Option } = Select;
const { TextArea } = Input;

const CreatePaymentLinkPage = () => {
    useTitle('Payment Link');

    const [result, setResult] = useState(null);
    const [isSendByEmail, setIsSendByEmail] = useState(false);

    const [createPaymentLinkForm] = Form.useForm();

    const submit = (values) => {
        API.post({
            url: isSendByEmail ? apiMethods.createPaymentLinkAndSend : apiMethods.createPaymentLink,
            body: {
                ...values,
                amount: String(values.amount).replace(/,/g, '.'),
            },
            success: (result) => {
                setResult(result || true);
                window.scrollTo(0, 0);
                notifications(isSendByEmail ? 'Email was sent' : 'Link was generated', 'success');
            },
        });
    };

    if (result === true) {
        return (
            <>
                <PageHeader title="Successfully sent" />
                <Button
                    onClick={() => {
                        setResult(null);
                        createPaymentLinkForm.resetFields();
                    }}
                >
                    Create one more payment link
                </Button>
            </>
        );
    }

    if (result) {
        return (
            <>
                <PageHeader title="Payment link generated" />
                <Space direction="vertical">
                    <Input value={result} style={{ minWidth: '500px' }} />
                    <Space>
                        <Button onClick={() => copyToClipboard(result)}>Copy to Clipboard</Button>
                        <Button onClick={() => setResult(null)}>Create one more payment link</Button>
                    </Space>
                </Space>
            </>
        );
    }

    return (
        <>
            <PageHeader title="Create a payment link" />
            <Form form={createPaymentLinkForm} layout="vertical" onFinish={submit} style={{ maxWidth: 500 }}>
                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[{ required: true, message: 'Please enter Amount' }]}
                >
                    <InputNumber
                        type="number"
                        placeholder="Please Enter Amount"
                        style={{ width: '100%' }}
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please select Currency' }]}
                >
                    <Select placeholder="Please Choose Currency">
                        <Option value="USD">USD</Option>
                        <Option value="AED">AED</Option>
                        <Option value="SAR">SAR</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="EUR">EUR</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Please enter Email' },
                        { type: 'email', message: 'Please enter a valid email address.' },
                    ]}
                >
                    <Input placeholder="Please Enter Email" />
                </Form.Item>
                <Form.Item
                    name="serviceType"
                    label="Service Type"
                    rules={[{ required: true, message: 'Please select Service Type' }]}
                >
                    <Select placeholder="Please Choose the Type of a Service">
                        <Option value="HTL">Hotel Booking (HTL)</Option>
                        <Option value="TRN">Transfer (TRN)</Option>
                        <Option value="CMS">Custom Service (CMS)</Option>
                        {(window.location.hostname === 'localhost' ||
                            window.location.hostname === 'manage-dev.happytravel.com') && (
                            <Option value="OB">Offline Booking (OB) (Hidden on PROD)</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="invoiceNumber"
                    label="Invoice Number"
                    rules={[
                        { required: true, message: 'Please enter Invoice Number' },
                        { whitespace: true, message: "Can't be empty" },
                    ]}
                >
                    <Input placeholder="Please Specify Invoice Number" />
                </Form.Item>
                <Form.Item
                    name="comment"
                    label="Commentary"
                    rules={[
                        { required: true, message: 'Please enter Commentary' },
                        { whitespace: true, message: "Can't be empty" },
                    ]}
                >
                    <TextArea placeholder="Please Enter Commentary" />
                </Form.Item>
                <Form.Item
                    name="paymentProcessor"
                    label="Payment Processor Preset"
                    rules={[{ required: true, message: 'Please select Payment Processor' }]}
                >
                    <PaymentProcessorSelector placeholder="Payment Processor Preset" />
                </Form.Item>
                <Row justify="space-between">
                    <Button type="secondary" htmlType="submit" onClick={() => setIsSendByEmail(false)}>
                        Generate
                    </Button>
                    <Button type="secondary" htmlType="submit" onClick={() => setIsSendByEmail(true)}>
                        Send by Email
                    </Button>
                </Row>
            </Form>
        </>
    );
};

export default CreatePaymentLinkPage;
