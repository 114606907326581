import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import $auth from 'stores/auth';
import { Menu, PageHeader } from 'antd';
import AccommodationSearchPage from './search/accommodation-search';
import AccommodationPageWrap from './details/accommodation-wrap';
import RestrictionsPage from './restrictions/restrictions';

const allMenuItems = [
    {
        label: <Link to="./">Accommodations</Link>,
        key: '',
    },
    {
        label: <Link to="./restrictions/markets">Market/Country Restrictions</Link>,
        key: 'restrictions',
        permissions: ['AccommodationRestrictionManagement'],
    },
];

const MapperIndexPage = ({ accommodationSearchForm }) => {
    const location = useLocation();

    const menuItems = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    return (
        <>
            <PageHeader title="Accommodations" style={{ paddingBottom: 16 }} />
            <Menu mode="horizontal" items={menuItems} selectedKeys={location.pathname.split('/')[2] || ''} />
            <Routes>
                <Route
                    path="/"
                    element={<AccommodationSearchPage accommodationSearchForm={accommodationSearchForm} />}
                />
                <Route path="/accommodation/:htId/*" element={<AccommodationPageWrap />} />
                <Route path="/restrictions/*" element={<RestrictionsPage />} />
            </Routes>
        </>
    );
};

export default MapperIndexPage;
