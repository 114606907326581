import React, { useEffect, useState } from 'react';
import { RequestSelector } from 'common/request-selector';
import apiOsakaMethods from 'core/osaka-methods';
import apiShimabaraMethods from 'core/shimabara-methods';
import { API } from 'htcore';

const optionsGenerator = (list = []) =>
    list.map((value) => ({
        value: value.htId,
        label: value.name,
    }));

const defaultRequestBody = {
    countryCodes: [],
    excludedCountryCodes: [],
    supplierCodes: [],
    excludedSupplierCodes: [],
};

const AgencyLocalitySelector = ({ countryId, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(null);
    const [requestBody, setRequestBody] = useState(defaultRequestBody);

    useEffect(() => {
        if (props.value) {
            API.get({
                osaka: apiOsakaMethods.predictionsRestore(props.value),
                success: ({ predictionText, htId }) =>
                    setDefaultOptions([{ value: htId, label: predictionText }]),
            });
        }
    }, []);

    useEffect(() => {
        setRequestBody({
            ...defaultRequestBody,
            countryCodes: [countryId],
        });
    }, [countryId]);

    return (
        <RequestSelector
            clearCache
            requestMethod="post"
            request={(value) => ({
                shimabara: apiShimabaraMethods.localities(value),
                body: requestBody,
            })}
            defaultOptions={defaultOptions}
            optionsGenerator={optionsGenerator}
            placeholder="Locality"
            {...props}
        />
    );
};

export default AgencyLocalitySelector;
