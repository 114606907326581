import React from 'react';
import { API } from 'htcore';
import { useNavigate, useParams } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Button, Form, Input, PageHeader } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import { Loader } from 'legacy';

const { TextArea } = Input;

const AdministratorRolesPage = ({ roles, reloadRoles }) => {
    const [administratorRolesPageForm] = Form.useForm();
    const navigate = useNavigate();

    const { id } = useParams();
    const role = roles?.find((role) => parseInt(role.id) === parseInt(id));

    const submit = (values) => {
        let body = {
            ...(id === 'create' ? {} : { id: role.id }),
            name: values.name,
            permissions: values.permissions
                ? values.permissions.replace(/( |\r\n|\n|\r)/gm, '').split(',')
                : [],
            notificationTypes: values.notificationTypes
                ? values.notificationTypes.replace(/( |\r\n|\n|\r)/gm, '').split(',')
                : [],
        };
        if (role) {
            API.put({
                url: apiMethods.administratorRole(role.id),
                body,
                success: () => {
                    notifications('Role Saved', 'success');
                    reloadRoles();
                },
            });
        } else {
            API.post({
                url: apiMethods.administratorRoles,
                body,
                success: () => {
                    notifications('Role Created!', 'success');
                    reloadRoles();
                },
            });
        }
        navigate('../');
    };

    const remove = () => {
        API.delete({
            url: apiMethods.administratorRole(role.id),
            success: () => {
                notifications('Role Deleted!', 'success');
                reloadRoles();
            },
        });
        navigate('../');
    };

    if (!role && id !== 'create') {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={role ? `Role #${role.id}: ${role.name}` : id === 'create' ? 'Role Creation' : ' '}
            />
            <Form
                form={administratorRolesPageForm}
                initialValues={
                    role
                        ? {
                              name: role.name,
                              permissions: role.permissions.join(', '),
                              notificationTypes: role.notificationTypes.join(', '),
                          }
                        : {}
                }
                layout="vertical"
                onFinish={submit}
                style={{ maxWidth: 500 }}
            >
                <Form.Item name="name" label="Role Name">
                    <Input placeholder="Role Name" />
                </Form.Item>
                <Form.Item name="permissions" label="Permissions (separated by comma)">
                    <TextArea placeholder="PermissionsOne, PermissionTwo, etc" style={{ minHeight: 160 }} />
                </Form.Item>
                <Form.Item name="notificationTypes" label="Notification Types (separated by comma)">
                    <TextArea
                        placeholder="NotificationTypeOne, NotificationTypeTwo, etc"
                        style={{ minHeight: 160 }}
                    />
                </Form.Item>
                <Button htmlType="submit">{id === 'create' ? 'Create a Role' : 'Save Changes'}</Button>
            </Form>
            {id !== 'create' && Boolean(role) && (
                <ConfirmationPopup
                    title={
                        <div>The Role will be deleted. Don't do it if you don't understand consequences.</div>
                    }
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={remove}
                >
                    <Button style={{ marginTop: 70 }}>
                        Delete Role #{role.id}: {role.name}
                    </Button>
                </ConfirmationPopup>
            )}
        </>
    );
};

export default AdministratorRolesPage;
