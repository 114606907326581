import React from 'react';
import { Button, Form, InputNumber, Space, Switch, Typography } from 'antd';
import { API } from 'htcore';
import { notifications } from '../notification';
import { Loader } from 'legacy';
import APRSelector from './apr-selector';
import DeadlineShift from './availability-search-settings-deadline-shift';

const { Title } = Typography;

const AvailabilitySearchSettings = ({ agency, method, agent, systemSettings, setSystemSettings }) => {
    const [form] = Form.useForm();

    const submit = (values) => {
        const body = {
            ...systemSettings,
            ...values,
            isApiClient: Boolean(values.isApiClient),
            ...(agency.contractKind === 'OfflineOrCreditCardPayments' ? {} : { customDeadlineShift: null }),
        };

        API.put({
            url: method,
            body,
            success: () => {
                notifications('Saved', 'success');
                setSystemSettings(body);
            },
        });
    };

    if (!systemSettings) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ marginBottom: 20 }}>
                Searching and Booking
            </Title>
            <Form
                form={form}
                initialValues={systemSettings}
                onFinish={submit}
                colon={false}
                layout="inline"
                style={{ marginBottom: 50 }}
            >
                <Space direction="vertical">
                    {agent && (
                        <Form.Item
                            name="rateLimit"
                            label="Max Search Query RPS"
                            rules={[
                                { required: true, message: 'Please enter Limit' },
                                { type: 'number', min: 10, message: 'Minimum 10' },
                                { type: 'number', max: 150, message: 'Maximum 150' },
                            ]}
                        >
                            <InputNumber min={1} style={{ marginLeft: 10, width: 120 }} />
                        </Form.Item>
                    )}
                    {agent && (
                        <Form.Item name="isApiClient" label="API Client" valuePropName="checked">
                            <Switch style={{ marginLeft: 10 }} />
                        </Form.Item>
                    )}
                    <Form.Item name="isSupplierVisible" label="Supplier Visible" valuePropName="checked">
                        <Switch style={{ marginLeft: 10 }} />
                    </Form.Item>
                    <Form.Item
                        name="isDirectContractFlagVisible"
                        label="Direct Contract Mark"
                        valuePropName="checked"
                    >
                        <Switch style={{ marginLeft: 10 }} />
                    </Form.Item>
                    {agent && (
                        <Form.Item name="isRoomGroupingEnabled" label="Room Mapping" valuePropName="checked">
                            <Switch style={{ marginLeft: 10 }} />
                        </Form.Item>
                    )}

                    {!agent && (
                        <Form.Item
                            name="isBookingAutoCancellationDisabled"
                            label="No Auto-Cancellation"
                            valuePropName="checked"
                        >
                            <Switch style={{ marginLeft: 10 }} />
                        </Form.Item>
                    )}
                    {!agent && (
                        <Form.Item
                            name="canModifyBookingSupplierReferenceCode"
                            label="Can Modify Booking Supplier Reference Code"
                            valuePropName="checked"
                        >
                            <Switch style={{ marginLeft: 10 }} />
                        </Form.Item>
                    )}
                    {!agent && (
                        <Form.Item
                            name="acceptLastMinuteBookings"
                            label="Accept Last Minute Bookings"
                            valuePropName="checked"
                        >
                            <Switch style={{ marginLeft: 10 }} />
                        </Form.Item>
                    )}
                    {!agent && agency.contractKind === 'OfflineOrCreditCardPayments' && <DeadlineShift />}
                    <Space direction="vertical" size="large">
                        <Space direction="vertical" size="small">
                            Non-Refundable Rate
                            <Form.Item name="nonRefundableMode">
                                <APRSelector agency={agency} />
                            </Form.Item>
                        </Space>
                        <Space direction="vertical" size="small">
                            Passed Deadline Offers Mode
                            <Form.Item name="passedDeadlineOffersMode">
                                <APRSelector agency={agency} />
                            </Form.Item>
                        </Space>
                        <Button type="primary" htmlType="submit">
                            Save Availability Search Settings
                        </Button>
                    </Space>
                </Space>
            </Form>
        </>
    );
};

export default AvailabilitySearchSettings;
