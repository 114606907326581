import React from 'react';
import moment from 'moment';
import { date } from 'legacy';
import { API } from 'htcore';
import { Button, DatePicker, Form, Space, message } from 'antd';
import { notifications } from 'components/notification';
import { EntitySelector } from 'common/entity-selector';
import { DocumentDownload } from 'iconsax-react';

let initialDateValues;

const filterTypeOptions = [
    { label: 'Travel Date', value: '0' },
    { label: 'Booking Date', value: '1' },
    { label: 'Customer Cancellation Deadline', value: '2' },
    { label: 'Supplier Cancellation Deadline', value: '3' },
    { label: 'Booking Cancellation Date', value: '4' },
];

const { RangePicker } = DatePicker;

const ReportCard = ({ report }) => {
    const [reportCardForm] = Form.useForm();

    if (report.excludeToday) {
        initialDateValues = {
            start: date.addMonth(new Date(), -1),
            end: date.addDay(new Date(), -1),
        };
    } else {
        initialDateValues = {
            start: date.addMonth(new Date(), -1),
            end: new Date(),
        };
    }

    const reportResponse = (res, values, hideMessage) => {
        if (res.status !== 200) {
            hideMessage();
            notifications("Couldn't download a report", 'warning');
        } else {
            let filename;
            if (res.headers.has('content-disposition')) {
                try {
                    filename = res.headers.get('content-disposition').split('filename=')[1].split(';')[0];
                } catch (e) {}
            } else if (values.date) {
                filename = `report${values.date[0]._d.toISOString()}${values.date[1]._d.toISOString()}.csv`;
            }
            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = filename;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success(report.name + ' Downloaded');
            });
        }
    };

    const createBody = (values) => {
        if (report.noDates) return {};

        if (report.extendedFilters) {
            return {
                from: date.format.apiDateOnly(values.date[0]),
                to: date.format.apiDateOnly(values.date[1]),
                filterType: values.filterType,
            };
        }

        return {
            from: date.format.apiDateOnly(values.date[0]),
            ...(report.customEndpointParameters
                ? {
                      to: date.format.apiDateOnly(values.date[1]),
                  }
                : {
                      end: date.format.apiDateOnly(values.date[1]),
                  }),
        };
    };

    const downloadReport = (values) => {
        const hideMessage = message.loading(report.name + ' Downloading...', 0);
        API.get({
            url: report.method,
            body: createBody(values),
            response: (res) => {
                reportResponse(res, values, hideMessage);
            },
        });
    };

    const handleDisabledDate = (current) => {
        if (report.future) {
            return current && current < moment().add(1, 'day');
        }
        if (report.past) {
            return current && current > moment().add(1, 'day');
        }
    };

    return (
        <Form
            layout="vertical"
            form={reportCardForm}
            initialValues={initialDateValues}
            onFinish={downloadReport}
        >
            <Space direction="vertical">
                <p>{report.description}</p>
                <Space direction={report.extendedFilters ? 'vertical' : 'horizontal'}>
                    {report.extendedFilters && (
                        <Form.Item name="filterType" label="Filter type" rules={[{ required: true }]}>
                            <EntitySelector placeholder="Any Filter Type" options={filterTypeOptions} />
                        </Form.Item>
                    )}

                    {!report.noDates && (
                        <Form.Item
                            name="date"
                            rules={[{ required: true }]}
                            initialValue={[moment(initialDateValues.start), moment(initialDateValues.end)]}
                        >
                            <RangePicker
                                format={date.format.c}
                                disabledDate={(current) => handleDisabledDate(current)}
                            />
                        </Form.Item>
                    )}
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            icon={<DocumentDownload style={{ verticalAlign: 'bottom', marginRight: 12 }} />}
                        >
                            Download
                        </Button>
                    </Form.Item>
                </Space>
            </Space>
        </Form>
    );
};

export default ReportCard;
