import React from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import sunpuMethods from 'core/sunpu-methods';
import { Button, Form, Input, Typography, Row, Col, Select } from 'antd';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';
import CurrencyNoRequestSelector from 'components/selectors/currency-norequest-selector';
import AgencySelector from 'components/selectors/agency-selector';
import SupplierFiles from './components/supplier-files';
import SupplierControlButtons from './components/supplier-control-buttons';
import CountrySelector from '../../../components/selectors/country-selector';

const { Title } = Typography;
const { TextArea } = Input;

const SupplierDetailsPage = ({ isReadOnly, supplier, code, loadSupplier }) => {
    const [supplierPageForm] = Form.useForm();

    const submit = (values) => {
        if (values.activeAgencyId) {
            values.activeAgencyId = String(values.activeAgencyId);
        }

        let body = {
            ...supplier,
            ...values,
        };

        API.post({
            sunpu: sunpuMethods.supplierDataSupplierDetails(code),
            body,
            success: () => {
                notifications('Supplier Saved!', 'success');
            },
        });
    };

    if (code && !supplier) return <Loader />;

    return (
        <Form
            form={supplierPageForm}
            initialValues={{
                ...supplier,
                ...supplier.accountManagerDetails,
            }}
            layout="vertical"
            onFinish={submit}
            style={{ maxWidth: 1000 }}
            className={isReadOnly && 'disable-full-form'}
            disabled={isReadOnly}
        >
            <SupplierControlButtons loadSupplier={loadSupplier} code={code} supplier={supplier} />
            <Title level={4} style={{ marginBottom: 20 }}>
                Supplier Details
            </Title>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="status" label="Status">
                        <Select placeholder="Select">
                            <Option value="Enabled">Active</Option>
                            <Option value="Disabled">Inactive</Option>
                            <Option value="TestOnly">Active for Test Only</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="supplierId" label="Supplier ID">
                        <Input placeholder="Supplier ID" disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="supplierName"
                        label="Supplier Name"
                        rules={[{ required: true, message: 'Please enter Supplier Name' }]}
                    >
                        <Input placeholder="Supplier Name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="companyName" label="Company Name">
                        <Input placeholder="Supplier Name" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="contractType"
                        label="Contract Type"
                        rules={[{ required: true, message: 'Please enter Contract Type' }]}
                    >
                        <Select placeholder="Select">
                            <Option value="NotSpecified">Not Specified</Option>
                            <Option value="OnlineAndOffline">Online / Offline</Option>
                            <Option value="Offline">Offline</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="currency"
                        label="Supplier Currency"
                        rules={[{ required: true, message: 'Please Select Currency' }]}
                    >
                        <CurrencyNoRequestSelector />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="channelType"
                        label="Channel Type"
                        rules={[{ required: true, message: 'Please Select' }]}
                    >
                        <Select placeholder="Select">
                            <Option value="NotApplicable">N/A</Option>
                            <Option value="SWITCH">SWITCH</Option>
                            <Option value="DirectConnect">Direct Connect</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.contractType !== currentValues.contractType
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('contractType') !== 'Offline' ? (
                                <Form.Item
                                    name="supplierType"
                                    label="Supplier Type / Channel"
                                    rules={[{ required: true, message: 'Please Select' }]}
                                >
                                    <Select placeholder="Select Supplier Type">
                                        <Option value="StaticContract">Static Contract</Option>
                                        <Option value="DirectConnectivity">Direct Connectivity</Option>
                                        <Option value="ChannelManager">Channel Manager</Option>
                                        <Option value="Party">3rd Party</Option>
                                    </Select>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
                <Col span={5} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="isCrossSell"
                        label="Cross-Sell"
                        rules={[{ required: true, message: 'Please Select' }]}
                        style={{ width: 150 }}
                    >
                        <Select placeholder="Select">
                            <Option value={false}>No</Option>
                            <Option value={true}>Yes</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.isCrossSell !== currentValues.isCrossSell
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('isCrossSell') ? (
                                <Form.Item name="activeAgencyId" label="Active Agency Id">
                                    <AgencySelector placeholder="Select" onlyActiveAgencies />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="country" label="Country">
                        <CountrySelector idName="countryName" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="city" label="City">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="address" label="Address">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="additionalSupplierDetailsPhone" label="Phone">
                        <Input placeholder="+XX (XXX) XXX XX XX" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="additionalSupplierDetailsEmail" label="Email">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="companyWebSite" label="Company Website">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>

            <Title level={4} style={{ marginBottom: 20 }}>
                Account Manager Contact
            </Title>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="contactName" label="Contact Name">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="accountManagerDetailsDesignation" label="Designation">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="accountManagerDetailsPhone" label="Phone">
                        <Input placeholder="+XX (XXX) XXX XX XX" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="accountManagerDetailsEmailId" label="Email Id">
                        <Input placeholder="Enter" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="supplierDescription" label="Supplier Description">
                <TextArea placeholder="Enter" />
            </Form.Item>

            <SupplierFiles code={code} supplier={supplier} loadSupplier={loadSupplier} />

            {$auth.permitted('SupplierManagement') && (
                <Button htmlType="submit" type="primary">
                    Save Changes
                </Button>
            )}
        </Form>
    );
};

export default SupplierDetailsPage;
