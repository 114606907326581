import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Loader } from 'legacy';
import { generateLabel } from 'legacy/generate-label';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';

const { Title } = Typography;

const AgentChangeAgency = observer(() => {
    const [allAgentRolesList, setAgentRoles] = useState(null);
    const [changeAgencyModalVisible, setChangeAgencyModalVisible] = useState(false);
    const [agentChangeAgencyValues, setAgentChangeAgencyValues] = useState({});
    const { id, agentId } = useParams();

    const [agentChangeAgencyForm] = Form.useForm();

    useEffect(() => {
        API.get({
            url: apiMethods.allAgentRolesList,
            success: (roles) => {
                setAgentRoles(roles);
            },
        });
    }, []);

    const openChangeAgencyModal = (values) => {
        setChangeAgencyModalVisible(true);
        setAgentChangeAgencyValues(values);
    };

    const changeAgency = (values) => {
        API.post({
            url: apiMethods.agentChangeAgency(id, agentId),
            body: {
                targetAgency: Number(values.newAgencyId),
                roleIds: Object.keys(values.roleIds)
                    .map((key) => (values.roleIds[key] ? parseInt(key) : false))
                    .filter((item) => item),
            },
            success: () => {
                notifications('Changed', 'success');
                setChangeAgencyModalVisible(false);
            },
        });
    };

    if (!allAgentRolesList) {
        return <Loader />;
    }

    return (
        <>
            <Form
                form={agentChangeAgencyForm}
                onFinish={openChangeAgencyModal}
                layout="vertical"
                style={{ maxWidth: 500 }}
            >
                <Form.Item name="newAgencyId" label="New Agency ID">
                    <Input placeholder="New Agency ID" />
                </Form.Item>
                <Title level={5}>Roles in New Agency</Title>
                {allAgentRolesList.map((role) => (
                    <>
                        <Form.Item name={['roleIds', role.id]} valuePropName="checked">
                            <Checkbox defaultChecked={false}>{role.name}</Checkbox>
                        </Form.Item>
                        <div style={{ margin: '-25px 0 15px' }}>
                            {role.permissions.map((permission) => generateLabel(permission)).join()}
                        </div>
                    </>
                ))}
                <Button htmlType="submit" style={{ marginTop: 40 }}>
                    Change Agency
                </Button>
            </Form>
            <ConfirmationSimpleModal
                visible={changeAgencyModalVisible}
                onCancel={() => setChangeAgencyModalVisible(false)}
                headerText="Are you sure?"
                submitText="Change"
                onConfirm={() => changeAgency(agentChangeAgencyValues)}
            />
        </>
    );
});

export default AgentChangeAgency;
