import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Space } from 'antd';
import SupplierSettings from 'components/supplier-settings';
import { useParams } from 'react-router-dom';
import AvailabilitySearchSettings from 'components/selectors/availability-search-settings';

const AgentSettings = ({ agency }) => {
    const { id, agentId } = useParams();

    const [systemSettings, setSystemSettings] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.agentSystemSettings(id, agentId),
            success: (data) => {
                setSystemSettings(data);
            },
        });
    }, []);

    return (
        <Space direction="vertical" size="large">
            <AvailabilitySearchSettings
                method={apiMethods.agentSystemSettings(id, agentId)}
                agency={agency}
                agent
                systemSettings={systemSettings}
                setSystemSettings={setSystemSettings}
            />
            <SupplierSettings
                method={apiMethods.agentSuppliers(id, agentId)}
                agencyMethod={apiMethods.agencySupplierSettings(id, agentId)}
            />
        </Space>
    );
};

export default AgentSettings;
