import React from 'react';
import { date } from 'legacy';
import { Button } from 'antd';

const CONVERSION_TYPES = {
    FixedPercent: '%',
    FixedConversionRate: 'Amount',
};

const createString = (string, preposition) => {
    const formattedString = string || string === 0 ? string.toString().trim() : '';

    return formattedString ? `${preposition} ${string}` : '';
};

const getConversion = (conversion) => (conversion === 'FixedPercent' ? '%' : '');

const DESCRIPTION = {
    Create: (cell) => `Created with ${cell.amount}${getConversion(cell.conversionType)}`,
    Modify: (cell) =>
        `Modified ${createString(cell.oldAmount, 'from') + getConversion(cell.oldConversionType)} ${
            createString(cell.amount, 'to') + getConversion(cell.conversionType)
        }`,
    Delete: () => 'Deleted',
};

export const columns = (openEditModal) => [
    {
        header: 'From',
        cell: (cell) => cell.source,
    },
    {
        header: 'To',
        cell: (cell) => cell.target,
    },
    {
        header: 'Conversion Type',
        cell: (cell) => CONVERSION_TYPES?.[cell.conversionType],
    },
    {
        header: 'Amount',
        cell: (cell) => cell.amount,
    },
    {
        header: 'Action',
        cell: (cell) => cell.eventType,
    },
    {
        header: 'Date',
        cell: (cell) => date.format.e(cell?.date || cell?.modified),
    },
    {
        header: 'Description',
        cell: (cell) => `${DESCRIPTION[cell.eventType]?.(cell)} ${createString(cell.userName, 'by')}`,
    },

    {
        header: '',
        cell: (cell) =>
            !!cell.children && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <Button type="primary" htmlType="button" onClick={() => openEditModal(cell)}>
                        Edit
                    </Button>
                </div>
            ),
    },
];
