import React from 'react';
import $auth from 'stores/auth';
import BookingDiscardButton from './booking-discard-button';
import CancelManuallyButton from './cancel-manually-button';
import ConfirmManuallyButton from './confirm-manually-button';
import RejectManuallyButton from './reject-manually-button';
import BookingCancelButton from './booking-cancel-button';
import CompletePaymentManuallyButton from './complete-payment-manually-button';
import DownloadDocumentButton from './download-document-button';
import { Space } from 'antd';
import { BOOKING_STATUS, PAYMENT_STATUS } from 'htcore/enum';
import OfflineBookingCancelButton from '../offline-bookings/offline-booking-cancel-button';

const BookingActions = ({ isOffline, status, booking, loadBooking, match, paymentStatus }) => {
    const isPermittedBookingManagement = $auth.permitted('BookingManagement');
    const isPermittedViewPaxNames = $auth.permitted('ViewPaxNames');
    const isWithCancellationFee =
        booking?.appliedCancellationFee?.amount > 0 && status === BOOKING_STATUS.Cancelled;

    const isStatusWithInvoice = ![
        BOOKING_STATUS.PendingCancellation,
        BOOKING_STATUS.ManualCorrectionNeeded,
        BOOKING_STATUS.Rejected,
        BOOKING_STATUS.Discarded,
    ].includes(status);

    const isCompleted = status === BOOKING_STATUS.Confirmed || status === BOOKING_STATUS.Completed;

    return (
        <Space direction="horizontal" wrap style={{ marginTop: -20, marginBottom: 40 }}>
            {!isOffline &&
                isPermittedBookingManagement &&
                ((status === BOOKING_STATUS.Confirmed &&
                    (paymentStatus === PAYMENT_STATUS.NotPaid ||
                        paymentStatus === PAYMENT_STATUS.Invoiced)) ||
                    status === BOOKING_STATUS.Pending) && (
                    <BookingCancelButton booking={booking} loadBooking={loadBooking} match={match} />
                )}

            {isOffline && isPermittedBookingManagement && status === BOOKING_STATUS.Confirmed && (
                <OfflineBookingCancelButton
                    onlyWithoutCharges={
                        paymentStatus === PAYMENT_STATUS.NotPaid ||
                        paymentStatus === PAYMENT_STATUS.Authorized
                    }
                    loadBooking={loadBooking}
                />
            )}
            {isPermittedBookingManagement && status === BOOKING_STATUS.ManualCorrectionNeeded && (
                <CancelManuallyButton booking={booking} loadBooking={loadBooking} />
            )}
            {!isOffline &&
                isPermittedBookingManagement &&
                (status === BOOKING_STATUS.Confirmed ||
                    status === BOOKING_STATUS.Pending ||
                    status === BOOKING_STATUS.ManualCorrectionNeeded) && (
                    <BookingDiscardButton booking={booking} loadBooking={loadBooking} match={match} />
                )}
            {$auth.permitted('OfflinePayment') &&
                status === BOOKING_STATUS.Confirmed &&
                paymentStatus === PAYMENT_STATUS.NotPaid && (
                    <CompletePaymentManuallyButton
                        booking={booking}
                        loadBooking={loadBooking}
                        match={match}
                    />
                )}
            {status === BOOKING_STATUS.ManualCorrectionNeeded && (
                <ConfirmManuallyButton booking={booking} loadBooking={loadBooking} match={match} />
            )}
            {status === BOOKING_STATUS.ManualCorrectionNeeded && (
                <RejectManuallyButton booking={booking} loadBooking={loadBooking} />
            )}
            {isPermittedViewPaxNames &&
                isCompleted &&
                (booking.paymentMethod === 'VirtualAccount' ||
                    PAYMENT_STATUS.Invoiced === booking.paymentStatus ||
                    PAYMENT_STATUS.Authorized === booking.paymentStatus ||
                    isOffline) && (
                    <DownloadDocumentButton
                        type="voucher"
                        booking={booking}
                        allButton={booking.itineraryBookingsCount > 1}
                    />
                )}

            {(isWithCancellationFee || (isPermittedViewPaxNames && isStatusWithInvoice && isCompleted)) && (
                <DownloadDocumentButton
                    type="invoice"
                    booking={booking}
                    allButton={booking.itineraryBookingsCount > 1}
                />
            )}
        </Space>
    );
};

export default BookingActions;
