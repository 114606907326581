import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HotelsList from './hotels-list';
import CustomHotelsList from './custom-hotels-list';

const AgencyHotels = () => (
    <Routes>
        <Route path="/" element={<HotelsList />} />
        <Route path="/:agentId" element={<CustomHotelsList />} />
    </Routes>
);

export default AgencyHotels;
