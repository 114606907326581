import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { Typography, Timeline, Space } from 'antd';

const { Title } = Typography;

const DefaultBufferHistory = ({ history }) => {
    if (!history) return null;

    return (
        <Space direction="vertical" size="large" style={{ marginTop: 60 }}>
            <Title level={4}>History of Default Conversion Buffer</Title>

            <Timeline>
                {history?.map((item, index) => (
                    <Timeline.Item key={index} color={index ? 'gray' : 'green'}>
                        <b>{date.format.a(item.date)}</b>{' '}
                        <span style={{ color: '#888', marginRight: 3 }}>
                            at {date.format.time(item.date)}.
                        </span>
                        <b>
                            Edit from {item.oldBuffer}% to {item.newBuffer}%
                        </b>{' '}
                        {!!item.userId && (
                            <span>
                                by{' '}
                                <Link
                                    target="_blank"
                                    className="link"
                                    to={`/administrators/${item.userId}/details`}
                                >
                                    Admin: {item.userName}
                                </Link>
                            </span>
                        )}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Space>
    );
};

export default DefaultBufferHistory;
