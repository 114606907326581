import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from 'legacy';
import { API } from 'htcore';
import apiGifuMethods from 'core/gifu-methods';
import { Button, Form, Table, Typography } from 'antd';
import { EntitySelector } from 'common/entity-selector';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import $auth from 'stores/auth';
import ProvidersHistory from './providers-history';

const { Title } = Typography;

const getColumns = (list, columnsFunc) => {
    return list?.length
        ? columnsFunc().map((column) => ({
              title: column.header,
              ...(typeof column.cell === 'string'
                  ? { dataIndex: column.cell }
                  : { render: (text, record) => column.cell(record) }),
              key: typeof column.cell === 'string' ? column.cell : column.title,
          }))
        : null;
};

const getOptions = (list) => list.map((_, index) => ({ label: index + 1, value: index + 1 }));

export const columns = (options) => [
    {
        header: 'Provider',
        cell: ({ provider }) => provider,
    },
    {
        header: 'Priority',
        cell: ({ provider }) => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                    <Form.Item noStyle name={provider}>
                        <EntitySelector value={getFieldValue(provider.toString())} options={options} />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
];

const ProvidersPage = () => {
    const [form] = Form.useForm();
    const formValues = Form.useWatch([], form);

    const [list, setList] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [loading, setLoading] = useState(false);

    const [history, setHistory] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const getData = () => {
        setLoading(true);

        API.get({
            gifu: apiGifuMethods.vendorsPriorities,
            success: ({ priorities, logs }) => {
                setInitialValues(priorities);
                setList(Object.keys(priorities).map((provider) => ({ provider })));
                setHistory(logs);
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const saveChanges = async () => {
        setLoading(true);

        API.post({
            gifu: apiGifuMethods.vendorsPriorities,
            body: {
                administratorName: $auth.fullName,
                administratorId: $auth?.information?.id,
                priorities: form.getFieldsValue(),
            },
            success: () => {
                getData();
                setOpenModal(false);
            },
            after: () => {
                setLoading(false);
            },
        });
    };

    const isDisableButton = useMemo(() => {
        const allValues = Object.values(formValues || {});

        return allValues.some((item, index, array) => array.indexOf(item) !== index);
    }, [formValues]);

    if (!initialValues) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Provider List
            </Title>
            <Form form={form} initialValues={initialValues} layout="vertical">
                <Table
                    pagination={false}
                    dataSource={list || []}
                    columns={getColumns(list, () => columns(getOptions(list)))}
                    loading={loading}
                    rowKey={(_, index) => index}
                    locale={{
                        emptyText: loading ? <Loader /> : 'Nothing to Show',
                    }}
                    style={{ marginBottom: 40 }}
                />
            </Form>

            <Button disabled={isDisableButton} onClick={() => setOpenModal(true)}>
                Save Changes
            </Button>

            <ConfirmationModal
                visible={openModal}
                onCancel={() => setOpenModal(false)}
                headerText="Save Changes"
                submitText="Save Changes"
                reason={false}
                onSubmit={saveChanges}
            >
                Do you want to save VCC Provider Priority changes?
            </ConfirmationModal>

            <ProvidersHistory history={history} />
        </>
    );
};

export default ProvidersPage;
