import React, { useState } from 'react';
import { PageHeader, Space, Button } from 'antd';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import NotificationsModal from './notifications-modal';

const MODAL_TYPES = {
    DEFAULT: '',
    RELEASE: 'release',
    MAINTENANCE: 'maintenance',
};

const NotificationsPage = () => {
    useTitle('Notifications');

    const [openModalType, setOpenModalType] = useState(MODAL_TYPES.DEFAULT);

    const handleCloseModal = () => setOpenModalType(MODAL_TYPES.DEFAULT);

    const sendEmail = (body, isRealise) => {
        const url = isRealise ? apiMethods.newslettersRelease() : apiMethods.newslettersMaintenance();

        API.post({
            url,
            body,
            success: () => notifications('Messages are sent!', 'success'),
        });
    };

    const getModalOpen = (type) => type === openModalType;

    return (
        <>
            <PageHeader title="Notifications" />

            <Space size="large">
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => setOpenModalType(MODAL_TYPES.RELEASE)}
                >
                    Send Release notifications
                </Button>

                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => setOpenModalType(MODAL_TYPES.MAINTENANCE)}
                >
                    Send Maintenance notification
                </Button>
            </Space>

            <NotificationsModal
                isOpen={getModalOpen(MODAL_TYPES.RELEASE)}
                title="Upcoming Release and Possible Technical Disruptions"
                startMessage={
                    <Space direction="vertical">
                        <p>Dear Partners,</p>
                        <p>
                            We hope this message finds you well. We wanted to inform you about an upcoming
                            release that may affect our systems.
                        </p>
                    </Space>
                }
                endMessage={
                    <Space direction="vertical">
                        <p>
                            (UTC+04:00), we will be deploying a new version of our platform. These changes are
                            part of our ongoing commitment to improving your experience with our services.
                        </p>
                        <p>
                            If you have any questions or require further information, please do not hesitate
                            to contact our support team at support@happytravel.com.
                        </p>
                        <p>Thank you for choosing us.</p>
                        <p>
                            Sincerely, <br /> The HappyTravel Tech Team
                        </p>
                    </Space>
                }
                handleClose={handleCloseModal}
                sendEmail={(values) => sendEmail(values, true)}
            />

            <NotificationsModal
                isOpen={getModalOpen(MODAL_TYPES.MAINTENANCE)}
                title="Scheduled Maintenance Works"
                startMessage={
                    <Space direction="vertical">
                        <p>Dear Partners,</p>
                        <p>
                            We hope this message finds you well. We would like to inform you about scheduled
                            maintenance works that are planned to take place
                        </p>
                    </Space>
                }
                endMessage={
                    <Space direction="vertical">
                        <p>
                            (UTC+04:00). These maintenance activities are essential for maintaining the
                            stability and security of our systems. During this maintenance period, there may
                            be temporary disruptions to our services and access to our platform may be briefly
                            unavailable.
                        </p>
                        <p>
                            We apologize for any inconvenience this may cause and appreciate your
                            understanding as we work to enhance the reliability of our systems.
                        </p>
                        <p>
                            Should you have any questions or require further information, please do not
                            hesitate to contact our support team at support@happytravel.com.
                        </p>
                        <p>Thank you for choosing us.</p>
                        <p>
                            Sincerely, <br /> The HappyTravel Tech Team
                        </p>
                    </Space>
                }
                handleClose={handleCloseModal}
                sendEmail={sendEmail}
            />
        </>
    );
};

export default NotificationsPage;
