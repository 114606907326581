import React from 'react';
import { Image, Space } from 'antd';

const Picture = ({ item }) => {
    if (!item?.sourceUrl) return null;

    return (
        <div style={{ width: 200, height: 150, overflow: 'hidden' }}>
            <Image width={200} src={item?.sourceUrl} />
        </div>
    );
};

const Gallery = ({ pictures }) => {
    if (!pictures?.length) return null;

    return (
        <Space wrap size="small" style={{ marginTop: 50 }}>
            {pictures.slice(0, 16).map((picture, index) => (
                <Picture item={picture} key={index} />
            ))}
        </Space>
    );
};

export default Gallery;
