import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { Typography, Timeline, Space } from 'antd';

const { Title } = Typography;

const AccountHistory = ({ history }) => {
    if (!history?.length) return null;

    return (
        <Space direction="vertical" size="large" style={{ marginTop: 60 }}>
            <Title level={4}>History of Default Conversion Buffer</Title>

            <Timeline>
                {history?.map((item, index) => (
                    <Timeline.Item key={index} color={index ? 'gray' : 'green'}>
                        <b>{date.format.a(item.created)}</b>{' '}
                        <span style={{ color: '#888', marginRight: 3 }}>
                            at {date.format.time(item.created)}.
                        </span>
                        <b>
                            {item.type} {item.accountNumber}
                        </b>{' '}
                        <span>
                            from {item.changes.ConversionBuffer.Before || 0}% to{' '}
                            {item.changes.ConversionBuffer.Current || 0}%
                        </span>
                        {!!item.administratorName && (
                            <span>
                                {' '}
                                by{' '}
                                <Link
                                    target="_blank"
                                    className="link"
                                    to={`/administrators/${item.administratorId}/details`}
                                >
                                    Admin: {item.administratorName}
                                </Link>
                            </span>
                        )}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Space>
    );
};

export default AccountHistory;
