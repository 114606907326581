import React, { useState } from 'react';
import { Loader } from 'legacy';
import { Form, Row, Table as AntdTable, Select, Input, Space } from 'antd';

const Table = ({
    columns,
    list,
    textEmptyResult,
    textEmptyList,
    filter,
    sorters,
    searches,
    CustomFilter = null,
    CustomSearch = null,
    searchText,
    rowKey = 'id',
    style,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState(0);

    columns = columns.map((column) => ({
        title: column.header,
        ...(typeof column.cell === 'string'
            ? { dataIndex: column.cell }
            : { render: (text, record) => column.cell(record) }),
        key: typeof column.cell === 'string' ? column.cell : column.title,
    }));

    const searcher = (result) => {
        if (searchQuery) {
            result = result.filter((item) => {
                if (typeof item != 'object') {
                    return false;
                }
                const found = (values) => {
                    const queries = searchQuery
                        .toLowerCase()
                        .split(' ')
                        .filter((v) => v.length);
                    for (let i = 0; i < queries.length; i++) {
                        if (
                            !values.some(
                                (v = '') => typeof v == 'string' && v.toLowerCase().indexOf(queries[i]) >= 0
                            )
                        ) {
                            return false;
                        }
                    }
                    return true;
                };
                return found(searches(item));
            });
        }
        return result;
    };

    const sorter = (result) => {
        if (!sorters) return result;

        result.sort((a, b) => {
            const func = sorters[sortBy].sorter;
            let x = func(a);
            let y = func(b);

            if (x?.toLowerCase) x = x.toLowerCase();
            if (y?.toLowerCase) y = y.toLowerCase();
            if (x < y) return 1;
            if (x > y) return -1;
            return 0;
        });
        return result;
    };

    const getResult = () => {
        if (!list?.length) return [];
        let result = list.filter(() => true);
        if (filter) result = filter(result);
        result = sorter(result);
        result = searcher(result);
        return result;
    };

    const result = getResult();

    return (
        <>
            <Row justify="space-between">
                <div>
                    {sorters && (
                        <Form layout="vertical">
                            <Form.Item label="Sort by">
                                <Select
                                    options={sorters.map((item, index) => ({
                                        label: item.title,
                                        value: index,
                                    }))}
                                    onChange={(value) => setSortBy(value)}
                                    style={{ minWidth: 200 }}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Form>
                    )}
                    {CustomFilter}
                </div>
                {searches && (
                    <Form layout="vertical">
                        <Space>
                            <Form.Item label={searchText || 'Search'}>
                                <Input
                                    allowClear
                                    onChange={(event) => setSearchQuery(event.target.value)}
                                    placeholder="Enter Search Text"
                                />
                            </Form.Item>
                            {CustomSearch}
                        </Space>
                    </Form>
                )}
            </Row>
            {list === null ? (
                <Loader />
            ) : (
                <AntdTable
                    dataSource={result}
                    columns={columns}
                    pagination={false}
                    rowKey={rowKey}
                    locale={{
                        emptyText: list?.length ? textEmptyResult : textEmptyList || textEmptyResult,
                    }}
                    style={style}
                />
            )}
        </>
    );
};

export default Table;
