import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { date, Loader } from 'legacy';
import { API, localStorage } from 'htcore';
import { BOOKING_STATUS } from 'htcore/enum';
import apiMethods from 'core/methods';
import apiMapperMethods from 'core/mapper-methods';
import sunpuMethods from 'core/sunpu-methods';
import { Form, Typography, Row, Input, Button, Space } from 'antd';
import { notifications } from 'components/notification';
import { serviceTypeOptions } from 'components/selectors/service-type-selector';
import TotalBuyingPrice from './total-buying-price';
import TotalTable from './total-table';
import BookingDetailsForm from './booking-details-form';
import RateDetails from '../rate-details';
import useCurrencies from '../use-currencies';

const { Title } = Typography;
const { TextArea } = Input;

const DUPLICATE_SERVICE_LS_KEY = 'duplicated_meal_service';

const countPassengers = (passengers) =>
    passengers.reduce((acc, { age }) => {
        if (age === null || age > 11) {
            acc.adults = (acc.adults || 0) + 1;
        } else {
            acc.children = (acc.children || 0) + 1;
        }
        return acc;
    }, {});

const createRoomsData = (rooms) =>
    rooms.map(({ passengers, roomReferenceCode: roomCode, contractName: roomName }) => {
        const { firstName, lastName, title } = passengers?.find((item) => item.isLeader);
        const { adults, children: childrenNumber } = countPassengers(passengers);

        return { roomCode, roomName, adults, childrenNumber, title, firstName, lastName };
    });

const getTotalPrice = (rooms = []) => rooms.reduce((acc, cur) => acc.plus(cur?.price || 0), new BigNumber(0));

const mapDataToPayload = (data) => {
    const {
        isNonRefundable,
        type,
        deadlineDate,
        rangeDates,
        nationality,
        residency,
        supplierCurrency: currency,
        rooms,
        markupValue,
        fixedMarkupAmount,
        supplierCode,
        ...rest
    } = data;

    return {
        ...rest,
        supplierCode: supplierCode || 'notContracted',
        isNonRefundable: !!isNonRefundable,
        type: type.value || type,
        deadline: isNonRefundable ? null : date.format.apiDateOnly(deadlineDate),
        checkInDate: date.format.apiDateOnly(rangeDates[0]),
        checkOutDate: date.format.apiDateOnly(rangeDates[1]),
        nationality: nationality.value,
        residency: residency.value,
        currency,
        markupValue: markupValue || 0,
        fixedMarkupAmount: fixedMarkupAmount || 0,
        rooms: rooms.map(({ childrenNumber, adults, price, ...room }) => ({
            ...room,
            startDate: date.format.apiDateOnly(rangeDates[0]),
            endDat: date.format.apiDateOnly(rangeDates[1]),
            children: childrenNumber || 0,
            adults: adults || 0,
        })),
    };
};

const OfflineBookingService = ({ serviceName, serviceForm }) => {
    const { action, referenceCode } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [isLoading, setLoading] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const rooms = Form.useWatch('rooms', form);

    const creation = useMemo(() => action === 'create' || action === 'duplicate', [action]);
    const duplicate = useMemo(() => action === 'duplicate', [action]);

    const { getCurrenciesData, exchangeRate } = useCurrencies({ form, duplicate, booking: serviceData });

    const getCountryData = (countryCode) =>
        new Promise((res) => {
            API.get({
                nakijin: apiMapperMethods.searchLocalitiesByCode(countryCode),
                success: ({ name, code }) => res({ label: name, value: code }),
                error: () => res({}),
            });
        });

    const getSupplierCode = (supplierName) =>
        new Promise((res) => {
            if (supplierName === 'notContracted') {
                res('nonContracted');
                return;
            }

            API.get({
                sunpu: sunpuMethods.adminSuppliers,
                success: (data) => res(data.find((item) => item.name === supplierName)?.code || null),
                error: () => res(null),
            });
        });

    const validateBooking = ({ bookingDetails }) => {
        if (bookingDetails?.status !== BOOKING_STATUS.Confirmed) {
            notifications('Booking must have status "Confirmed"', 'error');
            return;
        }

        if (
            moment(bookingDetails.checkInDate).isBefore(
                moment().add(-1, 'days').set({ hour: 23, minute: 59 })
            )
        ) {
            notifications('Booking Check-in date must be from today or from the future', 'error');
            return;
        }

        return true;
    };

    const updateSelectedBooking = async (data) => {
        if (creation && !duplicate && !validateBooking(data)) return;

        const [nationality, residency, supplierCode] = await Promise.all([
            getCountryData(data?.bookingDetails?.nationality),
            getCountryData(data?.bookingDetails?.residence),
            getSupplierCode(data.supplier),
        ]);

        form.setFieldsValue({
            customerCurrency: data.totalPrice.currency,
            ...(creation &&
                !duplicate && {
                    contracted: supplierCode === 'notContracted' ? 'nonContracted' : 'contracted',
                    supplierCode,
                    nationality,
                    residency,
                    rooms: createRoomsData(data?.bookingDetails?.roomDetails),
                    rangeDates: [
                        moment(data?.bookingDetails.checkInDate),
                        moment(data?.bookingDetails.checkOutDate),
                    ],
                }),
        });
        setSelectedBooking(data);
    };

    const getBooking = (refCode) => {
        setSelectedBooking(null);
        API.get({
            url: apiMethods.bookingsByReferenceCode(refCode),
            success: updateSelectedBooking,
        });
    };

    const getServiceData = () => {
        API.get({
            url: apiMethods.mealService(referenceCode),
            success: async (result) => {
                const [nationality, residency] = await Promise.all([
                    getCountryData(result.nationality),
                    getCountryData(result.residency),
                ]);

                getBooking(result.bookingReferenceCode);

                setServiceData({
                    ...result,
                    type: serviceTypeOptions.find((item) => item.value === result.type),
                    nationality,
                    residency,
                    rooms: result.rooms.map(({ children, ...room }) => ({
                        childrenNumber: children,
                        price: room?.rateDetails?.supplierPrice?.amount,
                        ...room,
                    })),
                    fixedMarkupAmount: result?.fixedMarkupAmount?.amount || 0,
                    supplierCurrency: result.currency,
                    deadlineDate: result.deadline ? moment(result.deadline) : null,
                    rangeDates: [moment(result.checkInDate), moment(result.checkOutDate)],
                });
            },
        });
    };

    const load = () => {
        if (creation) return;
        getServiceData();
    };

    const loadDuplicatedData = () => {
        const savedData = JSON.parse(localStorage.get(DUPLICATE_SERVICE_LS_KEY));
        serviceForm.setFieldValue('serviceName', savedData?.serviceName || 'FoodSupplement');

        if (!savedData) {
            getServiceData();
            return;
        }

        if (savedData?.bookingReferenceCode) getBooking(savedData.bookingReferenceCode);

        const [dateFrom, dateTo] = savedData?.rangeDates || [];
        setServiceData({
            ...savedData,
            deadlineDate: savedData.deadline ? moment(savedData.deadline) : null,
            rangeDates: dateFrom && dateTo ? [moment(dateFrom), moment(dateTo)] : null,
        });
    };

    useEffect(() => {
        if (duplicate) {
            loadDuplicatedData();
            return;
        }
        load();
    }, [action, referenceCode]);

    const passengersNumber = useMemo(() => {
        return (rooms || []).reduce((acc, cur) => {
            acc.adults = (acc.adults || 0) + (cur.adults || 0);
            acc.children = (acc.children || 0) + (cur.childrenNumber || 0);
            return acc;
        }, {});
    }, [rooms]);

    const totalPrice = useMemo(() => getTotalPrice(rooms), [rooms]);

    const redirectToBooking = (refCode) => {
        localStorage.remove(DUPLICATE_SERVICE_LS_KEY);
        navigate(`/booking/${refCode}`);
    };

    const createRoomsPrices = (data) => {
        const rooms = data.rooms.map(({ price: amount, ...room }) => {
            const {
                supplierCurrency,
                customerCurrency,
                totalWithoutMarkup,
                totalInUsd,
                totalInCustomerCurrency,
            } = getCurrenciesData(amount);

            return {
                ...room,
                rateDetails: {
                    supplierPrice: {
                        amount,
                        currency: supplierCurrency,
                    },
                    supplierConvertedPrice: {
                        amount: totalWithoutMarkup,
                        currency: customerCurrency,
                    },
                    supplierUsdPrice: totalInUsd,
                    customerPrice: {
                        amount: totalInCustomerCurrency,
                        currency: customerCurrency,
                    },
                },
            };
        });
        return { ...data, rooms };
    };

    const submit = (_, onSuccess) => {
        form.validateFields().then(() => {
            const pricesData = createRoomsPrices(form.getFieldsValue(true));
            const body = mapDataToPayload(pricesData);

            setLoading(true);
            if (creation) {
                API.post({
                    url: apiMethods.createMealService,
                    body,
                    success: (refCode) => {
                        notifications('Meal Service Created!', 'success');
                        onSuccess ? onSuccess(refCode) : redirectToBooking(refCode);
                    },
                    after: () => setLoading(false),
                });
            } else {
                API.put({
                    url: apiMethods.mealService(referenceCode),
                    body,
                    success: () => {
                        notifications('Meal Service Saved', 'success');
                        onSuccess ? onSuccess(referenceCode) : redirectToBooking(referenceCode);
                    },
                    after: () => setLoading(false),
                });
            }
        });
    };

    const confirmRequest = (refCode) => {
        setLoading(true);
        API.post({
            url: apiMethods.confirmMealService(refCode || referenceCode),
            success: () => {
                notifications('Booking Confirmed', 'success');
                redirectToBooking(refCode || referenceCode);
            },
            error: () => navigate(`/offline-bookings/food-supplement/edit/${refCode}`),
            after: () => setLoading(false),
        });
    };

    const confirmBooking = () => {
        if (form.isFieldsTouched() || creation) {
            submit(form.getFieldsValue(), confirmRequest);
            return;
        }

        confirmRequest();
    };

    const duplicateBooking = () => {
        localStorage.set(
            DUPLICATE_SERVICE_LS_KEY,
            JSON.stringify({ serviceName, ...form.getFieldsValue(true) })
        );
    };

    if ((!creation || duplicate) && !serviceData) {
        return <Loader />;
    }

    return (
        <>
            {isLoading && <Loader page />}
            <Form
                preserve
                form={form}
                layout="vertical"
                initialValues={
                    creation && !duplicate
                        ? {
                              contracted: 'contracted',
                          }
                        : {
                              ...serviceData,
                              contracted:
                                  serviceData.supplierCode === 'notContracted'
                                      ? 'nonContracted'
                                      : 'contracted',
                          }
                }
                onFinish={submit}
            >
                <BookingDetailsForm
                    creation={creation}
                    duplicate={duplicate}
                    form={form}
                    deadlineDate={serviceData?.creationDate}
                    selectedBooking={selectedBooking}
                    passengersNumber={passengersNumber}
                    getBooking={getBooking}
                />

                <TotalBuyingPrice creation={creation} />

                <TotalTable passengers={passengersNumber} totalPrice={totalPrice} />

                <RateDetails
                    form={form}
                    totalPrice={totalPrice}
                    exchangeRate={exchangeRate}
                    getCurrenciesData={getCurrenciesData}
                />

                <Title level={4}>Description</Title>

                <Row size="large">
                    <Form.Item name="description" label="Description" style={{ width: '100%' }}>
                        <TextArea placeholder="Enter Description" />
                    </Form.Item>
                </Row>

                <Space>
                    <Button type="primary" onClick={submit}>
                        {creation ? 'Create' : 'Save Changes'}
                    </Button>
                    <Button type="primary" onClick={confirmBooking}>
                        Confirm
                    </Button>

                    <Link
                        to={`/offline-bookings/food-supplement/duplicate/${referenceCode || ''}`}
                        className="button small"
                        target="_blank"
                    >
                        <Button type="primary" onClick={duplicateBooking}>
                            Duplicate
                        </Button>
                    </Link>
                </Space>
            </Form>
        </>
    );
};

export default OfflineBookingService;
