import React, { useEffect, useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import apiShimabaraMethods from 'core/shimabara-methods';
import { Col, PageHeader, Row } from 'antd';
import SearchTable from '../mapper/restrictions/search-table';
import LocalitiesTable from './localities/localities-table';

const DestinationMappingPage = () => {
    useTitle('Destination Mapping');

    const [list, setList] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [destinationList, setDestinationList] = useState(null);
    const [selectedDestination, setSelectedDestination] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.allCountries(),
            success: (result) => setList(result.map((item) => ({ ...item, id: item.code }))),
        });
    }, []);

    const onSelectCountry = (row) => {
        setSelectedDestination(null);
        setDestinationList(null);
        setSelectedCountry(row);

        API.get({
            shimabara: apiShimabaraMethods.destinations(row.id),
            success: (result) => {
                setDestinationList(result);
            },
        });
    };

    const onSelectDestination = (row) => setSelectedDestination(row);

    return (
        <>
            <PageHeader title="Destination Mapping" style={{ paddingBottom: 16 }} />

            <Row gutter={30} style={{ marginBottom: 40 }}>
                <Col span={!!destinationList?.length ? 12 : 24}>
                    <SearchTable
                        scroll={{ y: '30vh' }}
                        loading={list === null}
                        selectedRow={selectedCountry?.id}
                        tableTitle={`Countries List (${list?.length || 0})`}
                        list={list}
                        onRow={(record) => ({ onClick: () => onSelectCountry(record) })}
                    />
                </Col>
                {!!destinationList?.length && (
                    <Col span={12}>
                        <SearchTable
                            scroll={{ y: '30vh' }}
                            selectedRow={selectedDestination?.id?.toString()}
                            tableTitle={`Destinations for ${selectedCountry?.name} (${
                                destinationList?.length || 0
                            })`}
                            list={destinationList}
                            onRow={(record) => ({
                                onClick: () => onSelectDestination(record),
                            })}
                        />
                    </Col>
                )}
            </Row>

            {selectedCountry && (selectedDestination || destinationList?.length === 0) && (
                <Row>
                    <LocalitiesTable
                        selectedCountry={selectedCountry}
                        selectedDestination={selectedDestination}
                    />
                </Row>
            )}
        </>
    );
};

export default DestinationMappingPage;
