import React from 'react';
import { Link } from 'react-router-dom';
import { Button, PageHeader } from 'antd';
import Table from 'components/table';

const BanksListPage = ({ banks }) => (
    <>
        <PageHeader
            title="Company Account Banks List"
            extra={
                <Link to="./create">
                    <Button>Add a Bank</Button>
                </Link>
            }
        />
        <Table
            list={banks}
            columns={[
                {
                    header: 'ID',
                    cell: (cell) => <Link to={`/bank-details/${cell.id}/accounts`}>{cell.id}</Link>,
                },
                {
                    header: 'Name',
                    cell: (cell) => <Link to={`/bank-details/${cell.id}/accounts`}>{cell.name}</Link>,
                },
                {
                    header: 'Address',
                    cell: 'address',
                },
                {
                    header: 'Routing Code',
                    cell: 'routingCode',
                },
                {
                    header: 'Swift Code',
                    cell: 'swiftCode',
                },
            ]}
            textEmptyResult="Empty"
        />
    </>
);

export default BanksListPage;
