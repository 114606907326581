import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';

const ConfirmationSimpleModal = ({ children, onConfirm, submitText, visible, onCancel, headerText }) => {
    const [disabled, setDisabled] = useState(false);

    const confirm = () => {
        setDisabled(true);
        onConfirm();
    };

    useEffect(() => {
        if (!visible) {
            setDisabled(false);
        }
    }, [visible]);

    return (
        <Modal
            visible={visible}
            title={headerText}
            okText={submitText}
            onCancel={onCancel}
            footer={
                <>
                    <Button key="back" onClick={onCancel} className="modal-cancel-btn">
                        Cancel
                    </Button>
                    <Button onClick={confirm} disabled={disabled}>
                        {submitText}
                    </Button>
                </>
            }
        >
            {children}
        </Modal>
    );
};

export default ConfirmationSimpleModal;
